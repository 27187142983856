import React from "react";

import { MemoMultiFilterSelectInput } from "./filter-multi-select-input";
import { MemoFilterDateRangeInput } from "./filter-date-range-input";
import { MemoFilterCheckboxInput } from "./filter-checkbox-input";
import { MemoFilterSelectInput } from "./filter-select-input";
import { MemoFilterTextInput } from "./filter-text-input";

import * as FI from "../interfaces/filter-interfaces";

interface ISelectFilterConfig extends Omit<FI.IFilterSelectInputProps, "inputData"> {}
interface IMultiSelectFilterConfig extends Omit<FI.IFilterMultiSelectInputProps, "inputData"> {}
interface IDateFilterConfig extends Omit<FI.IFilterDateInputProps, "inputData"> {}
interface ICheckboxFilterConfig extends Omit<FI.IFilterCheckboxInputProps, "inputData"> {}

export const text = (inputData: FI.IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />;

export const number = (inputData: FI.IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} type="number" />;

export const select = (inputData: FI.IPrimeFilterData, filterConfig?: ISelectFilterConfig) => (
    <MemoFilterSelectInput inputData={inputData} {...filterConfig} />
);

export const multiSelect = (inputData: FI.IPrimeFilterData, filterConfig?: IMultiSelectFilterConfig) => (
    <MemoMultiFilterSelectInput inputData={inputData} {...filterConfig} />
);

export const date = (inputData: FI.IPrimeFilterData, filterConfig?: IDateFilterConfig) => (
    <MemoFilterDateRangeInput inputData={inputData} {...filterConfig} />
);

export const checkbox = (inputData: FI.IPrimeFilterData, filterConfig?: ICheckboxFilterConfig) => (
    <MemoFilterCheckboxInput inputData={inputData} {...filterConfig} />
);
