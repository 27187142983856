import { $, $wnd, $body } from './_utility';
/*------------------------------------------------------------------

  Init Transition Fix

-------------------------------------------------------------------*/

function initTransitionFix() {
  var timer = 0;
  var isYayShowed = false; // check if yay opened.

  $wnd.on('init.yaybar', function (e) {
    var data = $(e.target).data('yaybar');
    var defaultShowYay = data.showYay;
    var defaultHideYay = data.hideYay;

    data.showYay = function () {
      isYayShowed = true;
      defaultShowYay.call(data);
    };

    data.hideYay = function () {
      isYayShowed = true;
      defaultHideYay.call(data);
    };
  });
  $wnd.on('resize', function () {
    // if yay opened, we don't need to disable transitions.
    if (isYayShowed) {
      isYayShowed = false;
      return;
    }

    if (timer) {
      clearTimeout(timer);
      timer = null;
    } else {
      $body.addClass('rui-no-transition');
    }

    timer = setTimeout(function () {
      $body.removeClass('rui-no-transition');
      timer = null;
    }, 200);
  });
  $body.removeClass('rui-no-transition');
}

export { initTransitionFix };