type GtIcon = ILabelValue & { type: "gt" };

export const GT_ICONS: GtIcon[] = [
    {
        type: "gt",
        label: "active",
        value: "active",
    },
    {
        type: "gt",
        label: "add",
        value: "add",
    },
    {
        type: "gt",
        label: "alert",
        value: "alert",
    },
    {
        type: "gt",
        label: "archive",
        value: "archive",
    },
    {
        type: "gt",
        label: "block",
        value: "block",
    },
    {
        type: "gt",
        label: "blocked2",
        value: "blocked2",
    },
    {
        type: "gt",
        label: "calendar",
        value: "calendar",
    },
    {
        type: "gt",
        label: "certified",
        value: "certified",
    },
    {
        type: "gt",
        label: "check",
        value: "check",
    },
    {
        type: "gt",
        label: "create",
        value: "create",
    },
    {
        type: "gt",
        label: "decision",
        value: "decision",
    },
    {
        type: "gt",
        label: "decrease",
        value: "decrease",
    },
    {
        type: "gt",
        label: "default",
        value: "default",
    },
    {
        type: "gt",
        label: "delete",
        value: "delete",
    },
    {
        type: "gt",
        label: "diagnostic",
        value: "diagnostic",
    },
    {
        type: "gt",
        label: "draft",
        value: "draft",
    },
    {
        type: "gt",
        label: "edit",
        value: "edit",
    },
    {
        type: "gt",
        label: "effective",
        value: "effective",
    },
    {
        type: "gt",
        label: "empty_tube",
        value: "empty_tube",
    },
    {
        type: "gt",
        label: "empty_tubes",
        value: "empty_tubes",
    },
    {
        type: "gt",
        label: "experiment_flask",
        value: "experiment_flask",
    },
    {
        type: "gt",
        label: "half",
        value: "half",
    },
    {
        type: "gt",
        label: "in_use",
        value: "in_use",
    },
    {
        type: "gt",
        label: "inactive",
        value: "inactive",
    },
    {
        type: "gt",
        label: "info",
        value: "info",
    },
    {
        type: "gt",
        label: "lab_flask",
        value: "lab_flask",
    },
    {
        type: "gt",
        label: "lab_flask_2",
        value: "lab_flask_2",
    },
    {
        type: "gt",
        label: "lab_storage",
        value: "lab_storage",
    },
    {
        type: "gt",
        label: "lab_tube",
        value: "lab_tube",
    },
    {
        type: "gt",
        label: "login",
        value: "login",
    },
    {
        type: "gt",
        label: "logout",
        value: "logout",
    },
    {
        type: "gt",
        label: "money",
        value: "money",
    },
    {
        type: "gt",
        label: "negative",
        value: "negative",
    },
    {
        type: "gt",
        label: "notification",
        value: "notification",
    },
    {
        type: "gt",
        label: "notification_2",
        value: "notification_2",
    },
    {
        type: "gt",
        label: "pending",
        value: "pending",
    },
    {
        type: "gt",
        label: "pending_2",
        value: "pending_2",
    },
    {
        type: "gt",
        label: "pending_3",
        value: "pending_3",
    },
    {
        type: "gt",
        label: "production",
        value: "production",
    },
    {
        type: "gt",
        label: "question",
        value: "question",
    },
    {
        type: "gt",
        label: "receive",
        value: "receive",
    },
    {
        type: "gt",
        label: "recycle",
        value: "recycle",
    },
    {
        type: "gt",
        label: "repeat",
        value: "repeat",
    },
    {
        type: "gt",
        label: "rotated_tube",
        value: "rotated_tube",
    },
    {
        type: "gt",
        label: "send",
        value: "send",
    },
    {
        type: "gt",
        label: "service",
        value: "service",
    },
    {
        type: "gt",
        label: "service_2",
        value: "service_2",
    },
    {
        type: "gt",
        label: "start",
        value: "start",
    },
    {
        type: "gt",
        label: "stop",
        value: "stop",
    },
    {
        type: "gt",
        label: "submitted",
        value: "submitted",
    },
    {
        type: "gt",
        label: "timeout",
        value: "timeout",
    },
    {
        type: "gt",
        label: "timeout_2",
        value: "timeout_2",
    },
    {
        type: "gt",
        label: "transfer",
        value: "transfer",
    },
    {
        type: "gt",
        label: "view",
        value: "view",
    },
    {
        type: "gt",
        label: "virus",
        value: "virus",
    },
    {
        type: "gt",
        label: "wait",
        value: "wait",
    },
];
