import React, { createContext, useContext, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { useFetchData } from "hooks/useFetchData";
import { LocationSate } from "../content";

export const SamplingStepsContext = createContext<any>(null);

export const SamplingStepsContextProvider = ({ children }) => {
    const { state } = useLocation<LocationSate>();

    const params = useParams<{ id: string }>();

    const history = useHistory();

    const { data } = useFetchData<any>({
        url: !!state?.sample ? `samples/${params?.id}` : undefined,
    });

    const { samplingStartDt, samplingEndDt, equipments, description } = state?.sample || data?.data || {};

    const [activeStepIndex, setActiveStepIndex] = useState<number>(state?.activeStepIndex || 0);
    const [attachments, setAttachments] = useState<any[]>(state?.attachments || []);
    const [formData, setFormData] = useState<any>(
        state?.formData || {
            samplingStartDt: samplingStartDt,
            samplingEndDt: samplingEndDt,
            equipments: equipments?.map((eq) => eq.id) || [],
            description: description || "",
            address: "",
        }
    );
    const [errors, setErrors] = useState<IErrors>(state?.errors || {});

    const saveState = () => {
        history.replace(`/m/orders/sampling/${params.id}`, {
            activeStepIndex,
            attachments,
            formData,
            errors,
            sample: state?.sample,
        });
    };

    return (
        <SamplingStepsContext.Provider
            value={{
                activeStepIndex,
                setActiveStepIndex,
                attachments,
                setAttachments,
                formData,
                setFormData,
                errors,
                setErrors,
                saveState,
            }}
        >
            {children}
        </SamplingStepsContext.Provider>
    );
};

export const useSamplingSteps = (): IUseSamplingSteps => {
    const { activeStepIndex, setActiveStepIndex, attachments, setAttachments, formData, setFormData, errors, setErrors, saveState } =
        useContext(SamplingStepsContext);

    return {
        activeStepIndex,
        setActiveStepIndex,
        attachments,
        setAttachments,
        formData,
        setFormData,
        errors,
        setErrors,
        saveState,
    };
};

interface IUseSamplingSteps {
    activeStepIndex: number;
    setActiveStepIndex: React.Dispatch<React.SetStateAction<number>>;
    attachments: any[];
    setAttachments: React.Dispatch<React.SetStateAction<any[]>>;
    formData: any;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    errors: IErrors;
    setErrors: React.Dispatch<React.SetStateAction<IErrors>>;
    saveState: () => void;
}
