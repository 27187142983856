export type onPressType = "enter" | "tab";

export const findNextElement = (onPress: onPressType, ref, fieldName) => {
    let allRecords = ref.current?.getElementsByClassName("p-datatable-tbody")[0].children; // searching for all records currently displayed in the table
    allRecords = [...allRecords].filter((r) => !r?.className.includes("p-rowgroup-footer") && !r?.className.includes("p-rowgroup-header")); // in case if there is grouping enabled we have to filter it out from all records
    const currentRecordIndex = findCurrentRecord([...allRecords], "p-highlight"); // searching for currently selected record
    const currentEditFieldIndex = findChildIndex([...allRecords[currentRecordIndex].children], `column-${fieldName}`); // searching for currently selected input in current record

    let nextFoundEditField; // Our next input that we will return

    if (onPress == "enter" && allRecords) {
        const slicedAllRecords = allRecords.slice(currentRecordIndex + 1, allRecords.length);

        slicedAllRecords.some((record) => {
            const element = record?.children[currentEditFieldIndex].children[0].children[0];

            if (element?.id.includes("prime-input")) {
                nextFoundEditField = element;
                return true;
            }
        });
    } else if (onPress == "tab") {
        let newAllRecords = allRecords.slice(currentRecordIndex, allRecords.length);
        const firstRecordChildren = [...newAllRecords[0].children];
        const array = [
            { children: firstRecordChildren.slice(currentEditFieldIndex + 1, firstRecordChildren.length) },
            ...newAllRecords.slice(1, newAllRecords.length),
        ];

        array.some((record) => {
            return [...record.children].some((col) => {
                if (!col?.children[0]) return false;
                const element = col.children[0].children[0];

                if (col?.className.includes("editable-column") && element?.id.includes("prime-input")) {
                    nextFoundEditField = element;
                    return true;
                }
            });
        });
    }

    return {
        currentEditField: allRecords[currentRecordIndex]?.children[currentEditFieldIndex].children[0].children[0],
        nextFoundEditField,
    };
};

export const goToNextInput = ({ currentEditField, nextFoundEditField }) => {
    switch (nextFoundEditField?.id || "notFound") {
        case "prime-input-text":
            nextFoundEditField.select();
            break;
        case "prime-input-select":
            nextFoundEditField.click();
            break;
        case "prime-input-checkbox":
            nextFoundEditField.click();
            break;
        case "prime-input-date":
            nextFoundEditField.children[0].select()
            break;
        default:
            currentEditField.blur();
            break;
    }
};

const findCurrentRecord = (children, className) => {
    let childIndex = 0;
    children.find((col, index) => {
        if (col.className.includes(className)) {
            childIndex = index;
            return col;
        }
    });

    return childIndex;
};

const findChildIndex = (children, className) => {
    let childIndex = 0;
    children.find((col, index) => {
        if (col.className.includes("editable-column")) {
            if (className == col.className.slice(col.className.indexOf("editable-column") + "editable-column".length + 1)) {
                childIndex = index;
                return col;
            }
        }
    });

    return childIndex;
};
