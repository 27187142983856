/**
 * External Dependencies
 */
import React from 'react';

/**
 * Internal Dependencies
 */
import { OrderTestReportsContextProvider } from './context/test-reports-context';
import AsyncComponent from '../../../components/async-component';
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from '../../../components/page-content';
import PageWrap from '../../../components/page-wrap';
/**
 * Component
 */
class OrderTestReportsPage extends React.Component {
    componentDidMount = updateBreadcrumb

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <OrderTestReportsContextProvider>
                        <AsyncComponent component={ () => import( './content' ) } />
                    </OrderTestReportsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default OrderTestReportsPage;
