import React, { useEffect, useState } from "react";

import { Col, Label, Modal, ModalBody, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Axios from "axios";

import { parseSuperscriptToString } from "../../../superscript/superscript-display";
import { sweetConfirm } from "../../../sweet-alert/sweetConfirm";
import { IMeta } from "../../interfaces/table-interfaces";
import { handleRequest } from "utils/handleRequest";
import { deprecated_useFormData } from "hooks/useFormData";
import addToast from "utils/addToast";
import { GT } from "components/index";

interface IProps {
    selectedRecord?: any;
    closeModal: () => void;
    meta: IMeta;
}

interface ITranslateData {
    defaultLanguage?: {
        label: string;
        value: string;
    };
    model: string;
    languages: {
        label: string;
        value: string;
    }[];
    translatableFields: string[];
    translations?: any;
}

interface IFormData {
    ids?: any;
    values?: any;
}

const labelStyle = {
    fontWeight: 700,
    fontSize: "1.2rem",
};

export const PrimeTranslateModal = ({ selectedRecord, closeModal, meta }: IProps) => {
    const { t } = useTranslation();

    const { language } = useSelector((state) => ({ language: state.auth.language }));

    const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
    const [formData, setFormData] = useState<IFormData>({ ids: {}, values: {} });
    const [errors, setErrors] = useState<IErrors>({});

    const { formData: languagesData } = deprecated_useFormData<ITranslateData>({
        url: `bs/translations-form-data/${meta.ModelInfo?.app}/${meta.ModelInfo?.model}`,
    });

    useEffect(() => {
        selectedLanguage && getTranslations(selectedLanguage);
    }, []);

    const handleSubmitData = async () => {
        const payload = {
            inputData: Object.keys(formData?.values).map((key) => ({
                language: selectedLanguage,
                text: formData.values[key],
                field: key,
            })),
        };

        await handleRequest({
            request: () => Axios.put(`/bs/translations/${meta.ModelInfo?.app}/${meta.ModelInfo?.model}/${selectedRecord?.id}`, payload),
            onSuccess: () => {
                addToast({ title: "Translations saved successfully" });
                closeModal();
            },
            onError: () => {
                addToast({ title: "Something went wrong while saving translations", color: "danger" });
            },
            setErrors,
        });
    };

    const handleSelectChange = async (e) => {
        const { value: _language } = e.target;

        await getTranslations(_language);

        setSelectedLanguage(_language);
    };

    const getTranslations = async (_language) => {
        await handleRequest({
            request: () =>
                Axios.get(`/bs/translations-form-data/${meta.ModelInfo?.app}/${meta.ModelInfo?.model}/${selectedRecord?.id}/${_language}`),
            onSuccess: (response) => {
                const tempFormData = { ids: {}, values: {} };

                response.data.translations.map((translation) => {
                    tempFormData.values[translation.field] = translation.text;
                    tempFormData.ids[`${translation.field}Id`] = translation.id;
                });

                setFormData({ ...tempFormData });
            },
            onError: () => {
                addToast({ title: "Something went wrong while changing language", color: "danger" });
            },
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, values: { ...prevState.values, [name]: value } }));
    };

    const handleDeleteTranslation = async (field) => {
        if (!formData?.ids?.[`${field}Id`]) return;
        if (!(await sweetConfirm("Delete translation", "Do you want to delete?"))) return;

        try {
            await Axios.delete(`/bs/translations/${formData?.ids?.[`${field}Id`]}`);

            const tempFormData = JSON.parse(JSON.stringify(formData));
            delete tempFormData.values[field];
            delete tempFormData.ids[`${field}Id`];

            setFormData(tempFormData);
        } catch (err: any) {
            if (err.response?.status == "400") setErrors(err.response.data);
            addToast({ title: "Something went wrong while deleting translation", color: "danger" });
        }
    };

    return (
        <Modal isOpen={true} toggle={closeModal} fade>
            <GT.Form permCode="" disableTransactions onSubmit={handleSubmitData}>
                <GT.ModalHeader closeModal={closeModal} name="Translations" />
                <ModalBody>
                    <Row className="d-flex justify-content-center">
                        <Col sm={6}>
                            <Label style={{ fontSize: "1.2rem" }}>{t("Source language")}</Label>
                        </Col>
                        <Col sm={6}>
                            <GT.PrimeSelect
                                options={languagesData?.languages?.filter(
                                    (language) => language?.value != languagesData?.defaultLanguage?.value
                                )}
                                onChange={handleSelectChange}
                                value={selectedLanguage}
                                showClear={false}
                                filterEnabled={false}
                                errors={errors?.language}
                                translateOptions
                            />
                        </Col>
                    </Row>
                    {languagesData?.translatableFields.map(
                        (field) =>
                            selectedRecord?.[field] && (
                                <Row key={`translation-${field}`} className="mb-10">
                                    <Col sm={6} className="d-flex flex-column">
                                        <Label style={{ fontSize: "1.1rem" }} className="mb-0">
                                            {t(field[0].toUpperCase() + field.slice(1))}
                                        </Label>
                                        <Label style={labelStyle}>{parseSuperscriptToString(selectedRecord?.[field])}</Label>
                                    </Col>
                                    <GT.PrimeInputGroup
                                        noLabel
                                        className="mb-0 align-items-end col"
                                        name={field}
                                        type={field != "description" ? "text" : "textarea"}
                                        labelStyle={{
                                            wordBreak: "break-all",
                                            alignItems: "flex-start",
                                        }}
                                        value={formData?.values?.[field] ? formData?.values?.[field] : ""}
                                        handleChange={handleInputChange}
                                        placeholder="Translation..."
                                        disabled={
                                            !!languagesData?.defaultLanguage?.value &&
                                            selectedLanguage == languagesData?.defaultLanguage?.value
                                        }
                                        rightButton={
                                            <GT.Button
                                                icon={{ name: "trash" }}
                                                onClick={() => handleDeleteTranslation(field)}
                                                className={`textarea-inputgroup ${field}`}
                                            />
                                        }
                                    />
                                </Row>
                            )
                    )}
                </ModalBody>
                <GT.ModalFooter closeModal={closeModal} cancelName="Cancel" okName="Save" />
            </GT.Form>
        </Modal>
    );
};
