export const hasRemarks = (status: string): string => {
    switch (status) {
        case "active":
            return "pi pi-flag active ";
        case "withdrawn":
            return "pi pi-flag ";
        default:
            return "pi pi-ellipsis-h ";
    }
};
