import { ReactNode, RefObject } from "react";

import { FrozenColumnType, IGeneratedPrimeTableColumn, IPrimeTableColumn } from "../interfaces/table-interfaces";
import { DataTableColumnResizeEndParams } from "primereact/datatable";

// Sums columns width and returns true if sum is bigger then table container
export const isHorizontalScrollEnabled = (
    tableRef: RefObject<HTMLDivElement>,
    visibleColumns: IGeneratedPrimeTableColumn[] | undefined = []
): boolean => {
    if (!tableRef?.current) return false;

    let columnsWidth = 0;
    visibleColumns.forEach((col) => (columnsWidth += parseFloat(col.width)));
    return columnsWidth > tableRef.current.offsetWidth;
};

// return true if rows container (does not count paginator and header) is bigger then TABLE_SCROLL_HEIGHT_BREAKPOINT
export const isVerticalScrollEnabled = (
    tableRef: RefObject<HTMLDivElement>,
    tableScrollHeightBreakpoint: number,
    filtersEnabled: boolean
): boolean => {
    if (!tableRef?.current) return false;

    let offsetHeight: number = (tableRef.current.getElementsByClassName("p-datatable-tbody")[0] as HTMLElement)?.offsetHeight;

    if (filtersEnabled) offsetHeight += 32;
    return (offsetHeight && offsetHeight > tableScrollHeightBreakpoint) || false;
};

// Calculates columns width after user hides or shows columns
export const mapColumnsWidth = (
    newVisibleColumns: IGeneratedPrimeTableColumn[],
    tableRef: RefObject<HTMLDivElement>
): IGeneratedPrimeTableColumn[] => {
    if (!tableRef?.current) return newVisibleColumns;

    const tableWidth: number = tableRef.current.offsetWidth;
    let newColumnsWidth = 0;
    newVisibleColumns.forEach((item) => (newColumnsWidth += parseFloat(item.width)));

    if (tableWidth > newColumnsWidth) {
        const _newVisibleColumns = [...newVisibleColumns];
        const lastColumnIndex = _newVisibleColumns.length - 1;
        const lastColumnWidth = tableWidth - newColumnsWidth + parseFloat(_newVisibleColumns[lastColumnIndex].width);

        _newVisibleColumns[lastColumnIndex] = { ..._newVisibleColumns[lastColumnIndex], width: `${lastColumnWidth}px` };
        return _newVisibleColumns;
    }

    return newVisibleColumns;
};

// Generates columns
export const mapColumnsOnInit = (
    tableRef: RefObject<HTMLDivElement>,
    columns: IPrimeTableColumn[],
    t: Function | undefined,
    minimumColWidth: number
): IGeneratedPrimeTableColumn[] => {
    let columnsCount = columns.length;

    if (!tableRef.current || columnsCount == 0) return [];

    let tableWidth = tableRef.current.offsetWidth;

    columns.forEach((col) => {
        if (col?.width) {
            tableWidth = tableWidth - col.width;
            columnsCount--;
        }
    });

    // check if calculated columns width is smaller then min column width, if it is, then single column width is equal to min column width
    const singleColumnWidth = tableWidth / columnsCount < minimumColWidth ? minimumColWidth : tableWidth / columnsCount;

    return columns.map((col) => ({
        ...col,
        header: t && !col.noHeaderTranslation ? t(col.header) : col.header,
        width: `${col?.width ? col?.width : singleColumnWidth}px`,
    }));
};

export const handleColumnResize = (
    visibleColumns: IGeneratedPrimeTableColumn[],
    event: DataTableColumnResizeEndParams,
    tableRef: RefObject<HTMLDivElement>,
    frozenColumn: FrozenColumnType
): IGeneratedPrimeTableColumn[] => {
    if (!tableRef?.current) return visibleColumns;

    const headers = tableRef.current.getElementsByClassName("p-datatable-thead");
    let headerColumns = [...headers[headers.length - 1].children[0].children];
    let _visibleColumns: IGeneratedPrimeTableColumn[] = [];
    let resizedColIndex: number = 0;

    // if there is a column that is frozen, then we need to take that frozen column and put it in headerColumns array
    if (!!frozenColumn) {
        const frozenHeaderColumn = headers[0].children[0].children[0];

        headerColumns = [
            ...headerColumns.slice(0, frozenColumn.originalPosition),
            frozenHeaderColumn,
            ...headerColumns.slice(frozenColumn.originalPosition),
        ];
    }

    // START SEARCHING for resized column (in which width changed), and creating _visibleColumns, which is just visibleColumns in real column order
    // I have to create my own _visibleColumns from visibleColumns in headerColumns order, because visibleColumns not always will be in correct order.
    // It can happen because inner data-table logic remembers columns order somewhat different then you would expect and I'am unable to mimic it.
    headerColumns.forEach((columnRef, index) => {
        let refColumnField = columnRef.className.getAfter("column-");

        if (refColumnField == event.column.field) resizedColIndex = index;
        const visibleColumn = visibleColumns.find((col) => col.field == refColumnField) as IGeneratedPrimeTableColumn;

        _visibleColumns.push(visibleColumn);
    });
    // END SEARCHING

    // setting width on resized column
    const resizedColumn = _visibleColumns[resizedColIndex];
    const resizedColumnWidth = parseFloat(resizedColumn.width) + event.delta;
    //! width set to what came in event.delta
    _visibleColumns[resizedColIndex] = { ...resizedColumn, width: `${resizedColumnWidth}px` };

    // setting width on last column, only if:
    // - user is shrinking column(different then the last one)
    // - columns summed width won't be enough to fill the table
    if (event.delta < 0) {
        let columnWidthSum = 0;
        const tableWidth = tableRef.current.offsetWidth;

        _visibleColumns.forEach((item) => (columnWidthSum += parseFloat(item.width)));

        if (tableWidth > columnWidthSum) {
            const lastColumnIndex: number = _visibleColumns.length - 1;
            const lastColumn = _visibleColumns[lastColumnIndex];
            let lastColumnWidth = tableWidth - columnWidthSum + parseFloat(lastColumn.width);

            //! last column width set to calculated width needed for it to fill the empty space
            _visibleColumns[lastColumnIndex] = { ...lastColumn, width: `${lastColumnWidth}px` };
        }
    }

    return _visibleColumns;
};
