import React from "react";

class SuperscriptDisplay extends React.Component<any, any> {
    // needed to control the superscript div
    myRef = React.createRef() as any;

    componentDidMount() {
        // pretty display if values given
        this.myRef.current.innerHTML = typeof this.props.value == "string" ? parseSuperscript(this.props.value) : this.props.value ?? "";
    }

    componentDidUpdate() {
        this.myRef.current.innerHTML = typeof this.props.value == "string" ? parseSuperscript(this.props.value) : this.props.value ?? "";
    }

    render() {
        return (
            <div
                data-tag="___react-data-table-allow-propagation___"
                style={this.props.style || {}}
                className={`${this.props.className || "superscript-display"}`}
                ref={this.myRef}
            ></div>
        );
    }
}

// parse to pretty html
export const parseSuperscript = (text) => {
    let r = ["↑", "↓", "→", "←"];
    let rm = [
        '<sub data-tag="___react-data-table-allow-propagation___">',
        "</sub>",
        '<sup data-tag="___react-data-table-allow-propagation___">',
        "</sup>",
    ];

    r.forEach((e, i) => (text = text.replaceAll(e, rm[i])));
    return text;
};

export const parseSuperscriptToString = (text: string) => {
    return text.replaceAll(/[↑↓→←]/g, "");
};

export default SuperscriptDisplay;

export const SUPERSCRIPT_UNICODES = {
    "1": "\u00B9",
    "2": "\u00B2",
    "3": "\u00B3",
    "4": "\u2074",
    "5": "\u2075",
    "6": "\u2076",
    "7": "\u2077",
    "8": "\u2078",
    "9": "\u2079",
    "0": "\u2070",
};

export const SUBSCRIPTS_UNICODES = {
    "1": "\u2081",
    "2": "\u2082",
    "3": "\u2083",
    "4": "\u2084",
    "5": "\u2085",
    "6": "\u2086",
    "7": "\u2087",
    "8": "\u2088",
    "9": "\u2089",
    "0": "\u2080",
};
