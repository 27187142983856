import React, { createContext, useContext, useState } from "react";

import { orderModes, OrderModesTypes } from "../../utils/modes";
import { useFetchTableData } from "hooks/useFetchTableData";
import { IComment, IContainer } from "../../interface";
import { ISample } from "../../../Samples/interface";

export const OrderReceiveInLabContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.receiveInLab;
export const OrderReceiveInLabContextProvider = ({ children }) => {
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
    const [selectedInventoryContainers, setSelectedInventoryContainers] = useState<IComment[]>([]);

    const { data, isPending, refreshData, handleReload } = useFetchTableData<any>({
        url: `/orders/receive-in-lab`,
        additionalParams: "&samples=true",
        onUnmount: () => setSelectedSamples([]),
    });

    return (
        <OrderReceiveInLabContext.Provider
            value={{
                selectedSamples,
                setSelectedSamples,
                selectedInventoryContainers,
                setSelectedInventoryContainers,
                mode,
                data,
                refreshData,
                handleReload,
                isPending,
            }}
        >
            {children}
        </OrderReceiveInLabContext.Provider>
    );
};

export const useOrderReceiveInLab = (): IUseOrderReceiveInLab => {
    const {
        selectedSamples,
        setSelectedSamples,
        selectedInventoryContainers,
        setSelectedInventoryContainers,
        mode,
        data,
        refreshData,
        handleReload,
        isPending,
    } = useContext(OrderReceiveInLabContext);

    return {
        selectedSamples,
        setSelectedSamples,
        selectedInventoryContainers,
        setSelectedInventoryContainers,
        mode,
        data,
        refreshData,
        handleReload,
        isPending,
    };
};

interface IUseOrderReceiveInLab {
    selectedSamples: ISample[];
    setSelectedSamples: (arg: ISample[]) => void;
    selectedInventoryContainers: IContainer[];
    setSelectedInventoryContainers: (arg: IContainer[]) => void;
    mode: OrderModesTypes;
    data: any[];
    refreshData: () => void;
    handleReload: Function;
    isPending: boolean;
}
