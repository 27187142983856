import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import i18n from "../../i18n";
import "./styles.scss";

export function sweetConfirm(
    o: any,
    t: any = "",
    type: string = "warning",
    confirmBtn = "Yes",
    cancelBtn = "Cancel",
    showCancelBtn = true,
    focusCancel = false
) {
    return new Promise((resolve, reject) => {
        let title = o.text || o;
        let text = t.text || t;

        title = i18n.t(title, { count: o.count || null });
        title = title.length > 0 ? `${title[0].toUpperCase()}${title.slice(1)}` : ""; // capitalize first letter
        text = i18n.t(text, { count: t.count || null });

        Swal.fire({
            title,
            text,
            type,
            icon: type,

            showCancelButton: showCancelBtn,
            confirmButtonText: i18n.t(confirmBtn),
            cancelButtonText: i18n.t(cancelBtn),
            allowEnterKey: true,
            focusCancel,
            customClass: {
                confirmButton: "btn btn-outline-gt-success",
                cancelButton: "ml-10 btn btn-outline-secondary",
            },
            buttonsStyling: false,
        }).then((res) => {
            resolve(res.value || false);
        });
    });
}
