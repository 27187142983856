// internal
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { withTranslation } from 'react-i18next';
import axios from 'axios'

// external
import Icon from "../icon";
import ModalAddShortcut from './modal-add-shortcut'
import CustomButton from '../custom-button/custom-button'
import { sweetConfirm } from "../sweet-alert/sweetConfirm"
import addToast from '../../utils/addToast'

// styles
import './style.scss'

const getListStyle = (isDraggingOver) => ({
    display: "flex",
    listStyle: "none",
    backgroundColor: isDraggingOver ? "#725ec311" : "transparent",
    padding: "5px 0",
    borderRadius: "5px",
    border: isDraggingOver ? "2px dashed #725ec344" : "2px dashed #725ec333",
    position: 'relative',
    top: '-1px', // offset because of the (1/2) border
    left: '-2px' // offset because of the border
})

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
})


class NavbarShortcuts extends React.Component<any, any> {
    constructor(props) {
        super(props)

        this.state = {
            shortcuts: [],
            originalState: []
        }
    }

    componentDidMount = async () => {
        try {
            let res = await axios.get('nav-bar')
            let navBar = res.data[0].navBar || []
            let clone = JSON.parse(JSON.stringify(navBar))
            
            this.setState({ 
                shortcuts: navBar,
                originalState: clone,
            }, () => {
                window.jQuery("#navbar-data").data('save', this.saveNavbarShortcuts )
            })

        } catch (err) {
            console.log(err)
        }
    }
    
    saveNavbarShortcuts = async () => {
        let clone = JSON.parse(JSON.stringify(this.state.shortcuts))
        
        try {
            await axios.post('nav-bar', { navBar: this.state.shortcuts })
            this.setState({ originalState: clone })
        } catch (err){
            console.log(err)
        }
    }

    renderShortcut = (s,i) => {
        let isActive = false
        
        if (window.location.pathname == '/' && s.url == '/'){
            isActive = true
        } else {
            isActive = s.url[1] ? window.location.pathname == s.url : false
        }

        return (
            <li key={i} className="navbar-shortcut" title={this.translateFullName(s.name)}>
                <Link to={ s.url } >
                    <CustomButton outline={!isActive} color="secondary" text={`${this.translateName(s.name)}`} icon={{ name: s.icon || ['fas', 'layer-group'] }} />
                    {/* below is the old stylized navbar shortcut, looks more like yaybar links */}
                    {/* <span style={{display:"inline"}} className={`${ i == 0 ? 'ml-7' : ''} btn btn-custom-round nav-link mr-10 ${ isActive ? 'active-shortcut' : '' }`}>
                        <Icon name={ s.icon || ['fas', 'layer-group'] } />
                    </span>
                    <span style={{display:"inline"}} className={`nav-link nav-shortcut-label ${ isActive ? 'active-shortcut' : '' }`}> 
                        { this.translateName(s.name) }
                    </span> */}
                </Link>
            </li>
        )
    }

    handleAddShortcut = (s) => {
        if (this.state.shortcuts.length > 6){

            addToast({
                title: "Cannot add shortcut",
                content: "Shortcut limit has been reached",
                color: "danger"
            });

        } else {
            this.addShortcut(s)
        }
    }

    addShortcut = (s) => {
        let shortcuts = this.state.shortcuts
        shortcuts.push(s)
        this.setState({ shortcuts })

        const $dashboardData = window.jQuery('#dashboard-data')
        if ($dashboardData.length > 0){
            const setAsDirty = $dashboardData.data('dirty')
            setAsDirty()
        }
    }

    onDragEnd = async (result) => {
        const { source, destination } = result

        const $dashboardData = window.jQuery('#dashboard-data')
        if ($dashboardData.length > 0){
            const setAsDirty = $dashboardData.data('dirty')
            setAsDirty()
        }

        // dropped outside the list
        if (!result.destination) {
            // delete
            if (!(await sweetConfirm("Delete", "Do you want to remove this shortcut?"))) return

            const result = this.state.shortcuts
            result.splice(source.index, 1)
            this.setState({ shortcuts: result })
            axios.post('nav-bar', { navBar: result })
            return;
        }

        // dropped into droppable component
        switch (source.droppableId) {
            case destination.droppableId:
                // reorder
                const result = this.state.shortcuts
                const [removed] = result.splice(source.index, 1)
                result.splice(destination.index, 0, removed)
                
                this.setState({ shortcuts: result })
                break;

            default:
                console.log("default?");
                break;
        }
    }

    // app name with prepended path (if exist) of the app (e.g. Basic Settings > Specifications)
    translateFullName = (name : string) => name.split(' > ').map( n => this.props.t(n)).join(' > ')

    // just the app name (e.g. Specifications)
    translateName = (name : string) => this.props.t( name.split(' > ')[1] || name )

    renderDraggableShortcuts = () => {
        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="SHORTCUTS-ORDER" direction="horizontal">
                    {(provided, snapshot) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            
                            {this.state.shortcuts.map((s:any, i) => {
                                
                                let isActive = false
                                
                                if (window.location.pathname == '/' && s.url == '/'){
                                    isActive = true
                                } else {
                                    isActive = s.url[1] ? window.location.pathname == s.url : false
                                }
                                
                                return (
                                    <Draggable key={`shortcut-${i}`} draggableId={`shortcut-${i}`} index={i}>
                                        {(provided, snapshot) => (
                                            <li
                                                key={i} 
                                                className="navbar-shortcut"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div className="draggable-disabled-btn mr-10" title={this.translateFullName(s.name)}>
                                                    <span style={{display:"inline"}} className={`nav-shortcut-icon mr-5 ${ isActive ? 'active-shortcut' : '' }`}>
                                                        <Icon name={ s.icon || ['fas', 'layer-group'] } />
                                                    </span>
                                                    <span style={{display:"inline"}} className={`nav-shortcut-label ${ isActive ? 'active-shortcut' : '' }`}>
                                                        { this.translateName(s.name) }
                                                    </span>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                            
                        </ul>
                    )}
                    </Droppable>
                </DragDropContext>
            </>
        )
    }

    render(){
        return (
            <>
                { this.props.static ? (
                    <>
                        { this.state.originalState.map( (s,i) => this.renderShortcut(s,i) ) }
                    </>
                ) : (
                    <>
                        { this.renderDraggableShortcuts() }
                        <ModalAddShortcut shortcuts={this.state.shortcuts} addShortcutFunc={ this.handleAddShortcut }/>
                    </>
                ) }
            </>
        )
    } 
}

export default withTranslation()(connect(({ settings }) => ({ settings }))(NavbarShortcuts))
