import React, { Component } from "react";

import { QueryTemplateContextProvider } from "./context/query-template-context"; 
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

//Component
class QueryTemplate extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <QueryTemplateContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </QueryTemplateContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default QueryTemplate;
