import React from "react";

import { SamplingStepsContextProvider } from "./context/use-sampling-steps";
import AsyncComponent from "components/async-component";
import { updateBreadcrumb } from "utils/breadcrumbs";
import PageContent from "components/page-content";
import PageWrap from "components/page-wrap";

class SampleSamplingPage extends React.Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <SamplingStepsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </SamplingStepsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default SampleSamplingPage;
