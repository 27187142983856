import React from "react";

import { Body, Filters, I } from "components/prime-data-table";

export const externalSamplingPlansColumns: I.IPrimeTableColumn[] = [
    {
        header: "Name",
        field: "name",
        sortable: true,
        filterBody: Filters.text,
    },
    {
        header: "Year",
        field: "year",
        sortable: true,
        filterBody: Filters.select,
    },
    {
        header: "Status",
        field: "status",
        sortable: true,
        translateOptions: true,
        filterBody: Filters.select,
        body: Body.status,
    },
    {
        header: "Workflow step",
        field: "workflowStep.name",
        sortable: true,
        filterBody: Filters.text,
    },
];
