import React from "react";

import { MemoFilterSelectInput } from "components/prime-data-table/filter-body-components/filter-select-input";
import { MemoFilterTextInput } from "components/prime-data-table/filter-body-components/filter-text-input";
import { IPrimeRowData, IPrimeTableColumn } from "components/prime-data-table/interfaces";
import { IPrimeFilterData } from "components/prime-data-table/interfaces";
import PrettyCell from "components/prime-data-table/body-components/pretty-cell/pretty-cell";
import { ISamplingPlanTable } from "../../../../interfaces/interfaces";

export const samplingPlansColumns: IPrimeTableColumn[] = [
    {
        header: "Name",
        field: "name",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "Year",
        field: "year",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterSelectInput inputData={inputData} />,
    },
    {
        header: "Status",
        field: "status",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterSelectInput inputData={inputData} />,
        body: (rowData: IPrimeRowData<ISamplingPlanTable>) => <PrettyCell text={rowData.row.status} />,
    },
    {
        header: "Workflow step",
        field: "workflowStep.name",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
];
