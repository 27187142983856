export const mapStatusesStyles = (statuses) => {
    return {
        statuses: statuses.reduce((acc, current) => {
            acc[current.value] = {
                color: current.color,
                icon: [current.type, current.icon],
                label: current.label,
            };
            return acc;
        }, {}),
    };
};
