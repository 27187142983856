import { metadataUrls } from "pages/BasicSettings/Metadata/utils/metadata-urls";
import { useFetchData } from "hooks/useFetchData";

type SourceTypes =
    | "other"
    | "nonconformance_card"
    | "offer"
    | "contract"
    | "order"
    | "sample"
    | "cdr"
    | "container"
    | "sampling_site"
    | "sampling_point"
    | "test_plan"
    | "order_template"
    | "test"
    | "equipment"
    | "result_sheet"
    | "result"
    | "personnel"
    | "specification"
    | "position"
    | "equipment_type"
    | "sample_workflow"
    | "method"
    | "supplier"
    | "method_validation_step"
    | "project";

interface IUseGetTemplates {
    source: SourceTypes;
}

export const useGetTemplates = ({ source }: IUseGetTemplates) => {
    const { data: metadataTemplates } = useFetchData<ILabelValue<number>[]>({
        url: metadataUrls.templates.formData,
        params: { onlyWithReleasedVersion: "True", source: source },
    });

    return metadataTemplates;
};
