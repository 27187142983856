import React, { ReactNode } from "react";

import { DefaultCell } from "../components/default-cell";
import { I, Filters, EditBody } from "../index";
import { getSelectChoices } from "./getSelectChoices";

export const mapToAdditionalColumns = async (
    extraFields: I.ExtraFields | undefined,
    generateAdditionalColumns
): Promise<I.IPrimeTableColumn[]> => {
    if (!extraFields || !generateAdditionalColumns) return [];

    return await Promise.all(
        Object.keys(extraFields).map(async (key): Promise<I.IPrimeTableColumn> => {
            const _key: keyof I.ExtraField = key as keyof I.ExtraField;
            const column: I.ExtraField = extraFields[_key];

            return {
                header: column?.name,
                field: _key,
                sortable: true,
                noHeaderTranslation: true,
                additionalType: column?.type,
                lookupChoices: await getSelectChoices(column?.lookup),
            };
        })
    );
};

//? It is a good question either to use the object literal or the switch-case statement
export const additionalColumnEditBody = (inputData: I.IPrimeEditData<any>, type: I.ExtraFieldTypes): ReactNode => {
    switch (type) {
        case "str":
            return <div style={{ height: "100%" }}> {EditBody.text(inputData, { shouldDataSetRefresh: false })}</div>;
        case "date":
            return <div style={{ height: "100%" }}> {EditBody.date(inputData, { shouldDataSetRefresh: false })}</div>;
        case "int":
            return <div style={{ height: "100%" }}> {EditBody.text(inputData, { type: "integer", shouldDataSetRefresh: false })}</div>;
        case "float":
            return <div style={{ height: "100%" }}> {EditBody.text(inputData, { type: "number", shouldDataSetRefresh: false })}</div>;
        case "list":
            return <div style={{ height: "100%" }}> {EditBody.select(inputData, { shouldDataSetRefresh: false })}</div>;
        default:
            const value = inputData.row?.[inputData.fieldName];
            return <DefaultCell value={value}>{value}</DefaultCell>;
    }
};

export const additionalColumnFilterBody = (filterData: I.IPrimeFilterData, type: I.ExtraFieldTypes): ReactNode => {
    switch (type) {
        case "str":
            return Filters.text(filterData);
        case "date":
            return Filters.date(filterData);
        case "int":
            return Filters.number(filterData);
        case "float":
            return Filters.number(filterData);
        case "list":
            return Filters.select(filterData);
        default:
            return undefined;
    }
};

export const additionalColumnFilterField = (field: string): string => {
    return field.replace("extraField", "extra_field_");
};
