/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../../components/async-component';
import PageWrap from '../../../components/page-wrap';
import PageContent from '../../../components/page-content';
import { updateBreadcrumb } from '../../../utils/breadcrumbs';

/**
 * Component
 */
class CDRDocumentWorkflows extends Component {
    
    componentDidMount = updateBreadcrumb
    
    render() {
        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={ () => import( './content' ) } />
                </PageContent>
            </PageWrap>
        );
    }
}

export default CDRDocumentWorkflows;
