import React, { ReactElement, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
import axios from "axios";

import CustomButton from "../custom-button/custom-button";

import "./style.scss";

interface IProps {
    permCode?: string;
    file?: { name: string; path: string };
    noRecordSelectedMessage?: string;
    showDownloadBtn?: boolean;
    pageClassName?: string;
    loadingComponent?: ReactElement;
    containerClassName?: string;
    onLoad?: any;
    docRef?: any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfPreview = ({
    permCode = undefined,
    file = undefined,
    noRecordSelectedMessage = "You have to select report.",
    showDownloadBtn = false,
    pageClassName,
    loadingComponent,
    containerClassName,
    onLoad,
    docRef,
}: IProps) => {
    const { t } = useTranslation();

    const [fullPdfUrl, setFullPdfUrl] = useState<string>("");
    const [pageNumber, setPageNumber] = useState(0);
    const [numPages, setNumPages] = useState<number>(0);

    useEffect(() => {
        file?.path ? setFullPdfUrl(file?.path) : setFullPdfUrl("");
    }, [file?.path]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        pageNumber != 0 && setPageNumber(0);
        onLoad && onLoad();
    };

    const handleDownloadFile = () => {
        axios.get(fullPdfUrl, { responseType: "blob" }).then((response) => {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = file?.name || t("file_name_not_found");

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    };

    const handlePageChange = (e) => setPageNumber(e.page);
    
    return (
        <>
            <div className={`pdf-preview-container ${containerClassName ? containerClassName : ""}`}>
                <CustomButton
                    permCode={permCode}
                    text="Download"
                    onClick={handleDownloadFile}
                    icon={{ name: "save" }}
                    className="mb-10"
                    disabled={!file?.path}
                    display={showDownloadBtn}
                />
                {!file?.path ? (
                    <div className="pdf-preview-no-record-selected">
                        <h4>{t(noRecordSelectedMessage)}</h4>
                    </div>
                ) : (
                    <>
                        <Document file={fullPdfUrl} onLoadSuccess={onDocumentLoadSuccess} loading={loadingComponent} inputRef={docRef}>
                            <Page pageNumber={pageNumber + 1} className={pageClassName} loading={loadingComponent} />
                        </Document>
                        {numPages > 1 && (
                            <Paginator
                                className="custom-paginator"
                                first={pageNumber}
                                rows={1}
                                totalRecords={numPages}
                                onPageChange={handlePageChange}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
