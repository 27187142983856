import React, { useState, createContext, useContext } from "react";

export const PersonelContext = createContext<any>(null);

export const PersonelContextProvider = ({ children }) => {
    const [selectedPersons, setSelectedPersons] = useState<any>([]);

    return (
        <PersonelContext.Provider
            value={{
                selectedPersons,
                setSelectedPersons,
            }}
        >
            {children}
        </PersonelContext.Provider>
    );
};

export const usePersonel = () => {
    const { selectedPersons, setSelectedPersons } = useContext(PersonelContext);
    return {
        selectedPersons,
        setSelectedPersons,
    };
};
