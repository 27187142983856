import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { updatePageLayout } from "actions/index";

export const useTableScrollHeight = (gtTableRef, filtersEnabled, children, meta) => {
    const [scrollHeight, setScrollHeight] = useState<number>(0);
    const dispatch = useDispatch();

    const { settled } = useSelector((state) => ({
        settled: state.styles.pageLayout.settled,
    }));

    const prevHeight = useRef<number>(0);

    //? PAGE LAYOUT

    useEffect(() => {
        const pageContainer = document.getElementById("page-container");

        const calculatePageLayout = () => {
            // it takes height of pageContainer and divides it for every container that has table in it
            const tables = document.getElementsByClassName("gt-table");
            const containersThatShouldBeIncluded = document.getElementsByClassName("calculate-space");

            const pageContainerHeight = window.innerHeight - 107 || 0;
            let containersWithTables: number = 0;
            let nonTableContainerHeight: number = 0;

            if (pageContainer?.childNodes) {
                Array.from(pageContainer?.childNodes).forEach((child) => {
                    if (
                        Array.from(tables)?.some((table) => child.contains(table)) ||
                        Array.from(containersThatShouldBeIncluded)?.some((table) => child.contains(table))
                    ) {
                        containersWithTables += 1;
                    } else {
                        nonTableContainerHeight += (child as any).clientHeight;
                    }
                });
            }

            const PAGE_CONTENT_PADDING = 20;
            const gaps = 10 * (containersWithTables - 1);

            const containerHeight = (pageContainerHeight - PAGE_CONTENT_PADDING - nonTableContainerHeight - gaps) / containersWithTables;

            dispatch(updatePageLayout({ tableContainerHeight: containerHeight, settled: true }));
            getScrollHeight(gtTableRef?.current?.clientHeight);
        };

        const resizeObserver = new ResizeObserver((entries) => {
            const height = entries[0].borderBoxSize?.[0]?.blockSize;
            if (height === prevHeight.current) return;
            prevHeight.current = height;
            if (!settled) {
                calculatePageLayout();
                getScrollHeight(gtTableRef?.current?.clientHeight);
            }
        });

        if (!settled) {
            calculatePageLayout();
        }

        resizeObserver.observe(pageContainer as HTMLDivElement);
        return () => resizeObserver.unobserve(pageContainer as HTMLDivElement);
    }, []);

    //? PAGE LAYOUT END

    //? TABLE SCROLL
    const recalculate = (height?: number) => {
        getScrollHeight(gtTableRef?.current?.clientHeight || height);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const height = entries[0].borderBoxSize?.[0]?.blockSize;
            if (height === prevHeight.current) return;
            prevHeight.current = height;
            recalculate(height);
        });

        getScrollHeight(gtTableRef?.current?.clientHeight);

        resizeObserver.observe(gtTableRef.current);
        return () => resizeObserver.unobserve(gtTableRef.current);
    }, [settled]);

    useEffect(() => {
        getScrollHeight(prevHeight.current);
    }, [filtersEnabled, !!meta]);

    const getScrollHeight = (clientHeight) => {
        const HEADERS = 32;
        const BUTTONS = !!children ? 38 : 0;
        const TABLE_BUTTONS_GAP = 10;
        const PAGINATION_BAR = !!meta ? 41 : 0;
        const BUFFER = 5;
        const FILTERS = filtersEnabled ? 32 : 0;
        const MIN_TABLE_HEIGHT = 165;
        const fullscreenContainers = document.getElementsByClassName("fullscreen");

        if (!!fullscreenContainers.length) return MIN_TABLE_HEIGHT;

        let calculatedScrollHeight: number = clientHeight - HEADERS - BUTTONS - TABLE_BUTTONS_GAP - PAGINATION_BAR - BUFFER - FILTERS;

        if (calculatedScrollHeight < MIN_TABLE_HEIGHT) calculatedScrollHeight = MIN_TABLE_HEIGHT;
        setScrollHeight(calculatedScrollHeight);
    };

    return { scrollHeight };
};
