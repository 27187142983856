import { compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['auth', 'options'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const store = createStore(persistedReducer, composeEnhancers && composeEnhancers());
const persistor = persistStore(store)

export default {store, persistor};

