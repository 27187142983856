import React, { createContext, useContext, useState } from "react";
import { ITestMethodTable, ITestTable } from "../interfaces/interface";

export const TestsContext = createContext<any>(null);

export const TestsContextProvider = ({ children }) => {
    const [selectedTests, setSelectedTests] = useState<ITestTable[]>([]);
    const [selectedTestMethods, setSelectedTestMethods] = useState<ITestMethodTable[]>([]);

    return (
        <TestsContext.Provider value={{ selectedTests, setSelectedTests, selectedTestMethods, setSelectedTestMethods }}>
            {children}
        </TestsContext.Provider>
    );
};

export const useTests = (): IUseTests => {
    const { selectedTests, setSelectedTests, selectedTestMethods, setSelectedTestMethods } = useContext(TestsContext);

    return { selectedTests, setSelectedTests, selectedTestMethods, setSelectedTestMethods };
};

interface IUseTests {
    selectedTests: ITestTable[];
    setSelectedTests: (arg: ITestTable[]) => void;
    selectedTestMethods: ITestMethodTable[];
    setSelectedTestMethods: (arg: ITestMethodTable[]) => void;
}
