import React, { CSSProperties, MutableRefObject, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";
import { Button, Spinner } from "reactstrap";
import { createPortal } from "react-dom";
import Popper from "popper.js";
import axios from "axios";

import CustomModalRequirement from "../custom-modal-requirement/custom-modal-requirement";
import CustomHelpContextMenu from "../help-context-menu/help-context-menu";
import { IRequirements } from "../../pages/interfaces/IRequirements";
import { IPermCodeResponse } from "../help-context-menu/interface";
import { IReqParams } from "../../pages/interfaces/IReqParams";
import requirementStatus from "../../utils/requirementStatus";
import hasPermission from "../../utils/hasPermissions";
import { GtTooltip } from "../gt-tooltip/gt-tooltip";
import Icon from "../icon";

import "./mobile-button.styles.scss";

type ButtonTypes = "submit" | "reset" | "button";
export interface ICustomButtonProps {
    children?: any;
    t: Function;
    text?: string;
    type?: ButtonTypes;
    style?: CSSProperties;
    icon?: any;
    color?: string;
    className?: string | any;
    outline?: boolean;
    onClick?: Function;
    display?: boolean;
    disabled?: boolean;
    permCode?: string;
    isBusy?: boolean;
    active?: boolean;
    reqModal?: boolean;
    withoutPerm?: boolean;
    showTooltipDelay?: number;
    hideTooltipDelay?: number;
    buttonRef?: RefObject<Button>;
    innerRef?: MutableRefObject<HTMLButtonElement | null>;
    tooltipContent?: string | Element;
    tooltipPosition?: Popper.Placement;
    cyId?: string; // selector for tests
    startMoreMenu?: boolean; // flag telling the wrapper to render in "more" menu
    excludeFromMoreMenu?: boolean; // flag telling the wrapper to exclude it from "more" menu
}

const CustomButton = (props: ICustomButtonProps) => {
    const {
        icon,
        children,
        t,
        text,
        type = "button",
        style,
        color,
        className,
        outline = true,
        onClick,
        display = true,
        disabled = false,
        permCode,
        isBusy,
        active,
        reqModal = false,
        withoutPerm = false,
        tooltipContent,
        tooltipPosition = "top",
        showTooltipDelay = 200,
        hideTooltipDelay = 0,
        buttonRef,
        innerRef,
    } = props;

    const [toggleReq, setToggleReq] = useState<boolean>(false);
    const [requirements, setRequirements] = useState({} as IRequirements);

    const [btnIsBusy, setBtnIsBusy] = useState(false);

    // Code to display help menu START
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [helpPath, setHelpPath] = useState<string | undefined>(undefined);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const innerRefLocal = useRef<any>(null);

    useEffect(() => {
        setShowContextMenu(false);

        const handleOutsideClick = (e) => {
            if (innerRefLocal.current && !innerRefLocal.current.contains(e.target)) {
                setShowContextMenu(false);
            }
        };

        const handleScroll = () => {
            setShowContextMenu(false);
        };

        const eventTypes = ["contextmenu", "click"];
        eventTypes.forEach((eventType) => {
            document.addEventListener(eventType, handleOutsideClick);
        });

        window.addEventListener("scroll", handleScroll);

        return () => {
            eventTypes.forEach((eventType) => {
                document.removeEventListener(eventType, handleOutsideClick);
            });
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleContextMenu = async (e) => {
        e.preventDefault();

        if (!permCode) return;

        try {
            const response: IPermCodeResponse = await axios.get("accessSettings/permissions", {
                params: { code: permCode },
            });

            const helpData = response?.data?.url;
            if (helpData) {
                setShowContextMenu(true);
                setHelpPath(helpData);

                const buttonRect = innerRefLocal.current.getBoundingClientRect();
                const menuPosition = {
                    x: buttonRect.left + 15,
                    y: buttonRect.top + buttonRect.height + 5,
                };
                setMenuPosition(menuPosition);
            }
        } catch (err) {}
    };

    const handleMenuClick = () => {
        setShowContextMenu(false);
    };

    // Code to display help menu END

    let _icon = icon;
    if (typeof _icon == "string") _icon = { name: _icon };

    let checkPerm = false;
    if (process.env.REACT_APP_CHECK_PERMCODES_ON_BUTTONS == undefined || process.env.REACT_APP_CHECK_PERMCODES_ON_BUTTONS) {
        checkPerm = true;
    }

    let disp = display && (permCode != undefined ? hasPermission(permCode) : true);

    const handleClick = async (e) => {
        setIsHovered(false);
        if (onClick) {
            setBtnIsBusy(true);
            try {
                if (reqModal && permCode) {
                    const req = await requirementStatus(permCode);
                    req["perm_code"] = permCode;
                    if (req.requireComment || req.requirePassword) {
                        setToggleReq(true);
                        setRequirements(requirementStatus(permCode));
                    } else await onClick(e, req);
                } else {
                    await onClick(e);
                }
            } catch {
            } finally {
                setBtnIsBusy(false);
            }
        } else return null;
    };

    const handleSubmitReq = (e: SyntheticEvent, reqParams: IReqParams) => {
        if (onClick) {
            reqParams.required = true;
            return onClick(e, reqParams);
        }
    };

    const btnColor = () => {
        if (withoutPerm) return color || "gt-success-btn";
        else if (!permCode && checkPerm) {
            return "dark";
        } else return color || "gt-success-btn";
    };

    return (
        <>
            {disp && (
                <>
                    {tooltipContent && (
                        <GtTooltip
                            targetRef={innerRefLocal}
                            isOpen={isHovered}
                            tooltipContent={tooltipContent}
                            showTooltipDelay={showTooltipDelay}
                            hideTooltipDelay={hideTooltipDelay}
                            placement={tooltipPosition}
                        />
                    )}
                    <Button
                        style={style}
                        color={btnColor()}
                        type={type}
                        className={(className ?? "mr-10") + (className && className.includes("mt-") ? "" : " mt-10")}
                        onClick={(e) => handleClick(e)}
                        outline={outline}
                        disabled={btnIsBusy || isBusy || !!disabled}
                        active={active}
                        onContextMenu={handleContextMenu}
                        innerRef={(ref) => {
                            innerRefLocal.current = ref;
                            innerRef && (innerRef.current = ref);
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        ref={buttonRef}
                    >
                        {children}
                        {(btnIsBusy || isBusy) && !className?.includes("cancel-modal-button") && <Spinner size="sm" className="mr-10" />}
                        {!btnIsBusy && !isBusy && _icon && <Icon {..._icon} className={text ? "mr-5" : ""} />}
                        {t(text) || ""}
                    </Button>
                    {toggleReq &&
                        createPortal(
                            <CustomModalRequirement
                                toggleModal={setToggleReq}
                                isOpen={toggleReq}
                                requirements={requirements}
                                submit={handleSubmitReq}
                                permCode={permCode || ""}
                            />,
                            document.body
                        )}
                    {permCode && showContextMenu && (
                        <CustomHelpContextMenu
                            positionX={menuPosition.x}
                            positionY={menuPosition.y}
                            handleMenuClick={handleMenuClick}
                            helpPath={helpPath}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default withTranslation()(CustomButton);
