import React from "react";

import { withRouter } from "react-router-dom";

import { OrderReleaseSamplesContextProvider } from "./context/order-release-samples-context";
import { isMobile } from "../../../../common-assets/js/rootui-parts/_utility";
import AsyncComponent from "components/async-component";
import { updateBreadcrumb } from "utils/breadcrumbs";
import PageContent from "components/page-content";
import PageWrap from "components/page-wrap";

class OrdersReleaseSamplesPage extends React.Component<any, any> {
    componentDidMount = updateBreadcrumb;

    render() {
        const { history, location } = this.props;

        if (isMobile) history.push(`/m${location.pathname}`);

        return (
            <PageWrap>
                <PageContent>
                    <OrderReleaseSamplesContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </OrderReleaseSamplesContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default withRouter(OrdersReleaseSamplesPage);
