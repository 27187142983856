// internal
import React, { Component } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

// external
import Icon from "../icon";
import CustomModalSelect from "../custom-modal-elements/custom-modal-select";
import hasPermission from "../../utils/hasPermissions";
import CustomModalHeader from "../../components/custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../components/custom-modal-elements/custom-modal-footer";
import CustomForm from "../custom-form/custom-form";

class ModalAddShortcut extends Component<any, any> {
    baseState: Readonly<any>;

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            errors: {} as any,
            options: [],
            selectedApps: [],
            url: "",
        };

        this.baseState = this.state;
    }

    openModal = () => {
        let apps = this.props.settings.navigation_sidebar;

        const { t } = this.props;

        let appChoices = Object.entries(apps)
            .filter(([k, o]: any) => o.name && (!o.permCode ? true : hasPermission(o.permCode)))
            .map(([k, o]: any) => ({ label: t(o.name), value: k }));

        this.setState({ modalOpen: true, options: [appChoices] });
    };

    closeModal = () => {
        this.setState(this.baseState);
    };

    addShortcut = (event) => {
        event.preventDefault();
        const { t } = this.props;

        if (this.state.selectedApps.length == 0) {
            this.setState({
                errors: {
                    app: t("navbarErrors>>noAppSelected"),
                },
            });
            return;
        }

        const $navbarData = window.jQuery("#navbar-data");

        if ($navbarData.length > 0) {
            const refreshShortcutsFunc = $navbarData.data("func");
            refreshShortcutsFunc();
        }

        let shortcutName = this.state.selectedApps.map((a) => a.name).join(" > ");

        let newShortcut = {
            name: shortcutName,
            icon: this.state.selectedApps[0].icon || null,
            url: this.state.url,
        };

        this.props.addShortcutFunc(newShortcut);
        this.setState(this.baseState);
    };

    handleChangeApp = (event, index = 0) => {
        let val = event.target.value || "";
        let options = this.state.options;
        let selectedApps = this.state.selectedApps;

        selectedApps[index] = this.props.settings.navigation_sidebar[val] || selectedApps[index - 1]?.sub[val] || [];

        if (selectedApps[index].sub) {
            const { t } = this.props;

            let nextSelect = Object.entries(selectedApps[index].sub)
                .filter(([k, o]: any) => o.name && (!o.permCode ? true : hasPermission(o.permCode)))
                .map(([k, o]: any) => ({ label: t(o.name), value: k }));

            options[index + 1] = nextSelect;
        } else {
            options.length = index + 1;
            selectedApps.length = index + 1;
        }

        this.setState(
            {
                options: options.slice(0, index + 1),
            },
            () => {
                this.setState({
                    selectedApps: selectedApps,
                    options: options,
                    url: val,
                    errors: {},
                });
            }
        );
    };

    render() {
        const { t } = this.props;

        return (
            <>
                <Button className="btn btn-custom-round ml-20" onClick={this.openModal}>
                    <Icon name="plus" />
                </Button>

                <Modal isOpen={this.state.modalOpen} toggle={this.openModal} className={this.props.className} fade>
                    <CustomForm permCode="" disableTransactions onSubmit={this.addShortcut}>
                        <CustomModalHeader closeModal={this.closeModal} name="Add Shortcut" />

                        <ModalBody>
                            <>
                                {this.state.options.map((apps, index) => {
                                    return (
                                        <CustomModalSelect
                                            key={index}
                                            errors={this.state.errors.app}
                                            labelName={index == 0 ? "Application" : "Sub"}
                                            options={apps}
                                            handleChange={(event) => this.handleChangeApp(event, index)}
                                            name={`app-${index}`}
                                        />
                                    );
                                })}
                            </>
                        </ModalBody>

                        <CustomModalFooter closeModal={this.closeModal} cancelName="Cancel" okName="Save" />
                    </CustomForm>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(connect(({ settings }) => ({ settings }))(ModalAddShortcut));
