import React, { useEffect, useState, useRef, SyntheticEvent } from "react";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

import { IEditStatusInputProps } from "../interfaces/edit-interfaces";
import PrettyCell from "../body-components/pretty-cell/pretty-cell";
import { sweetConfirm } from "../../sweet-alert/sweetConfirm";
import { ReqParamsHOC } from "../helpers/req-params-hoc";
import Icon from "components/icon";
// import { moveKeyPress } from "../helpers/moveKeyPress";

// table edit component, you send it in the column as editBody prop
export const EditStatusInput = (props: IEditStatusInputProps) => {
    const {
        inputData: { row, fieldName, selectChoices },
        inputEnabled = true,
        CustomPlaceholder = undefined,
        value,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-status-${fieldName}-${row.id}`}>
            {CustomPlaceholder ? <CustomPlaceholder /> : <PrettyCell text={value || row[fieldName]} />}
        </div>
    );
};

const EnabledInput = (props: IEditStatusInputProps) => {
    const { t } = useTranslation();

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        secondPartEditUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        extraParametersToSet = undefined,
        //common
        placeholder = "Choose value...",
        //unique
        sweetConfirmMessage = undefined,
        optionFilter = false,
        options,
    } = props;

    const { row, fieldName, handleEditSubmit, selectChoices, isBusy, permCode = "", rowIndex, handleFocusSelectRecord } = props.inputData;

    const [error, setError] = useState<boolean>(false);
    const [selectValue, setSelectValue] = useState<number | string>(value || row[fieldName]);

    const formRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<any>(null);

    const _name = name || fieldName;

    useEffect(() => {
        error && setError(false);
    }, [row]);

    const _value = selectValue || "";

    const handleOnChange = async (e) => {
        if (sweetConfirmMessage && !(await sweetConfirm("Select", sweetConfirmMessage))) return;
        setSelectValue(e.target.value);
        // rowValue is needed for checking if value before change is different then value on blur'e
        let _e = { ...e, target: { ...e.target, name: e.target.name, value: !e.target.value ? null : e.target.value } };

        if (!permCode) handleSubmit(e, undefined);

        inputRef.current = _e;

        formRef.current && formRef.current.click();
    };

    const handleSubmit = async (e: SyntheticEvent, reqParams?: IReqParams) => {
        const params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, inputRef.current) : extraEditParams;
        const _e = { target: { name: _name, value: inputRef?.current?.value } };

        await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
            editUrl,
            reqParams: reqParams,
        });
    };

    const finalValue = ((value || row[fieldName]) != _value ? value || row[fieldName] : _value).toLowerCase();

    return (
        <>
            <ReqParamsHOC onSubmit={handleSubmit} permCode={permCode} formRef={formRef} onClose={undefined}>
                <Dropdown
                    key={`prime-input-status-${fieldName}-${row.id}`}
                    id={`prime-input-status-${fieldName}-${rowIndex}`}
                    emptyMessage={t("No records found")}
                    optionLabel="label"
                    optionValue="value"
                    className={`prime-table-cell-edit prime-input-status ${className} ${error ? "error" : ""} no-dropdown-icon`}
                    style={{ width: "100%", ...style }}
                    name={_name}
                    options={selectChoices?.filter(
                        (choice) => !options || choice.value == finalValue || options?.[finalValue]?.includes(choice.value as string)
                    )}
                    value={finalValue}
                    placeholder={t(placeholder)}
                    filter={optionFilter}
                    disabled={isBusy}
                    onFocus={() => handleFocusSelectRecord(row)}
                    onChange={(e) => handleOnChange(e)}
                    valueTemplate={(option) => <PrettyCell text={option?.value} />}
                    itemTemplate={(option) => <PrettyCell text={option?.value} className="pretty-cell-edit" />}
                    showOnFocus
                />
                <Icon className="edit-status-icon" name={"edit2"} />
            </ReqParamsHOC>
        </>
    );
};
