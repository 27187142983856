import React, { createContext, useContext, useState } from "react";
import { ITableSupplier, ITableContract } from "../interfaces/interface";

export const SuppliersContext = createContext<any>(null);

export const SuppliersProvider = ({ children }) => {
    const [selectedSuppliers, setSelectedSuppliers] = useState<ITableSupplier[]>([]);
    const [selectedContracts, setSelectedContracts] = useState<ITableContract[]>([]);

    return (
        <SuppliersContext.Provider value={{ selectedSuppliers, setSelectedSuppliers, selectedContracts, setSelectedContracts }}>
            {children}
        </SuppliersContext.Provider>
    );
};

export const useSuppliers = (): IUseSuppliers => {
    const { selectedSuppliers, setSelectedSuppliers, selectedContracts, setSelectedContracts } = useContext(SuppliersContext);

    return { selectedSuppliers, setSelectedSuppliers, selectedContracts, setSelectedContracts };
};

interface IUseSuppliers {
    selectedSuppliers: ITableSupplier[];
    setSelectedSuppliers: (arg: ITableSupplier[]) => void;
    selectedContracts: ITableContract[];
    setSelectedContracts: Function;
}
