import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { DropdownGlobalSearch } from "./components/global-search-dropdown";
import { ModalGlobalSearch } from "./components/global-search-modal";
import { useMountTransition } from "../../hooks/useMountTransition";
import { useGlobalSearch } from "./hook/useGlobalSearch";
import Icon from "../icon";

import "./global-search.style.scss";

export const GlobalSearch = (props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [navbarInput, setNavbarInput] = useState<string>("");
    const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);

    const hasTransitionedIn = useMountTransition(isModalOpen, 200);

    const ref = useRef<HTMLInputElement | null>(null);

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const { t } = useTranslation();

    //* There has to be a delay for link to fires an event
    const linkDelay = useMountTransition(isSearchFocused, 200);

    useEffect(() => {
        if (hasTransitionedIn && ref.current) {
            ref.current.focus();
        }
    }, [hasTransitionedIn]);

    const { results, slicedResults, isLoading } = useGlobalSearch({
        triggerValues: [isSearchFocused],
        input: navbarInput,
        condition: isSearchFocused,
    });

    const handleKeyDown = (e) => {
        if (e.key == "Enter") {
            e.preventDefault();
            setIsModalOpen(true);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setNavbarInput(value);
    };

    return (
        <div className="global-search">
            {(isSearchFocused || linkDelay) && navbarInput && (
                <DropdownGlobalSearch
                    searchData={slicedResults}
                    isLoading={isLoading}
                    dropdownRef={dropdownRef}
                    navbarInput={navbarInput}
                />
            )}
            <div className="btn btn-custom-round" onClick={() => setIsModalOpen((prev) => !prev)} role="button">
                <Icon name="search" />
            </div>
            <div className="navbar-search-container">
                <input
                    type="search"
                    className="form-control form-control-clean navbar-search"
                    placeholder={t("Search") + "..."}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={navbarInput}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                />
            </div>
            {(isModalOpen || hasTransitionedIn) && (
                <ModalGlobalSearch
                    isModalOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    inputRef={ref}
                    navbarInput={navbarInput}
                    searchData={results}
                />
            )}
        </div>
    );
};
