import React, { memo } from "react";

import { TooltipContentType } from "components/gt-tooltip/gt-tooltip";
import { TooltipHOC } from "components/index";

interface IDefaultCellProps {
    children: TooltipContentType;
    tooltipContent?: TooltipContentType;
    value: string;
}

const arePropsEqual = (prev, next) => {
    return prev.value == next.value;
};

export const DefaultCell = memo(
    (props: IDefaultCellProps) => (
        <TooltipHOC tooltipContent={props.tooltipContent || props.children} className="cell-tooltip">
            {props.children}
        </TooltipHOC>
    ),
    arePropsEqual
);
