import React, { memo } from "react";

import { ILitePrimeTableColumn } from "../interfaces";
import { TooltipHOC } from "components/index";

interface IDefaultHeaderProps {
    liteColumn: ILitePrimeTableColumn;
}

const arePropsEqual = (prev, next) => {
    return prev.liteColumn.header == next.liteColumn.header;
};

export const DefaultHeader = memo(({ liteColumn }: IDefaultHeaderProps) => {
    const handleSort = (e) => {
        const header = e.target.closest(".p-sortable-column");

        if (!header) return;

        const ctrlClickEvent = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
            ctrlKey: e.ctrlKey,
        });

        header.dispatchEvent(ctrlClickEvent);
    };

    return (
        <TooltipHOC onClick={handleSort} tooltipContent={liteColumn.header}>
            {liteColumn.header}
        </TooltipHOC>
    );
}, arePropsEqual);
