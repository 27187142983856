import { DataTableColReorderParams } from "primereact/datatable";
import { IGeneratedPrimeTableColumn } from "../interfaces/table-interfaces";

export const getReorderedColumns = (event: DataTableColReorderParams, selectedColumns: IGeneratedPrimeTableColumn[]) => {
    let reorderedColumns: any[] = [];
    const _columns = event.columns as any;

    _columns.forEach((col) => reorderedColumns.push(selectedColumns.find((selCol) => selCol.field == col.props?.field)));
    return reorderedColumns;
};

// First version is easier to read and for now there are none optimization problems
// But second version have better optimization when there is more columns

// export const getReorderedColumns1 = (event, selectedColumns) => {
//     let reorderedColumns: any[] = [...selectedColumns];
//     const { dragIndex, dropIndex } = event;
//     const reorderedColumn: any[] = reorderedColumns[dragIndex];

//     reorderedColumns.splice(dragIndex, 1);

//     return [...reorderedColumns.slice(0, dropIndex), reorderedColumn, ...reorderedColumns.slice(dropIndex, reorderedColumns.length)];
// };
