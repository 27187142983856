import React, { createContext, useContext, useState } from "react";
import { IReport } from "../interface/interface";

export const OrderTestReportsContext = createContext<any>(null);

export const OrderTestReportsContextProvider = ({ children }) => {
    const [selectedReports, setSelectedReports] = useState<IReport[]>([]);

    return <OrderTestReportsContext.Provider value={{ selectedReports, setSelectedReports }}>{children}</OrderTestReportsContext.Provider>;
};

export const useOrderTestReports = (): IUseOrderTestReports => {
    const { selectedReports, setSelectedReports } = useContext(OrderTestReportsContext);

    return { selectedReports, setSelectedReports };
};

interface IUseOrderTestReports {
    selectedReports: IReport[];
    setSelectedReports: (arg: IReport[]) => void;
}
