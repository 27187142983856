import React, { ReactNode, memo } from "react";

import { useTranslation } from "react-i18next";
import classNames from "classnames/dedupe";
import { Tooltip } from "reactstrap";
import Popper from "popper.js";

import "./gt-tooltip.style.scss";

export type TooltipContentType = string | number | ReactNode | undefined | null;

interface GtTooltipProps {
    targetRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    tooltipContent: TooltipContentType;
    className?: string;
    showTooltipDelay?: number;
    hideTooltipDelay?: number;
    placement?: Popper.Placement;
    disableTranslation?: boolean;
}

const _GtTooltip = (props: GtTooltipProps) => {
    const {
        targetRef,
        isOpen,
        showTooltipDelay = 450,
        hideTooltipDelay = 100,
        tooltipContent,
        className,
        placement = "top",
        disableTranslation,
    } = props;
    //!delay props are not working correctly as well as fade props
    // to change showDelay directly change transition-delay value for selectors .gt-popper-tooltip .arrow::before

    const { t } = useTranslation();

    if (!targetRef?.current || !tooltipContent) return <></>;

    return (
        <Tooltip
            flip
            target={targetRef}
            isOpen={isOpen}
            delay={{ show: showTooltipDelay, hide: hideTooltipDelay }}
            placement={placement}
            className={classNames("gt-tooltip", className)}
            popperClassName={classNames("gt-popper-tooltip")}
            container={document.body}
        >
            {!disableTranslation && typeof tooltipContent == "string" ? t(tooltipContent) : tooltipContent}
        </Tooltip>
    );
};

export const GtTooltip = memo(_GtTooltip, (prev, next) => prev.isOpen == next.isOpen);
