import React, { Component } from "react";

import AsyncComponent from "../../../../components/async-component";
import { updateBreadcrumb } from "../../../../utils/breadcrumbs";
import PageContent from "../../../../components/page-content";
import PageWrap from "../../../../components/page-wrap";
class MyResultSheetPage extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={() => import("./content")} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default MyResultSheetPage;
