import React, { Component } from "react";

import { MethodsContextProvider } from "./context/methods-context";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class BSMethods extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <MethodsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </MethodsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default BSMethods;
