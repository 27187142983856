import React, { useState } from "react";

import { AddSamplingSiteModal } from "pages/BasicSettings/SamplingSites/components/modals/add-edit-sampling-site-modal";
import { samplingSitesUrls } from "pages/BasicSettings/SamplingSites/utils/sampling-sites-urls";
import { deprecated_useFormData } from "hooks/useFormData";

import * as GT from "components";

import "../../modal-styles.scss";

interface ISamplingSiteSelectProps {
    errors: string | string[];
    client?: number | undefined;
    handleChange: (e: { target: { name: string; value: number[] } } | any) => void;
    value: number[] | undefined | null;
    labelSm?: number;
    colSm?: number;
    url?: string;
}

export const SamplingSiteSelect = ({ errors, client, handleChange, value, labelSm, colSm, url }: ISamplingSiteSelectProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const _value = value || [];

    const { formData: samplingSiteOptions, refreshFormData: refreshSamplingSiteOptions } = deprecated_useFormData<
        ILabelValue<number>[],
        any
    >({
        url: url ?? samplingSitesUrls.samplingSitesFormData,
        triggerValues: [client],
        params: { params: client ? { client: client } : { client__isnull: "True" } },
        mapFormData: (formData) => formData.SamplingSiteSet ?? formData?.sites,
    });

    const handleSelectedSamplingSite = (samplingSitesId: number) => {
        refreshSamplingSiteOptions();
        handleChange({
            target: {
                name: "samplingSites",
                value: value?.length ? [...value, samplingSitesId] : [samplingSitesId],
            },
        });
    };

    const handleCloseModal = () => setOpenModal(false);

    return (
        <>
            <GT.PrimeMultiSelect
                labelSm={labelSm}
                colSm={colSm}
                rowClassName={`sampling-location-select-row ${_value.length > 0 ? "filled" : ""}`}
                options={samplingSiteOptions}
                label="Sampling site"
                name="samplingSites"
                errors={errors}
                onChange={(e) => handleChange({ ...e, target: { ...e.target, value: e.value === null ? [] : e.value } })}
                value={_value}
                filter
            >
                <GT.Icon className="sampling-location-modal-button" name={["fas", "plus"]} onClick={() => setOpenModal(true)} />
            </GT.PrimeMultiSelect>
            {openModal && (
                <AddSamplingSiteModal handleSelected={handleSelectedSamplingSite} client={client} handleCloseModal={handleCloseModal} />
            )}
        </>
    );
};
