import React, { createContext, useContext, useState } from "react";

export const CustomFormContext = createContext<any>(null);

export const CustomFormContextProvider = ({ children }) => {
    const [isHideMode, setIsHideMode] = useState<boolean>(false);

    return (
        <CustomFormContext.Provider
            value={{
                isHideMode,
                setIsHideMode,
            }}
        >
            {children}
        </CustomFormContext.Provider>
    );
};

export const useCustomForm = (): IUseOrderRegister => {
    const context = useContext(CustomFormContext);
    if (context == null) return { isHideMode: false, setIsHideMode: undefined };
    return { ...context };
};

interface IUseOrderRegister {
    isHideMode: boolean;
    setIsHideMode?: React.Dispatch<React.SetStateAction<boolean>>;
}
