import { useEffect, useRef, useState } from "react";
import Axios from "axios";

interface IOption {
    app: string;
    model: string;
}

interface IOptions {
    [key: string]: IOption;
}

interface IUseGetGetModelFormData<T> {
    options: IOptions;
    mapFormData?: (data: T) => any;
    onSuccess?: Function;
    triggerValues?: any[];
    shouldFetch?: any;
}

// options eq: options: { status: { app: "demand", model: "SupplierContract" } },
export function useGetModelFormData<T = unknown>({
    options,
    mapFormData,
    onSuccess,
    triggerValues = [],
    shouldFetch = undefined,
}: IUseGetGetModelFormData<T>) {
    const [formData, setFormData] = useState<T | undefined | null>(undefined);

    useEffect(() => {
        const source = Axios.CancelToken.source();

        const getFormData = async () => {
            try {
                const response = await Axios.get("filter-options-prime", {
                    params: { json: btoa(JSON.stringify(options)) },
                    cancelToken: source.token,
                });
                const data = response.data.data ? response.data.data : response.data;
                const mappedFormData = mapFormData ? mapFormData(data) : data;
                setFormData(mappedFormData);
                onSuccess && onSuccess(mappedFormData);
            } catch {}
        };

        shouldFetch != false && Object.keys(options).length > 0 && getFormData();
        return () => source.cancel();
    }, [...triggerValues, shouldFetch]);

    return { formData };
}
