import React, { createContext, useContext, useState } from "react";

import { TABS } from "../components/sections/SamplingPlansTab";
import { ISamplingPlanTable } from "../interfaces/interfaces";

export const SamplingPlansContext = createContext<any>(null);

export const SamplingPlansContextProvider = ({ children }) => {
    const [selectedSamplingPlans, setSelectedSamplingPlans] = useState<ISamplingPlanTable[]>([]);
    const [selectedExternalSamplingPlans, setSelectedExternalSamplingPlans] = useState<ISamplingPlanTable[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>(TABS?.samplingPlans);

    return (
        <SamplingPlansContext.Provider
            value={{
                selectedSamplingPlans,
                setSelectedSamplingPlans,
                selectedExternalSamplingPlans,
                setSelectedExternalSamplingPlans,
                selectedTab,
                setSelectedTab,
            }}
        >
            {children}
        </SamplingPlansContext.Provider>
    );
};

export const useSamplingPlans = (): IUseSamplingPlans => {
    const {
        selectedSamplingPlans,
        setSelectedSamplingPlans,
        selectedExternalSamplingPlans,
        setSelectedExternalSamplingPlans,
        selectedTab,
        setSelectedTab,
    } = useContext(SamplingPlansContext);

    return {
        selectedSamplingPlans,
        setSelectedSamplingPlans,
        selectedExternalSamplingPlans,
        setSelectedExternalSamplingPlans,
        selectedTab,
        setSelectedTab,
    };
};

interface IUseSamplingPlans {
    selectedSamplingPlans: ISamplingPlanTable[];
    setSelectedSamplingPlans: (arg: ISamplingPlanTable[]) => void;
    selectedExternalSamplingPlans: ISamplingPlanTable[];
    setSelectedExternalSamplingPlans: (arg: ISamplingPlanTable[]) => void;
    selectedTab: string;
    setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}
