/**
 * External Dependencies
 */
import React from "react";
import { withRouter } from "react-router-dom";
/**
 * Internal Dependencies
 */
import { isMobile } from "../../../../common-assets/js/rootui-parts/_utility";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";
/**
 * Component
 */

class OrdersSamplingPage extends React.Component<any, any> {
    componentDidMount = updateBreadcrumb;

    render() {
        const { history, location } = this.props;

        if (isMobile) history.push(`/m${location.pathname}`);

        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={() => import("./content")} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default withRouter(OrdersSamplingPage);
