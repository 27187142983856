import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Collapse, Form, Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import Axios from "axios";

import CustomModalFooter from "../../../../../components/custom-modal-elements/custom-modal-footer";
import CustomModalHeader from "../../../../../components/custom-modal-elements/custom-modal-header";
import CustomModalInput from "../../../../../components/custom-modal-elements/custom-modal-input";
import { GtMarker } from "../../../../../components/gt-google-map-marker/gt-google-map-marker";
import PrimeInputGroup from "../../../../../components/prime-input-group/prime-input-group";
import { IClientSamplingSiteTable } from "../../../../CRM/Clients/interfaces/interfaces";
import { ISamplingSiteModal, ISamplingSiteTable } from "../../interfaces/interfaces";
import CustomSpinner from "../../../../../components/custom-spinner/custom-spinner";
import CustomButton from "../../../../../components/custom-button/custom-button";
import { handleErrorDelete } from "../../../../../utils/handleDeleteError";
import { samplingSitesUrls } from "../../utils/sampling-sites-urls";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { IReqParams } from "../../../../interfaces/IReqParams";
import { useResolved } from "../../../../../hooks/useResolved";
import { IErrors } from "../../../../interfaces/IErrors";
import addToast from "../../../../../utils/addToast";

import "../../modal-styles.scss";

type dataSetType = ISamplingSiteTable | IClientSamplingSiteTable;
interface ISamplingSiteModalProps {
    disabled?: boolean;
    editedRecord?: dataSetType | undefined;
    refresh?: Function;
    handleSelected?: (addedSiteId: number) => void;
    client?: number;
    permCode?: string;
    handleCloseModal?: () => void;
}

interface IModalContent extends ISamplingSiteModalProps {
    modalOpen: boolean;
    handleToggleModal: (modalState: boolean) => void;
    content: IContent;
}

interface IContent {
    headerText: string;
    footerText: string;
    permCode: string;
}

export interface IApiKey {
    googleMapsApiKey: string;
}

export const AddSamplingSiteModal = (props: ISamplingSiteModalProps) => {
    const { disabled = false, permCode = "basic-settings::sampling-sites.main", handleSelected, handleCloseModal } = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [content] = useState<IContent>({ headerText: "Add", permCode: `add.${permCode}.add`, footerText: "Save" });

    return (
        <>
            {!handleCloseModal && (
                <CustomButton
                    disabled={disabled}
                    text={handleSelected ? "Add sampling site" : "Add"}
                    onClick={() => setModalOpen(true)}
                    icon="plus"
                    permCode={content.permCode}
                />
            )}
            {(modalOpen || handleCloseModal) && (
                <ModalContent
                    {...props}
                    handleToggleModal={handleCloseModal ? () => handleCloseModal() : (modalOpen) => setModalOpen(modalOpen)}
                    modalOpen={modalOpen || !!handleCloseModal}
                    content={content}
                    permCode={permCode}
                />
            )}
        </>
    );
};

export const EditSamplingSiteModal = (props: ISamplingSiteModalProps) => {
    const { disabled = false, permCode = "basic-settings::sampling-sites.main" } = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [content] = useState<IContent>({
        headerText: "Edit",
        permCode: `change.${permCode}.change`,
        footerText: "Save changes",
    });

    return (
        <>
            <CustomButton disabled={disabled} text="Edit" onClick={() => setModalOpen(true)} icon="edit" permCode={content.permCode} />
            {modalOpen && (
                <ModalContent
                    {...props}
                    handleToggleModal={(modalOpen) => setModalOpen(modalOpen)}
                    modalOpen={modalOpen}
                    content={content}
                    permCode={permCode}
                />
            )}
        </>
    );
};
const DEFAULT_MAP_PROPS = {
    center: {
        lat: 50.0638439390694,
        lng: 19.87137369348826,
    },
    zoom: 14,
};

const ModalContent = (props: IModalContent) => {
    const { editedRecord, modalOpen, handleToggleModal, content, refresh, handleSelected, client, permCode } = props;
    const isExternal = permCode != "basic-settings::sampling-sites.main";

    const ref = useRef<GoogleMapReact>(null);

    const [data, setData] = useState<ISamplingSiteModal | undefined>(undefined);

    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
    const [errors, setErrors] = useState<IErrors>({});

    const { googleMapsApiKey } = useSelector((state) => ({ googleMapsApiKey: state.systemSettings?.googleMapsApiKey }));

    useEffect(() => {
        const onInit = () => {
            let _data: ISamplingSiteModal = {
                name: editedRecord?.name,
                address: editedRecord?.address,
                zipCode: editedRecord?.zipCode,
                city: editedRecord?.city,
                country: editedRecord?.country,
                externalCode: editedRecord?.externalCode,
                lat: editedRecord?.lat ? parseFloat(editedRecord.lat) : undefined,
                lng: editedRecord?.lng ? parseFloat(editedRecord.lng) : undefined,
            };

            setData(_data);
        };

        editedRecord && onInit();
    }, []);

    const handleCloseModal = () => handleToggleModal(false);

    const handleSubmitData = async (e: SyntheticEvent, reqParams: IReqParams = {}) => {
        e.preventDefault();

        try {
            let response;

            if (editedRecord) response = await Axios.patch(`${samplingSitesUrls.samplingSites(editedRecord?.id)}`, { ...data, reqParams });
            else response = await Axios.post(samplingSitesUrls.samplingSites(), { ...data, client, reqParams });

            refresh && refresh();
            handleSelected && handleSelected(response.data.data.id);

            addToast({ title: `Sampling site has been ${editedRecord ? "edited" : "added"} successfully.` });
            handleCloseModal();

            return response.status;
        } catch (e: any) {
            e.response?.status == "400" && setErrors(e.response.data);

            if (e.response?.status == "403") return e.response.data;
            else return e.response.status;
        }
    };

    const handleChangeState = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value || "" });
        errors[name] && setErrors(handleErrorDelete(errors, name));
    };

    const handleGetLatLng = async () => {
        try {
            const response = await Axios.post("get-google-location", {
                address: data?.name,
            });

            if (["ZERO_RESULTS", "INVALID_REQUEST"].includes(response.data.status)) {
                setErrors({ name: "Unable to get location" });
                return;
            }

            setData((prev) => ({
                ...prev,
                ...response.data.results[0]?.geometry.location,
                address: response.data.results[0]?.formattedAddress,
                city: response.data.results[0]?.addressComponents.find((component) => component.types.includes("locality"))?.longName,
                zipCode: response.data.results[0]?.addressComponents.find((component) => component.types.includes("postal_code"))?.longName,
                country: response.data.results[0]?.addressComponents.find((component) => component.types.includes("country"))?.longName,
            }));

            isExternal && ref.current && ref.current.map_.setCenter(response.data.results[0]?.geometry.location);
        } catch (err: any) {
            addToast({ title: "Unable to get location", color: "danger" });
            console.error(err);
        }
    };

    const handleLoadingLocationOnEdit = () => {
        editedRecord?.lat &&
            editedRecord?.lng &&
            ref.current &&
            ref.current.map_.setCenter({ lat: parseFloat(editedRecord.lat), lng: parseFloat(editedRecord.lng) });
    };

    return (
        <Modal isOpen={modalOpen} toggle={() => handleToggleModal(false)} fade>
            <CustomModalHeader closeModal={handleCloseModal} name={`${content.headerText} sampling site`} />
            <ModalBody>
                <PrimeInputGroup
                    errors={errors.name}
                    handleChange={handleChangeState}
                    forLabel="name"
                    labelText="Name"
                    name="name"
                    value={data?.name}
                    rightButton={
                        googleMapsApiKey ? (
                            <CustomButton
                                onClick={handleGetLatLng}
                                text=""
                                icon={{ name: ["fas", "location-crosshairs"] }}
                                tooltipContent="Get location"
                            />
                        ) : (
                            <></>
                        )
                    }
                />
                <div
                    className={`collapse-toggle-button ${isCollapseOpen ? "opened" : "closed"}`}
                    onClick={() => setIsCollapseOpen((prevState) => !prevState)}
                >
                    <span className="fa fa-chevron-down" />
                </div>
                <Collapse isOpen={isCollapseOpen}>
                    <CustomModalInput
                        errors={errors.externalCode}
                        handleChange={handleChangeState}
                        labelText="External code"
                        type="text"
                        name="externalCode"
                        value={data?.externalCode || ""}
                    />
                    <CustomModalInput
                        errors={errors.address}
                        handleChange={handleChangeState}
                        labelText="Address"
                        type="text"
                        name="address"
                        value={data?.address || ""}
                    />
                    <CustomModalInput
                        errors={errors.zipCode}
                        handleChange={handleChangeState}
                        labelText="Zip code"
                        type="text"
                        name="zipCode"
                        value={data?.zipCode || ""}
                    />
                    <CustomModalInput
                        errors={errors.city}
                        handleChange={handleChangeState}
                        labelText="City"
                        type="text"
                        name="city"
                        value={data?.city || ""}
                    />
                    <CustomModalInput
                        errors={errors.country}
                        handleChange={handleChangeState}
                        labelText="Country"
                        type="text"
                        name="country"
                        value={data?.country || ""}
                    />
                    <CustomModalInput
                        errors={errors.lat}
                        handleChange={handleChangeState}
                        labelText="Latitude"
                        type="text"
                        name="lat"
                        value={data?.lat || ""}
                    />
                    <CustomModalInput
                        errors={errors.lng}
                        handleChange={handleChangeState}
                        labelText="Longitude"
                        type="text"
                        name="lng"
                        value={data?.lng || ""}
                    />
                    {isExternal && googleMapsApiKey && (
                        <div style={{ height: "500px" }}>
                            <GoogleMapReact
                                onGoogleApiLoaded={handleLoadingLocationOnEdit}
                                bootstrapURLKeys={{ key: googleMapsApiKey }}
                                defaultCenter={DEFAULT_MAP_PROPS.center}
                                // center={{ lat: data?.lat, lng: data?.lng }}
                                defaultZoom={DEFAULT_MAP_PROPS.zoom}
                                options={{ fullscreenControl: false, zoomControl: false, clickableIcons: false }}
                                ref={ref}
                            >
                                <GtMarker lat={data?.lat} lng={data?.lng} tooltip={data?.address} />
                            </GoogleMapReact>
                        </div>
                    )}
                </Collapse>
            </ModalBody>
            <CustomModalFooter
                permCodeOk={content.permCode}
                okName={content.footerText}
                onClick={handleSubmitData}
                closeModal={handleCloseModal}
                cancelName="Cancel"
            />
        </Modal>
    );
};

const ModalCustomSpinner = () => (
    <div className="modal-custom-spinner-container">
        <CustomSpinner />
    </div>
);
