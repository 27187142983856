import React from "react";
 
import { IPrimeTableColumn } from "components/prime-data-table/interfaces";

import { Filters, Body } from "components/prime-data-table";
export const dealMaterialsDualTableColumns: IPrimeTableColumn[] = [
    {
        header: "Sampling point",
        field: "samplingPoint.name",
        sortable: true,
        filterBody: Filters.text,
    },
    {
        header: "Address",
        field: "samplingPoint.address",
        sortable: true,
        filterBody: Filters.text,
    },
    {
        header: "Material",
        field: "material.name",
        sortable: true,
        body: Body.superscript,
        filterBody: Filters.text,
    },
    {
        header: "Contract",
        field: "deal.code",
        sortable: true,
        filterBody: Filters.text
    },
    {
        header: "Client",
        field: "client",
        filterFieldName: 'deal.client.name',
        sortFieldName: 'deal.client.name',
        sortable: true,
        filterBody: Filters.text
    }
];
