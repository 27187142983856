import React, { CSSProperties, ReactNode, useRef, useState, MouseEventHandler } from "react";

import { GtTooltip, TooltipContentType } from "./gt-tooltip";
import Popper from "popper.js";

interface GtTooltipHOCProps {
    children: ReactNode;
    tooltipContent: TooltipContentType;
    placement?: Popper.Placement;
    style?: CSSProperties;
    className?: string;
    disableTranslation?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const TooltipHOC = ({
    children,
    tooltipContent,
    placement,
    style = {},
    onClick = undefined,
    className = "",
    disableTranslation = false,
}: GtTooltipHOCProps) => {
    if (tooltipContent === undefined) return <>{children}</>;

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => setIsHovered(true);

    const handleMouseLeave = () => setIsHovered(false);

    return (
        <>
            <GtTooltip
                targetRef={ref}
                isOpen={isHovered}
                tooltipContent={tooltipContent}
                placement={placement}
                disableTranslation={disableTranslation}
            />
            <div className={className} onClick={onClick} ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {children}
            </div>
        </>
    );
};
