import React, { useEffect, useState } from "react";

import hasPermission from "../../utils/hasPermissions";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "../icon";

import "./tileview.scss";

interface IProps {
    apps: {
        [key: string]: {
            icon: Array<string>;
            name: string;
            permCode: string;
        };
    };
    t: Function;
}

const TileView = (props: IProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const appsMapped =
        props.apps &&
        Object.entries(props.apps)
            .filter(([k, o]: any) => !o.permCode || hasPermission(o.permCode))
            .map(([k, o]: any) => ({
                name: props.t(o.name),
                url: k,
                icon: o.icon,
            }));

    //show tile after page transition, page transition with tiles looks terrible
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 200);
    }, []);

    return (
        <div className="content-wrap">
            <div className="ml-10">
                {isVisible &&
                    appsMapped?.map((app: any, i) => (
                        <Link key={i} to={app.url} className="bs-tile">
                            <div className="tile-back-style">
                                <Icon className="fa-7x" name={app.icon} />
                            </div>
                            <div className="tile-bottom-style font-weight-bold">{app.name}</div>
                        </Link>
                    ))}
            </div>
        </div>
    );
};

export default withTranslation()(connect(({ settings }) => ({ settings }))(TileView));
