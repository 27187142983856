import React, { Component } from "react";

import AsyncComponent from "../../../components/async-component";
import { TestsContextProvider } from "./context/tests-context";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class BSTests extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <TestsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </TestsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default BSTests;
