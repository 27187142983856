/**
 * Styles.
 */
import "./style.scss";

/**
 * External Dependencies
 */
import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../components/async-component";
import PageWrap from "../../../components/page-wrap";
import PageContent from "../../../components/page-content";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import { MetadataContextProvider } from "./context/metadata-context";

/**
 * Component
 */
class Metadata extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <MetadataContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </MetadataContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default Metadata;
