import React from "react";

import { Body, Filters, I } from "components/prime-data-table";

export const auditColumns: I.IPrimeTableColumn[] = [
    {
        header: "historyId",
        field: "historyId",
        sortable: true,
        width: 80,
        filterBody: Filters.text,
    },
    {
        header: "historyType",
        field: "historyType",
        sortable: true,
        body: Body.status,
        width: 150,
    },
    {
        header: "historyDate",
        field: "historyDate",
        filterBody: Filters.date,
        sortable: true,
        width: 170,
    },
    {
        header: "historyClientDate",
        field: "historyClientDate",
        filterBody: Filters.date,
        sortable: true,
        width: 170,
    },
    {
        header: "historyUser",
        field: "historyUser.fullName",
        sortable: true,
        filterBody: Filters.text,
    },
    {
        header: "historyChangeReason",
        field: "historyChangeReason",
        filterBody: Filters.text,
        sortable: true,
    },
    {
        header: "historyTransactionName",
        field: "historyTransactionName",
        filterBody: Filters.text,
        body: Body.translate,
        sortable: true,
    },
];
