import { ISelectedNodes } from "./interfaces/tree-interfaces";

interface ISelectedRecord {
    id: number;
    [key: string]: any;
}

export const generateSelectedNodes = (initSelectedNodes: ISelectedRecord[] | undefined): ISelectedNodes => {
    let selectedNodes = {};
    initSelectedNodes?.forEach((item) => (selectedNodes = { ...selectedNodes, [item.id]: { checked: true } }));
    return selectedNodes;
};

export const partialNodeToFully = (selected: ISelectedNodes): ISelectedNodes => {
    const _selected = {};

    Object.keys(selected).forEach((key) => {
        _selected[parseInt(key)] = { checked: selected[parseInt(key)].checked || selected[parseInt(key)].partialChecked == true };
    });

    return _selected;
};
