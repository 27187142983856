import React, { createContext, useContext, useState } from "react";

export const AdvancedRemarksContext = createContext<any>(null);

export const AdvancedRemarksContextProvider = ({ children }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>({});

    return (
        <AdvancedRemarksContext.Provider
            value={{
                modalOpen,
                setModalOpen,
                modalData,
                setModalData,
            }}
        >
            {children}
        </AdvancedRemarksContext.Provider>
    );
};

export const useAdvancedRemarks = (): IUseAdvancedRemarks => {
    const { modalOpen, setModalOpen, modalData, setModalData } = useContext(AdvancedRemarksContext);

    return {
        modalOpen,
        setModalOpen,
        modalData,
        setModalData,
    };
};

interface IUseAdvancedRemarks {
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    modalData: any;
    setModalData: React.Dispatch<any>;
}
