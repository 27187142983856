import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";

import { IFilterCheckboxInputProps } from "../interfaces/filter-interfaces";
import { memoFilter } from "./memoFilter";

// table header filter component, you send it in the column as filterBody prop
// eg. (filterBody: (inputData: IPrimeFilterData) => <MemoFilterCheckboxInput inputData={inputData} />)
export const MemoFilterCheckboxInput = memoFilter((props: IFilterCheckboxInputProps) => {
    const { column, filterArrayLength } = props.inputData;

    if (filterArrayLength < 2) return <FilterSelectInput {...props} />;
    else return <DisabledFilterSelectInput field={column.field} />;
});

export const FilterSelectInput = ({ inputData, placeholder, clearable = true }: IFilterCheckboxInputProps) => {
    const { t } = useTranslation();
    const { column, onFilterChange, filter } = inputData;

    const [filterChoices] = useState<ILabelValue[]>(
        [
            { label: "Selected", value: "True" },
            { label: "Not selected", value: "False" },
        ].map((o) => ({ ...o, label: t(o.label) }))
    );

    return (
        <div key={`prime-checkbox-input-${column.field}-container`} className="filter-select-input-container">
            <Dropdown
                key={`prime-checkbox-input-${column.field}`}
                className="custom-search-table-input prime-table-filter-input-font"
                name={column.field}
                value={filter?.value}
                options={filterChoices}
                onChange={(e) => onFilterChange({ name: e.target.name, value: e.target.value }, "__exact")}
                optionLabel="label"
                optionValue="value"
                placeholder={placeholder ? t(placeholder) : `${t("Filter")} ${t(column.header)}`}
                showClear={clearable}
            />
        </div>
    );
};

interface IDisabledFilterSelectInput {
    field: string;
}

export const DisabledFilterSelectInput = ({ field }: IDisabledFilterSelectInput) => {
    const { t } = useTranslation();

    return (
        <div key={`prime-checkbox-input-${field}-container-disabled`} className="filter-select-input-container">
            <Dropdown
                key={`prime-checkbox-input-${field}-disabled`}
                className="custom-search-table-input prime-table-filter-input-font"
                placeholder={t("Advanced filter...")}
                disabled
            />
        </div>
    );
};
