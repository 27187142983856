import React from "react";

import { StabilityTemplatesContextProvider } from "./context/sampling-plans-context";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class StabilityTemplatesPage extends React.Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <StabilityTemplatesContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </StabilityTemplatesContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default StabilityTemplatesPage;
