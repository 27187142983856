import React, { createContext, useContext, useState } from "react";
import { IReportTemplate, IReportTemplateCategory } from "../interface/interface";

export const ReportTemplatesContext = createContext<any>(null);

export const ReportTemplatesContextProvider = ({ children }) => {
    const [selectedReportTemplateCategories, setSelectedReportTemplateCategories] = useState<IReportTemplateCategory[]>([]);
    const [selectedReportTemplates, setSelectedReportTemplates] = useState<IReportTemplate[]>([]);
    const [selectedRBTemplates, setSelectedRBTemplates] = useState<any[]>([]);
    const [reportBroData, setReportBroData] = useState<IReportBroData>({ isReportBroOpen: false });

    return (
        <ReportTemplatesContext.Provider
            value={{
                selectedReportTemplateCategories,
                setSelectedReportTemplateCategories,
                selectedReportTemplates,
                setSelectedReportTemplates,
                selectedRBTemplates,
                setSelectedRBTemplates,
                reportBroData,
                setReportBroData,
            }}
        >
            {children}
        </ReportTemplatesContext.Provider>
    );
};

export const useReportTemplates = (): IUseReportTemplates => {
    const {
        selectedReportTemplateCategories,
        setSelectedReportTemplateCategories,
        selectedReportTemplates,
        setSelectedReportTemplates,
        selectedRBTemplates,
        setSelectedRBTemplates,
        reportBroData,
        setReportBroData,
    } = useContext(ReportTemplatesContext);

    return {
        selectedReportTemplateCategories,
        setSelectedReportTemplateCategories,
        selectedReportTemplates,
        setSelectedReportTemplates,
        selectedRBTemplates,
        setSelectedRBTemplates,
        reportBroData,
        setReportBroData,
    };
};

interface IUseReportTemplates {
    selectedReportTemplateCategories: IReportTemplateCategory[];
    setSelectedReportTemplateCategories: React.Dispatch<React.SetStateAction<IReportTemplateCategory[]>>;
    selectedReportTemplates: IReportTemplate[];
    setSelectedReportTemplates: React.Dispatch<React.SetStateAction<IReportTemplate[]>>;
    selectedRBTemplates: any[];
    setSelectedRBTemplates: React.Dispatch<React.SetStateAction<any[]>>;
    reportBroData: IReportBroData;
    setReportBroData: React.Dispatch<React.SetStateAction<IReportBroData>>;
}

interface IReportBroData {
    isReportBroOpen: boolean;
    report?: {
        reportDefinition: string;
        name: string;
        id: number;
    };
    qbes?: string[];
}
