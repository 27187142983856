import Axios from "axios";

export const patchTable = (newRecord, setData) => {
    setData((prev) => ({ ...prev, data: prev.data.map((record) => (record.id == newRecord.id ? newRecord : record)) }));
};

export const patchTableRequest = async (url, payload, setData, onResponse?: any) => {
    const response = await Axios.patch(url, payload);
    onResponse && (await onResponse(response));
    !!response.data?.data && patchTable(response.data.data, setData);
    return response;
};
