import React, { Component } from "react";

import { ReportTemplatesContextProvider } from "./context/context";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class BSReportTemplate extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <ReportTemplatesContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </ReportTemplatesContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default BSReportTemplate;
