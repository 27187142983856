/**
 * Styles
 */
import "./style.scss";

/**
 * External Dependencies
 */
import DatePicker from "react-datepicker";

export default DatePicker;
