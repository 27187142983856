import React from "react";

import classNames from "classnames/dedupe";

export const highlightTextIncluded = (text: string, highlight: string) => {
    const rePos = new RegExp(
        "(?<=" + highlight + ")|(?<=" + highlight.toUpperCase() + ")|(?=" + highlight + ")|(?=" + highlight.toUpperCase() + ")"
    );
    const split = text.split(rePos);
    return split.map((item) => (
        <span className={classNames({ highlight: item == highlight || item == highlight.toUpperCase() })}>{item}</span>
    ));
};
