import React from "react";

import { useSamplingPlans } from "pages/CRM/SamplingPlans/context/sampling-plans-context";
import { ExternalSamplingPlansTable } from "./ExternalSamplingPlans/sampling-plans-table";
import { SamplingPlansTable } from "./SamplingPlans/sampling-plans-table";
import CustomTabs from "components/custom-tabs/custom-tabs";

export type Tabs = "samplingPlans" | "externalSamplingPlans";

export const SamplingPlansTab = () => {
    const { selectedTab, setSelectedTab } = useSamplingPlans();

    return (
        <CustomTabs
            tabs={[
                { name: TABS.samplingPlans, permCode: "view.crm::sampling-plans.main.view" },
                { name: TABS.externalSamplingPlans, permCode: "view.crm::sampling-plans.external-sampling-plans.view" },
            ]}
            value={selectedTab}
            handleChange={setSelectedTab}
            tabContent={(tab) =>
                ({
                    [TABS.samplingPlans]: <SamplingPlansTable />,
                    [TABS.externalSamplingPlans]: <ExternalSamplingPlansTable />,
                }[tab])
            }
        />
    );
};

export const TABS: { [key in Tabs]: string } = {
    samplingPlans: "Sampling plans",
    externalSamplingPlans: "External sampling plans",
};
