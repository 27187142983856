import { Modal, ModalBody } from "reactstrap";
import React, { useState } from "react";
import Axios from "axios";

import { PdfPreview } from "./PdfPreview";

import { useMountTransition } from "hooks";
import * as GT from "components";

interface IPdfPreviewModalProps {
    file: { fileName: string; file: string };
    disabled?: boolean;
    closeModal?: () => void;
    btnPermCode?: string;
}

interface IModalContent extends IPdfPreviewModalProps {
    modalOpen: boolean;
    closeModal: () => void;
}

export const PdfPreviewModal = (props: IPdfPreviewModalProps) => {
    const { disabled = false, closeModal, btnPermCode } = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const hasTransitionedIn = useMountTransition(modalOpen);

    return (
        <>
            {!closeModal && <GT.Button disabled={disabled} text="Pdf preview" onClick={() => setModalOpen(true)} permCode={btnPermCode} />}
            {(modalOpen || hasTransitionedIn || closeModal) && (
                <ModalContent
                    {...props}
                    closeModal={closeModal ? () => closeModal() : () => setModalOpen(false)}
                    modalOpen={modalOpen || !!closeModal}
                />
            )}
        </>
    );
};

const ModalContent = ({ modalOpen, closeModal, file }: IModalContent) => {
    const fileBaseURL = Axios.defaults.baseURL?.replace(/\/api$/, "");

    return (
        <Modal isOpen={modalOpen} toggle={closeModal} fade>
            <GT.ModalHeader closeModal={closeModal} name="Pdf preview" />
            <ModalBody>
                <PdfPreview
                    showDownloadBtn
                    containerClassName="sample-workflow-pdf-preview"
                    file={{ name: file.fileName, path: fileBaseURL + "/" + getFile(file.file) }}
                    loadingComponent={
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <GT.Spinner />
                        </div>
                    }
                />
            </ModalBody>
            <GT.ModalFooter closeModal={closeModal} cancelName="close" />
        </Modal>
    );
};

const getFile = (file: string): string => {
    let strToExclude = file.getBefore("media/") as string;

    return file.getAfter(strToExclude) as string;
};
