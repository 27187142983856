import React, { useEffect, useState } from "react";

import { MultiSelect } from "primereact/multiselect";
import { Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

import CustomModalHeader from "../../../custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../../custom-modal-elements/custom-modal-footer";
import { getVisibleAndHiddenColumns } from "../../helpers/context-menu-helpers";
import { IGeneratedPrimeTableColumn } from "../../interfaces/table-interfaces";
import CustomForm from "../../../custom-form/custom-form";

interface IHideColumns {
    defaultColumns: IGeneratedPrimeTableColumn[];
    visibleColumns: IGeneratedPrimeTableColumn[];
    handleHideColumns: (arg1: IGeneratedPrimeTableColumn[], arg2: IGeneratedPrimeTableColumn[]) => void;
    closeModal: () => void;
}

export interface IVisibleColumn extends IGeneratedPrimeTableColumn {
    index?: number;
}

export const HideColumnsModal = ({ defaultColumns, visibleColumns, handleHideColumns, closeModal }: IHideColumns) => {
    const { t } = useTranslation();

    const [selectedVisibleColumns, setSelectedVisibleColumns] = useState<string[]>(visibleColumns.map((col) => col.field));

    useEffect(() => {
        setSelectedVisibleColumns(visibleColumns.map((col) => col.field));
    }, []);

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const columns = getVisibleAndHiddenColumns(defaultColumns, visibleColumns, selectedVisibleColumns);

        handleHideColumns(columns.visibleColumns, columns.hiddenColumns);
        closeModal();
    };

    return (
        <Modal isOpen={true} fade toggle={closeModal}>
            <CustomForm permCode="" disableTransactions onSubmit={handleSave}>
                <CustomModalHeader closeModal={closeModal} name="Columns settings" />
                <ModalBody>
                    <MultiSelect
                        className="custom-search-table-input"
                        value={selectedVisibleColumns}
                        options={defaultColumns}
                        optionLabel="header"
                        optionValue="field"
                        onChange={(e) => setSelectedVisibleColumns(e.target.value)}
                        placeholder={`${t("Select columns")}`}
                        maxSelectedLabels={0}
                        selectedItemsLabel={`${t("items selected", { count: selectedVisibleColumns.length })}`}
                        filter
                    />
                </ModalBody>
                <CustomModalFooter
                    closeModal={closeModal}
                    okName="Save"
                    cancelName="Close"
                    okIsDisabled={selectedVisibleColumns.length < 1}
                />
            </CustomForm>
        </Modal>
    );
};
