import React from "react";

import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import Icon from "../icon";

import SuperscriptDisplay from "../superscript/superscript-display";
import { useCustomForm } from "../custom-form/context/context";

import "./custom-modal-header.scss";

interface ICustomModalHeader {
    t?(name: string, nameVars?: any): string;
    closeModal?: Function | (() => void);
    name?: string;
    nameVars?: any;
    superscript?: boolean;
}

const CustomModalHeader = (props: ICustomModalHeader) => {
    const { t, closeModal, name, nameVars, superscript } = props;

    const { isHideMode, setIsHideMode } = useCustomForm();

    return (
        <div className="modal-header">
            {name &&
                (superscript ? (
                    <SuperscriptDisplay className="modal-title h2" value={t && t(name, nameVars)} />
                ) : (
                    <h5 className="modal-title h2">{t && t(name, nameVars)}</h5>
                ))}
            <div>
                <Button className="close" color="" onClick={closeModal as () => void}>
                    <Icon name="x" />
                </Button>
                {/* hidden till done */}
                {/* {setIsHideMode != undefined && (
                    <Button
                        className={`close btn-hide-fields ${isHideMode ? "rotate" : ""}`}
                        color=""
                        onClick={setIsHideMode && (() => setIsHideMode((prev: boolean) => !prev))}
                    >
                        <Icon name={["fas", "ellipsis"]} />
                    </Button>
                )} */}
            </div>
        </div>
    );
};

export default withTranslation()(CustomModalHeader);
