import React, { Component } from "react";

import { LocationContextProvider } from "./context/location-context";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class BSLocations extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <LocationContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </LocationContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default BSLocations;
