import React, { useState, useEffect, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import axios from "axios";

import { generateAdvancedFiltersOptions, IAdvancedFiltersOptions } from "../../../helpers/generateAdvancedFilter";
import { customStyles } from "../../../../custom-modal-elements/custom-modal-select";
import { checkboxOptions, filterTypeOptions } from "./filterTypeOptions";
import { FilteringType } from "components/prime-data-table/interfaces";
import CustomSpinner from "components/custom-spinner/custom-spinner";
import CustomButton from "components/custom-button/custom-button";
import { generateFilters, refreshFilters } from "./functions";
import { FilterInput } from "./filter-input";
import Icon from "components/icon";

import * as interfaces from "./interfaces";

export const AdvanceFiltersWrapper = (props: interfaces.IAdvanceFilters) => {
    const { advancedFiltersConfig } = props;
    if (!advancedFiltersConfig) return <></>;

    const { t } = useTranslation();

    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [advancedFiltersOptions, setAdvancedFiltersOptions] = useState<IAdvancedFiltersOptions[] | undefined>(undefined);

    const _filterTypeOptions: interfaces.IFilterTypeOptions = useMemo(
        () => ({
            number: filterTypeOptions.number.map((o) => ({ ...o, label: t(o.label) })),
            date: filterTypeOptions.date.map((o) => ({ ...o, label: t(o.label) })),
            text: filterTypeOptions.text.map((o) => ({ ...o, label: t(o.label) })),
            dropdown: filterTypeOptions.dropdown.map((o) => ({ ...o, label: t(o.label) })),
            checkbox: filterTypeOptions.checkbox.map((o) => ({ ...o, label: t(o.label) })),
        }),
        []
    );

    useEffect(() => {
        setAdvancedFiltersOptions(generateAdvancedFiltersOptions(advancedFiltersConfig, t));
    }, []);

    return (
        <>
            <CustomButton text="Advanced filtering" icon={{ name: ["fas", `search`] }} withoutPerm onClick={() => setFilterVisible(true)} />
            {filterVisible && advancedFiltersOptions && (
                <AdvanceFilters
                    {...props}
                    togglePopup={(popUpOpen) => setFilterVisible(popUpOpen)}
                    advancedFiltersOptions={advancedFiltersOptions}
                    filterTypeOptions={_filterTypeOptions}
                    t={t}
                />
            )}
        </>
    );
};

interface IFilterValueOptions {
    [key: string]: { [key: string]: ILabelValue[] };
}

const AdvanceFilters = (props: interfaces.IExtendedAdvanceFilters) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [filters, setFilters] = useState<interfaces.IFilter[]>([]);
    const [filterValueOptions, setFilterValueOptions] = useState<IFilterValueOptions | undefined>(undefined);

    const { onReload, tableFilters, togglePopup, advancedFiltersOptions, t, filterTypeOptions } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                togglePopup(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const getOptions = async () => {
            const filtersWithOptions = advancedFiltersOptions.filter((filter) => filter.filterOptionConfig);
            let mappedOptions: interfaces.IMappedOptions = {};

            filtersWithOptions.forEach((o) => {
                const optionsKey: string = o.filterOptionConfig?.appModelPath as string;
                const filterField: string = o.filterOptionConfig?.field as string;

                mappedOptions[optionsKey] = mappedOptions[optionsKey] ? [...mappedOptions[optionsKey], filterField] : [filterField];
            });

            if (Object.keys(mappedOptions).length != 0) {
                try {
                    const response = await axios.put("filters/fields-choices", mappedOptions);
                    setFilterValueOptions({
                        ...response.data,
                        additionalOptions: { checkboxOptions: checkboxOptions.map((o) => ({ ...o, label: t(o.label) })) },
                    });
                } catch (e) {}
            }

            generateFilters(tableFilters, setFilters);
        };

        getOptions();
    }, [advancedFiltersOptions]);

    const handleChange = (inputValue: string | undefined | FilteringType, index: number, inputType: interfaces.IInputType) => {
        const _filters = [...filters];
        const advancedFilter = advancedFiltersOptions.find((f) => f.filterField == inputValue);

        switch (inputType) {
            case "column":
                if (advancedFilter?.filterOptionConfig || advancedFilter?.type == "checkbox") {
                    _filters[index].filterType = "__exact";
                    _filters[index].value = "";
                } else {
                    _filters[index].filterType = "";
                    _filters[index].value = "";
                }

                _filters[index].filterField = inputValue;
                break;
            case "condition":
                _filters[index].filterType = inputValue as FilteringType;
                break;
            case "value":
                _filters[index].value = inputValue;
                break;
        }

        setFilters(_filters);
    };

    const handleRefresh = () => refreshFilters(filters, onReload, togglePopup);

    const handleAddFilter = () => {
        const _filters = [...filters];

        _filters.push({ id: uuid(), filterField: "", filterType: "", value: "" });
        setFilters([..._filters]);
    };

    const handleDeleteFilter = (index) => {
        if (filters.length == 1) {
            setFilters([{ id: filters[0].id, filterField: null, filterType: "", value: "" }]);
        } else {
            const _filters = [...filters];

            _filters.splice(index, 1);
            setFilters([..._filters]);
        }
    };

    return (
        <>
            <div className="popup" ref={wrapperRef}>
                {filterValueOptions ? (
                    <>
                        {filters.map((filter, index) => {
                            const advancedFilter = advancedFiltersOptions.find((f) => f.filterField == filter.filterField);

                            return (
                                <Row key={`custom-filter-table-${filter.id}`} className="advanced-filters-row">
                                    <Col sm={4}>
                                        {/* Filter field input */}
                                        <Select
                                            className="custom-gt-select"
                                            styles={customStyles}
                                            options={advancedFiltersOptions}
                                            onChange={(e) => handleChange(e.filterField, index, "column")}
                                            placeholder={`${t("Select")}...`}
                                            value={advancedFilter ? advancedFilter : null}
                                            getOptionLabel={(o) => o.filterLabel}
                                            getOptionValue={(o) => o.filterField}
                                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        {/* Filter type input */}
                                        <Select
                                            className="custom-gt-select"
                                            styles={customStyles}
                                            options={advancedFilter ? filterTypeOptions[advancedFilter.type] : []}
                                            onChange={(o) => handleChange(o.value, index, "condition")}
                                            placeholder={`${t("Select")}...`}
                                            value={
                                                advancedFilter
                                                    ? filterTypeOptions[advancedFilter.type].find((o) => o.value == filter.filterType)
                                                    : null
                                            }
                                            isDisabled={advancedFilter?.filterOptionConfig || advancedFilter?.type == "checkbox"}
                                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <FilterInput
                                            advancedFilter={advancedFilter}
                                            filter={filter}
                                            onChange={handleChange}
                                            filterValueOptions={filterValueOptions}
                                            index={index}
                                        />
                                    </Col>
                                    <Col sm={1} className="ml-4">
                                        <span
                                            className="btn btn-custom-round mt-10"
                                            onClick={() => handleDeleteFilter(index)}
                                            title={t("Remove filter")}
                                        >
                                            <Icon name={["fas", "minus"]} color="#ef5164ee" />
                                        </span>
                                    </Col>
                                </Row>
                            );
                        })}

                        <Row>
                            <Col sm="12">
                                <div className="filter-placeholder" onClick={handleAddFilter}>
                                    {t("Click to add filter")}
                                </div>
                            </Col>
                        </Row>

                        <span className="btn btn-outline-brand mt-5 mb-5 mr-0" onClick={handleRefresh} title={t("Filter")}>
                            {t("Filter")}
                        </span>
                    </>
                ) : (
                    <CustomSpinner />
                )}
            </div>
        </>
    );
};
