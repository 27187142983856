import React, { CSSProperties, MutableRefObject, RefObject, SetStateAction, useState } from "react";

import { COLORS_ARRAY } from "../../utils/colors-array";
import ReactQuill from "react-quill";

import "./rich-text-input.scss";
import { useTranslation } from "react-i18next";

// https://quilljs.com/docs/modules/toolbar/#container
const TOOLBAR_TEMPLATE = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ color: COLORS_ARRAY }, { background: COLORS_ARRAY }],
    [{ font: [] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    ["clean"],
];

interface IRichTextInput {
    id?: string;
    className?: string;
    style?: CSSProperties;
    value?: string;
    readOnly?: boolean;
    defaultValue?: string;
    placeholder?: string;
    toolbarTemplate?: any[];
    autofocus?: boolean;
    rtRef?: MutableRefObject<ReactQuill | null>;
    onChange?: (content: string) => void;
    onChangeSelection?: (range: IRange) => void;
    onFocus?: (range: IRange) => void;
    onBlur?: (range: IRange) => void;
    onKeyPress?: React.EventHandler<any>;
    onKeyDown?: React.EventHandler<any>;
    onKeyUp?: React.EventHandler<any>;
}

interface IRange {
    index: number;
    length: number;
}

export const RichTextInput = (props: IRichTextInput) => {
    const [_value, setValue] = useState<string>("");
    const {
        id = "gt-rich-text-input",
        className = "gt-rich-text-input",
        style,
        value,
        readOnly = false,
        defaultValue,
        placeholder = !readOnly ? "Start editing..." : "",
        toolbarTemplate = TOOLBAR_TEMPLATE,
        autofocus = false,
        onChange = setValue,
        onChangeSelection,
        onFocus,
        onBlur,
        onKeyPress,
        onKeyDown,
        onKeyUp,
    } = props;

    let { rtRef } = props;

    const { t } = useTranslation();

    return (
        <ReactQuill
            id={id}
            className={className}
            style={style}
            value={value || _value}
            ref={(ref) => {
                rtRef && (rtRef.current = ref);
                autofocus && ref?.focus();
            }}
            readOnly={readOnly}
            defaultValue={defaultValue}
            placeholder={t(placeholder)}
            onChange={onChange}
            onChangeSelection={onChangeSelection}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            modules={{
                toolbar: !readOnly && toolbarTemplate,
            }}
            theme="snow"
        />
    );
};

export const isRichTextInputEmpty = (value: string): boolean => {
    const regex = /(<([^>]+)>)/gi;
    return !value.replace(regex, "").length;
};
