import i18n from "i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

const localStorage = JSON.parse(window.localStorage["persist:root"] ?? null);
const savedLanguage = JSON.parse(localStorage?.auth ?? null)?.language ?? process.env.REACT_APP_LANGUAGE_CODE;

const importAll = (r) => r.keys().map(r);

const pl = importAll(require.context(`../locales/pl`, true, /\.(json)$/));
const en = importAll(require.context(`../locales/en`, true, /\.(json)$/));
const cz = importAll(require.context(`../locales/cz`, true, /\.(json)$/));
const de = importAll(require.context(`../locales/de`, true, /\.(json)$/));
const fr = importAll(require.context(`../locales/fr`, true, /\.(json)$/));

import plTimeAgo from "../locales/pl/timeAgo/pl"; // custom made translation because original creator didnt account for %d seconds
import enTimeAgo from "../locales/en/timeAgo/en";
import czTimeAgo from "../locales/cz/timeAgo/cz";
import deTimeAgo from "../locales/de/timeAgo/de";
import frTimeAgo from "../locales/fr/timeAgo/fr";

export const timeAgoLangs = {
    pl: plTimeAgo,
    en: enTimeAgo,
    cz: czTimeAgo,
    de: deTimeAgo,
    fr: frTimeAgo,
};

const translations = {
    pl: {
        translation: Object.assign(...pl),
    },
    en: {
        translation: Object.assign(...en),
    },
    cz: {
        translation: Object.assign(...cz),
    },
    de: {
        translation: Object.assign(...de),
    },
    fr: {
        translation: Object.assign(...fr),
    },
};

i18n.use(XHR)
    //.use(LanguageDetector)
    .init({
        debug: false, // change to false later
        lng: savedLanguage,
        fallbackLng: "en", // use en if detected lng is not available
        //keySeparator: true, // ...

        keySeparator: ">>",
        nsSeparator: false,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        resources: translations,

        // have a common namespace used around the full app
        // ns: ["translations"],
        // defaultNS: "translations"
    });

export default i18n;
