import { v4 as uuid } from "uuid";

import { IPrimeTableFilters } from "components/prime-data-table/interfaces";
import { IFilter, IValidatedFilter } from "./interfaces";

export const generateFilters = (tableFilters, setFilters) => {
    if (tableFilters) {
        const _tableFilters = { ...tableFilters };
        let _filters: IFilter[] = [];

        Object.keys(_tableFilters).map((columnFilterKey) => {
            _tableFilters[columnFilterKey].forEach((columnsFilter) => {
                if (typeof columnsFilter.value != "string" && typeof columnsFilter.value != "number") {
                    _filters.push(
                        {
                            id: uuid(),
                            filterField: columnFilterKey,
                            filterType: "__gte",
                            value: columnsFilter.value.startDt,
                        },
                        {
                            id: uuid(),
                            filterField: columnFilterKey,
                            filterType: "__lte",
                            value: columnsFilter.value.endDt,
                        }
                    );
                } else {
                    _filters.push({
                        id: uuid(),
                        filterField: columnFilterKey,
                        filterType: columnsFilter.filteringType,
                        value: columnsFilter.value,
                    });
                }
            });
        });
        setFilters(_filters);
    } else {
        setFilters([{ id: uuid(), filterField: "", filterType: "", value: "" }]);
    }
};

export const refreshFilters = (filters, onReload, togglePopup) => {
    const validatedFilters: IValidatedFilter[] = filters.filter((filter) =>
        filter.filterField &&
        filter.filterField.trim().length > 0 &&
        filter.filterType &&
        filter.filterType.trim().length > 0 &&
        filter.value &&
        typeof filter.value == "string"
            ? filter.value.trim().length > 0
            : filter.value
    ) as IValidatedFilter[];

    let primeTableFilters: IPrimeTableFilters = {};

    validatedFilters.forEach((filter) => {
        if (primeTableFilters[filter.filterField])
            primeTableFilters[filter.filterField].push({ filteringType: filter.filterType, value: filter.value });
        else primeTableFilters[filter.filterField] = [{ filteringType: filter.filterType, value: filter.value }];
    });

    const keys = Object.keys(primeTableFilters);

    if (keys.length > 0) {
        keys.map((filterKey) => {
            if (filterKey.endsWith("Dt")) {
                if (
                    primeTableFilters[filterKey].length == 2 &&
                    primeTableFilters[filterKey][0].filteringType == "__gte" &&
                    primeTableFilters[filterKey][1].filteringType == "__lte"
                ) {
                    primeTableFilters[filterKey] = [
                        {
                            filteringType: "__range",
                            value: {
                                startDt: primeTableFilters[filterKey][0].value,
                                endDt: primeTableFilters[filterKey][1].value,
                            } as any,
                        },
                    ];
                }
            }
        });

        onReload(primeTableFilters);
    } else onReload(undefined);

    togglePopup(false);
};
