import React from "react";

import Icon from "../icon";

import "./error-page-fallback.style.scss";
import { useTranslation } from "react-i18next";

export const ErrorPageFallback = () => {
    const { t } = useTranslation();

    return (
        <div className="error-page-fallback">
            <Icon name={["far", "face-frown"]}></Icon>
            <h1>{t("OOOPS! Something went wrong here...")}</h1>
            <p>{t("Sorry we're having some technical issue. Try to refresh the page, sometime it works.")}</p>
        </div>
    );
};

export const NetworkErrorPageFallback = () => {
    const { t } = useTranslation();

    return (
        <div className="error-page-fallback">
            <Icon name={["far", "face-frown"]}></Icon>
            <h1>{t("OOOPS! There is no connection with server...")}</h1>
            <p>{t("Connection to the server has not been established.")}</p>
            <p>{t("Click the refresh button or try again later.")}</p>
            <div onClick={() => window.location.reload()} className="refresh-button" role="button">
                <Icon name={["fas", "arrows-rotate"]} />
            </div>
        </div>
    );
};
