import React, { Fragment } from "react";

import { IAsyncTask } from "../async-tasks-dropdown";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import Icon from "../../icon";

export interface IAsyncTasksCollapse {
    content: (task: IAsyncTask) => React.ReactElement<any>;
    tasks: IAsyncTask[];
    title: string;
}

interface IAsyncTasksCollapseProps {
    onToggleCollapse: (index: number) => void;
    indexOpen: number[];
    configObject: IAsyncTasksCollapse[];
}

export const AsyncTasksDropdownContent = (props: IAsyncTasksCollapseProps) => {
    const { indexOpen, onToggleCollapse, configObject } = props;
    const { t } = useTranslation();
    return (
        <>
            {configObject.map(({ title, tasks, content }, index) => (
                <Fragment key={index}>
                    <div className="collapse-btn" onClick={() => onToggleCollapse(index)}>
                        <h4 className={`p-15 m-0 no-select ${indexOpen.includes(index) ? "open" : ""}`}>
                            <div>
                                <Icon name={["fas", "angle-right"]} className="icon" />
                                {t(title)}
                            </div>
                            <span className="badge">{tasks.length}</span>
                        </h4>
                    </div>
                    <Collapse isOpen={indexOpen.includes(index)} className="async-tasks-collapse">
                        <div className="tasks-container">
                            <ul className="tasks">
                                {tasks.map((task, index) => (
                                    <li key={index}>{content(task)}</li>
                                ))}
                            </ul>
                        </div>
                    </Collapse>
                </Fragment>
            ))}
        </>
    );
};
