import React, { Fragment, useState } from "react";
import { Label, FormGroup, Col} from "reactstrap";
import DateTimePicker from "../../components/date-time-picker";
import { withTranslation } from 'react-i18next';

import pl from 'date-fns/locale/pl'
import en from 'date-fns/locale/en-GB'

import Select from "react-select";
import {customStyles} from '../../components/custom-modal-elements/custom-modal-select'
import Icon from "../icon";

const languages = {
    'pl': pl,
    'en': en
}

const CustomModalDateTimePickerDouble  = (props) => {
    const { i18n, t, handleChange, labelText, name, format="yyyy-MM-dd HH:mm", startDate, endDate, errors, showTimeInput, disabled } = props

    
    const years = Array.from({length: 130}, (_, i) => i + 1900)
    const yearsOpts = years.map( y => ({ label: y, value: y }))

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    const monthsOpts = months.map( m => ({ label: m, value: m }))
    const today = new Date()

    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                placeItems: "center"
            }}
        >
            { !prevMonthButtonDisabled && (
                <span className="btn btn-custom-round inline-block mr-20" onClick={ decreaseMonth } >
                    <Icon name="chevron-left" />
                </span>
            ) }
            
            <Select className='custom-gt-select w-100px'
                styles={customStyles}
                options={ yearsOpts }
                onChange={ e => changeYear(e.value) }
                placeholder={ `${props.t('Year')}...` }
                defaultValue = { yearsOpts.find( x => x.value == today.getFullYear() ) } 
            />
            
            <Select className='custom-gt-select w-130px'
                styles={customStyles}
                options={ monthsOpts }
                onChange={ e => changeMonth(months.indexOf(e.value)) }
                placeholder={ `${props.t('Month')}...` }
                defaultValue = { monthsOpts.find( x => x.value == months[today.getMonth()].toString() ) } 
            />

            { !nextMonthButtonDisabled && (
                <span className="btn btn-custom-round inline-block ml-20" onClick={ increaseMonth } >
                    <Icon name="chevron-right" />
                </span>
            ) }
        </div>
    )


    return (
        <>
            <FormGroup row>
                <Label sm={3}>
                    { t(labelText[0]) }:
                </Label>
                <Col>
                    <DateTimePicker
                        selectsStart
                        startDate={(startDate) ? new Date(startDate) : undefined}
                        endDate={(endDate) ?  new Date(endDate) : undefined}
                        name={name[0]}
                        onChange={(date) => handleChange(date, name[0])}
                        className= {`rui-datetimepicker form-control w-100 ${errors[0] ? " is-invalid":""}`}
                        selected={startDate ? new Date(startDate) : false}
                        dateFormat={format}
                        showMonthDropdown
                        showYearDropdown
                        showTimeInput
                        placeholderText={`${ t(labelText[0]) }...`}
                        locale={ languages[i18n.language] }
                        autoComplete="off"
                        renderCustomHeader={customHeader}
                        disabled={disabled}

                        timeInputLabel={t('Time') + ":"}
                    />
                    {errors[0] && (  <Label className="invalid-feedback">{t(errors[0])}</Label> )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3}>
                    { t(labelText[1]) }:
                </Label>
                <Col>
                    <DateTimePicker
                        selectsEnd
                        startDate={startDate && new Date(startDate)}
                        endDate={endDate &&  new Date(endDate)}
                        name={name[1]}
                        onChange={(date) => handleChange(date, name[1])}
                        className= {`rui-datetimepicker form-control w-100 ${errors[1] ? " is-invalid":""}`}
                        selected={endDate &&  new Date(endDate)}
                        dateFormat={format}
                        showMonthDropdown
                        showYearDropdown
                        showTimeInput
                        placeholderText={`${ t(labelText[1]) }...`}
                        locale={ languages[i18n.language] }
                        autoComplete="off"
                        renderCustomHeader={customHeader}
                        disabled={disabled}
                        
                        timeInputLabel={t('Time') + ":"}
                    />
                    {errors[1] && (<Label className="invalid-feedback">{t(errors[1])}</Label> )}
                </Col>
            </FormGroup>
        </>
            
    );
};

export default withTranslation()(CustomModalDateTimePickerDouble)