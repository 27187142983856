export function formatDate(d) {
    if (!d) return undefined;
    let date = new Date(d);
    let dateMonth: any = date.getMonth() + 1;
    let dateDay: any = date.getDate();

    if (dateMonth < 10) {
        dateMonth = `0${dateMonth}`;
    }
    if (dateDay < 10) {
        dateDay = `0${dateDay}`;
    }

    // as YYYY-MM-DD
    return String(`${date.getFullYear()}-${dateMonth}-${dateDay}`);
}

export const formatDateWithTime = (d?: Date): string | undefined => {
    if (!d) return undefined;

    let dateMonth: any = d.getMonth() + 1;
    let dateDay: any = d.getDate();
    let dateHours: any = d.getHours();
    let dateMinutes: any = d.getMinutes();

    if (dateMonth < 10) dateMonth = `0${dateMonth}`;
    if (dateDay < 10) dateDay = `0${dateDay}`;
    if (dateHours < 10) dateHours = `0${dateHours}`;
    if (dateMinutes < 10) dateMinutes = `0${dateMinutes}`;

    // as YYYY-MM-DD HH:MM:SS
    return String(`${d.getFullYear()}-${dateMonth}-${dateDay} ${dateHours}:${dateMinutes}`);
};

export const formatDateWithFullTime = (d?: Date, seconds?: number): string | undefined => {
    if (!d) return undefined;

    let dateMonth: any = d.getMonth() + 1;
    let dateDay: any = d.getDate();
    let dateHours: any = d.getHours();
    let dateMinutes: any = d.getMinutes();
    let dateSeconds: any = seconds ? seconds : d.getSeconds();

    if (dateMonth < 10) dateMonth = `0${dateMonth}`;
    if (dateDay < 10) dateDay = `0${dateDay}`;
    if (dateHours < 10) dateHours = `0${dateHours}`;
    if (dateMinutes < 10) dateMinutes = `0${dateMinutes}`;
    if (dateSeconds < 10) dateSeconds = `0${dateSeconds}`;

    // as YYYY-MM-DD HH:MM:SS
    return String(`${d.getFullYear()}-${dateMonth}-${dateDay} ${dateHours}:${dateMinutes}:${dateSeconds}`);
};
