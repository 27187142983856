import { useEffect, useState } from "react";
import Axios, { CancelToken } from "axios";

import { useSelector } from "react-redux";

interface IUseFormData<T, K> {
    url: string | string[] | undefined;
    mapFormData?: (arg: K) => T;
    onSuccess?: Function;
    triggerValues?: any[];
    shouldFetch?: any;
    params?: any;
    defaultEmptyValue?: any;
}

//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE
//! DEPRECATED, DO NOT USE

export function deprecated_useFormData<T = any, K = T>({
    url,
    mapFormData,
    onSuccess,
    triggerValues = [],
    shouldFetch = undefined,
    params = {},
    defaultEmptyValue = null,
}: IUseFormData<T, K>) {
    const [formData, setFormData] = useState<T | undefined | null>(undefined);

    const { isRefreshing } = useSelector((state) => ({ isRefreshing: state.serverConnection.isRefreshing }));

    const getFormData = async (token: CancelToken | undefined = undefined) => {
        if (!url) {
            setFormData(defaultEmptyValue);
            onSuccess && onSuccess(defaultEmptyValue);
            return;
        }
        try {
            let data;

            if (typeof url == "string") {
                const response = await Axios.get(url, { cancelToken: token, ...params });
                data = response.data.data ? response.data.data : response.data;
            } else {
                data = await Promise.all(url.map((url, index) => Axios.get(url, params?.[index])));
                data = data.map((response) => response.data);
            }

            const formDataToSet = mapFormData ? mapFormData(data) : data;

            setFormData(formDataToSet);
            onSuccess && onSuccess(formDataToSet);
        } catch {}
    };

    useEffect(() => {
        const source = Axios.CancelToken.source();
        shouldFetch != false && getFormData(source.token);
        return () => source.cancel();
    }, [...triggerValues, shouldFetch, isRefreshing]);

    return { formData, setFormData, refreshFormData: getFormData };
}
