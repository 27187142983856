import React, { createContext, useContext, useState } from "react";

export const QueryTemplateSectionsContext = createContext<any>(null);

interface ISectionVisible {
    crossTable: boolean;
    treeTable: boolean;
}

export const QueryTemplateSectionsContextContextProvider = ({ children }) => {
    const [sectionVisible, setSectionVisible] = useState<ISectionVisible>({ crossTable: false, treeTable: false });

    const toggleVisibleCrossTable = () => setSectionVisible({ treeTable: false, crossTable: !sectionVisible.crossTable });
    const toggleVisibleTreeTable = () => setSectionVisible({ crossTable: false, treeTable: !sectionVisible.treeTable });
    const closeAdditionalTables = () => setSectionVisible({ crossTable: false, treeTable: false });

    return (
        <QueryTemplateSectionsContext.Provider
            value={{
                sectionVisible,
                setSectionVisible,
                toggleVisibleCrossTable,
                toggleVisibleTreeTable,
                closeAdditionalTables,
            }}
        >
            {children}
        </QueryTemplateSectionsContext.Provider>
    );
};

export const useQueryTemplateSections = (): IUseQueryTemplateSectionsContext => {
    const { sectionVisible, setSectionVisible, toggleVisibleCrossTable, toggleVisibleTreeTable, closeAdditionalTables } =
        useContext(QueryTemplateSectionsContext);

    return {
        sectionVisible,
        setSectionVisible,
        toggleVisibleCrossTable,
        toggleVisibleTreeTable,
        closeAdditionalTables,
    };
};

interface IUseQueryTemplateSectionsContext {
    sectionVisible: ISectionVisible;
    setSectionVisible: Function;
    toggleVisibleCrossTable: Function;
    toggleVisibleTreeTable: Function;
    closeAdditionalTables: Function;
}
