import React, { SyntheticEvent, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Axios from "axios";

import CustomModalFooter from "../../../../../../components/custom-modal-elements/custom-modal-footer";
import CustomModalHeader from "../../../../../../components/custom-modal-elements/custom-modal-header";
import CustomModalSelect from "../../../../../../components/custom-modal-elements/custom-modal-select";
import { ISamplingPointTable } from "../../../../../BasicSettings/SamplingSites/interfaces/interfaces";
import CustomModalInput from "../../../../../../components/custom-modal-elements/custom-modal-input";
import { mapToIds } from "components/prime-data-table/helpers/primeHelpers";
import { ISamplingPlanModal, ISamplingPlanTable } from "../../../interfaces/interfaces";
import { ITestPlan } from "../../../../../BasicSettings/TestPlans/test-plan-interface";
import CustomSpinner from "../../../../../../components/custom-spinner/custom-spinner";
import CustomButton from "../../../../../../components/custom-button/custom-button";
import { samplingPointsDualTableColumns } from "./add-edit-sampling-plan-columns";
import { IMeta } from "components/prime-data-table/interfaces";
import { DualTable } from "../../../../../../components/dual-table/dual-table";
import { handleErrorDelete } from "../../../../../../utils/handleDeleteError";
import CustomForm from "../../../../../../components/custom-form/custom-form";
import { samplingPlansUrls } from "../../../utils/sampling-plans-urls";
import { IReqParams } from "../../../../../interfaces/IReqParams";
import { useResolved } from "../../../../../../hooks/useResolved";
import { IErrors } from "../../../../../interfaces/IErrors";
import addToast from "../../../../../../utils/addToast";
import { yearsOptions } from "../../../utils/helpers";

interface ISamplingPlanModalProps {
    disabled?: boolean;
    editedRecord?: ISamplingPlanTable | undefined;
    dataSet?: ISamplingPlanTable[];
    setDataSet?: (newDataSet: ISamplingPlanTable[]) => void;
    refresh?: Function;
}

interface IModalContent extends ISamplingPlanModalProps {
    modalOpen: boolean;
    handleToggleModal: (modalState: boolean) => void;
    content: IContent;
}

interface IContent {
    headerText: string;
    footerText: string;
    permCode: string;
}

export const AddSamplingPlanModal = (props: ISamplingPlanModalProps) => {
    const { disabled = false } = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [content] = useState<IContent>({ headerText: "Add", permCode: "add.crm::sampling-plans.main.add", footerText: "Save" });

    return (
        <>
            <CustomButton disabled={disabled} text="Add" onClick={() => setModalOpen(true)} icon="plus" permCode={content.permCode} />
            {modalOpen && (
                <ModalContent
                    {...props}
                    handleToggleModal={(modalOpen) => setModalOpen(modalOpen)}
                    modalOpen={modalOpen}
                    content={content}
                />
            )}
        </>
    );
};

export const EditSamplingPlanModal = (props: ISamplingPlanModalProps) => {
    const { disabled = false } = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [content] = useState<IContent>({
        headerText: "Edit",
        permCode: "change.crm::sampling-plans.main.change",
        footerText: "Save changes",
    });

    return (
        <>
            <CustomButton disabled={disabled} text="Edit" onClick={() => setModalOpen(true)} icon="edit" permCode={content.permCode} />
            {modalOpen && (
                <ModalContent
                    {...props}
                    handleToggleModal={(modalOpen) => setModalOpen(modalOpen)}
                    modalOpen={modalOpen}
                    content={content}
                />
            )}
        </>
    );
};

export interface ISamplingPointsData {
    notSelected: {
        data: ISamplingPointTable[];
        meta: IMeta;
    };
    selected: ITestPlan[];
}

const ModalContent = (props: IModalContent) => {
    const { editedRecord, modalOpen, handleToggleModal, content, dataSet, setDataSet, refresh } = props;

    const [samplingPointsData, setSamplingPointsData] = useState<ISamplingPointsData | undefined>(undefined);
    const [formData, setFormData] = useState<ISamplingPlanModal>({ year: new Date().getFullYear() });

    const [errors, setErrors] = useState<IErrors>({});

    const resolved = useResolved(samplingPointsData);

    useEffect(() => {
        const onInit = () => {
            if (editedRecord) {
                let _data: ISamplingPlanModal = {
                    name: editedRecord.name,
                    year: editedRecord.year,
                };

                setFormData(_data);
                getSamplingPlans(undefined, mapToIds(editedRecord.samplingPoints));
            } else getSamplingPlans(undefined, []);
        };

        onInit();
    }, []);

    const getSamplingPlans = async (
        urlParams = `?pageSize=${samplingPointsData?.notSelected?.meta.pageSize || 5}&page=1`,
        excluded: number[]
    ) => {
        try {
            const response = await Axios.get(
                samplingPlansUrls.samplingPlanDualTable + urlParams, { 
                    params: { excluded, search_field: "sampling_site__client__isnull", search: true } 
                });


            setSamplingPointsData(response.data);
        } catch {}
    };

    const handleCloseModal = () => handleToggleModal(false);

    const handleSubmitData = async (e: SyntheticEvent, reqParams: IReqParams = {}) => {
        e.preventDefault();

        try {
            let response;

            const payload = { ...formData, reqParams };

            if (editedRecord && dataSet) {
                response = await Axios.patch(`${samplingPlansUrls.samplingPlans(editedRecord?.id)}`, payload);
                const newData = dataSet?.map((r) => (r.id == response.data.data.id ? { ...response.data.data } : r));

                setDataSet && setDataSet(newData);
            } else {
                response = await Axios.post(samplingPlansUrls.samplingPlans(), payload);
                refresh && refresh();
            }

            addToast({ title: `Sampling plan has been ${editedRecord ? "edited" : "added"} successfully.` });
            handleCloseModal();

            return response.status;
        } catch (e: any) {
            e.response?.status == "400" && setErrors(e.response.data);

            if (e.response?.status == "403") return e.response.data;
            else return e.response.status;
        }
    };

    const handleChangeState = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value || "" });
        errors[name] && setErrors(handleErrorDelete(errors, name));
    };

    return (
        <Modal isOpen={modalOpen} toggle={() => handleToggleModal(!modalOpen)} fade className="wide-modal-container-65">
            <CustomForm onSubmit={handleSubmitData} permCode={content.permCode}>
                <CustomModalHeader closeModal={handleCloseModal} name={`${content.headerText} sampling plan`} />
                {resolved ? (
                    <ModalBody>
                        <CustomModalInput
                            errors={errors.name}
                            handleChange={handleChangeState}
                            labelText="Name"
                            type="text"
                            name="name"
                            value={formData?.name || ""}
                        />
                        <CustomModalSelect
                            errors={errors.year}
                            name="year"
                            labelName="Year"
                            options={yearsOptions}
                            handleChange={handleChangeState}
                            value={yearsOptions.find((o) => o.value == formData?.year) || null}
                        />
                        <DualTable
                            layout="column"
                            dualListName="sampling_plans-dual_table"
                            meta={samplingPointsData?.notSelected?.meta}
                            dataSet={samplingPointsData?.notSelected?.data}
                            secondTableDataSet={samplingPointsData?.selected}
                            columns={samplingPointsDualTableColumns}
                            handleReload={getSamplingPlans}
                            handleSelected={(selected) =>
                                handleChangeState({ target: { value: mapToIds(selected), name: "samplingPoints" } })
                            }
                        />
                    </ModalBody>
                ) : (
                    <ModalCustomSpinner />
                )}
                <CustomModalFooter closeModal={handleCloseModal} cancelName="Cancel" okName={content.footerText} />
            </CustomForm>
        </Modal>
    );
};

const ModalCustomSpinner = () => (
    <div className="modal-custom-spinner-container">
        <CustomSpinner />
    </div>
);
