import React, { createContext, useContext, useState } from "react";
import { IMetadataTemplateTable, IMetadataVersionTable } from "../interfaces/interfaces";

export const MetadataContext = createContext<any>(null);

export const MetadataContextProvider = ({ children }) => {
    const [selectedTemplates, setSelectedTemplates] = useState<IMetadataTemplateTable[]>([]);
    const [selectedVersions, setSelectedVersions] = useState<IMetadataVersionTable[]>([]);

    return (
        <MetadataContext.Provider value={{ selectedTemplates, setSelectedTemplates, selectedVersions, setSelectedVersions }}>
            {children}
        </MetadataContext.Provider>
    );
};

export const useMetadata = (): IUseMetadata => {
    const { selectedTemplates, setSelectedTemplates, selectedVersions, setSelectedVersions } = useContext(MetadataContext);

    return { selectedTemplates, setSelectedTemplates, selectedVersions, setSelectedVersions };
};

interface IUseMetadata {
    selectedTemplates: IMetadataTemplateTable[];
    setSelectedTemplates: (arg: IMetadataTemplateTable[]) => void;
    selectedVersions: IMetadataVersionTable[];
    setSelectedVersions: (arg: IMetadataVersionTable[]) => void;
}
