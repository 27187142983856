import { RefObject } from "react";
import { CssVariableNameType } from "../interfaces/helpers-interfaces";

export const getCssVariable = (ref: RefObject<HTMLDivElement>, cssProperty: CssVariableNameType) => {
    if (!ref.current) return "";
    return window.getComputedStyle(ref.current).getPropertyValue(cssProperty);
};

export const setCssVariable = (ref: RefObject<HTMLDivElement>, cssVariableName: CssVariableNameType, value: string | null) => {
    const tableElement = ref.current;
    tableElement?.style.setProperty(cssVariableName, value);
};
