import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";

import { findNextElement, goToNextInput } from "../helpers/goToNextInput";
import { PrimeSelect } from "../../prime-select/prime-select";
import { getNestedObjValue } from "../helpers/primeHelpers";
import { IEditDateInputProps } from "../interfaces";
import { memoEditInput } from "./memoEditInput";

// table edit component, you send it in the column as editBody prop
const EditDateInputBase = (props: IEditDateInputProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        value = undefined,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-date-${fieldName}-${row.id}`}>
            {value ? value : getNestedObjValue(row, fieldName.split("."))}
        </div>
    );
};

export const EditDateInput = memoEditInput(EditDateInputBase);

const EnabledInput = (props: IEditDateInputProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState<boolean>(false);

    const {
        //base
        value = undefined,
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        secondPartEditUrl = undefined,
        className = "",
        clientSideEdit = false,
        style = {},
        editParamsBeforeChange = undefined,
        extraParametersToSet = undefined,
        //common
        placeholder = "Enter value...",
        nextRecordOnEnter = false,
        //unique
        showTime = false,
    } = props;

    const { row, fieldName, handleEditSubmit, ref, isBusy, handleFocusSelectRecord, rowIndex } = props.inputData;

    const propsValue = (value || row[fieldName])?.split("+")?.[0]?.trim();
    const _name = name || fieldName;

    const [_value, setValue] = useState<Date | undefined>(propsValue ? new Date(propsValue) : undefined);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(propsValue ? new Date(propsValue) : undefined);
    }, [propsValue]);

    useEffect(() => {
        const element = document.getElementById(`prime-input-date-${fieldName}-${rowIndex}`);

        // this event has to be blocked, because context menu and calendar was opening at the same time
        const blockRightClick = (event: MouseEvent) => {
            if (event.button != 0) {
                event.stopPropagation();
                event.preventDefault();
            }
        };

        if (element) element.addEventListener("mousedown", blockRightClick);

        return () => {
            if (element) element.removeEventListener("mousedown", blockRightClick);
        };
    }, []);

    const selectTemplate = (e) => {
        let { options, value } = e;
        options = options.map((o) => ({ ...o, label: t(o.label) }));

        return (
            <PrimeSelect
                className="prime-calendar-select"
                value={value}
                options={options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
                showClear={false}
            />
        );
    };

    const handleOnChange = async (e) => {
        const { value } = e.target;
        setValue(
            String(new Date(value || inputRef.current?.value)) == "Invalid Date" ? undefined : value || new Date(inputRef.current?.value!)
        );
    };

    const handleHide = async () => {
        await handleUpdate(inputRef.current?.value, _name);
    };

    const handleUpdate = async (valueToUpdate, name, e?: any) => {
        if ((propsValue || "") == valueToUpdate) return;
        let _e = { target: { name: name, value: valueToUpdate, oldValue: propsValue || "" } };
        let params = editParamsBeforeChange ? editParamsBeforeChange(extraEditParams, e.target) : extraEditParams;

        const response = await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
            editUrl,
        });

        if (response?.status?.toString()[0] == "2") {
            error && setError(false);
            if (nextRecordOnEnter) {
                const elements = findNextElement("enter", ref, fieldName);
                goToNextInput(elements);
            }
        } else if (response?.status?.toString()[0] == "4") {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
    };

    return (
        <Calendar
            style={style}
            id={`prime-input-date-${fieldName}-${rowIndex}`}
            key={`prime-input-date-${fieldName}-${row.id}`}
            className={`prime-table-cell-edit ${className} ${error ? "error" : ""}`}
            disabled={isBusy}
            name={_name}
            value={_value}
            placeholder={t(placeholder)}
            onChange={handleOnChange}
            onShow={() => handleFocusSelectRecord(row)}
            onHide={handleHide}
            yearRange="2020:2030"
            dateFormat="yy-mm-dd"
            monthNavigator
            yearNavigator
            monthNavigatorTemplate={selectTemplate}
            yearNavigatorTemplate={selectTemplate}
            showTime={showTime}
            showSeconds={showTime}
            showIcon
            inputRef={inputRef}
            mask={showTime ? "9999-99-99 99:99" : "9999-99-99"}
        />
    );
};
