import React from "react";
import { ITextareaInputPlaceholderProps } from "../interfaces/placeholders-interfaces";

// table textarea placeholder, you send it in the column as body prop
// body: (rowData: IPrimeRowData<ProperInterface>) => (<TextareaInputPlaceholder inputData={inputData} />),
export const TextareaInputPlaceholder = ({ rowData, className = "", style = {} }: ITextareaInputPlaceholderProps) => (
    <textarea
        disabled
        key={`prime-input-text-${rowData.fieldName}-${rowData.row.id}`}
        id="prime-input-text"
        rows={1}
        style={style}
        value={rowData.row[rowData.fieldName] || ""}
        className={`input-value prime-edit-textarea-input ${className}} `}
    />
);
