import React, { useEffect, useRef, useState } from "react";

import axios, { CancelToken } from "axios";

import { AsyncTasksDropdownToggle } from "./components/toggle";
import { AsyncTasksDropdownMenu } from "./components/menu";
import Dropdown from "../bs-dropdown";

import "./async-tasks-dropdown.scss";

export interface IAsyncTask {
    type: string;
    id: number;
    progress: number;
    name: string;
    instanceId: number;
    model_app: string;
    model_name: string;
    status: string;
    createdDt: string;
}

export interface ITasks {
    completed: IAsyncTask[];
    active: IAsyncTask[];
}

export const AsyncTasksDropdown = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [tasks, setTasks] = useState<ITasks>({ active: [], completed: [] });
    const baseUrl = (axios.defaults.baseURL || "").split("//");
    const url = baseUrl[baseUrl.length - 1].replace("/api", "").replace("/admin", "");
    const webSocketProtocol = window.location.protocol.includes("https") ? "wss" : "ws";

    const tasksWebSocket = useRef<WebSocket | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        getData(source.token);

        let socket;
        try {
            socket = new WebSocket(`${webSocketProtocol}://${url}/ws/tasks/`);
        } catch (err: any) {
            console.error(err);
        }
        if (socket) {
            socket.onmessage = async (e) => {
                let tempTasks = [...tasks.active];
                const dataParsed = JSON.parse(e?.data);

                tempTasks = tempTasks.map((task) => task.id).includes(dataParsed?.id)
                    ? [...tempTasks.filter((o) => o.id != dataParsed?.id), dataParsed]
                    : [...tempTasks, dataParsed];

                setTasks((prev) => ({ ...prev, active: tempTasks }));

                if (dataParsed.status == "done") {
                    getData(source.token);
                }
            };

            socket.onclose = function (e) {
                console.error("tasksWebSocket closed unexpectedly");
            };
        }
        tasksWebSocket.current = socket;
        return () => {
            source?.cancel();
            socket?.close();
        };
    }, []);

    const getData = async (token: CancelToken) => {
        try {
            const response = await axios.get("user-tasks", {
                params: {
                    search_field: "status",
                    search: "done",
                },
                cancelToken: token,
            });
            setTasks((prev) => ({ ...prev, completed: response.data }));
        } catch (err: any) {
            console.error(err);
        }
    };

    const handleDismiss = async (id) => {
        try {
            await axios.patch(`user-tasks/${id}`, { status: "dismissed" });
            setTasks((prev) => ({ ...prev, completed: prev.completed.filter((o) => o.id != id) }));
        } catch (err: any) {
            console.error(err);
        }
    };

    return (
        <Dropdown
            tag="li"
            direction="up"
            toggle={() => {}}
            onMouseOver={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            dropdownOpen={isOpen}
            showTriangle
        >
            <div data-taskswebsocket={tasksWebSocket.current?.readyState}>
                <AsyncTasksDropdownToggle tasks={tasks.active} />
                <AsyncTasksDropdownMenu tasks={tasks} handleDismiss={handleDismiss} />
            </div>
        </Dropdown>
    );
};
