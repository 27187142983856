import React, { createContext, useContext, useState } from "react";

import { ITraining, ITrainingTemplate } from "../interfaces/interface";

export const TrainingsContext = createContext<any>(null);

export const TrainingsContextProvider = ({ children }) => {

    const [selectedTrainings, setSelectedTrainings] = useState<ITraining[]>([])
    const [selectedTrainingTemplates, setSelectedTrainingTemplates] = useState<ITrainingTemplate[]>([])
    const [refresh, setRefresh] = useState<Function>()

    return (
        <TrainingsContext.Provider
            value={{
                selectedTrainings,
                setSelectedTrainings,
                selectedTrainingTemplates,
                setSelectedTrainingTemplates,
                refresh,
                setRefresh,
            }}
        >
            {children}
        </TrainingsContext.Provider>
    );
};

export const useTrainings = () => {
    const {
        selectedTrainings,
        setSelectedTrainings,
        selectedTrainingTemplates,
        setSelectedTrainingTemplates,
        refresh,
        setRefresh,
    } = useContext(TrainingsContext);

    return {
        selectedTrainings,
        setSelectedTrainings,
        selectedTrainingTemplates,
        setSelectedTrainingTemplates,
        refresh,
        setRefresh,
    };
};

interface IUseTraiuseTrainings {
}
