// external
import React, { Component } from "react";

import { Container, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import { connect } from "react-redux";
import Icon from "../icon";

// internal
import { URL_LIST, toRouterUrl, unifyParams } from "utils/urlList";
import Dropdown from "../bs-dropdown";

class PageTitle extends Component {
    isMounted = false;

    constructor(props) {
        super(props);
        this.prepareSubmenus = this.prepareSubmenus.bind(this);
        this.prepareCrumbs = this.prepareCrumbs.bind(this);

        this.state = { breadcrumbs: {} };
        this.updateBreadcrumbs();
    }

    prepareSubmenus(sub) {
        const { settings } = this.props;

        if (settings.breadcrumbs_presets[sub]) {
            return (
                <>
                    {Object.keys(settings.breadcrumbs_presets[sub]).map((url) => {
                        const isActive = window.location.pathname === url;

                        return (
                            <li key={`crumb-${url}`}>
                                <Link to={url} className={classnames("dropdown-item", isActive ? "active" : "")}>
                                    {settings.breadcrumbs_presets[sub][url]}
                                </Link>
                            </li>
                        );
                    })}
                </>
            );
        }

        return sub || "";
    }

    prepareCrumbs(crumbs) {
        let _keys = Object.keys(crumbs);
        let _num = 0;

        return _keys
            .map((url) => {
                _num++;
                const title = crumbs[url];

                // crumb with dropdown.
                if (typeof title !== "string") {
                    const data = title;

                    return (
                        <Dropdown key={`crumb-${url}`} tag="li" className="breadcrumb-item dropdown-menu-sm" openOnHover showTriangle>
                            <Dropdown.Toggle tag="a" href={url || "#"} className="dropdown-item">
                                {data.title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="div">
                                <ul className="dropdown-menu-scroll">{this.prepareSubmenus(data.sub)}</ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }

                // default crumb.
                //! if mobile check if its contains mobile url first then redirect it to mobile version
                const hasMobileVersion =
                    window.location.pathname.includes("/m") && URL_LIST.map((url) => unifyParams(url)).includes("/m" + toRouterUrl(url));
                return (
                    <React.Fragment key={`crumb-${url}-container`}>
                        <BreadcrumbItem key={`crumb-${url}`}>
                            <Link to={(hasMobileVersion ? "/m" + url : url) || "#"}>{crumbs[url]}</Link>
                        </BreadcrumbItem>
                        {_num != _keys.length && (
                            <BreadcrumbItem key={`separator-${_num}`}>
                                <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                            </BreadcrumbItem>
                        )}
                    </React.Fragment>
                );
            })
            .filter((i) => i != null);
    }

    componentDidMount = () => {
        window.jQuery("#breadcrumb-data").data("update", this.updateBreadcrumbs);
        this.isMounted = true;
    };

    updateBreadcrumbs = () => {
        if (!this.isMounted) return;

        let breadcrumbs = {};
        let pathSplit = window.location.pathname.replace("/m/", "/").slice(1).split("/");
        let prev =
            this.props.settings.navigation_sidebar[`/${pathSplit[0]}`] || this.props.settings.navigation_profile_panel[`/${pathSplit[0]}`];
        let urlBuilder = "";

        const { t } = this.props;

        for (let s of pathSplit) {
            urlBuilder += `/${s}`;

            if (prev) {
                breadcrumbs[urlBuilder] = t(prev.name) || t(prev[urlBuilder]?.name);
                prev = prev.sub;
            } else {
                breadcrumbs[urlBuilder] = t(s.firstLetterToUpperCase());
            }
        }

        this.setState({
            breadcrumbs: {
                "/": t("Dashboard"),
                ...breadcrumbs,
            },
        });
    };

    render() {
        const {
            className,
            children,
            //breadcrumbs,
        } = this.props;

        return (
            <div className={classnames("content-wrap", "rui-page-title", className)}>
                <div id="breadcrumb-data" style={{ display: "none" }}></div>

                <Container fluid className="ml-15 mt-15 mb-5">
                    {this.state.breadcrumbs && Object.keys(this.state.breadcrumbs).length ? (
                        <Breadcrumb>{this.prepareCrumbs(this.state.breadcrumbs)}</Breadcrumb>
                    ) : (
                        ""
                    )}
                    {children}
                </Container>
            </div>
        );
    }
}

export default withTranslation()(connect(({ settings }) => ({ settings }))(PageTitle));
