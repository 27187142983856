import React, { ReactChild, ReactElement, useLayoutEffect, useMemo, useRef, useState } from "react";

import classnames from "classnames/dedupe";

import { OverflowMenu } from "./overflowMenu";

import "./styles.scss";

interface ResponsiveButtonsContainerProps {
    children?: any;
    disableButtons?: boolean;
}

interface IVisibilityMap {
    [key: string]: boolean;
}

export function ResponsiveButtonsContainer({ children, disableButtons }: ResponsiveButtonsContainerProps) {
    const navRef = useRef<HTMLDivElement>(null);

    const [visibilityMap, _setVisibilityMap] = useState<IVisibilityMap>({});
    const [init, setInit] = useState<boolean>(true);

    const stateRef = useRef<IVisibilityMap>(visibilityMap);

    const isOverflowMenuRef = useRef<boolean>(false);

    const setVisibilityMap = (data) => {
        stateRef.current = data;
        _setVisibilityMap(data);
        init && setInit(false);
    };

    const handleIntersection = (entries) => {
        let updatedEntries = { ...stateRef.current };
        entries.forEach((entry, index) => {
            const targetid = entry.target.dataset.targetid;
            const start = entry.target.dataset.start;
            const exclude = entry.target.dataset.exclude;
            updatedEntries[targetid] = exclude || (!start && entry.isIntersecting);
        });
        // Overwrite previous state values with current state
        setVisibilityMap(updatedEntries);
    };

    isOverflowMenuRef.current = !!Object.values(visibilityMap).filter((button) => button == false).length;

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: navRef.current,
            rootMargin: isOverflowMenuRef.current ? "0px -76px 0px 0px" : undefined,
            threshold: 1,
        });

        navRef.current &&
            Array.from((navRef.current?.firstChild as HTMLDivElement)?.children).forEach((item) => {
                if ((item as HTMLDivElement)?.dataset.targetid) {
                    observer.observe(item);
                }
            });

        return () => {
            return observer.disconnect();
        };
    }, [isOverflowMenuRef.current]);

    const areButtonsDisabled = disableButtons ? { disabled: disableButtons } : undefined;

    return (
        <div ref={navRef} className="intersection-observer">
            <div className="intersection-observer-wrapper">
                {React.Children.map(children, (child, index) => {
                    return (
                        <div
                            data-targetid={`button-${index}`}
                            data-start={child?.props?.startMoreMenu}
                            data-exclude={child?.props?.excludeFromMoreMenu}
                            className={classnames("button-wrapper", {
                                invisible: visibilityMap[`button-${index}`] === false,
                                init: init,
                            })}
                        >
                            {React.cloneElement(child as ReactElement, areButtonsDisabled)}
                        </div>
                    );
                })}
                {isOverflowMenuRef.current && (
                    <OverflowMenu init={init}>
                        {React.Children.map(children, (child, index) => {
                            return visibilityMap[`button-${index}`] === false ? (
                                React.cloneElement(child as ReactElement, {
                                    className: "context-menu-option",
                                    isIntersectingMenu: true,
                                    ...areButtonsDisabled,
                                })
                            ) : (
                                <></>
                            );
                        })}
                    </OverflowMenu>
                )}
            </div>
        </div>
    );
}
