import { IVisibleColumn } from "../components/modals/hide-columns-modal";
import { IGeneratedPrimeTableColumn } from "../interfaces/table-interfaces";

interface IGetVisibleAndHiddenColumns {
    visibleColumns: IGeneratedPrimeTableColumn[];
    hiddenColumns: IGeneratedPrimeTableColumn[];
}

export const getVisibleAndHiddenColumns = (
    defaultColumns: IGeneratedPrimeTableColumn[],
    visibleColumns: IGeneratedPrimeTableColumn[],
    selectedVisibleColumns: string[]
): IGetVisibleAndHiddenColumns => {
    let _visibleColumns: IVisibleColumn[] = [];
    let hiddenColumns: IGeneratedPrimeTableColumn[] = [];

    defaultColumns.forEach((defaultColumn, index) => {
        let width = visibleColumns.find((visibleColumn) => visibleColumn.field == defaultColumn.field)?.width || defaultColumn.width;

        if (selectedVisibleColumns.includes(defaultColumn.field)) _visibleColumns.push({ ...defaultColumn, index, width });
        else hiddenColumns.push({ ...defaultColumn, width: width });
    });

    _visibleColumns = _visibleColumns.sort((colA, colB) => {
        if (!colA?.index || !colB?.index) return -1;
        return colA.index - colB.index;
    });

    _visibleColumns.map((col) => delete col["index"]);

    return { visibleColumns: _visibleColumns, hiddenColumns: hiddenColumns };
};

export const getColumnToGroupBy = (options: IGeneratedPrimeTableColumn[], selectedToGroupBy: string) =>
    options?.find((col) => col.field == selectedToGroupBy);

export const getColumnField = (target): string | null => {
    const element: HTMLDivElement = target;
    let fieldName: string | null = null;

    if (element.tagName == "TD" && typeof element?.className == "string") fieldName = element?.className?.getAfter("column-") || null;
    else {
        const tdElement = element.closest("td");

        if (tdElement && typeof tdElement?.className == "string") {
            fieldName = tdElement.className?.getAfter("column-") || null;
        }
    } 
    
    return fieldName;
};
