import React, { MutableRefObject } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { highlightTextIncluded } from "../highlightTextIncluded";
import CustomSpinner from "../../custom-spinner/custom-spinner";
import { TooltipHOC } from "../../gt-tooltip/gt-tooltip-hoc";
import PrettyCell from "../../prime-data-table/body-components/pretty-cell/pretty-cell";
import { mapModelToRoute } from "../mapModelToRoute";
import { ISearchData } from "../interface";
import { NoResults } from "./noResults";

interface IDropdownGlobalSearchProps {
    searchData: ISearchData;
    isLoading: boolean;
    dropdownRef: MutableRefObject<HTMLDivElement | null>;
    navbarInput: string;
}

export const DropdownGlobalSearch = ({ searchData, isLoading, dropdownRef, navbarInput }: IDropdownGlobalSearchProps) => {
    const { t } = useTranslation();

    return (
        <div className="gs-dropdown-container">
            <div className="gs-dropdown " ref={dropdownRef}>
                {isLoading ? (
                    <CustomSpinner />
                ) : Object.values(searchData).every((value) => !value.length) ? (
                    <NoResults />
                ) : (
                    Object.entries(searchData).map(([key, value]) => (
                        <>
                            <h1 className="gs-dropdown-key">{t(key)}</h1>
                            {value.map((r) => (
                                <Link to={mapModelToRoute(key, r)} className="result-link">
                                    <div className="result-grid">
                                        <div>
                                            <h1 className="result-item">{r.code || `${t("Result")}: ${r.analyte}`}</h1>
                                            <strong>{t("Found in")}:</strong>
                                            <div className="result-match">
                                                {r.matches.map((match) => (
                                                    <span>
                                                        {`${match?.parentField || ""} ${match?.field}: `}&nbsp;
                                                        <TooltipHOC tooltipContent={match?.value}>
                                                            {highlightTextIncluded(match?.value, navbarInput)}
                                                        </TooltipHOC>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="result-key">
                                            {t(key)}
                                            <TooltipHOC tooltipContent={key == "sample" ? "Sample status" : "Order status"}>
                                                <PrettyCell text={r?.status} />
                                            </TooltipHOC>
                                            <TooltipHOC tooltipContent={"Created date"}>
                                                <div>{r.loggedDt || r.createdDt}</div>
                                            </TooltipHOC>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </>
                    ))
                )}
            </div>
        </div>
    );
};
