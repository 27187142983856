import React, { ChangeEventHandler, ReactChild, useEffect, useRef, useState } from "react";

import { Col, FormGroup, Label } from "reactstrap";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

import { translateErrorString } from "../../utils/translateErrorString";
import LabelError from "../label-error/label-error";

import "./prime-popup-input.scss";

interface ICustomModalInput {
    children: ReactChild;
    value: any;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    popupCloseCondition?: (event) => boolean;
    readOnly?: boolean;
    errors?: any;
    forLabel?: string;
    className?: string;
    label?: string;
    labelSm?: number;
    autofocus?: boolean;
    disabled?: boolean;
    colSm?: number;
    placeholder?: string;
}

export const PrimePopupInput = (props: ICustomModalInput) => {
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    const {
        children,
        value,
        onChange,
        popupCloseCondition,
        readOnly = true,
        forLabel = "",
        className,
        label,
        labelSm = 3,
        colSm = label ? 9 : 12,
        autofocus,
        placeholder,
        disabled,
    } = props;
    let { errors } = props;
    const inputPopupRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputPopupRef.current &&
                !inputPopupRef.current.contains(event.target) &&
                (popupCloseCondition ? popupCloseCondition(event) : true)
            ) {
                setIsPopupOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [inputPopupRef]);

    useEffect(() => {
        isPopupOpen && setIsPopupOpen(false);
    }, [value]);

    if (value && errors && errors[0] == "This field is required." && errors.length == 1) {
        errors = undefined;
    }

    return (
        <FormGroup row className={className}>
            {!!label && (
                <Label for={t(forLabel)} sm={labelSm}>
                    {t(label)}:
                </Label>
            )}
            <Col sm={colSm}>
                <InputText
                    disabled={disabled}
                    className={"prime-popup-input"}
                    onClick={() => setIsPopupOpen(true)}
                    value={value || ""}
                    onChange={onChange ? onChange : () => {}}
                    readOnly={readOnly}
                    placeholder={`${t(label || "")}...`}
                    autoFocus={autofocus}
                />
                <div className="input-popup-container" id="input-popup-container">
                    <div ref={inputPopupRef} className={`input-popup ${isPopupOpen ? "opened" : ""}`}>
                        {children}
                    </div>
                </div>
                {errors && <LabelError id={forLabel} error={translateErrorString(errors, t)} />}
            </Col>
        </FormGroup>
    );
};
