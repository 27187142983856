import { useEffect, useState } from "react";

export function useDebounce<T = unknown>(value, delay = 500, emptyValueDelay = 500) {
    const [debounceValue, setDebounceValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebounceValue(value), !!value ? delay : emptyValueDelay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay, emptyValueDelay]);

    return debounceValue;
}
