import React, { createContext, useContext, useState } from "react";

import { orderModes, OrderModesTypes } from "../../utils/modes";
import { ISample } from "../../../Samples/interface";

export const OrderReleaseSamplesContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.releaseSamples;
export const OrderReleaseSamplesContextProvider = ({ children }) => {
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
    const [refreshSamplesData, setRefreshSamplesData] = useState<Function | undefined>(undefined);

    return (
        <OrderReleaseSamplesContext.Provider
            value={{ selectedSamples, setSelectedSamples, refreshSamplesData, setRefreshSamplesData, mode }}
        >
            {children}
        </OrderReleaseSamplesContext.Provider>
    );
};

export const useOrderReleaseSamples = (): IUseOrderReleaseSamples => {
    const { selectedSamples, setSelectedSamples, refreshSamplesData, setRefreshSamplesData, mode } = useContext(OrderReleaseSamplesContext);

    return { selectedSamples, setSelectedSamples, refreshSamplesData, setRefreshSamplesData, mode };
};

interface IUseOrderReleaseSamples {
    selectedSamples: ISample[];
    setSelectedSamples: Function;
    refreshSamplesData: Function;
    setRefreshSamplesData: Function;
    mode: OrderModesTypes;
}
