// main tab section
export type OfferAppTabKeysType = "offers" | "contracts";
export type OfferAppTabValuesType = "offers" | "contracts";

export type OfferAppTabsTypes = {
    [key in OfferAppTabKeysType]: OfferAppTabValuesType;
};

export const offerAppTabs: OfferAppTabsTypes = {
    offers: "offers",
    contracts: "contracts",
};

// service tab section
export type OfferServiceTabKeysType = "basicServices" | "packageServices" | "containers" | "metadata";
export type OfferServiceTabValuesType = "Basic services" | "Package services" | "Containers" | "Metadata";

export type OfferServiceTabs = {
    [key in OfferServiceTabKeysType]: OfferServiceTabValuesType;
};

export const offerServiceTabs: OfferServiceTabs = {
    basicServices: "Basic services",
    packageServices: "Package services",
    containers: "Containers",
    metadata: "Metadata"
};
