import React from "react";

import { I, Body } from "components/prime-data-table";
import * as GT from "components";

export type FieldType =
    | "AutoField"
    | "BigAutoField"
    | "BigIntegerField"
    | "BinaryField"
    | "BooleanField"
    | "CharField"
    | "CommaSeparatedIntegerField"
    | "DateField"
    | "DateTimeField"
    | "DecimalField"
    | "DurationField"
    | "EmailField"
    | "FileField"
    | "FloatField"
    | "ForeignKey"
    | "GenericIPAddressField"
    | "ImageField"
    | "IntegerField"
    | "ManyToManyField"
    | "NullBooleanField"
    | "OneToOneField"
    | "PositiveIntegerField"
    | "PositiveSmallIntegerField"
    | "SlugField"
    | "SmallAutoField"
    | "SmallIntegerField"
    | "TextField"
    | "TimeField"
    | "URLField"
    | "UUIDField";

export interface IQbeResultsColumns {
    id: number;
    field_type: { label: FieldType; value: FieldType };
    relation: { id: number };
    relation_field_path: string;
    field: string;
    label: string;
    company: number;
    is_superscript: boolean;
}

export type ColumnType = "super-script" | "status" | "checkbox" | undefined;
type qbeMode = "new" | "old";

export const generateQbeColumns = (columns?: any[], qbeMode: qbeMode = "old"): I.IPrimeTableColumn[] => {
    if (!columns) return [];

    return columns?.map((column) => {
        const columnType: ColumnType = getColumnType(column, qbeMode);

        return {
            header: column.label,
            field:
                qbeMode == "old"
                    ? column[fieldMap[qbeMode].relationFieldPath]
                    : column[fieldMap[qbeMode].relationFieldPath].replaceAll(/__([a-z])/g, (match, group) => `_${group.toUpperCase()}`),
            sortable: false,
            className: columnType == "checkbox" ? "checkbox-container" : "",
            body: columnType ? (rowData: I.IPrimeRowData<any>) => getBody(rowData, columnType) : undefined,
        };
    });
};

const getColumnType = (column: any, qbeMode: qbeMode = "old"): ColumnType => {
    if (column[fieldMap[qbeMode].isSuperscript]) return "super-script";
    else if (column.field === "status") return "status";

    switch (column[fieldMap[qbeMode].fieldType].value) {
        case "BooleanField":
            return "checkbox";
        default:
            return undefined;
    }
};

const getBody = (rowData: I.IPrimeRowData<any>, columnType: ColumnType) => {
    switch (columnType) {
        case "super-script":
            return <GT.SuperScriptDisplay value={rowData.row[rowData.fieldName]} />;
        case "status":
            return Body.status(rowData);
        case "checkbox":
            return Body.checkbox(rowData);
    }
};

const fieldMap = {
    old: {
        isSuperscript: "is_superscript",
        fieldType: "field_type",
        relationFieldPath: "relation_field_path",
    },
    new: {
        isSuperscript: "isSuperscript",
        fieldType: "fieldType",
        relationFieldPath: "relationFieldPath",
    },
};
