import React from "react";

import { Column, ColumnProps } from "primereact/column";
import { TFunction } from "react-i18next";

import { DefaultHeader } from "../components/default-header";
import { DefaultCell } from "../components/default-cell";
import hasPermission from "utils/hasPermissions";

// interfaces
import * as FI from "../interfaces/filter-interfaces"; // internal filters interfaces import
import * as I from "../interfaces"; // internal table interfaces import
import * as H from "../helpers"; // helpers import

interface DynamicColumns<T extends I.IBaseRecord = I.IBaseRecord> {
    props: I.IPrimeDataTable<T>;
    column: I.IGeneratedPrimeTableColumn;
    args: {
        tableConfig: any;
        params: I.IPrimeParams;
        isBusy: boolean;
        filterFocusedFieldName: any;
        tableContainerRef: React.RefObject<HTMLDivElement>;
        setFilterFocusedFieldName: React.Dispatch<
            React.SetStateAction<{
                fieldName: string;
                tableName: string;
            }>
        >;
        handleFilterChange: (input: I.IPrimeFilterInput, filterType: FI.FilteringType) => void;
        handleClearDateFilter: (name: string) => void;
        handleCalendarClose: (name: string) => void;
        handleDateFilterChange: FI.OnDateFilterChangeType;
        handleFocusSelectRecord: (row) => void;
        handleAdditionalColumnEditSubmit: (submitEvent: I.EditSubmitEventType) => any;
        handleEditSubmit: (submitEvent: I.EditSubmitEventType) => any;
        t: TFunction<string>;
    };
}

export const getDynamicColumns = <T extends I.IBaseRecord = I.IBaseRecord>({ props, column, args }: DynamicColumns<T>) => {
    // TODO! dobry pomysłem było ustawianie kolumn na wejściu a nie przy każdym renderze
    const {
        className = "",
        width,
        header,
        field,
        filterFieldName,
        headerClassName = "",
        translateOptions,
        editBody,
        body,
        filterBody,
    } = column;
    const { customHeader, tableName, selectChoices, permCode, cellEditEnabled, meta, customRefresh, onReload } = props;
    const {
        tableConfig,
        params,
        handleFilterChange,
        handleClearDateFilter,
        handleCalendarClose,
        handleDateFilterChange,
        filterFocusedFieldName,
        setFilterFocusedFieldName,
        tableContainerRef,
        isBusy,
        handleFocusSelectRecord,
        handleAdditionalColumnEditSubmit,
        handleEditSubmit,
        t,
    } = args;

    const liteColumn: I.ILitePrimeTableColumn = { header: header, field };
    let columnProps: ColumnProps = { className: "", style: {}, filter: false, filterElement: undefined, body: undefined };
    let _frozen = tableConfig.frozenColumn?.field == field;

    // column className, if you want to add some class to the header do it here
    columnProps.className = `${className} ${headerClassName} ${editBody ? " editable-column" : ""} ${
        params.multiSortMeta?.length != 1 && params.multiSortMeta?.some((sort) => sort.field == liteColumn.field) ? "p-multi-sort" : ""
    } `;

    // special column className, this class has to be always at the and of the string. do not change this
    columnProps.className = columnProps.className.concat(`column-${liteColumn.field}`);

    // column width
    columnProps.style = { width: width };

    // select choices
    const _selectChoices = translateOptions
        ? selectChoices?.[liteColumn.field]?.map((o) => ({ ...o, label: t(o.label) }))
        : selectChoices?.[liteColumn.field];

    // header filter
    if (filterBody && tableConfig.filtersEnabled) {
        columnProps.filter = true;
        columnProps.filterElement = filterBody({
            column: { ...liteColumn, field: filterFieldName || field },
            onFilterChange: handleFilterChange,
            onClearDateFilter: handleClearDateFilter,
            onCalendarClose: handleCalendarClose,
            onDateFilterChange: handleDateFilterChange,
            filter: params?.filters?.[H.convertDotsToUnderscores(filterFieldName || field)]?.[0],
            filterChoices: _selectChoices,
            filterFocusedFieldName: filterFocusedFieldName,
            setFilterFocusedFieldName: setFilterFocusedFieldName,
            filterArrayLength: (params?.filters?.[H.convertDotsToUnderscores(filterFieldName || field)] || []).length,
            tableRef: tableContainerRef,
            tableName: tableName,
        });
    } else if (tableConfig.frozenColumn && tableConfig.filtersEnabled) {
        columnProps.filter = true;
        columnProps.filterElement = <div />;
    }

    // body & editing start
    if (editBody && cellEditEnabled && (!permCode || hasPermission(permCode))) {
        columnProps.body = (row, rowProps) => {
            return (
                <>
                    {editBody({
                        ref: tableContainerRef,
                        row: row,
                        fieldName: liteColumn.field,
                        selectChoices: _selectChoices,
                        handleFocusSelectRecord: handleFocusSelectRecord,
                        handleEditSubmit: handleEditSubmit,
                        translate: (value, config) => t(value, config),
                        isBusy: isBusy,
                        customRefresh: customRefresh,
                        modelInfo: meta?.ModelInfo,
                        refresh: onReload ? () => onReload(undefined, true) : undefined,
                        permCode: permCode,
                        rowIndex: (rowProps as any).rowIndex,
                    })}
                </>
            );
        };
    } else if (body) {
        columnProps.body = (row, rowProps) => {
            return (
                <>
                    {body({
                        row: row,
                        selectChoices: _selectChoices,
                        fieldName: liteColumn.field,
                        translate: (value, config) => t(value, config),
                        tableRef: tableContainerRef,
                        rowIndex: (rowProps as any).rowIndex,
                    })}
                </>
            );
        };
    } else if (column.additionalType) {
        //? EXTRA COLUMNS
        const additionalType = column.additionalType;

        if (tableConfig.filtersEnabled) {
            const _field = H.additionalColumnFilterField(filterFieldName || field);

            const filterData: FI.IPrimeFilterData = {
                column: { ...liteColumn, field: _field },
                onFilterChange: handleFilterChange,
                onClearDateFilter: handleClearDateFilter,
                onCalendarClose: handleCalendarClose,
                onDateFilterChange: handleDateFilterChange,
                filter: params?.filters?.[H.convertDotsToUnderscores(_field)]?.[0],
                filterChoices: selectChoices?.[liteColumn.field],
                filterFocusedFieldName: {
                    ...filterFocusedFieldName,
                    fieldName: H.additionalColumnFilterField(filterFocusedFieldName.fieldName),
                },
                setFilterFocusedFieldName: setFilterFocusedFieldName,
                filterArrayLength: (params?.filters?.[H.convertDotsToUnderscores(_field)] || []).length,
                tableRef: tableContainerRef,
                tableName: tableName,
            };

            columnProps.filter = true;
            columnProps.filterElement = H.additionalColumnFilterBody(filterData, additionalType);
        }

        columnProps.body = (row, rowProps) => {
            const inputData: I.IPrimeEditData<T> = {
                ref: tableContainerRef,
                row: row,
                fieldName: liteColumn.field,
                selectChoices: column?.lookupChoices || selectChoices?.[liteColumn.field],
                handleFocusSelectRecord: handleFocusSelectRecord,
                handleEditSubmit: handleAdditionalColumnEditSubmit,
                translate: (value, config) => t(value, config),
                isBusy: isBusy,
                customRefresh: customRefresh,
                modelInfo: meta?.ModelInfo,
                refresh: onReload ? () => onReload(undefined, true) : undefined,
                rowIndex: (rowProps as any).rowIndex,
                permCode: permCode,
            };

            return H.additionalColumnEditBody(inputData, additionalType);
        };
    } else {
        columnProps.body = (row) => {
            const value = H.getNestedObjValue(row, liteColumn.field.split("."));

            return (
                <DefaultCell key={`${liteColumn.field}-${tableName}`} value={value}>
                    {value}
                </DefaultCell>
            );
        };
    }

    // body & editing end
    return (
        <Column
            header={customHeader ? customHeader(column) : <DefaultHeader liteColumn={liteColumn} />}
            key={`${liteColumn.field}-${tableName}`}
            field={field}
            sortable={column.sortable} // sorting
            frozen={_frozen} // frozen column
            {...columnProps}
        />
    );
};
