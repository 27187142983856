export const handleKeyMoveFirstChildElement = (e, ref) => {
    if (e.keyCode == 40 && ref.current) {
        (ref.current.firstElementChild as HTMLDivElement)?.focus();
    }
};

export const handleKeyMoveList = (e) => {
    if (e.keyCode == 40) {
        e.target?.nextSibling?.focus();
    }
    if (e.keyCode == 38) {
        e.target?.previousSibling?.focus();
    }
};
