/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */

 // KM: typescriptowe rzeczy
declare global {
  interface Window { 
    jQuery: any; 
    $: any; 
    RootUI: any; 
  }
}

const jQuery = require( 'jquery' );

window.jQuery = jQuery;
window.$ = jQuery;

/*------------------------------------------------------------------

  RootUI Class

-------------------------------------------------------------------*/
class ROOTUI {}
window.RootUI = new ROOTUI();
