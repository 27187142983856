import React, { useEffect, useState } from "react";

import Axios from "axios";

import { locationUrls } from "../../pages/BasicSettings/Locations/utils/locationUrls";
import { ISelectedNodes } from "../prime-tree/interfaces/tree-interfaces";
import { PrimePopupInput } from "../prime-popup-input/prime-popup-input";
import { PrimeTree } from "../prime-tree/prime-tree";

interface ILocationInputProps {
    value?: number | null;
    name: string;
    onChange: (event: { target: { value: number; name: string } }) => void;
    errors?: string | string[];
    disabled?: boolean;
    url?: string;
    rootName?: string;
    filterPlaceholder?: string;
    label?: string;
    labelSm?: number;
    colSm?: number;
    treeTriggerValue?: any;
    treeAdditionalParams?: string;
}

export const LocationInput = ({
    value,
    name,
    onChange,
    errors,
    disabled,
    url = locationUrls.main(),
    rootName = "Locations tree",
    filterPlaceholder = "Filter locations tree",
    label = "Location",
    labelSm = 3,
    colSm,
    treeTriggerValue,
    treeAdditionalParams,
}: ILocationInputProps) => {
    const [selectedNodes, setSelectedNodes] = useState<ISelectedNodes>({});

    useEffect(() => {
        const handleExternalValueChange = async () => {
            if (!value) {
                setSelectedNodes({});
                return;
            }

            try {
                const response = await Axios.get(`${url}/${value}`);

                setSelectedNodes({ [response.data.data.id]: { checked: true, partialChecked: false, label: response.data.data.name } });

                //TODO!
                //! - przerobić PrimeTree żeby miał dwa propsy expanded, setExpanded, żeby to które node'y są expanded dało sie kontrolować na zewnątrz komponentu
                //! - wyciągnąć do helper'a/utils'ów funkcje odpowiedzialne za expanded w komponencie PrimeTree, i zrobić tak żeby dało się je odpalić na zewnątrz komponentu tylko z zmienną selectedNodes
                //! - po każdym zmienieniu state'u selectedNodes w funkcji handleExternalValueChange, uruchomić ustawianie nowych expanded za pomocą funkcji które wyciągnęliśmy w poprzednim kroku
            } catch (err: any) {
                console.error(err);
            }
        };

        value != parseInt(Object.keys(selectedNodes)[0]) && handleExternalValueChange();
    }, [value]);

    const handleChange = (selectedNodes) => {
        setSelectedNodes(selectedNodes);

        onChange({ target: { name: name, value: parseInt(Object.keys(selectedNodes)[0]) } });
    };

    return (
        <PrimePopupInput
            labelSm={labelSm}
            colSm={colSm ? colSm : 12 - labelSm}
            value={selectedNodes?.[Object.keys(selectedNodes)[0]]?.label}
            label={label}
            errors={errors}
            disabled={disabled}
        >
            <PrimeTree
                triggerValue={treeTriggerValue}
                additionalParams={treeAdditionalParams}
                url={url}
                searchField="name"
                rootName={rootName}
                selectedNodes={selectedNodes}
                onSelectedNodes={(selectedNodes) => handleChange(selectedNodes)}
                selectionMode="single"
                filterPlaceholder={filterPlaceholder}
                className="pt-0 w-100"
                height="25"
                selectFirst={false}
            />
        </PrimePopupInput>
    );
};
