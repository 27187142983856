import React from "react";

import { ISelectInputPlaceholderProps } from "../interfaces";
import { getNestedObjValue } from "../helpers";

export const SelectInputPlaceholder = ({ rowData, className = "", style = {}, value = undefined }: ISelectInputPlaceholderProps) => {
    const { row, fieldName, selectChoices } = rowData;

    const content = (() => {
        if (value) return value;
        if (fieldName.includes("label")) return getNestedObjValue(row, fieldName.split("."));
        else return selectChoices?.find((item) => item.value == getNestedObjValue(row, fieldName.split(".")))?.label;
    })();

    return (
        <div className="input-placeholder" id="input-placeholder" key={`prime-input-select-${fieldName}-${row.id}`}>
            {content}
        </div>
    );
};
