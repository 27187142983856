import React from "react";

import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import Select from "react-select";

import { customStyles } from "components/custom-modal-elements/custom-modal-select";
import DatePicker from "components/date-time-picker";
import { formatDate } from "utils/formatDate";

const getInputType = (advancedFilter, filter) => {
    if (advancedFilter?.filterOptionConfig) return "select";
    else if (advancedFilter?.type == "checkbox") return "checkbox";
    else if (filter.filterField?.endsWith("Dt")) return "date";
    else return "text";
};

export const FilterInput = (props) => {
    const { advancedFilter, filter, onChange, filterValueOptions, index } = props;

    const { t } = useTranslation();

    const inputType = getInputType(advancedFilter, filter);

    return (
        <>
            {
                {
                    select: (
                        <Select
                            className="custom-gt-select"
                            styles={customStyles}
                            options={
                                filterValueOptions?.[advancedFilter?.filterOptionConfig?.appModelPath]?.[
                                    advancedFilter.filterOptionConfig.field
                                ]
                            }
                            getOptionLabel={(o) => t(o.label)}
                            getOptionValue={(o) => o.value}
                            onChange={(o) => onChange(o.value, index, "value")}
                            placeholder={`${t("Select")}...`}
                            value={filterValueOptions?.[advancedFilter?.filterOptionConfig?.appModelPath]?.[
                                advancedFilter?.filterOptionConfig?.field
                            ].find((o) => o.value == filter.value)}
                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                        />
                    ),
                    checkbox: (
                        <Select
                            className="custom-gt-select"
                            styles={customStyles}
                            options={filterValueOptions.additionalOptions.checkboxOptions}
                            getOptionLabel={(o) => t(o.label)}
                            getOptionValue={(o) => o.value}
                            onChange={(o) => onChange(o.value, index, "value")}
                            placeholder={`${t("Select")}...`}
                            value={filterValueOptions.additionalOptions.checkboxOptions?.find((o) => o.value == filter.value) || null}
                            noOptionsMessage={({ inputValue }) => !inputValue && t("No options")}
                        />
                    ),
                    date: (
                        <DatePicker
                            autoComplete="off"
                            selected={filter.value && new Date(filter.value)}
                            onChange={(date) => onChange(formatDate(date), index, "value")}
                            dateFormat="yyyy-MM-dd"
                            className="rui-datetimepicker form-control w-100"
                        />
                    ),
                    text: <Input type="text" onChange={(e) => onChange(e.target.value, index, "value")} value={filter.value} />,
                }[inputType]
            }
        </>
    );
};
