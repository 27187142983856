import React, { Children, useEffect, useLayoutEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames/dedupe";

import { updatePageLayout } from "actions/index";

import "../../page-layout.scss";

const PageContent = ({ children }) => {
    const { tableContainerHeight } = useSelector((state) => {
        return { tableContainerHeight: state.styles.pageLayout.tableContainerHeight };
    });

    const containerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            updatePageLayout({
                tableContainerHeight: 150,
                settled: false,
            })
        );
    }, []);

    return (
        <div
            id="page-container"
            className="rui-page-content"
            ref={containerRef}
            style={{ "--table-container-height": `${tableContainerHeight}px` } as any}
        >
            {children}
        </div>
    );
};

export default PageContent;
