import React, { createContext, useContext, useState } from "react";
import { IStabilityTemplateTable, ITestPatternTable } from "../interfaces/interfaces";

export const StabilityTemplatesContext = createContext<any>(null);

export const StabilityTemplatesContextProvider = ({ children }) => {
    const [selectedStabilityTemplates, setSelectedStabilityTemplates] = useState<IStabilityTemplateTable[]>([]);
    const [selectedTestPatterns, setSelectedTestPatterns] = useState<ITestPatternTable[]>([]);

    return (
        <StabilityTemplatesContext.Provider
            value={{ selectedStabilityTemplates, setSelectedStabilityTemplates, selectedTestPatterns, setSelectedTestPatterns }}
        >
            {children}
        </StabilityTemplatesContext.Provider>
    );
};

export const useStabilityTemplates = (): IUseStabilityTemplates => {
    const { selectedStabilityTemplates, setSelectedStabilityTemplates, selectedTestPatterns, setSelectedTestPatterns } =
        useContext(StabilityTemplatesContext);

    return { selectedStabilityTemplates, setSelectedStabilityTemplates, selectedTestPatterns, setSelectedTestPatterns };
};

interface IUseStabilityTemplates {
    selectedStabilityTemplates: IStabilityTemplateTable[];
    setSelectedStabilityTemplates: (arg: IStabilityTemplateTable[]) => void;
    selectedTestPatterns: ITestPatternTable[];
    setSelectedTestPatterns: (arg: ITestPatternTable[]) => void;
}
