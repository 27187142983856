import RoutesList from "../pages";

export const URL_LIST = Object.keys(RoutesList);

export const toRouterUrl = (url) =>
    url
        .split("/")
        .map((item) => (isNaN(Number(item)) || item == "" ? item : ":id"))
        .join("/");

export const unifyParams = (url) =>
    url
        .split("/")
        .map((item) => (item.includes(":") ? ":id" : item))
        .join("/");
