import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import classnames from "classnames/dedupe";
import Axios from "axios";

import { getNestedObjValue } from "components/prime-data-table/helpers";
import { useFetchTableData } from "hooks/useFetchTableData";
import { downloadFile } from "utils/file/download-file";
import { handleRequest } from "utils/handleRequest";
import { auditColumns } from "./audit-columns";
import { GT } from "components/index";
import addToast from "utils/addToast";

import "./style.scss";

export const AuditTable = (props) => {
    const { closeModal, auditData, instance, tableColumns } = props;
    const { model, appLabel } = auditData;

    const { t } = useTranslation();

    const wrappedColumns = useMemo(() => {
        return tableColumns.map((column) => {
            return {
                ...column,
                body: (rowData) => {
                    const value = getNestedObjValue(rowData.row, rowData.fieldName.split("."));
                    const className = (() => {
                        const currentElement = document.getElementById(`${column.field}-${rowData.rowIndex}`);
                        const prevElement = document.getElementById(`${column.field}-${rowData.rowIndex + 1}`);

                        const prevValue = prevElement?.innerText;
                        const currentValue = currentElement?.innerText?.replace(t("Removed"), "");

                        if (!!currentValue && !prevValue) return "audit-added";
                        if (!currentValue && !!prevValue) return "audit-removed";
                        if (currentValue != prevValue) return "audit-modified";
                    })();

                    return (
                        <div
                            className={classnames(className, { "pl-5": !column?.body || className === "audit-removed" })}
                            id={`${column.field}-${rowData.rowIndex}`}
                        >
                            {(className === "audit-removed" && t("Removed")) || (column?.body && column.body(rowData)) || value}
                        </div>
                    );
                },
            };
        });
    }, []);

    const { data, handleReload } = useFetchTableData({
        url: "audit",
        configParams: { params: { hideToast: true } },
        additionalParams: `&appLabel=${appLabel}&model=${model}&instanceId=${instance?.id}`,
        onSuccess: (dataSet) => {
            if (!dataSet[0]) {
                closeModal();
                addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
                return;
            }
        },
        onError: (err: any) => {
            console.log(err);
            addToast({ title: "No audit events found", content: "or audit for this table is disabled", color: "danger" });
        },
    });

    const handleExport = async () => {
        await handleRequest({
            request: () =>
                Axios.post("export-audit-to-xlsx", {
                    modelName: model,
                    appLabel,
                    instanceId: instance?.id,
                }),
            onSuccess: (response) => {
                downloadFile({ data: { file: response.data?.file.replace("media/", ""), name: `${appLabel}-${model}-audit` } });
            },
        });
    };

    return (
        <Modal className="audit-window" isOpen={true} toggle={closeModal} fade>
            <GT.ModalHeader closeModal={closeModal} name="Audit" />
            <ModalBody style={{ overflowY: "auto" }}>
                <GT.PrimeDataTable
                    tableName={`audit-table-${appLabel}-${model}`}
                    notSelectFirst
                    onReload={handleReload}
                    dataSet={data?.data}
                    meta={data?.meta}
                    columns={[...auditColumns, ...wrappedColumns.map((col) => ({ ...col, width: 150 }))]}
                    idSelector="historyId"
                    defaultTableHeight={700}
                >
                    <GT.Button text="Export XLSX" onClick={handleExport} withoutPerm />
                </GT.PrimeDataTable>
            </ModalBody>
            <GT.ModalFooter closeModal={closeModal} cancelName="Close" />
        </Modal>
    );
};
