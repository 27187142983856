import React from "react";

import { Col, Row } from "reactstrap";

import { GT } from "components/index";
import { useFetchData } from "hooks/useFetchData";

export const DurationField = ({ field, formData, onChange, errors }) => {
    const { data: units } = useFetchData<{ unitsOfTime: ILabelValue[] }>({ url: "units-of-time" });

    return (
        <Row>
            <Col md={9}>
                <GT.CustomInput
                    labelText={field.label}
                    type="number"
                    name={`${field.name}.time`}
                    handleChange={field.onChange || onChange}
                    value={field.value || formData?.[field.name]?.time || ""}
                    errors={field.errors || errors?.[field.name]?.time}
                    className={field.className}
                    noLabel={!field.label}
                    disabled={field.disabled}
                    colSm={8}
                    labelSm={4}
                    min={0}
                    {...field.props}
                />
            </Col>
            <Col md={3}>
                <GT.PrimeSelect
                    placeholder="Unit"
                    name={`${field.name}.unit`}
                    value={field.value || formData?.[field.name]?.unit}
                    onChange={field.onChange || onChange}
                    options={field.options || units?.unitsOfTime}
                    disabled={field.disabled}
                    errors={field.errors || errors?.[field.name]?.timeUnit || errors?.[field.name]?.unit}
                    className={field.className}
                    translateOptions
                    {...field.props}
                />
            </Col>
        </Row>
    );
};
