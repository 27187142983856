import { useTranslation } from "react-i18next";
import { MenuItem } from "primereact/menuitem";
import React from "react";
 
import { TooltipHOC } from "components";

interface IMenuItemTemplateProps {
    item: MenuItem;
    text: string;
    tooltipText: string;
    disabled?: boolean;
}

export const TooltipMenuItem = ({ item, text, tooltipText, disabled = false }: IMenuItemTemplateProps) => {
    const { t } = useTranslation();

    if (!disabled)
        return (
            <a
                onClick={(e) => {
                    e.preventDefault();
                    item?.command && item.command({ originalEvent: e, item: item } as any); // there is a bug in prime types
                }}
                href="#"
                className={`p-menuitem-link ${disabled ? "p-disabled" : ""}`}
                role="menuitem"
                aria-haspopup="false"
                aria-disabled="true"
            >
                <span className="p-menuitem-text">{t(text)}</span>
            </a>
        );

    return (
        <TooltipHOC tooltipContent={tooltipText}>
            <a
                href="#"
                className={`p-menuitem-link ${disabled ? "p-disabled" : ""}`}
                role="menuitem"
                aria-haspopup="false"
                aria-disabled="true"
            >
                <span className="p-menuitem-text">{t(text)}</span>
            </a>
        </TooltipHOC>
    );
};
