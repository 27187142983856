import React, { createContext, useContext, useState } from "react";

import { ISelectedNodes } from "../../../../components/prime-tree/interfaces/tree-interfaces";
import { deprecated_useFormData } from "../../../../hooks/useFormData";
import { ILocation, ILocationProcessParameterTable } from "../interfaces/interfaces";
import { locationUrls } from "../utils/locationUrls";

export const LocationContext = createContext<any>(null);

export const LocationContextProvider = ({ children }) => {
    const [selectedLocations, setSelectedLocations] = useState<ISelectedNodes>({});
    const [selectedLocationProcessParameters, setSelectedLocationProcessParameters] = useState<ILocationProcessParameterTable[]>([]);

    const locationIds = Object.keys(selectedLocations);

    const { formData: selectedLocationObj, refreshFormData: refreshLocationObject } = deprecated_useFormData<ILocation>({
        url: locationIds.length == 1 ? locationUrls.main(parseInt(locationIds[0])) : undefined,
        triggerValues: [locationIds.toString()],
    });

    return (
        <LocationContext.Provider 
            value={{ 
                selectedLocations, 
                setSelectedLocations, 
                selectedLocationObj, 
                refreshLocationObject,
                selectedLocationProcessParameters,
                setSelectedLocationProcessParameters
            }}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = (): IUseLocation => {
    const { 
        selectedLocations, 
        setSelectedLocations, 
        selectedLocationObj, 
        refreshLocationObject, 
        selectedLocationProcessParameters, 
        setSelectedLocationProcessParameters 
    } = useContext(LocationContext);

    return { 
        selectedLocations, 
        setSelectedLocations, 
        selectedLocationObj, 
        refreshLocationObject, 
        selectedLocationProcessParameters, 
        setSelectedLocationProcessParameters 
    };
};

interface IUseLocation {
    selectedLocations: ISelectedNodes;
    setSelectedLocations: (arg: ISelectedNodes) => void;
    selectedLocationObj: ILocation | undefined;
    refreshLocationObject: () => void;
    selectedLocationProcessParameters: ILocationProcessParameterTable[];
    setSelectedLocationProcessParameters: (selected: ILocationProcessParameterTable[]) => void;
}
