export const samplingPlansUrls = {
    samplingPlans: (samplingPlanId?: number) => (samplingPlanId ? `crm/sampling-plans/${samplingPlanId}` : "crm/sampling-plans"),
    samplingPlanDualTable: "crm/sampling-points-dual-table",
    dealMaterialsDualTable: "crm/deal-materials-dual-table/contract",
    samplingPlanDealMaterials: "crm/sampling-plan-deal-materials",
    
    samplingPlanPoints: (samplingPlanPointId?: number) =>
        samplingPlanPointId ? `crm/sampling-plan-points/${samplingPlanPointId}` : "crm/sampling-plan-points",
    
    samplingPlanPointEvents: (eventId?: number) =>
        eventId ? `crm/sampling-plan-point-events/${eventId}` : "crm/sampling-plan-point-events",
};
