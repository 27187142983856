import { formatDate } from "../../../utils/formatDate";
import addToast from "../../../utils/addToast"; 
import { getStatusDisplay } from "../body-components/pretty-cell/pretty-cell-helpers";

const superscriptsUnicodes = {
    "1": "\u00B9",
    "2": "\u00B2",
    "3": "\u00B3",
    "4": "\u2074",
    "5": "\u2075",
    "6": "\u2076",
    "7": "\u2077",
    "8": "\u2078",
    "9": "\u2079",
    "0": "\u2070",
};

const subscriptsUnicodes = {
    "1": "\u2081",
    "2": "\u2082",
    "3": "\u2083",
    "4": "\u2084",
    "5": "\u2085",
    "6": "\u2086",
    "7": "\u2087",
    "8": "\u2088",
    "9": "\u2089",
    "0": "\u2080",
};

export const handleExportRawCsv = async (dataSet) => {
    downloadCSV(dataSet);
};

export const handleExportCsv = (dataSet, dataColumns, t) => {
    //get map selector => name
    const translateMap = new Map();

    dataColumns.map((o) => {
        translateMap.set(o.field, o.header);
    });

    //formating logic, pretty columns (with map), pretty status (with t)
    const formattedDataSet = dataSet.map((row) => {
        var key,
            keys = Object.keys(row);
        var n = keys.length;
        var newobj = {};
        for (let n = 0; n < keys.length; n++) {
            if (keys[n] == "isSelected") continue;
            key = keys[n];
            if (key.toLowerCase().includes("status")) {
                if (row[key]) newobj[translateMap.get(key)] = t(getStatusDisplay(row[key]));
            } else if (key.toLowerCase().includes("unit")) {
                var displayUnit = "";
                if (row[key]) {
                    for (var i = 0; i < row[key].length; i++) {
                        if (row[key][i] == "↓" || row[key][i] == "←" || row[key][i] == "" || row[key][i] == " ") continue;
                        if (row[key][i] == "↑") {
                            displayUnit = displayUnit.concat(subscriptsUnicodes[row[key][i + 1]]);
                            i++;
                        } else if (row[key][i] == "→") {
                            displayUnit = displayUnit.concat(superscriptsUnicodes[row[key][i + 1]]);
                            i++;
                        } else {
                            displayUnit = displayUnit.concat(row[key][i]);
                        }
                    }
                }
                newobj[translateMap.get(key)] = displayUnit;
            } else {
                newobj[translateMap.get(key)] = row[key];
            }
        }
        return newobj;
    });
    downloadCSV(formattedDataSet);
};

const downloadCSV = (array) => {
    if (!array || array.length == 0) {
        addToast({
            title: "Export to CSV failed",
            content: "This table is empty",
            color: "danger",
        });
        return;
    }

    const link = document.createElement("a");

    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const pathFix = window.location.pathname.replaceAll("/", "-").toLowerCase();
    const filename = `weblab${pathFix}-export-${formatDate(Date.now())}.csv`;
    const encoding = "utf-8,\uFEFF";

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv; charset=${encoding}${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
    link.remove();
};

const convertArrayOfObjectsToCSV = (array) => {
    array = array.map((r) => {
        const { excludedRow, isSelected, ...rest } = r;
        return rest;
    });

    const columnDelimiter = ";";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    let result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    // jacek question -- kolumny widoczne z columns czy z dataset (backend?) wszystkie czy nie wszystkie
    // czy tlumaczymy fieldy (np status) bo export / import itp tid

    array.forEach((item) => {
        keys.forEach((key, ctr) => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key] ?? "";
        });
        result += lineDelimiter;
    });

    return result;
};
