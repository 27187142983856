import { useEffect, useState } from "react";

export function useResolved(...values) {
    const [resolved, setResolved] = useState<boolean>(false);

    useEffect(() => {
        const isResolved = values.every((v) => v != undefined);
        resolved != isResolved && setResolved(isResolved);
    }, [values]);

    return resolved;
}
