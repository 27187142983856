import React from "react";

import { XCircle } from "react-feather";

import "./styles.scss";
import { useTranslation } from "react-i18next";

interface EmptyMessageProps {
    height?: number;
}

export const EmptyMessage = ({ height }: EmptyMessageProps) => {
    const { t } = useTranslation();

    return (
        <div className="prime-data-table-message" style={{ height: `${height}px` }}>
            <XCircle />
            <p>{t("No records found")}</p>
        </div>
    );
};
