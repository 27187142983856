import React, { RefObject, useEffect } from "react";

import { BarcodeScanner } from "../components/barcode-scanner/barcode-scanner";

/**
 * Hook that initialize barcode class and add custom event "onbarcodescanned"
 * when onkeypress event is fired faster than in 10 milliseconds periods
 * @param onScan @type {void}
 */
export const useBarcodeScanner = (onScan: (e: CustomEvent<string>) => void, ref: RefObject<any> | undefined = undefined) => {
    useEffect(() => {
        const barcode = new BarcodeScanner();
        barcode.initialize();
        return () => {
            barcode.close();
        };
    }, []);

    useEffect(() => {
        const scanHandler = (code) => {
            onScan(code);
        };

        (ref?.current || document).addEventListener("onbarcodescanned", scanHandler);
        return () => {
            (ref?.current || document).removeEventListener("onbarcodescanned", scanHandler);
        };
    }, []);
};

/**
 * function to simulate barcode behavior
 * @param text @type {string}
 */
export const simulateBarcode = (text: string = "12345678900") => {
    text.split("").forEach((c) => document.dispatchEvent(new KeyboardEvent("keypress", { key: c })));
};
