import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { Form, Modal, ModalBody } from "reactstrap";
import Axios from "axios";

import { getSelectChoices } from "components/prime-data-table/helpers/getSelectChoices";
import { tableUrls } from "components/prime-data-table/utils/table-urls";
import { handleErrorDelete } from "utils/handleDeleteError";
import { handleRequest } from "utils/handleRequest";
import { I } from "components/prime-data-table";
import { GT } from "components";

interface IAdditionalColumnsModalProps {
    mode: "add" | "edit";
    closeModal: () => void;
    meta: I.IMeta;
    columnField: string;
    handleAdditionalColumns: I.HandleAdditionalColumns;
}

const extraFieldsAllPossibleKeys: I.ExtraFieldsKeys[] = [
    "extraField1",
    "extraField2",
    "extraField3",
    "extraField4",
    "extraField5",
    "extraField6",
    "extraField7",
    "extraField8",
    "extraField9",
    "extraField10",
];

const typeOptions: ILabelValue<I.ExtraFieldTypes, "String" | "Date" | "Integer" | "Number">[] = [
    { label: "String", value: "str" },
    { label: "Date", value: "date" },
    { label: "Integer", value: "int" },
    { label: "Number", value: "float" },
    { label: "List", value: "list" },
];

export const AddEditAdditionalColumnsModal = ({
    closeModal,
    meta,
    columnField,
    handleAdditionalColumns,
    mode,
}: IAdditionalColumnsModalProps) => {
    const extraFields = meta.ExtraFields;

    const [data, setData] = useState<Partial<I.ExtraField> | undefined>(mode === "edit" ? extraFields[columnField] : undefined);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const [errors, setErrors] = useState<IErrors>({});

    const handleCloseModal = () => closeModal();

    const handleSubmitData = async (e: SyntheticEvent) => {
        setIsBusy(true);
        let _errors: IErrors = {};

        if (!data?.name && !data?.type) _errors = { name: "Column name is required", type: "Column type is required" };
        else if (!data?.name) _errors = { name: "Column name is required" };
        else if (!data?.type) _errors = { type: "Column type is required" };

        if (Object.keys(_errors).length > 0) {
            setErrors(_errors);
            return;
        }

        const _data = data as I.ExtraField;
        let _extraFields: I.ExtraFields = { ...extraFields };
        let column: I.IPrimeTableColumn = {
            sortable: true,
            noHeaderTranslation: true,
            additionalType: data?.type,
            header: _data.name,
            field: columnField,
            lookupChoices: await getSelectChoices(data?.lookup),
        };

        if (mode == "edit") _extraFields[columnField] = data?.type != extraFields[columnField]?.type ? { ..._data, clean: true } : _data;
        else {
            const possibleKey: I.ExtraFieldsKeys = extraFieldsAllPossibleKeys.filter(
                (possibleKey) => !Object.keys(extraFields).includes(possibleKey)
            )[0];

            _extraFields[possibleKey] = data as I.ExtraField;
            column.field = possibleKey;
        }

        await handleRequest({
            request: () => Axios.post(tableUrls.additionalColumns, { info: _extraFields, modelLabel: meta.ModelInfo?.appModelLabel }),
            onSuccess: () => {
                handleAdditionalColumns(
                    mode == "edit"
                        ? { payload: { column: column }, action: I.ADDITIONAL_COLUMNS_EVENT_ACTION.EDIT }
                        : {
                              payload: { column: column, selectedContextColumnField: columnField },
                              action: I.ADDITIONAL_COLUMNS_EVENT_ACTION.ADD,
                          }
                );
                closeModal();
                setIsBusy(false);
            },
            onError: () => {
                setIsBusy(false);
            },
        });
    };

    const handleChangeState = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value || "" });
        errors[name] && setErrors(handleErrorDelete(errors, name));
    };

    const fields: IFormField[] = [
        {
            fieldType: "input",
            name: "name",
            label: "Name",
        },
        {
            fieldType: "select",
            name: "type",
            label: "Type",
            options: () => typeOptions,
            props: {
                translateOptions: true,
            },
        },
        {
            fieldType: "select",
            name: "lookup",
            label: "Lookup",
            display: data?.type == "list",
            url: `bs/lookups`,
            options: (options) => {
                return options?.data?.map((option) => ({ label: option.name, value: option.name }));
            },
            params: {
                noPagination: true,
            },
        },
    ];

    return (
        <Modal isOpen={true} toggle={() => closeModal()} fade>
            <GT.Form onSubmit={handleSubmitData} permCode="" disableTransactions>
                <GT.ModalHeader closeModal={handleCloseModal} name="Additional columns" />
                <ModalBody>
                    <GT.FormFields fields={fields} formData={data} setFormData={setData} errors={errors} onChange={handleChangeState} />
                </ModalBody>
                <GT.ModalFooter closeModal={handleCloseModal} cancelName="Cancel" okName="Save" isBusy={isBusy} />
            </GT.Form>
        </Modal>
    );
};
