import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";

import "./style.scss";

export interface LabelErrorProps {
    id: string;
    error: Array<string>;
    t: (string) => void;
}

const LabelError = React.memo<any>(({ id, error, t }) => {
    const labelRef = useRef<HTMLLabelElement>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [tooltipError, setTooltipError] = useState<boolean>(false);

    useEffect(() => {
        if (labelRef.current) {
            labelRef.current.offsetWidth < labelRef.current.scrollWidth ? setVisible(true) : setVisible(false);
        }
        id = id ? id : "NonFieldErrors";
    }, [error]);

    return (
        <>
            <label id={id} ref={labelRef} className="invalid-feedback label-error">
                {t(error)}
            </label>
            {visible && (
                <Tooltip target={id} isOpen={tooltipError} toggle={() => setTooltipError((prev) => !prev)}>
                    {t(error)}
                </Tooltip>
            )}
        </>
    );
});

export default withTranslation()(LabelError);
