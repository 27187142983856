import React, { createContext, useContext, useEffect, useState } from "react";
import { IPriceList, IService } from "../interfaces/interfaces";

export const PriceListContext = createContext<any>(null);

export const PriceListProvider = ({ children }) => {
    const [selectedPriceLists, setSelectedPriceLists] = useState<IPriceList[]>([]);
    const [selectedPackageServices, setSelectedPackageServices] = useState<IService[]>([]);

    const [refreshPackageServiceData, setRefreshPackageServiceData] = useState<Function | undefined>(undefined);

    const [tabActive, setTabActive] = useState<ITab>();

    useEffect(() => {
        setSelectedPackageServices([]);
    }, [tabActive]);

    return (
        <PriceListContext.Provider
            value={{
                selectedPriceLists,
                setSelectedPriceLists,
                selectedPackageServices,
                setSelectedPackageServices,
                tabActive,
                setTabActive,
                refreshPackageServiceData,
                setRefreshPackageServiceData,
            }}
        >
            {children}
        </PriceListContext.Provider>
    );
};

export const usePriceList = (): IUsePriceListContext => {
    const {
        selectedPriceLists,
        setSelectedPriceLists,
        selectedPackageServices,
        setSelectedPackageServices,
        tabActive,
        setTabActive,
        refreshPackageServiceData,
        setRefreshPackageServiceData,
    } = useContext(PriceListContext);

    return {
        selectedPriceLists,
        setSelectedPriceLists,
        selectedPackageServices,
        setSelectedPackageServices,
        tabActive,
        setTabActive,
        refreshPackageServiceData,
        setRefreshPackageServiceData,
    };
};

interface IUsePriceListContext {
    selectedPriceLists: IPriceList[];
    setSelectedPriceLists: (arg: IPriceList[]) => void;
    selectedPackageServices: IService[];
    setSelectedPackageServices: (arg: IService[]) => void;
    tabActive: ITab;
    setTabActive: (arg: ITab) => void;
    refreshPackageServiceData: Function | undefined;
    setRefreshPackageServiceData: (arg: Function | undefined) => void;
}

type ITab = "Package services" | "Basic services" | "Additional services";
