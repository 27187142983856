export const locationUrls = {
    main: (locationId?: number) => (locationId ? `bs/locations/${locationId}` : "bs/locations"),

    metaData: {
        groups: "metadata/group-form-data",
        templates: (groupId?: number) => (groupId ? `metadata/template-form-data/${groupId}` : "metadata/template-form-data"),
        versions: (templateId?: number) => (templateId ? `metadata/version-form-data/${templateId}` : "metadata/version-form-data"),

        getIds: (versionId: number) => `metadata/version-template-group-ids/${versionId}`,
    },

    addFromTemplate: "bs/locations/create-location-from-template",
};
