import React from "react";

import { useTranslation } from "react-i18next";

import "./styles.scss";

interface ICustomHelpContextMenuProps {
    positionX: number;
    positionY: number;
    handleMenuClick: () => void;
    helpPath?: string;
}

const HelpContextMenu = (props: ICustomHelpContextMenuProps) => {
    const { t } = useTranslation();

    const { positionX, positionY, handleMenuClick, helpPath } = props;

    const handleButtonClick = () => {
        if (!helpPath) return;

        let url = "";

        const hashIndex = helpPath.indexOf("#");

        if (hashIndex != -1) {
            const article = helpPath.slice(0, hashIndex);
            const section = helpPath.slice(hashIndex + 1);

            url = `/help?article=${article}&section=${section}`;
        } else {
            url = `/help?article=${helpPath}`;
        }

        window.open(url, "_blank");
    };

    return (
        <div
            style={{
                position: "fixed",
                top: positionY,
                left: positionX,
                padding: "4px 6px",
                border: "2px solid #725ec366",
                borderRadius: "4px",
                wordBreak: "break-all",
                backgroundColor: "white",
                animation: "filter-fade-in 0.25s ease-out",
                zIndex: 11000000000000000,
            }}
        >
          
                <div className="help-context-menu" onClick={handleMenuClick}>
                    <div onClick={handleMenuClick}>
                        <i className="pi pi-question-circle"></i>
                    </div>
                    <div onClick={handleButtonClick}>{t("Go to help")}</div>
                </div>
        
        </div>
    );
};

export default HelpContextMenu;
