import { ISearchResult } from "./interface";

interface ILinkState {
    pathname: string;
    state: {
        instanceId: number;
    };
}

export const mapModelToRoute = (model: string, result: ISearchResult): ILinkState => {
    const MODEL_TO_ROUTE: {
        readonly [key: string]: ILinkState;
    } = {
        order: { pathname: "/orders/edit", state: { instanceId: result.orderId || result.id } },
        sample: { pathname: "/samples", state: { instanceId: result.id } },
        result: { pathname: "/orders/edit", state: { instanceId: result.id } },
    };

    return MODEL_TO_ROUTE[model];
};
