import React, { createContext, useContext, useState } from "react";
import {  IMappedTests, IRelatedCompany } from "../interfaces/interface";

export const MappingContext = createContext<any>(null);

export const MappingProvider = ({ children }) => {
    const [selectedRelatedCompanies, setSelectedRelatedCompanies] = useState<IRelatedCompany[]>([]);
    const [selectedMappedTests, setSelectedMappedTests] = useState<IMappedTests[]>([]);

    return (
        <MappingContext.Provider
            value={{
                selectedRelatedCompanies,
                setSelectedRelatedCompanies,
                selectedMappedTests,
                setSelectedMappedTests,
            }}
        >
            {children}
        </MappingContext.Provider>
    );
};

export const useMapping = (): IUseMapping => {
    const {
        selectedRelatedCompanies,
        setSelectedRelatedCompanies,
        selectedMappedTests,
        setSelectedMappedTests,
    } = useContext(MappingContext);

    return {
        selectedRelatedCompanies,
        setSelectedRelatedCompanies,
        selectedMappedTests,
        setSelectedMappedTests,
    };
};

interface IUseMapping {
    selectedRelatedCompanies: IRelatedCompany[];
    setSelectedRelatedCompanies: (arg: IRelatedCompany[]) => void;
    selectedMappedTests: IMappedTests[];
    setSelectedMappedTests: (arg: IMappedTests[]) => void;
}
