import React, { SyntheticEvent, useEffect, useState } from "react";

import { Modal, ModalBody } from "reactstrap";
import Axios from "axios";

import { IDealMaterial, ISamplingPlanModal, ISamplingPlanTable } from "../../../interfaces/interfaces";
import { dealMaterialsDualTableColumns } from "./add-edit-sampling-plan-columns";
import { mapToIds } from "components/prime-data-table/helpers/primeHelpers";
import { samplingPlansUrls } from "../../../utils/sampling-plans-urls";
import { IMeta } from "components/prime-data-table/interfaces";
import { handleErrorDelete } from "utils/handleDeleteError";
import CustomForm from "components/custom-form/custom-form";
import { yearsOptions } from "../../../utils/helpers";
import addToast from "utils/addToast";
import { GT } from "components/index";

interface ISamplingPlanModalProps {
    disabled?: boolean;
    editedRecord?: ISamplingPlanTable | undefined;
    dataSet?: ISamplingPlanTable[];
    setDataSet?: (newDataSet: ISamplingPlanTable[]) => void;
    refreshData?: () => void;
}

interface IModalContent extends ISamplingPlanModalProps {
    modalOpen: boolean;
    closeModal: () => void;
    content: IContent;
}

interface IContent {
    headerText: string;
    footerText: string;
    permCode: string;
}

export const AddExternalSamplingPlanModal = (props: ISamplingPlanModalProps) => {
    const { disabled = false } = props;

    const content = { headerText: "Add", permCode: "add.crm::sampling-plans.external-sampling-plans.add", footerText: "Save" };

    return (
        <GT.ModalWrapper disabled={disabled} text="Add" icon="plus" permCode={content.permCode}>
            {(modalOpen, closeModal) => <ModalContent {...props} closeModal={closeModal} modalOpen={modalOpen} content={content} />}
        </GT.ModalWrapper>
    );
};

export const EditExternalSamplingPlanModal = (props: ISamplingPlanModalProps) => {
    const { disabled = false } = props;

    const content = {
        headerText: "Edit",
        permCode: "change.crm::sampling-plans.external-sampling-plans.change",
        footerText: "Save changes",
    };

    return (
        <GT.ModalWrapper disabled={disabled} text="Edit" icon="edit" permCode={content.permCode}>
            {(modalOpen, closeModal) => <ModalContent {...props} closeModal={closeModal} modalOpen={modalOpen} content={content} />}
        </GT.ModalWrapper>
    );
};

export interface IDealMaterialData {
    notSelected: {
        data: IDealMaterial[];
        meta: IMeta;
    };
    selected: IDealMaterial[];
}

const ModalContent = (props: IModalContent) => {
    const { editedRecord, modalOpen, closeModal, content, dataSet, setDataSet, refreshData } = props;

    const [dealMaterialsData, setDealMaterialsData] = useState<IDealMaterialData | undefined>(undefined);
    const [formData, setFormData] = useState<ISamplingPlanModal>({ year: new Date().getFullYear() });

    const [errors, setErrors] = useState<IErrors>({});

    useEffect(() => {
        const onInit = () => {
            if (editedRecord) {
                let _data: ISamplingPlanModal = {
                    name: editedRecord.name,
                    year: editedRecord.year,
                };

                setFormData(_data);
                getDealMaterials(undefined, mapToIds(editedRecord.dealMaterials));
            } else getDealMaterials(undefined, []);
        };

        onInit();
    }, []);

    const getDealMaterials = async (
        urlParams = `?pageSize=${dealMaterialsData?.notSelected?.meta.pageSize || 5}&page=1`,
        excluded: number[]
    ) => {
        try {
            const response = await Axios.get(samplingPlansUrls.dealMaterialsDualTable + urlParams, {
                params: { excluded, search_field: "sampling_point__isnull", search: false },
            });

            setDealMaterialsData(response.data);
        } catch (err: any) {
            console.error(err);
        }
    };

    const handleSubmitData = async (e: SyntheticEvent, reqParams: IReqParams = {}) => {
        try {
            let response;

            const payload = { ...formData, reqParams };

            if (editedRecord && dataSet) {
                response = await Axios.patch(`${samplingPlansUrls.samplingPlans(editedRecord?.id)}`, payload);
                const newData = dataSet?.map((r) => (r.id == response.data.data.id ? { ...response.data.data } : r));

                setDataSet && setDataSet(newData);
            } else {
                response = await Axios.post(samplingPlansUrls.samplingPlans(), { isExternal: true, ...payload });
                refreshData && (await refreshData());
            }

            addToast({ title: `Sampling plan has been ${editedRecord ? "edited" : "added"} successfully.` });
            closeModal();

            return response.status;
        } catch (e: any) {
            e.response?.status == "400" && setErrors(e.response.data);

            if (e.response?.status == "403") return e.response.data;
            else return e.response.status;
        }
    };

    const handleChangeState = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value || "" });
        errors[name] && setErrors(handleErrorDelete(errors, name));
    };

    return (
        <Modal isOpen={modalOpen} toggle={closeModal} fade className="wide-modal-container-65">
            <CustomForm onSubmit={handleSubmitData} permCode={content.permCode}>
                <GT.ModalHeader closeModal={closeModal} name={`${content.headerText} sampling plan`} />
                <ModalBody>
                    <GT.CustomInput
                        errors={errors.name}
                        handleChange={handleChangeState}
                        labelText="Name"
                        type="text"
                        name="name"
                        value={formData?.name || ""}
                    />
                    <GT.CustomSelect
                        errors={errors.year}
                        name="year"
                        labelName="Year"
                        options={yearsOptions}
                        handleChange={handleChangeState}
                        value={yearsOptions.find((o) => o.value == formData?.year) || null}
                    />
                    <GT.DualTable
                        layout="column"
                        dualListName="sampling_plans-dual_table"
                        meta={dealMaterialsData?.notSelected?.meta}
                        dataSet={dealMaterialsData?.notSelected?.data}
                        secondTableDataSet={dealMaterialsData?.selected}
                        columns={dealMaterialsDualTableColumns}
                        handleReload={getDealMaterials}
                        permCode={content.permCode}
                        handleSelected={(selected) => handleChangeState({ target: { value: mapToIds(selected), name: "dealMaterials" } })}
                    />
                </ModalBody>
                <GT.ModalFooter closeModal={closeModal} cancelName="Cancel" okName={content.footerText} />
            </CustomForm>
        </Modal>
    );
};
