import React, { useState } from "react";

import { AsyncTasksDropdownContent, IAsyncTasksCollapse } from "./dropdown-collapse";
import { IAsyncTask, ITasks } from "../async-tasks-dropdown";
import { ProgressBar } from "primereact/progressbar";
import Dropdown from "../../bs-dropdown";
import Icon from "../../icon";

interface IAsyncTasksDropdownMenuProps {
    tasks: ITasks;
    handleDismiss: (id: number) => void;
}

export const AsyncTasksDropdownMenu = (props: IAsyncTasksDropdownMenuProps) => {
    const [indexOpen, setIndexOpen] = useState<number[]>([0]);

    const { tasks, handleDismiss } = props;

    const handleToggleCollapse = (index: number) => {
        setIndexOpen((prev) => (prev.includes(index) ? prev.filter((o) => o != index) : [...prev, index]));
    };

    const mapStatus = {
        done: "circle-check",
        fail: "circle-xmark",
    };

    const asyncTasksCollapses: IAsyncTasksCollapse[] = [
        {
            tasks: tasks.active.filter((o) => o.status != "done"),
            title: "Tasks in progress",
            content: (task: IAsyncTask) => (
                <>
                    <span className="task-name">{task.name}</span>
                    <ProgressBar
                        className="progress-bar"
                        mode={task.progress != undefined ? "determinate" : "indeterminate"}
                        value={task.progress}
                    ></ProgressBar>
                </>
            ),
        },
        {
            tasks: tasks.completed.filter((o) => o.status == "done"),
            title: "Completed",
            content: (task: IAsyncTask) => (
                <div className="d-flex flex-row align-items-center w-100">
                    <Icon name={["fas", mapStatus[task.status]]} className={`status-icon ${task.status}`} />
                    <div className="d-flex justify-content-center align-items-start flex-column">
                        <span className="task-name">{task.name}</span>
                        <span className="created-date">{task.createdDt}</span>
                    </div>
                    <div className="dismiss-btn-container">
                        <div className="dismiss-btn">
                            <Icon name="x" onClick={() => handleDismiss(task.id)} />
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Dropdown.Menu
            right
            tag="div"
            className="async-tasks-dropdown dropdown-notification nav dropdown-menu p-0 flex-column"
            modifiers={{ offset: { offset: "0,12" } }}
        >
            <AsyncTasksDropdownContent configObject={asyncTasksCollapses} indexOpen={indexOpen} onToggleCollapse={handleToggleCollapse} />
        </Dropdown.Menu>
    );
};
