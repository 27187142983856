import React from "react";
import { Spinner } from "reactstrap";

import "./spinner.styles.scss";

const CustomSpinner = (props) => {
    const { color, type } = props;

    return (
        <div className="spinner-container">
            <div className="spinner">
                <Spinner color={color || "secondary"} type={type || "grow"}>
                    Loading...
                </Spinner>
            </div>
        </div>
    );
};

export default CustomSpinner;
