// external
import React, { Component } from "react";

import { BrowserRouter, withRouter, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { TransitionGroup } from "react-transition-group";
import { Provider, connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";

// internal
import TilesView from "./components/app-tiles-view/app-tiles-view";
import { PageToasts } from "./components/page-toasts/page-toasts";
import { default as persistor } from "./reducers/configureStore";
import PageNavbar from "./components/page-navbar";
import PageYaybar from "./components/page-yaybar";
import PageTitle from "./components/page-title";
import addToast from "./utils/addToast";
import Routes from "./Routes";
import {
    updateAuth as actionUpdateAuth,
    updatePermissions as actionUpdatePermissions,
    updateOptions as actionUpdateOptions,
    logOut,
} from "./actions";

// fontawesome
import "./../common-assets/icons/fontawesome/css/all.css";
// styles
import "./style-override.scss";
import "./style-night.scss";
//external style import
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";

import "./components/animated-route";
import "./utils/replaceAll";

const $html = window.jQuery("html");
const $body = window.jQuery("body");

/**
 * Component PageWrap
 */

class PageWrap extends Component<any> {
    constructor(props) {
        super(props);

        this.maybeCheckAuth = this.maybeCheckAuth.bind(this);
        this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind(this);
        this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setUpAxiosInterceptors();

        this.state = {
            windowSize: { width: undefined, height: undefined },
        };
    }

    setUpAxiosInterceptors() {
        const DEBUG = process.env.REACT_APP_DEBUG == "true";

        axios.interceptors.response.use(
            (res) => {
                if (DEBUG) {
                    console.info("✔️ ", res, "\t\t\t", res.data || "");
                }
                return res;
            },
            async (err) => {
                if (DEBUG) {
                    console.error("❌ ", err, "\n\n", err.response || "");
                }

                const unauthErrors = [
                    "unauthenticated",
                    "Authentication credentials were not provided.",
                    "Nie podano danych uwierzytelniających.",
                ];

                if (this.props.auth.isLoggedIn && err?.response?.data?.detail && unauthErrors.includes(err.response.data.detail)) {
                    await this.handleLogOut(err);

                    return Promise.reject(err);
                }

                if (this.props.auth.isLoggedIn && !err?.config?.params?.hideToast && err.response?.status) {
                    // check status for 403?
                    // if(err.response.config.method == 'get' && redirect403){
                    //     window.location.pathname = '/403'
                    // }

                    let toastMsg = "";

                    if (typeof err.response.data == "string" && err.response.data.startsWith("<!DOCTYPE html>")) {
                        toastMsg = `${err.response?.statusText} (${err.response?.status})`;
                    } else if (typeof err.response.data == "string") {
                        toastMsg = err.response.data;
                    } else {
                        const { details, ...toastData } = err.response.data;
                        toastMsg = Object.values(toastData).some((e: any) =>
                            ["This field is required.", "To pole jest wymagane."].includes(e?.[0] as string)
                        )
                            ? this.props.t("Fill all required fields.")
                            : Object.values(toastData)

                                  .map((e) => (typeof e == "string" ? this.props.t(e) : Array.isArray(e) ? this.props.t(e[0]) : undefined))
                                  .toString()
                                  .slice(0, 500);
                    }

                    addToast({
                        title: err.response?.statusText,
                        content: toastMsg,
                        color: "danger",
                    });
                }

                return Promise.reject(err);
            }
        );
    }

    handleLogOut = async (err) => {
        const { logOut: logOutAction } = this.props;

        const csrfToken = Cookies.get("csrftoken");

        try {
            logOutAction();

            await axios.post("logout", undefined, csrfToken ? { headers: { "X-CSRFToken": csrfToken } } : undefined);

            addToast({
                title: "You've been logged out",
                content: err.response.data.detail,
                color: "danger",
            });
        } catch (err: any) {
            console.error(err);
        }
    };

    onOffline = () => addToast({ title: "No network connection" });
    onOnline = () => addToast({ title: "Connected" });

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        window.addEventListener("offline", this.onOffline);
        window.addEventListener("online", this.onOnline);
        this.maybeCheckAuth();
        this.maybeUpdateGlobalSettings();

        $body.addClass("rui-section-lines");
    }

    componentWillUnmount() {
        window.removeEventListener("offline", this.onOffline);
        window.removeEventListener("online", this.onOnline);
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps) {
        this.maybeCheckAuth(prevProps);
        this.maybeUpdateGlobalSettings(prevProps);
        this.maybeScrollPageToTop(prevProps);
    }

    isSignPage(check = "") {
        if (!check) {
            check = window.location.pathname;
        }

        return check === "/sign-in" || check === "/sign-up";
    }

    maybeCheckAuth(prevProps: any = null) {
        const { auth, updateAuth, history }: any = this.props;
        const referrerUrl = auth.referrer && !this.isSignPage(auth.referrer) ? auth.referrer : "/";
        let newRedirect: string | null = null;

        // Redirect from Auth page.
        if (this.isSignPage() && auth.token) {
            newRedirect = referrerUrl;

            // Redirect to Auth page.
        } else if (!this.isSignPage() && !auth.token) {
            newRedirect = "/sign-in";

            // Check if use logged out or logged in.
        } else if (prevProps && auth.token !== prevProps.auth.token) {
            newRedirect = auth.token ? referrerUrl : "/sign-in";
        }

        // Redirect.
        if (newRedirect) {
            updateAuth({
                referrer: window.location.pathname,
            });
            newRedirect === "/sign-in" ? history.push(newRedirect) : history.push("");
        }
    }

    maybeUpdateGlobalSettings(prevProps: any = null) {
        const { settings }: any = this.props;

        // night mode.
        if (prevProps && prevProps.settings.night_mode !== settings.night_mode) {
            if (settings.night_mode) {
                $html.addClass("rui-night-mode");
                //import( './style-night.scss' );
            } else {
                $html.removeClass("rui-night-mode");
            }
        }
        if (!prevProps && settings.night_mode) {
            $html.addClass("rui-night-mode");
            // import( './style-night.scss' );
        }
    }

    maybeScrollPageToTop(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }

    updateWindowDimensions() {
        const { updateOptions }: any = this.props;

        updateOptions({
            screenSize: { width: window.innerWidth, height: window.innerHeight },
        });
    }

    render(): JSX.Element {
        const { auth, location, history }: any = this.props;

        return (
            <TransitionGroup>
                <PageToasts />
                <Route>
                    {auth.token ? (
                        <>
                            <Route component={PageYaybar} />
                            <Route component={PageNavbar} />
                            <Route component={PageTitle} />
                            <Route component={TilesView} />
                        </>
                    ) : (
                        ""
                    )}
                </Route>
                <>
                    <Routes />
                </>
            </TransitionGroup>
        );
    }
}

const PageWrapWithState = withTranslation()(
    connect(({ auth, settings }) => ({ auth, settings }), {
        updateAuth: actionUpdateAuth,
        updateOptions: actionUpdateOptions,
        updatePermissions: actionUpdatePermissions,
        logOut,
    })(withRouter(PageWrap))
);

/**
 * Component App
 */
interface App {
    store: any;
}
class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Provider store={persistor.store}>
                <PersistGate loading={null} persistor={persistor.persistor}>
                    <BrowserRouter>
                        <PageWrapWithState />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
