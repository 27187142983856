import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { getFilterOptions } from "../../../prime-data-table/helpers/getTranslatedFilterOptions";
import { TextInputType } from "components/prime-data-table/interfaces/edit-interfaces";
import { FilterType } from "../../interfaces/tree-interfaces";
import { PrimeSelect } from "../../../prime-select/prime-select";

interface IFilterTypePopupProps {
    filterType: FilterType;
    handleFilterTypeChange: Function;
    t: Function;
    type: TextInputType;
    tableRef?: any;
    inputRef?: any;
}

export const FilterTypePopup = ({ filterType, handleFilterTypeChange, t, type, tableRef, inputRef }: IFilterTypePopupProps) => {
    const popUpRef = useRef<HTMLDivElement>(null);
    const [filterTypeValue, setFilterTypeValue] = useState<FilterType | undefined>(undefined);
    const [popUpPosition, setPopUpPosition] = useState<any>({ top: 0, left: 0, width: 0 });

    const [popUpOpen, setPopUpOpen] = useState<boolean>(false);

    const translatedOptions = getFilterOptions(type);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popUpRef.current &&
                !popUpRef.current.contains(event.target) &&
                !["p-dropdown-items", "p-dropdown-item", "p-dropdown-header", "p-dropdown-filter", "p-dropdown-filter-icon"].some(
                    (className) => (event.target.className as string).includes(className)
                )
            )
                closePopUp();
        };

        if (popUpOpen) {
            setFilterTypeValue(filterType);
            document.addEventListener("mousedown", handleClickOutside);
            checkPopUpPosition();
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [popUpOpen]);

    const checkPopUpPosition = () => {
        if (tableRef.current && popUpRef.current) {
            const popUpInputWidth = inputRef.current?.offsetWidth;

            const popUpPositionY = inputRef.current?.offsetTop * 0.5 + inputRef.current?.offsetHeight + tableRef.current?.offsetTop;
            const popUpPositionX = inputRef.current?.offsetLeft + tableRef.current?.offsetLeft;
            const popUpWidth = 350;

            let _popUpPosition = { top: popUpPositionY, left: popUpPositionX + (popUpInputWidth - popUpWidth), width: popUpWidth };

            setPopUpPosition(_popUpPosition);
        }

        return false;
    };

    const closePopUp = () => setPopUpOpen(false);

    const handleSaveFilterType = () => {
        handleFilterTypeChange(filterTypeValue);
        closePopUp();
    };

    return (
        <>
            <span onClick={() => setPopUpOpen(true)} className={"pi pi-cog tree-filter-type-button"} />

            {popUpOpen &&
                createPortal(
                    <div className="popup" style={popUpPosition} ref={popUpRef}>
                        <PrimeSelect
                            value={filterTypeValue}
                            options={translatedOptions}
                            onChange={(e) => setFilterTypeValue(e.target.value)}
                            placeholder={`${t("Filter Type")}`}
                            name="filterType"
                        />
                        <span
                            className="popup-btn btn btn-outline-brand ml-5 mt-5 mr-10 mb-5"
                            onClick={handleSaveFilterType}
                            title={t("Save")}
                        >
                            {t("Save")}
                        </span>
                    </div>,
                    tableRef.current
                )}
        </>
    );
};
