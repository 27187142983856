import React, { memo } from "react";

import { deepCompare } from "utils/memo/deepCompare";
import { getNestedObjValue } from "../helpers";

const arePropsEqual = (prev, next) => {
    const prevValue = prev.value != undefined ? prev.value : getNestedObjValue(prev.inputData.row, prev.inputData.fieldName.split("."));
    const nextValue = next.value != undefined ? next.value : getNestedObjValue(next.inputData.row, next.inputData.fieldName.split("."));

    return (
        prev.inputEnabled == next.inputEnabled &&
        prevValue == nextValue &&
        prev.inputData.row.id == next.inputData.row.id &&
        deepCompare(prev.inputData?.selectChoices, next.inputData?.selectChoices)
    );
};

export const memoEditInput = (component) => memo<any>(component, arePropsEqual);
