import { getNestedObjValue } from "components/prime-data-table/helpers";
import { isEqual } from "lodash";

export const deepCompare = (item, itemToCompare) => {
    return isEqual(item, itemToCompare);
};

export const deepCompareProps = (props: string[], prev, next) => {
    return props.every((prop) => deepCompare(getNestedObjValue(prev, prop.split(".")), getNestedObjValue(next, prop.split("."))));
};
