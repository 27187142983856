import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { createPortal } from "react-dom";

import { FilteringType } from "components/prime-data-table/interfaces";
import { getFilterOptions } from "../../helpers/getTranslatedFilterOptions";

interface IFilterDatePopupProps {
    filterDateType: FilteringType;
    handleFilterDateTypeChange: Function;
    tableRef?: any;
    inputRef?: any;
    t: Function;
}

export const FilterDateTypePopup = ({ filterDateType, handleFilterDateTypeChange, t, tableRef, inputRef }: IFilterDatePopupProps) => {
    const popUpRef = useRef<HTMLDivElement>(null);

    const [filterDateTypeValue, setFilterDateTypeValue] = useState<FilteringType>();
    const [popUpPosition, setPopUpPosition] = useState<any>({ top: 0, left: 0, width: 0 });

    const [popUpOpen, setPopUpOpen] = useState<boolean>(false);

    const translatedOptions = getFilterOptions("date");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popUpRef.current &&
                !popUpRef.current.contains(event.target) &&
                !["p-dropdown-items", "p-dropdown-item", "p-dropdown-header", "p-dropdown-filter", "p-dropdown-filter-icon"].some(
                    (className) => (event.target.className as string).includes(className)
                )
            )
                closePopUp();
        };

        if (popUpOpen) {
            setFilterDateTypeValue(filterDateType);
            document.addEventListener("mousedown", handleClickOutside);
            checkPopUpPosition();
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [popUpOpen]);

    const checkPopUpPosition = () => {
        if (tableRef.current && popUpRef.current) {
            const tabelWidth = tableRef.current?.offsetWidth;
            const popUpInputWidth = inputRef.current?.offsetWidth;

            const popUpPositionY = inputRef.current?.offsetTop * 0.5 + inputRef.current?.offsetHeight + tableRef.current?.offsetTop;
            const popUpPositionX = inputRef.current?.offsetLeft + tableRef.current?.offsetLeft;
            const popUpWidth = inputRef.current?.offsetWidth > 175 ? inputRef.current?.offsetWidth : 175;

            let _popUpPosition = { top: popUpPositionY, left: popUpPositionX, width: popUpWidth };

            if (tabelWidth - (inputRef.current?.offsetLeft + popUpWidth + 6) <= 0) {
                _popUpPosition = { ..._popUpPosition, left: popUpPositionX + (popUpInputWidth - popUpWidth) };
            }

            setPopUpPosition(_popUpPosition);
        }
        return false;
    };

    const closePopUp = () => {
        setPopUpOpen(false);
    };

    const handleSaveFilterDateType = () => {
        handleFilterDateTypeChange(filterDateTypeValue);
        closePopUp();
    };
    return (
        <>
            <span onClick={() => setPopUpOpen(true)} className={"pi pi-cog state-input-filter-settings-button"} />

            {popUpOpen &&
                createPortal(
                    <div className="popup" style={popUpPosition} ref={popUpRef}>
                        <Dropdown
                            className="custom-search-table-input prime-table-filter-input-font"
                            value={filterDateTypeValue}
                            options={translatedOptions}
                            onChange={(e) => setFilterDateTypeValue(e.target.value)}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={`${t("Filter Type")}`}
                            name="filterDataType"
                        />
                        <span
                            className="popup-btn btn btn-outline-brand ml-5 mt-5 mr-10 mb-5"
                            onClick={handleSaveFilterDateType}
                            title={t("Save")}
                        >
                            {t("Save")}
                        </span>
                    </div>,
                    tableRef.current
                )}
        </>
    );
};
