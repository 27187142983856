import Axios from "axios";

export const addToTable = (prev: IData<any>, pageSize: number, newItem: any): IData<any> => {
    let dataCopy = [...(prev?.data || [])];
    if (dataCopy.length == pageSize) {
        dataCopy = dataCopy.slice(0, -1);
        dataCopy.unshift(newItem);
    } else {
        dataCopy.unshift(newItem);
    }
    return { data: dataCopy };
};

export const addToTableRequest = async (url, payload, setData, pathResponse?: (response: any) => any, onResponse?: any) => {
    const response = await Axios.post(url, payload);
    onResponse && onResponse(response);

    setData((prev) => {
        const { count, pageSize, lastPage } = prev?.meta;

        const newCount = count + 1;

        return {
            ...prev,
            meta: { ...prev?.meta, lastPage: newCount % pageSize == 0 ? lastPage + 1 : lastPage, count: newCount },
            ...addToTable(prev, pageSize as number, pathResponse ? pathResponse(response) : response.data.data || response.data),
        };
    });
    return response;
};
