import React, { createContext, useContext, useState } from "react";
import { IEquipment } from "../interfaces/equipment-interface";

export const EquipmentsContext = createContext<any>(null);

export const EquipmentsContextProvider = ({ children }) => {
    const [selectedEquipments, setSelectedEquipments] = useState<IEquipment[]>([]);

    return (
        <EquipmentsContext.Provider
            value={{
                selectedEquipments,
                setSelectedEquipments,
            }}
        >
            {children}
        </EquipmentsContext.Provider>
    );
};

export const useEquipments = (): IUseEquipments => {
    const { selectedEquipments, setSelectedEquipments } = useContext(EquipmentsContext);

    return {
        selectedEquipments,
        setSelectedEquipments,
    };
};

interface IUseEquipments {
    selectedEquipments: IEquipment[];
    setSelectedEquipments: React.Dispatch<React.SetStateAction<IEquipment[]>>;
}
