import React, { useMemo } from "react";

// use only when there is more modals that you want to wrap
export const ModalMemoWrapper = ({ children, modalOpen }) => {
    const memoModals = useMemo(() => {
        return children ? children : <></>;
    }, [modalOpen]);

    return memoModals;
};
