import React, { Component } from "react";
import AsyncComponent from "../../../components/async-component";
import PageWrap from "../../../components/page-wrap";
import PageContent from "../../../components/page-content";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import { ElnTemplatesContextProvider } from "./context/eln-templates-context";

class ElnTemplatesPage extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <ElnTemplatesContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </ElnTemplatesContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default ElnTemplatesPage;
