import { $, throttleScroll, bodyOverflow, $body, $doc } from './_utility';
/*------------------------------------------------------------------

  Init Navbar

-------------------------------------------------------------------*/

function initNavbar() {
  var self = this;
  var $navbar = $('.rui-navbar');
  var $navbarTop = $('.rui-navbar-top'); // hide / show
  // add / remove solid color

  var $autohideNav = $body.filter('.rui-navbar-autohide');

  if ($autohideNav.length) {
    self.throttleScroll(function (type, scroll) {
      var start = 400;
      var hideClass = 'rui-navbar-hide';
      var showClass = 'rui-navbar-show'; // hide / show

      if (type === 'down' && scroll > start) {
        $autohideNav.removeClass(showClass).addClass(hideClass);
      } else if (type === 'up' || type === 'end' || type === 'start') {
        $autohideNav.removeClass(hideClass).addClass(showClass);
      }
    });
  } // Mobile (show / hide)


  var $navbarMobile = $('.rui-navbar-mobile');
  var $navbarCollapse = $navbarMobile.find('.rui-navbar-collapse');
  $doc.on('show.bs.collapse hide.bs.collapse', '.rui-navbar-collapse', function (e) {
    if (e.type === 'show') {
      $navbarMobile.addClass('rui-navbar-show');
      bodyOverflow(1);
    }

    if (e.type === 'hide') {
      $navbarMobile.removeClass('rui-navbar-show');
      bodyOverflow(0);
    }
  });
  $doc.on('keyup', function (e) {
    if (e.keyCode === 27 && $navbarCollapse.hasClass('show') && $navbarCollapse.collapse) {
      $navbarCollapse.collapse('hide');
    }
  });
  $doc.on('click', '.rui-navbar-bg', function () {
    if ($navbarCollapse.collapse) {
      $navbarCollapse.collapse('hide');
    }
  }); // Scroll

  if ($navbarTop.hasClass('rui-navbar-fixed') || $navbarTop.hasClass('rui-navbar-sticky')) {
    throttleScroll(function (type, scroll) {
      if (scroll > 200) {
        $navbarTop.addClass('rui-navbar-scroll');
      } else {
        $navbarTop.removeClass('rui-navbar-scroll');
      }
    });
  } // Dark


  function toggleDarkNavbar() {
    // don't run if NightMode
    if (self.isNightMode()) {
      return;
    }

    $('input.rui-darkNavbar-toggle').prop('checked', $navbar.hasClass('rui-navbar-dark'));
    var mode = '';

    if ($navbar.hasClass('rui-navbar-dark')) {
      mode = 'night';
    } else {
      mode = 'day';
    }

    $(".rui-navbar [data-src-".concat(mode, "]")).each(function () {
      var $img = $(this);
      $img.attr('src', $img.attr("data-src-".concat(mode)));
    });
  }

  toggleDarkNavbar();
  $doc.on('change', 'input.rui-darkNavbar-toggle', function () {
    $navbar.toggleClass('rui-navbar-dark');
    toggleDarkNavbar();
  });
}

export { initNavbar };