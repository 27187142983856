import React, { useState, createContext, useContext } from "react";
import IPictogramTable from "../interfaces";

export const PictogramContext = createContext<any>(null);

export const PictogramContextProvider = ({ children }) => {
    const [selectedPictograms, setSelectedPictograms] = useState<any>([]);

    return (
        <PictogramContext.Provider
            value={{
                selectedPictograms,
                setSelectedPictograms,
            }}
        >
            {children}
        </PictogramContext.Provider>
    );
};

export const usePictogram = (): IUsePictograms => {
    const { selectedPictograms, setSelectedPictograms } = useContext(PictogramContext);
    return {
        selectedPictograms,
        setSelectedPictograms,
    };
};

interface IUsePictograms {
    selectedPictograms: IPictogramTable[];
    setSelectedPictograms: React.Dispatch<React.SetStateAction<IPictogramTable[]>>;
}
