import React, { createContext, useContext, useState } from "react";
import { clientsTabs, ClientsTabValuesType } from "../interfaces/clients-tabs";

import { IClientTable, IDepartmentTable, IClientSamplingSiteTable } from "../interfaces/interfaces";

export const ClientsContext = createContext<any>(null);

export const ClientsContextProvider = ({ children }) => {
    const [selectedClients, setSelectedClients] = useState<IClientTable[]>([]);

    const [selectedDepartments, setSelectedDepartments] = useState<IDepartmentTable[]>([]);
    const [selectedSamplingSites, setSelectedSamplingSites] = useState<IClientSamplingSiteTable[]>([]);

    const [refreshClientData, setRefreshClientData] = useState<Function | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState<ClientsTabValuesType>(clientsTabs.departments);

    return (
        <ClientsContext.Provider
            value={{
                selectedClients,
                setSelectedClients,
                selectedDepartments,
                setSelectedDepartments,
                selectedSamplingSites,
                setSelectedSamplingSites,
                selectedTab,
                setSelectedTab,
                refreshClientData,
                setRefreshClientData,
            }}
        >
            {children}
        </ClientsContext.Provider>
    );
};

export const useClients = (): IUseClients => {
    const {
        selectedClients,
        setSelectedClients,
        selectedDepartments,
        setSelectedDepartments,
        selectedSamplingSites,
        setSelectedSamplingSites,
        selectedTab,
        setSelectedTab,
        refreshClientData,
        setRefreshClientData,
    } = useContext(ClientsContext);

    return {
        selectedClients,
        setSelectedClients,
        selectedDepartments,
        setSelectedDepartments,
        selectedSamplingSites,
        setSelectedSamplingSites,
        selectedTab,
        setSelectedTab,
        refreshClientData,
        setRefreshClientData,
    };
};

interface IUseClients {
    selectedClients: IClientTable[];
    setSelectedClients: (arg: IClientTable[]) => void;
    selectedDepartments: IDepartmentTable[];
    setSelectedDepartments: (arg: IDepartmentTable[]) => void;
    selectedSamplingSites: IClientSamplingSiteTable[];
    setSelectedSamplingSites: (arg: IClientSamplingSiteTable[]) => void;
    selectedTab: ClientsTabValuesType;
    setSelectedTab: (arg: ClientsTabValuesType) => void;
    refreshClientData: Function | undefined;
    setRefreshClientData: (arg: Function | undefined) => void;
}
