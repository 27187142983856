// internal
import React, { CSSProperties } from "react";

import { withTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import { useSelector } from "react-redux";

import { PRETTY_CELL_CONFIG, prettyCellColorsMap } from "./pretty-cell-helpers";
import { TooltipHOC, Icon } from "components";

import "./styles.scss";

export interface IPrettyCellProps {
    text: string | undefined;
    className?: string;
    invSamplesInSameStatus?: boolean;
    t?: (str: string) => string;
}

interface IPrettyCell {
    icon: [string, string] | string;
    label: string;
    color: string;
}

const PrettyCell = ({ text, t = undefined, className, invSamplesInSameStatus }: IPrettyCellProps) => {
    const status = text || "";

    const { statuses } = useSelector((state) => ({ statuses: state.styles.statuses }));

    const prettyCell: IPrettyCell | undefined = statuses[status] || PRETTY_CELL_CONFIG[status];

    if (!prettyCell) return <></>;

    let statusText = prettyCell.label || status;

    statusText = t ? t(statusText) : statusText;

    return (
        <TooltipHOC tooltipContent={statusText}>
            <div
                className={classnames("prime-table-pretty-cell", className, { "partial-status": invSamplesInSameStatus === false })}
                style={{ "--background-color": prettyCell?.color || prettyCellColorsMap.defaultColor } as CSSProperties}
            >
                {prettyCell?.icon && <Icon name={prettyCell.icon} />}
                <>{statusText}</>
            </div>
        </TooltipHOC>
    );
};

export default withTranslation()(PrettyCell);
