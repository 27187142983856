import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { FilteringType, IFilterTextInputProps, TextFilterInputType } from "../interfaces/filter-interfaces";
import { FilterTypePopup } from "../components/pop-ups/filter-type-popup";
import { memoFilter } from "./memoFilter";

import { useDebounce } from "hooks";

import "../../custom-modal-elements/custom-input-with-state.styles.scss";

type ValueType = string | number;

// table header filter component, you send it in the column as filterBody prop
// eg. (filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />)
export const MemoFilterTextInput = memoFilter((props: IFilterTextInputProps) => {
    const { column } = props.inputData;

    if (props.inputData.filterArrayLength < 2) return <FilterTextInput {...props} />;
    else return <DisabledFilterTextInput field={column.field} type={props?.type || "text"} />;
});

export const FilterTextInput = ({ inputData, type = "text", placeholder }: IFilterTextInputProps) => {
    const { column, onFilterChange, tableRef, filter, tableName, filterFocusedFieldName, setFilterFocusedFieldName } = inputData;

    const _value: ValueType = typeof filter?.value == "string" || typeof filter?.value == "number" ? filter?.value : "";
    const _filterType: FilteringType = filter?.filteringType || "__icontains";

    const { t } = useTranslation();
    const inputRef = useRef<HTMLDivElement>(null);
    const inputFilterRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState<ValueType>(_value);
    const [filterType, setFilterType] = useState<FilteringType>(_filterType);

    const debounceValue = useDebounce(value, 500, 0);

    useEffect(() => {
        filterFocusedFieldName &&
            column.field == filterFocusedFieldName.fieldName &&
            tableName == filterFocusedFieldName.tableName &&
            inputFilterRef.current?.focus();
    }, [filterFocusedFieldName]);

    useEffect(() => {
        if (value != _value || (value == "" && _value != "")) {
            onFilterChange({ value: type == "number" ? parseFloat(value as string) : value, name: column.field }, filterType);
        }
    }, [debounceValue]);

    useEffect(() => {
        _value != value && setValue(_value);
    }, [_value]);

    useEffect(() => {
        _filterType != filterType && setFilterType(_filterType);
    }, [_filterType]);

    const handleFilterTypeChange = (newFilterType) => {
        setFilterType(newFilterType);

        if ((typeof value == "number" && value == undefined) || (typeof value == "string" && value.trim() == "")) return;

        onFilterChange({ value: value, name: column.field }, newFilterType);
    };

    const onFocus = () => {
        inputFilterRef.current && setFilterFocusedFieldName && setFilterFocusedFieldName({ fieldName: column.field, tableName: tableName });
    };

    const preventSubmitInput = (e) => {
        if (e.key == "Enter") e.preventDefault();
    };

    return (
        <div key={`prime-filter-input-${column.field}-${tableName}-container`} className="custom-search-table-input" ref={inputRef}>
            <Input
                key={`prime-filter-input-${column.field}-${tableName}`}
                type={type}
                name={column.field}
                className="prime-table-filter-input-font"
                placeholder={placeholder ? t(placeholder) : `${t("Filter")} ${t(column.header)}`}
                value={value}
                onChange={(e) => setValue(type == "number" ? parseFloat(e.target.value) : e.target.value)}
                step="any"
                onFocus={onFocus}
                onKeyPress={preventSubmitInput}
                innerRef={inputFilterRef}
                autoFocus={false}
            />
            <FilterTypePopup
                t={t}
                type={type}
                tableRef={tableRef}
                inputRef={inputRef}
                filterType={filterType}
                handleFilterTypeChange={handleFilterTypeChange}
            />
            <span onClick={() => setValue("")} className="pi pi-times state-input-clear-button" />
        </div>
    );
};

interface IDisabledFilterTextInput {
    field: string;
    type: TextFilterInputType;
}

export const DisabledFilterTextInput = ({ field, type = "text" }: IDisabledFilterTextInput) => {
    const { t } = useTranslation();

    return (
        <div key={`prime-filter-input-${field}-container-disabled`} className="custom-search-table-input">
            <Input
                key={`prime-filter-input-${field}-disabled`}
                type={type}
                className="prime-table-filter-input-font"
                placeholder={t("Advanced filter...")}
                disabled
            />
        </div>
    );
};
