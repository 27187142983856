export type ClientsTabKeysType = "departments" | "clientContacts" | "samplingSite";
export type ClientsTabValuesType = "Departments" | "Client contacts" | "Sampling site";

export type ClientsTabs = {
    [key in ClientsTabKeysType]: ClientsTabValuesType;
};

export const clientsTabs: ClientsTabs = {
    departments: "Departments",
    clientContacts: "Client contacts",
    samplingSite: "Sampling site",
};
