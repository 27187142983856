import { IFilterTypeOptions } from "./interfaces";

export const filterTypeOptions: IFilterTypeOptions = {
    number: [
        { label: "equals", value: "__exact" },
        { label: "like", value: "__icontains" },
        { label: "lower than", value: "__lt" },
        { label: "lower or equal", value: "__lte" },
        { label: "greater than", value: "__gt" },
        { label: "greater or equal", value: "__gte" },
    ],
    date: [
        { label: "equals", value: "__icontains" },
        { label: "lower than", value: "__lt" },
        { label: "lower or equal", value: "__lte" },
        { label: "greater than", value: "__gt" },
        { label: "greater or equal", value: "__gte" },
    ],
    text: [
        { label: "equals", value: "__exact" },
        { label: "like", value: "__icontains" },
    ],
    dropdown: [{ label: "equals", value: "__exact" }],
    checkbox: [{ label: "equals", value: "__exact" }],
};

export const checkboxOptions: ILabelValue[] = [
    { label: "Selected", value: "True" },
    { label: "Not selected", value: "False" },
];
