import { IPermCodeResponse } from "../../help-context-menu/interface";
import axios from "axios";

export const handleHelpClick = (helpPath) => {
    let url = "";

    const hashIndex = helpPath!.indexOf("#");

    if (hashIndex != -1) {
        const article = helpPath!.slice(0, hashIndex);
        const section = helpPath!.slice(hashIndex + 1);

        url = `/help?article=${article}&section=${section}`;
    } else {
        url = `/help?article=${helpPath}`;
    }

    window.open(url, "_blank");
};

export const getHelpPath = async ({ permCode, setItems, onHelpClick, setIsBusy }) => {
    if (!permCode) return setIsBusy(false);
    try {
        const response: IPermCodeResponse = await axios.get("accessSettings/permissions", {
            params: { code: permCode },
        });

        const helpPath = response?.data?.url;

        helpPath &&
            setItems((prev) => [
                ...prev,
                {
                    label: "Go to help",
                    icon: ["far", "circle-question"],
                    command: () => onHelpClick(helpPath),
                },
            ]);
    } catch (err: any) {
        console.error(err);
    } finally {
        setIsBusy(false);
    }
};
