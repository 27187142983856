import React, { createContext, useContext, useState } from "react";
import { ITableIntegratedSystemInstance, ITableInstanceDocument } from "../interfaces/interface";

export const IntegratedSystemsInstancesContext = createContext<any>(null);

export const IntegratedSystemsProvider = ({ children }) => {
    const [selectedIntegratedSystemsInstances, setSelectedIntegratedSystemsInstances] = useState<ITableIntegratedSystemInstance[]>([]);
    const [selectedInstanceDocuments, setSelectedInstanceDocuments] = useState<ITableInstanceDocument[]>([]);

    return (
        <IntegratedSystemsInstancesContext.Provider
            value={{
                selectedIntegratedSystemsInstances,
                setSelectedIntegratedSystemsInstances,
                selectedInstanceDocuments,
                setSelectedInstanceDocuments,
            }}
        >
            {children}
        </IntegratedSystemsInstancesContext.Provider>
    );
};

export const useIntegratedSystemsInstances = (): IUseIntegratedSystemsInstances => {
    const {
        selectedIntegratedSystemsInstances,
        setSelectedIntegratedSystemsInstances,
        selectedInstanceDocuments,
        setSelectedInstanceDocuments,
    } = useContext(IntegratedSystemsInstancesContext);

    return {
        selectedIntegratedSystemsInstances,
        setSelectedIntegratedSystemsInstances,
        selectedInstanceDocuments,
        setSelectedInstanceDocuments,
    };
};

interface IUseIntegratedSystemsInstances {
    selectedIntegratedSystemsInstances: ITableIntegratedSystemInstance[];
    setSelectedIntegratedSystemsInstances: (arg: ITableIntegratedSystemInstance[]) => void;
    selectedInstanceDocuments: ITableInstanceDocument[];
    setSelectedInstanceDocuments: (arg: ITableInstanceDocument[]) => void;
}
