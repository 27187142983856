import React from "react";
import { ModalFooter } from "reactstrap";
import CustomButton from "../custom-button/custom-button";

interface IModalFooter {
    closeModal: Function;
    cancelName?: string;
    okName?: string;
    isBusy?: boolean;
    okIsBusy?: boolean;
    cancelIsBusy?: boolean;
    okIsDisabled?: boolean;
    additionalButtons?: any;
    permCodeOk?: any;
    onClick?: Function;
}

const CustomModalFooterReverse = (props: IModalFooter) => {
    const {
        closeModal,
        cancelName,
        okName,
        isBusy,
        okIsBusy,
        cancelIsBusy,
        okIsDisabled,
        additionalButtons,
        permCodeOk,
        onClick = undefined,
    } = props;
    const [disabled, setDisabled] = React.useState(false);
    let enableOk = permCodeOk == undefined ? true : permCodeOk;

    // disable the submit button for a brief period to avoid multiple
    // forms being sent due to the modal closing animation taking place
    // should use (.|ok|cancel)isBusy prop instead, but its better to have a default timeout than nothing at all
    const unhandledDisable = isBusy || okIsBusy;

    const handleClick = (e) => {
        onClick && onClick(e);
        handleToggle(true, 0);
        !unhandledDisable && handleToggle(false, 500);
    };

    const handleToggle = (val: boolean, ms: number) => setTimeout(() => setDisabled(val), ms);

    return (
        <ModalFooter>
            {additionalButtons}
            {cancelName && (
                <CustomButton
                    disabled={isBusy || cancelIsBusy || false}
                    text={cancelName}
                    className="mr-0"
                    color="secondary"
                    onClick={closeModal}
                />
            )}
            {okName && enableOk && (
                <CustomButton
                    isBusy={isBusy || okIsBusy || false}
                    type={onClick ? "button" : "submit"}
                    text={okName}
                    disabled={disabled || okIsDisabled}
                    onClick={handleClick}
                />
            )}
        </ModalFooter>
    );
};

export default CustomModalFooterReverse;
