export const queryTemplateUrls = {
    groups: {
        main: (groupId?: number) => (groupId ? `query-by-example/template-groups/${groupId}` : "query-by-example/template-groups"),
    },
    templates: {
        main: (templateId?: number) => (templateId ? `query-by-example/templates/${templateId}` : "query-by-example/templates"),
        formData: "query-by-example/templates-form-format",
        createViewModal: "query-by-example/create-view-model",
        exportTemplate: (templateId: number) => `query-by-example/templates/${templateId}/export`,
        importTemplate: "query-by-example/templates/import",
        notification: {
            main: (id?: number) => (id ? `query-by-example/schedules/${id}` : "/reports/run-qbe-schedule"),
            formData: "query-by-example/schedules-form-data",
        },
    },
    relations: {
        main: (relationId?: number) => (relationId ? `query-by-example/relations/${relationId}` : "query-by-example/relations"),
        formData: "query-by-example/relations/form-data",
    },
    viewFields: {
        main: (fieldId?: number) => (fieldId ? `query-by-example/view-fields/${fieldId}` : "query-by-example/view-fields"),
        formData: "query-by-example/model-fields/form-data",
    },
    filterFields: {
        main: (fieldId?: number) => (fieldId ? `query-by-example/filter-fields/${fieldId}` : "query-by-example/filter-fields"),
        formData: "query-by-example/filter-fields/form-data",
        possibleChoicesFormData: "query-by-example/filter-fields/possible-choices/form-data",
        possibleChoice: (possibleChoiceId?: string) => (possibleChoiceId ? `/${possibleChoiceId}` : ""),
    },
};
