import React, { Dispatch, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import axios from "axios";

import { getHelpPath, handleHelpClick } from "./handlers";
import Icon from "../../icon";

interface ITabContextMenu {
    isFullscreen: boolean;
    onFullScreen?: (() => void) | boolean;
    permCode: string | undefined;
    setContextMenuOpen: Dispatch<React.SetStateAction<number | undefined>> | Dispatch<React.SetStateAction<boolean | undefined>>;
    position: { x: number; y: number };
}

interface IItem {
    label: string;
    icon?: string | string[];
    command: any;
}

const initItems = (isFullscreen, onFullScreen): IItem[] => [
    {
        label: !isFullscreen ? "Maximize" : "Minimize",
        icon: ["fas", isFullscreen ? "down-left-and-up-right-to-center" : "up-right-and-down-left-from-center"],
        command: onFullScreen,
    },
];

export const TabContextMenu = ({ isFullscreen, onFullScreen, permCode, setContextMenuOpen, position }: ITabContextMenu) => {
    const { t } = useTranslation();

    const [items, setItems] = useState<IItem[]>(initItems(isFullscreen, onFullScreen));
    const [isBusy, setIsBusy] = useState<boolean>(true);

    useEffect(() => {
        const source = axios.CancelToken.source();

        getHelpPath({ permCode, setItems, onHelpClick, setIsBusy });

        return () => source.cancel();
    }, []);

    const onHelpClick = (helpPath) => handleHelpClick(helpPath);

    const onClickItem = (command) => {
        command();
        setContextMenuOpen(undefined);
    };

    if (isBusy) return <></>;
    return (
        <div className="context-menu-dummy-container">
            <div className="context-menu-container" style={{ top: position?.y, left: position?.x }}>
                {items.map((item, index) => (
                    <div key={index} className="context-menu-item" onClick={() => onClickItem(item.command)}>
                        {item.icon && <Icon name={item.icon} />}
                        {t(item.label)}
                    </div>
                ))}
            </div>
        </div>
    );
};
