import { useState } from "react";

export const useIsBusy = () => {
    const [isBusyArray, setIsBusyArray] = useState<string[]>([]);

    const setIsBusy = (index: string) =>
        setIsBusyArray((prev) => (prev.includes(index) ? prev.filter((o) => o != index) : [...prev, index]));

    const checkIsBusy = (index: string) => isBusyArray.includes(index);

    return { checkIsBusy, setIsBusy };
};
