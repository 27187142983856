import React, { Component } from "react";
import AsyncComponent from "../../components/async-component";
import PageWrap from "../../components/page-wrap";
import PageContent from "../../components/page-content";
import { updateBreadcrumb } from "../../utils/breadcrumbs";

class CSVTilesPage extends Component {

    componentDidMount = updateBreadcrumb
    
    render() {
        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={() => import("./content")} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default CSVTilesPage;
