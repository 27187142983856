export const userManagementUrls = {
    users: (userId?: number) => (userId ? `bs/users-management/users/${userId}` : "bs/users-management/users"),

    signatures: "user-signatures",
    digitalSignature: (digitalSignatureId?: number) =>
        digitalSignatureId ? `user-digital-signature/${digitalSignatureId}` : "user-digital-signature",
        
    signFile: "sign-pdf-file",
    verifyFile: "verify-pdf-file",

    sessions: "bs/users-management/session",

    companyUsers: (companyId: number) => `bs/company/users/${companyId}`,
    companies: (companyId?: number) => (companyId ? `bs/companies/${companyId}` : "bs/companies"),

    anonymizeUser: (userId: number) => `bs/users-management/users/${userId}/anonymize`,
    //form-data
    companiesFormData: "bs/companies-form-data",
    companyUsersFormData: (companyId: number) => `bs/company-users-form-data/${companyId}`,
};
