/**
 * Class that fires event onbarcodescanned when keypress event is fired in between 10 miliseconds
 */
class BarcodeScanner {
    initialize = () => {
        document.addEventListener("keypress", this.keyup);
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
        }
        this.timeoutHandler = setTimeout(() => {
            this.inputString = "";
        }, 10);
    };

    close = () => {
        document.removeEventListener("keypress", this.keyup);
    };

    timeoutHandler;

    inputString = "";

    keyup = (e) => {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
            if (e.key != "Enter") this.inputString += e.key;
        }

        this.timeoutHandler = setTimeout(() => {
            if (this.inputString.length <= 3) {
                this.inputString = "";
                return;
            }

            const event = new CustomEvent("onbarcodescanned", { detail: this.inputString });

            document.dispatchEvent(event);

            this.inputString = "";
        }, 10);
    };
}
export { BarcodeScanner };
