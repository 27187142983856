import React, { memo, useRef } from "react";

import { Row, Col } from "reactstrap";

import { RichTextInput } from "../../../../rich-text-input.tsx/rich-text-input";
import { AddFromLookup } from "../add-from-lookup";

import * as GT from "components";

const AdvancedRemarkEditor = (props) => {
    const {
        handleChange,
        formData,
        handleChangeCheckbox,
        addFromLookupProps,
        collapseData,
        setCollapseData,
        handleSetFormData,
        typeOptions,
    } = props;

    const subjectRef = useRef<HTMLInputElement>(null);
    const typeRef = useRef<HTMLInputElement>(null);

    const handleOpen = (ref) => {
        ref.current && ref.current.focus();
        ref.current && ref.current.select();
    };

    return (
        <>
            <Row>
                <Col>
                    <GT.LookupField
                        handleChange={handleChange}
                        name="subject"
                        lookupName="AdvancedRemarks-Topics"
                        label="Subject"
                        value={formData.subject}
                        selectProps={{
                            innerRef: subjectRef,
                            onMenuOpen: () => handleOpen(subjectRef),
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <GT.CustomSelect
                        labelName="Type"
                        name="type"
                        className="pt-10"
                        options={typeOptions?.type || []}
                        handleChange={handleChange}
                        value={typeOptions?.type.find((option) => option.value == formData.type)}
                        isClearable={false}
                        innerRef={typeRef}
                        onOpen={() => handleOpen(typeRef)}
                    />
                </Col>
                <Col sm={3}>
                    <GT.CustomCheckbox
                        labelText="Visible on report"
                        checked={formData.isVisibleOnReport}
                        handleChange={handleChangeCheckbox}
                        name="isVisibleOnReport"
                    />
                </Col>
                <Col sm={3}>
                    <GT.CustomCheckbox
                        labelText="advancedRemarks>>Default"
                        checked={formData.isDefault}
                        handleChange={handleChangeCheckbox}
                        name="isDefault"
                    />
                </Col>
            </Row>
            {addFromLookupProps ? (
                <AddFromLookup
                    {...addFromLookupProps}
                    handleChange={handleChange}
                    value={formData.content}
                    selectedPosition={collapseData.selectedPosition}
                    setCollapseData={setCollapseData}
                />
            ) : (
                <RichTextInput autofocus value={formData.content} onChange={(content) => handleSetFormData("content", content)} />
            )}
        </>
    );
};

export const MemoAdvancedRemarkEditor = memo(AdvancedRemarkEditor);
