import React, { memo, useState } from "react";

import { IEditAdvancedRemarksBaseProps, IEditAdvancedRemarksProps } from "components/prime-data-table/interfaces/edit-interfaces";
import { useAdvancedRemarks } from "components/prime-data-table/advancedRemarksContext/context";
import { hasRemarks } from "../../../../utils/hasRemarks";

import "./advanced-remark.scss";

const arePropsEqual = (prev, next) => {
    return prev.inputData.row.hasRemarks == next.inputData.row.hasRemarks;
};

export const EditAdvancedRemarks = (props: IEditAdvancedRemarksProps) => {
    const { setModalOpen, setModalData } = useAdvancedRemarks();

    return <EditAdvancedRemarksBase setModalData={setModalData} setModalOpen={setModalOpen} {...props} />;
};

const EditAdvancedRemarksBase = memo((props: IEditAdvancedRemarksBaseProps) => {
    const { setModalData, setModalOpen } = props;
    const { row } = props.inputData;

    const handleOpen = () => {
        setModalData({
            rowId: row.id,
            addFromLookupProps: props.addFromLookupProps,
        });
        setModalOpen(true);
    };

    return (
        <div className="advanced-remark">
            <span onClick={handleOpen} className={hasRemarks(row?.hasRemarks)} />
        </div>
    );
}, arePropsEqual);
