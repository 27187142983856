import { mapToIds } from "./primeHelpers";

export const setSelected = (
    dataSet: any[],
    notSelectFirst: boolean,
    idSelector: string,
    onSelect: (arg: any[]) => void,
    selectedRecords: any[]
) => {
    let recordToSelect: any[] = [];

    if (dataSet?.length == 0 && selectedRecords?.length == 0) return; // this line might create bugs
    if (dataSet?.length > 0) {
        const recordToBeSelected = dataSet.find((data) => data.isSelected == true);

        if (recordToBeSelected) recordToSelect = [recordToBeSelected];
        else if (selectedRecords?.length > 0) {
            const foundRecords = dataSet.filter((r) => selectedRecords.includes(r[idSelector]));
            if (foundRecords.length) recordToSelect = foundRecords;
            else recordToSelect = notSelectFirst ? [] : [dataSet[0]];
        } else {
            recordToSelect = notSelectFirst ? [] : [dataSet[0]];
        }
    }

    onSelect(mapToIds(recordToSelect, idSelector));
};
