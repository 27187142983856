//external import
import { DataTable, DataTableColReorderParams, DataTableColumnResizeEndParams } from "primereact/datatable";
import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "primereact/column";

//internal import
import { PrimeContextMenu } from "../components/contextMenu/context-menu";
import { EmptyMessage } from "../components/messages/empty-message";
import { useDebouncedCallback } from "../../../hooks/useDebouncedCallback";
import {
    IGeneratedPrimeTableColumn,
    IGeneratedPrimeColumns,
    HandleScrollConfigType,
    IUserTableConfig,
    ListActionType,
    IScrollConfig,
    ITableConfig,
} from "../interfaces/table-interfaces";

//helpers import
import { getDataSetBreakpoint, getFrozenColWidth, mapToIds } from "../helpers/primeHelpers";
import { IClientSidePrimeDataTableProps } from "../client-side-interface";
import { getTableConfig, saveTableConfig, compareColumns } from "../helpers/tableConfig";
import { getCssVariable, setCssVariable } from "../helpers/cssHelpers";
import { getReorderedColumns } from "../helpers/getReorderedColumns";
import { sweetConfirm } from "../../sweet-alert/sweetConfirm";
import { setSelected } from "../helpers/setSelected";
import { handleSelection } from "../helpers/onSelection";
import {
    isHorizontalScrollEnabled,
    isVerticalScrollEnabled,
    mapColumnsOnInit,
    handleColumnResize,
    mapColumnsWidth,
} from "../helpers/tableColumnsWidthHelpers";

//internal style import
import "./../style.scss";
import { useTableScrollHeight } from "hooks/useTableScrollHeight";

const RECORDS_HEIGHT: number = 31;
export const ClientSidePrimeDataTable = (props: IClientSidePrimeDataTableProps) => {
    const {
        dataSet = [],
        columns,
        tableName, // element send via this prop will be displayed above data table, this prop was mainly created with buttons in mind

        // START pagination section
        rowsPerPage = [5, 10, 15, 25, 50],
        // END pagination section

        // START visual settings section
        className = "",
        children,
        btnContainerClassName = "hpx-36",
        showGridlines = true,
        reorderColumnsEnabled = true,
        resizableColumnsEnabled = true,
        minimumColWidth = 123, // 123 is a perfect number for table to scalable in normal and scroll mode
        defaultTableHeight = 155,
        // END visual settings section

        // START select record section
        selectedRecords = undefined,
        onSelect = undefined,
        notSelectFirst = false,
        selectionMode = "multiple",
        idSelector = "id",
        // END select record section

        // START sorting, grouping and filtering section
        sortable = true,
        // END sorting, grouping and filtering section

        // START context menu section, if any prop below is set to true or has correct data, then context menu will be enabled and context menu item will show when right clicked on table
        auditData = undefined,
        exportCSVEnabled = false,
        hidingColumnEnabled = false,
        filterEnabled = true,
        contextMenuItems = [],
        translateDisabled = false,
        // additionalColumnsEnabled = false,
        // END context menu section

        handleDoubleClick = undefined,

        // start client side pagination section
        clientSidePagination = false,
        clientSideMeta = undefined,
        onClientSideMetaChange: handleClientSideMetaChange = undefined,
        // end client side pagination section
    } = props;

    const { t } = useTranslation();

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const contextMenuRef = useRef<any>(null);

    const [selection, onSelection] = useState<number[]>([]);

    const _selection = dataSet.filter((record) => selection.includes(record[idSelector]));

    useEffect(() => {
        onSelect && onSelect(_selection);
    }, [selection, JSON.stringify(dataSet)]);

    const [generatedColumns, setGeneratedColumns] = useState<IGeneratedPrimeColumns>({
        visible: [],
        default: [],
        columnGenerateStatus: "NOT_GENERATED_COLUMNS",
    });

    const [tableConfig, setTableConfig] = useState<ITableConfig>({
        contextItemFilterEnable: false,
        filtersEnabled: true,
        frozenColumn: undefined,
        tableHeight: defaultTableHeight,
    });

    const [scrollConfig, setScrollConfig] = useState<IScrollConfig | undefined>(undefined);

    const tableClassName: string = useMemo((): string => {
        const frozenColumnClass = tableConfig.frozenColumn ? "frozen-mode" : "";
        const verticalScrollClass = `vertical-scroll ${scrollConfig?.vertical ? "enabled" : "disabled"}`;
        const disableLastColResize = !scrollConfig?.horizontal ? "disable-last-col-resize" : "";
        const filtersEnabled = tableConfig.filtersEnabled ? "filters-enabled" : "";

        return `${frozenColumnClass} ${verticalScrollClass} ${disableLastColResize} ${filtersEnabled}`;
    }, [tableConfig.frozenColumn, tableConfig.filtersEnabled, scrollConfig]);

    useEffect(() => {
        scrollConfig && handleScrollConfig("vertical");
    }, [clientSideMeta?.rows]);

    useEffect(() => {
        Array.isArray(selectedRecords) && onSelect && setSelected(dataSet, notSelectFirst, idSelector, onSelection, selection);
        scrollConfig && handleScrollConfig("vertical");
    }, [dataSet]);

    useEffect(() => {
        scrollConfig && handleScrollConfig("vertical");
    }, [selectedRecords]);

    useEffect(() => {
        // Configuring table options based on columns and user saved table config
        const configureTable = async () => {
            // Generating default columns
            let _columns: IGeneratedPrimeTableColumn[] = mapColumnsOnInit(tableContainerRef, columns, t, minimumColWidth);
            const defaultColumns: IGeneratedPrimeTableColumn[] = _columns;
            let tableConfigResponse: IUserTableConfig | undefined = undefined;

            // Fetching columns, validating fetched columns with generated default columns, and if fetched columns exist,
            // mapping default columns to user saved columns
            if (!!tableName) {
                tableConfigResponse = await getTableConfig(tableName);

                if (tableConfigResponse && tableConfigResponse?.columns?.length > 0) {
                    _columns = await compareColumns(tableConfigResponse.columns, defaultColumns, () =>
                        handleSaveTableConfig({ type: "columns", payload: { columns: defaultColumns } })
                    );
                } else if (_columns.length > 0) {
                    // saving generated columns in DB if table config does not exist
                    handleSaveTableConfig({ type: "columns", payload: { columns: defaultColumns } }); // saves columns change  in config
                }
            }

            // saving visible columns in state
            setGeneratedColumns({ visible: _columns, default: defaultColumns, columnGenerateStatus: "GENERATED_COLUMNS" });

            // configure rest of the table settings
            handleTableConfig(_columns, tableConfigResponse);
        };

        configureTable();
    }, [columns]);

    useEffect(() => {
        // checks which scrolls should be enabled, on init when visible columns are already generated, and when visible columns changes
        if (generatedColumns.visible && generatedColumns.visible.length > 0) {
            handleScrollConfig("default");
        }
    }, [generatedColumns.visible]);

    useEffect(() => {
        // this event listener has to be added every time when visible columns changes
        if (generatedColumns.visible && generatedColumns.visible.length > 0)
            window.addEventListener("resize", () => handleScrollConfig("horizontal"));

        return () => window.removeEventListener("resize", () => handleScrollConfig("horizontal"));
    }, [scrollConfig]);

    useLayoutEffect(() => {
        const dataSetLength = dataSet.length;
        const dataSetBreakpoint = getDataSetBreakpoint(tableConfig.tableHeight, RECORDS_HEIGHT);

        if (
            dataSetLength > 0 &&
            (dataSetLength <= dataSetBreakpoint || (clientSideMeta?.rows && clientSideMeta.rows <= dataSetBreakpoint))
        ) {
            if (scrollConfig && !scrollConfig?.vertical) {
                const verticalScroll = isVerticalScrollEnabled(tableContainerRef, tableConfig.tableHeight, tableConfig.filtersEnabled);
                verticalScroll && setScrollConfig({ vertical: true, horizontal: scrollConfig?.horizontal });
            }
        }
    });

    // generates request for saveTableConfig function and saves new config
    const handleSaveTableConfig = async (action: ListActionType) => {
        let request: IUserTableConfig = {
            columns: generatedColumns.visible,
            filtersEnabled: tableConfig.filtersEnabled,
        };

        switch (action.type) {
            case "columns":
                request.columns = action.payload.columns;
                break;
            case "filterEnabled":
                request.filtersEnabled = action.payload.filterEnabled;
                break;
        }

        await saveTableConfig(tableName, request);
    };

    const handleTableConfig = (columns: IGeneratedPrimeTableColumn[], fetchedTableConfig?: IUserTableConfig) => {
        let filtersEnabled = fetchedTableConfig?.filtersEnabled ? fetchedTableConfig?.filtersEnabled : tableConfig.filtersEnabled;
        let tableHeight = tableConfig.tableHeight;

        let config: ITableConfig = { frozenColumn: undefined, contextItemFilterEnable: false, tableHeight, filtersEnabled };

        columns.forEach((col, index) => {
            // if there is even one filterBody in columns then context menu show/hide menu item will be enabled
            if (!!col.filterBody && !config.contextItemFilterEnable) {
                config.contextItemFilterEnable = true;
            }

            // checks if there is column that should be frozen on init, it also sets frozen col width
            if (col.frozen && !config.frozenColumn) {
                config.frozenColumn = {
                    field: col.field,
                    width: getFrozenColWidth(col),
                    originalPosition: index,
                };
                delete col.frozen;
            }
        });

        setTableConfig(config);
    };

    // Resize event listener, it checks if table should be in horizontal scroll mode or not
    const handleScrollConfig = useDebouncedCallback(
        (
            type: HandleScrollConfigType,
            tableHeight: number = tableConfig.tableHeight,
            filtersEnabled: boolean = tableConfig.filtersEnabled
        ) => {
            let _scrollConfig: IScrollConfig = { vertical: scrollConfig?.vertical || false, horizontal: scrollConfig?.horizontal || false };
            const dataSetBreakpoint = type != "horizontal" ? getDataSetBreakpoint(tableConfig.tableHeight, RECORDS_HEIGHT) : 0;

            switch (type) {
                case "horizontal":
                    _scrollConfig.horizontal = isHorizontalScrollEnabled(tableContainerRef, generatedColumns.visible);

                    break;
                case "vertical":
                    _scrollConfig.vertical =
                        (dataSet?.length > dataSetBreakpoint && clientSideMeta?.rows && clientSideMeta.rows > dataSetBreakpoint) ||
                        isVerticalScrollEnabled(tableContainerRef, tableHeight, filtersEnabled);
                    break;
                default:
                    _scrollConfig = {
                        ..._scrollConfig,
                        vertical:
                            (dataSet?.length > dataSetBreakpoint && clientSideMeta?.rows && clientSideMeta.rows > dataSetBreakpoint) ||
                            isVerticalScrollEnabled(tableContainerRef, tableHeight, filtersEnabled),
                        horizontal: isHorizontalScrollEnabled(tableContainerRef, generatedColumns.visible),
                    };
                    break;
            }

            if (JSON.stringify(scrollConfig) != JSON.stringify(_scrollConfig)) setScrollConfig(_scrollConfig);
        },
        75
    );

    const handleHideColumns = (newVisibleColumns, hiddenColumns) => {
        const mappedColumns = mapColumnsWidth(newVisibleColumns, tableContainerRef);
        setGeneratedColumns({ ...generatedColumns, visible: mappedColumns });
        if (tableName) handleSaveTableConfig({ type: "columns", payload: { columns: mappedColumns } }); // saves columns change  in config
    };

    const handleToggleFilters = () => {
        const _filterEnabled = !tableConfig.filtersEnabled;
        const tableHeight = tableConfig.tableHeight;

        let filterHeight: number = parseInt(getCssVariable(tableContainerRef, "--header-height"));
        const newTableHeight: number = _filterEnabled ? tableHeight - filterHeight : tableHeight + filterHeight;

        handleScrollConfig("vertical", newTableHeight, _filterEnabled);
        setTableConfig({ ...tableConfig, tableHeight: newTableHeight, filtersEnabled: _filterEnabled });

        tableName && handleSaveTableConfig({ type: "filterEnabled", payload: { filterEnabled: _filterEnabled } }); // saves filterEnabled change in config
    };

    const handleFreezeColumn = (frozenColumn?: IGeneratedPrimeTableColumn, originalPosition?: number) => {
        setTableConfig({
            ...tableConfig,
            frozenColumn:
                frozenColumn && originalPosition
                    ? {
                          field: frozenColumn.field,
                          width: getFrozenColWidth(frozenColumn),
                          originalPosition: originalPosition,
                      }
                    : undefined,
        });
    };

    const handleSelectedContextRecord = (e) => {
        onSelection([e.value[idSelector]]);
    };

    const handleRestoreColumns = async () => {
        if (!(await sweetConfirm("Restore default columns", "This operation is not reversible."))) return;
        const _columns = mapColumnsOnInit(tableContainerRef, columns, t, minimumColWidth);

        handleTableConfig(_columns);

        setGeneratedColumns({ visible: _columns, default: _columns, columnGenerateStatus: "GENERATED_COLUMNS" });
        if (tableName) handleSaveTableConfig({ type: "columns", payload: { columns: _columns } }); // saves columns change  in config
    };

    const handleColumnResizeEnd = (event: DataTableColumnResizeEndParams) => {
        if (tableConfig.frozenColumn && tableConfig.frozenColumn.field == event.column.field) {
            const frozenColumn = { ...tableConfig.frozenColumn, width: `${parseInt(tableConfig.frozenColumn?.width) + event.delta}px` };
            setTableConfig({ ...tableConfig, frozenColumn: frozenColumn });
        } else {
            const resizedColumns = handleColumnResize(generatedColumns.visible, event, tableContainerRef, tableConfig.frozenColumn);
            tableName && handleSaveTableConfig({ type: "columns", payload: { columns: resizedColumns } }); // saves columns change in config
            setGeneratedColumns({ ...generatedColumns, visible: resizedColumns });
        }
    };

    const handleColReorder = (event: DataTableColReorderParams) => {
        const reorderedColumns = getReorderedColumns(event, generatedColumns.visible);
        tableName && handleSaveTableConfig({ type: "columns", payload: { columns: reorderedColumns } }); // saves columns change in config

        setGeneratedColumns({ ...generatedColumns, visible: reorderedColumns });
    };

    const getDynamicColumn = (column, index) => {
        const { className: colClassName, editBody, body, width, header } = column;
        const liteColumns = { header: header, field: column.field };
        let columnProps: any = {};
        let frozen = false;

        columnProps.className = `${colClassName ? colClassName : ""} ${editBody ? " editable-column" : ""} column-${liteColumns.field}`;

        // column width
        columnProps.style = { width: width };

        if (tableConfig.filtersEnabled) {
            columnProps.filter = true;
            columnProps.filterMatchMode = "contains";
            columnProps.filterPlaceholder = `${t("Filter")} ${column.header}`;
            columnProps.filterHeaderClassName = "client-side-filter";
        }

        if (body) {
            columnProps.body = (row) => {
                return (
                    <>
                        {body({
                            row: row,
                            fieldName: liteColumns.field,
                            translate: (value) => t(value),
                        })}
                    </>
                );
            };
        }
        // body & editing end

        return (
            <Column
                key={`${column.field}-${index}`}
                bodyClassName="cell-container"
                header={header}
                field={column.field}
                sortable={column.sortable} // sorting
                frozen={frozen} // frozen column
                {...columnProps}
            />
        );
    };

    const tableRef = useRef<HTMLDivElement>(null);

    const { scrollHeight } = useTableScrollHeight(tableRef, tableConfig.filtersEnabled, children, true);

    return (
        <div className="gt-table" ref={tableRef}>
            {children && <div className={btnContainerClassName}>{children}</div>}
            <PrimeContextMenu
                // Props needed for context-menu
                contextMenuRef={contextMenuRef}
                contextMenuItems={contextMenuItems}
                // Props that provide data
                auditData={auditData}
                selectedRecord={_selection?.[0]}
                dataSet={dataSet}
                tableConfig={tableConfig}
                // Enabled/disabled
                exportCSVEnabled={exportCSVEnabled && dataSet.length > 0}
                hidingColumnEnabled={hidingColumnEnabled}
                filterEnabled={filterEnabled && tableConfig.contextItemFilterEnable}
                translateDisabled={translateDisabled}
                additionalColumnsEnabled={false}
                // Columns
                defaultColumns={generatedColumns.default}
                visibleColumns={generatedColumns.visible}
                // handlers
                onRestoreColumns={handleRestoreColumns}
                onHideColumns={handleHideColumns}
                onToggleFilters={handleToggleFilters}
                onFreezeColumn={handleFreezeColumn}
            />
            <div
                ref={tableContainerRef}
                className={`${className} prime-table-container`}
                style={{ "--table-min-height": `${scrollHeight}px` } as any}
            >
                <DataTable
                    value={dataSet}
                    // Display and styles
                    className={`p-datatable-sm ${tableClassName}`}
                    showGridlines={showGridlines}
                    scrollHeight={`${scrollHeight}px`}
                    scrollable={scrollConfig?.horizontal || scrollConfig?.vertical}
                    emptyMessage={<EmptyMessage height={scrollHeight} />}
                    // Column resize && reorder
                    frozenWidth={tableConfig.frozenColumn?.width}
                    reorderableColumns={reorderColumnsEnabled}
                    resizableColumns={resizableColumnsEnabled}
                    columnResizeMode="expand"
                    onColumnResizeEnd={handleColumnResizeEnd}
                    onColReorder={handleColReorder}
                    // Context Menu
                    contextMenuSelection={_selection?.[0]}
                    onContextMenuSelectionChange={handleSelectedContextRecord}
                    onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
                    // Selection // Context Menu
                    {...(Array.isArray(selection) &&
                        onSelect && {
                            selection: _selection,
                            onSelectionChange: (selected) => handleSelection(selected, dataSet, selection, onSelection, idSelector),
                            selectionMode: selectionMode,
                        })}
                    // Automatic client side pagination
                    {...(clientSidePagination && {
                        paginator: dataSet?.length > 0,
                        rows: 10,
                        rowsPerPageOptions: rowsPerPage,
                    })}
                    // Manual client side pagination
                    {...(!clientSidePagination &&
                        clientSideMeta &&
                        handleClientSideMetaChange && {
                            paginator: dataSet?.length > 0,
                            first: clientSideMeta.first,
                            rows: clientSideMeta.rows,
                            totalRecords: dataSet.length,
                            rowsPerPageOptions: rowsPerPage,
                            onPage: (pagination) => {
                                handleClientSideMetaChange({
                                    first: pagination.first,
                                    rows: pagination.rows,
                                    page: pagination.page + 1,
                                    totalRecords: dataSet.length,
                                });
                            },
                        })}
                    // Sort
                    {...(sortable && {
                        removableSort: true,
                        sortMode: "multiple",
                    })}
                    onRowDoubleClick={(e) => handleDoubleClick && handleDoubleClick(e)}
                >
                    {generatedColumns.visible.map((col, index) => getDynamicColumn(col, index))}
                </DataTable>
            </div>
        </div>
    );
};
