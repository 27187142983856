export const moveKeyPress = (id, rowIndex, inputRef) => {
    const el: any = document.getElementById(`${id}${rowIndex + 1}`);
    if (!el) {
        const inputs = document.querySelectorAll(`input[id^=${id}]`);
        Array.from(inputs).some((input) => {
            const _id = input.id.replace(id, "").slice(0, 1);

            if (Number(_id) > Number(rowIndex)) {
                (input as HTMLInputElement)?.select();
                return true;
            }
            return false;
        });
    }
    el?.select();
};
