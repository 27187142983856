import React from "react";

import { Spinner } from "reactstrap";

import "./styles.scss";

export const LoadingMessage = () => {
    return (
        <div className="prime-data-table-message loading">
            <Spinner />
        </div>
    );
};
