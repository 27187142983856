import React from "react";

import { TooltipPositionType } from "primereact/tooltip/tooltipoptions";
import { useTranslation } from "react-i18next";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import { TooltipHOC } from "..";

import "./style.scss";

export interface InformationBadgeProps {
    type?: "warning" | "info";
    tooltip?: any;
    selector?: string;
    position?: TooltipPositionType;
}

export const InformationBadge = ({ type = "info", tooltip = "", selector, position }: InformationBadgeProps) => {
    return (
        <div className="information-badge-container">
            <TooltipHOC tooltipContent={tooltip}>
                <Badge value={type == "info" ? "?" : "!"} className={`information-badge ${selector} ${type}`} />
            </TooltipHOC>
        </div>
    );
};
