import React from "react";

import { OrderReleaseResultsContextProvider } from "./context/order-release-results-context";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class OrdersReleaseResultsPage extends React.Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <OrderReleaseResultsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </OrderReleaseResultsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default OrdersReleaseResultsPage;
