import React, { createContext, useContext, useState } from "react";

import { ISampleWorkflow, ISampleWorkflowStep, ISampleWorkflowSubstep } from "../interface/interface";

export const SampleWorkflowContext = createContext<any>(null);

export const SampleWorkflowContextProvider = ({ children }) => {
    const [selectedSampleWorkflows, setSelectedSampleWorkflows] = useState<ISampleWorkflow[]>([]);
    const [selectedSampleWorkflowSteps, setSelectedSampleWorkflowSteps] = useState<ISampleWorkflowStep[]>([]);
    const [selectedSampleWorkflowSubsteps, setSelectedSampleWorkflowSubsteps] = useState<ISampleWorkflowSubstep[]>([]);
    return (
        <SampleWorkflowContext.Provider
            value={{
                selectedSampleWorkflows,
                setSelectedSampleWorkflows,
                selectedSampleWorkflowSteps,
                setSelectedSampleWorkflowSteps,
                selectedSampleWorkflowSubsteps,
                setSelectedSampleWorkflowSubsteps,
            }}
        >
            {children}
        </SampleWorkflowContext.Provider>
    );
};

export const useSampleWorkflows = (): IUseSampleWorkflows => {
    const {
        selectedSampleWorkflows,
        setSelectedSampleWorkflows,
        selectedSampleWorkflowSteps,
        setSelectedSampleWorkflowSteps,
        selectedSampleWorkflowSubsteps,
        setSelectedSampleWorkflowSubsteps,
    } = useContext(SampleWorkflowContext);

    return {
        selectedSampleWorkflows,
        setSelectedSampleWorkflows,
        selectedSampleWorkflowSteps,
        setSelectedSampleWorkflowSteps,
        selectedSampleWorkflowSubsteps,
        setSelectedSampleWorkflowSubsteps,
    };
};

interface IUseSampleWorkflows {
    selectedSampleWorkflows: ISampleWorkflow[];
    setSelectedSampleWorkflows: React.Dispatch<React.SetStateAction<ISampleWorkflow[]>>;
    selectedSampleWorkflowSteps: ISampleWorkflowStep[];
    setSelectedSampleWorkflowSteps: React.Dispatch<React.SetStateAction<ISampleWorkflowStep[]>>;
    selectedSampleWorkflowSubsteps: ISampleWorkflowSubstep[];
    setSelectedSampleWorkflowSubsteps: React.Dispatch<React.SetStateAction<ISampleWorkflowSubstep[]>>;
}
