import React from "react";

import { Col, Row } from "reactstrap";

import { useFetchData } from "hooks/useFetchData";
import { GT } from "components/index";

export const DurationFieldV2 = ({ field, formData, onChange, errors }) => {
    const { data: units } = useFetchData<{ unitsOfTime: ILabelValue[] }>({ url: "units-of-time" });

    const UNIT_SUFFIX = "Unit";

    return (
        <Row>
            <Col xl={9}>
                <GT.CustomInput
                    labelText={field.label}
                    type="number"
                    name={field.name}
                    handleChange={field.onChange || onChange}
                    value={field.value || formData?.[field.name] || ""}
                    errors={field.errors || errors?.[field.name]}
                    className={field.className}
                    noLabel={!field.label}
                    disabled={field.disabled}
                    colSm={8}
                    labelSm={4}
                    min={0}
                    {...field.props}
                />
            </Col>
            <Col xl={3}>
                <GT.PrimeSelect
                    placeholder="Unit"
                    name={field.name + UNIT_SUFFIX}
                    value={field.value || formData?.[field.name + UNIT_SUFFIX]}
                    onChange={field.onChange || onChange}
                    options={field.options || units?.unitsOfTime}
                    disabled={field.disabled}
                    errors={field.errors || errors?.[field.name + UNIT_SUFFIX] || errors?.[field.name + UNIT_SUFFIX]}
                    className={field.className}
                    translateOptions
                    {...field.props}
                />
            </Col>
        </Row>
    );
};
