import React, { Component, forwardRef, useImperativeHandle, useRef } from "react";

import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames/dedupe";

interface TabsProps {
    children: Children;
    className?: string;
}

export const Tabs = forwardRef((props: TabsProps, ref) => {
    const { children, className } = props;

    const ulRef = useRef(null);

    useImperativeHandle(ref, () => ulRef?.current, []);

    return (
        <ul ref={ulRef} className={classnames("gt-nav-pills", className)}>
            {children}
        </ul>
    );
});

export const TabsNavItem = (props) => {
    const { children, isActive, className, onClick, disabled, onDoubleClick, onContextMenu } = props;

    return (
        <NavItem>
            <NavLink
                className={classnames({ active: isActive }, "rui-tabs-link", className)}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                href="#"
                disabled={disabled}
                onContextMenu={onContextMenu}
            >
                {children}
            </NavLink>
        </NavItem>
    );
};
