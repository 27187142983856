import React, { createContext, useContext, useState } from "react";
import { IShipment } from "../interface";

export const OrderShipmentOfSamplesContext = createContext<any>(null);

export const OrderShipmentOfSamplesContextProvider = ({ children }) => {
    const [selectedShipments, setSelectedShipments] = useState<IShipment[]>([]);

    return (
        <OrderShipmentOfSamplesContext.Provider
            value={{ selectedShipments, setSelectedShipments }}
        >
            {children}
        </OrderShipmentOfSamplesContext.Provider>
    );
};

export const useOrderShipmentOfSamples = (): IUseOrderShipmentOfSamples => {
    const { selectedShipments, setSelectedShipments } = useContext(OrderShipmentOfSamplesContext);

    return { selectedShipments, setSelectedShipments };
};

interface IUseOrderShipmentOfSamples {
    selectedShipments: IShipment[];
    setSelectedShipments: () => void;
}
