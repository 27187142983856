import React, { Component } from "react";
import AsyncComponent from "../../components/async-component";
import PageWrap from "../../components/page-wrap";
import PageContent from "../../components/page-content";
import { updateBreadcrumb } from "../../utils/breadcrumbs";
import { TrainingsContextProvider } from "./context/trainings-context";

class TrainingsPage extends Component {

    componentDidMount = updateBreadcrumb

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <TrainingsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </TrainingsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default TrainingsPage;
