import React, { useEffect, useState } from "react";

import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { useSelector, useDispatch } from "react-redux";
import i18n, { timeAgoLangs } from "../../i18n";
import { Toast, ToastBody } from "reactstrap";
import classnames from "classnames/dedupe";
import TimeAgo from "react-timeago";

import { removeToast } from "../../actions";
import Icon from "../icon";

import "./style.scss";

export interface IToast {
    color?: string;
    time?: Date;
    title?: string | any;
    content?: string | any;
    closeButton?: boolean;
    duration?: number;
    timeless?: boolean;
}

export const PageToasts = () => {
    const [hiddenToasts, setHiddenToasts] = useState<any>({});

    const dispatch = useDispatch();

    const { toasts }: { toasts: { [key: string]: IToast } } = useSelector((state) => ({ toasts: state.toasts }));

    useEffect(() => {
        maybePrepareToastsTimeout();
    });

    const maybePrepareToastsTimeout = () => {
        Object.keys(toasts).forEach((uid) => {
            const toast = toasts[uid];

            if (toast.duration) {
                setTimeout(() => {
                    hideToast(uid);
                }, toast.duration);
            }
        });
    };

    const hideToast = (uid) => {
        // hide toast.
        setHiddenToasts((prev) => ({
            ...prev,
            [uid]: true,
        }));
        // completely remove toast from store.
        setTimeout(() => {
            dispatch(removeToast(uid));
        }, 600);
    };

    return (
        <div className="rui-toast-container">
            {Object.entries(toasts)
                .reverse()
                .map(([uid, toast]) => {
                    return (
                        <Toast key={uid} className={classnames("rui-toast", `toast-${toast.color}`)} isOpen={!hiddenToasts[uid]}>
                            <div className="toast-header">
                                {toast.title && <h5 className="mr-auto mnb-2">{toast.title}</h5>}
                                {toast.time && (
                                    <small className="toast-date">
                                        <TimeAgo date={toast.time} formatter={buildFormatter(timeAgoLangs[i18n.language])} />
                                    </small>
                                )}
                                {toast.closeButton && (
                                    <button
                                        type="button"
                                        className="ml-15 mnt-4 mnr-4 toast-close close"
                                        aria-label="Close"
                                        onClick={() => hideToast(uid)}
                                    >
                                        <Icon name="x" />
                                    </button>
                                )}
                            </div>
                            {toast.content ? <ToastBody>{toast.content}</ToastBody> : ""}
                        </Toast>
                    );
                })}
        </div>
    );
};
