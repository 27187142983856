import React from "react";

import { Button } from "reactstrap";
import Icon from "../icon";

import "./hide-button.scss";

export const HideButton = ({ isHidden, setIsHidden }) => {
    return (
        <div className="hide-button-dummy-container">
            <div className="hide-button-container">
                <Button className={isHidden && "hidden-active"} onClick={() => setIsHidden((prev) => !prev)}>
                    <Icon name={["far", isHidden ? "eye-slash" : "eye"]} />
                </Button>
            </div>
        </div>
    );
};
