import React, { CSSProperties, useRef, useState, useEffect } from "react";

import { IPrimeRowData } from "../interfaces/placeholders-interfaces";
import { DefaultCell } from "../components/default-cell";

interface ITableRecord1 {
    [key: number]: INameId<number>[];
}

interface ITableRecord2 {
    [key: string]: INameId<number>[];
}

export interface IDropdownPlaceholderProps {
    rowData: IPrimeRowData<ITableRecord1 | ITableRecord2>;
}

interface IPopUpConfig {
    style?: CSSProperties;
    isOpen: boolean;
}

//! this components works only when column that you want to display has {name: string, id: any} format
export const DropdownPlaceholder = ({ rowData }: IDropdownPlaceholderProps) => {
    const { fieldName } = rowData;

    const containerRef = useRef<HTMLDivElement>(null);
    const popUpRef = useRef<HTMLDivElement>(null);

    const [popUpConfig, setPopUpConfig] = useState<IPopUpConfig>({ isOpen: false });

    const values: INameId<number>[] = rowData.row?.[rowData.fieldName];
    const popUpValues: INameId<number>[] = values?.slice(1) || [];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popUpConfig.isOpen &&
                popUpRef.current &&
                !popUpRef.current.contains(event.target) &&
                !containerRef.current?.contains(event.target)
            ) {
                setPopUpConfig((pervState) => ({ isOpen: false, style: { ...pervState.style, height: "0px" } }));
            }
        };

        if (popUpConfig) document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [popUpConfig]);

    const openPopUp = () => {
        const tdElement = containerRef.current?.closest("td");

        if (tdElement) {
            const popUpPositionY = tdElement.offsetTop + tdElement.offsetHeight + 1;
            const popUpPositionX = tdElement.offsetLeft;

            setPopUpConfig((prev) => ({
                style: { top: popUpPositionY, left: popUpPositionX + 1, width: tdElement.offsetWidth - 2 },
                isOpen: !prev.isOpen,
            }));
        }
    };

    if (!values?.length) return <></>;

    return (
        <>
            <div className="multi-item-container" ref={containerRef}>
                <DefaultCell value={values[0]?.name}>{values[0]?.name}</DefaultCell>
                {popUpValues?.length > 0 && <span className="pi pi-angle-down multi-item-icon" onClick={openPopUp} />}
            </div>
            {popUpConfig.isOpen && containerRef?.current && (
                <div className="multi-items-popup" style={popUpConfig.style} ref={popUpRef}>
                    {popUpValues.map((value, index) => (
                        <div className="multi-item-container" key={`${index}-${fieldName}-popup`}>
                            <DefaultCell value={value.name}>{value.name}</DefaultCell>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
