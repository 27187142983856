import React, { useEffect, useState } from "react";

import { unstable_batchedUpdates } from "react-dom";
import Axios, { CancelTokenSource } from "axios";

interface IUseFetchDataArguments {
    url: string | undefined;
    params?: any;
    triggerValues?: any;
    withIsBusy?: boolean;
    initState?: any;
    type?: "GET" | "POST";
    postData?: any;
    onSuccess?: (data) => void;
    onError?: (err) => void;
}

interface IUseFetchData<T> {
    data: T | undefined | null;
    isBusy: boolean;
    setData: React.Dispatch<React.SetStateAction<T | null | undefined>>;
    refreshData: () => void;
}

export const useFetchData = <T,>({
    url,
    onSuccess,
    onError,
    params,
    triggerValues,
    withIsBusy = false,
    initState = undefined,
    type = "GET",
    postData,
}: IUseFetchDataArguments): IUseFetchData<T> => {
    const [data, setData] = useState<T | undefined | null>(initState);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const fetchData = async (url: string, source?: CancelTokenSource) => {
        try {
            withIsBusy && setIsBusy(true);
            const response =
                type == "GET"
                    ? await Axios.get(url, { params, cancelToken: source?.token })
                    : await Axios.post(url, postData, { params, cancelToken: source?.token });
            unstable_batchedUpdates(() => {
                setData(response.data);
                onSuccess && onSuccess(response.data);
            });
        } catch (err: any) {
            onError && onError(err);
            console.error(err);
        } finally {
            withIsBusy && setIsBusy(false);
        }
    };

    useEffect(() => {
        if (!url) return;

        const source = Axios.CancelToken.source();

        fetchData(url, source);

        return () => source.cancel();
    }, [...(triggerValues || [])]);

    const refreshData = async () => fetchData(url as string);

    return { data, setData, refreshData, isBusy };
};
