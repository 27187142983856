import React, { useState } from "react";

import { useFetchData } from "hooks/useFetchData";

import { GT } from "components";

//? autofocus props comes from index == 0, if the first element in the form is rendered it should be focused or controlled by field prop

interface ISelectWithOptionsProps {
    field: IFormField;
    formData: any;
    errors: IErrors;
    onChange: (e: any, f?: any) => void;
    autofocus: boolean;
}

export const SelectWithOptions = (props: ISelectWithOptionsProps) => {
    const { field, formData, errors, onChange, autofocus } = props;

    const { data } = useFetchData<any>({
        url: field.url,
        triggerValues: [...(field.triggerValues || [])],
        params: field.params,
        onSuccess: (data) => field?.onOptionSuccess && field.onOptionSuccess(data),
    });

    const options = typeof field.options != "function" ? field.options || data : field.options(data);

    const isClearable = (() => {
        if (field.required === true) return false;
        else return field.isClearable;
    })();

    return (
        <>
            {
                {
                    select: (
                        <GT.CustomSelect
                            labelName={field.label}
                            name={field.name}
                            options={options}
                            value={options?.find((option) => (field?.value || formData?.[field.name]) == option.value) || ""}
                            handleChange={field.onChange || onChange}
                            disabled={
                                typeof field.disabled == "boolean" || field.disabled === undefined
                                    ? field.disabled
                                    : field.disabled(options)
                            }
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    multiSelect: (
                        <GT.PrimeMultiSelect
                            rowClassName="mb-20"
                            label={field.label}
                            name={field.name}
                            options={options}
                            value={field?.value || formData?.[field.name]}
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            onChange={(e) =>
                                (field.onChange || onChange)({ ...e, target: { ...e.target, value: e.value === null ? [] : e.value } })
                            }
                            disabled={
                                typeof field.disabled == "boolean" || field.disabled === undefined
                                    ? field.disabled
                                    : field.disabled(options)
                            }
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    creatableSelect: (
                        <GT.CustomCreatableSelect
                            labelName={field.label}
                            name={field.name}
                            options={options}
                            value={formData?.[field.name] || ""}
                            handleChange={field.onChange || onChange}
                            disabled={
                                typeof field.disabled == "boolean" || field.disabled === undefined
                                    ? field.disabled
                                    : field.disabled(options)
                            }
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    multiCreatableSelect: (
                        <GT.CustomMultiCreatableSelect
                            labelName={field.label}
                            name={field.name}
                            options={options}
                            value={options?.find((option) => (field?.value || formData?.[field.name]) == option.value)}
                            handleChange={field.onChange || onChange}
                            disabled={
                                typeof field.disabled == "boolean" || field.disabled === undefined
                                    ? field.disabled
                                    : field.disabled(options)
                            }
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            isClearable={isClearable}
                            {...field.props}
                        />
                    ),
                    primeSelect: (
                        <GT.PrimeSelect
                            rowClassName="mb-20"
                            label={field.label}
                            name={field.name}
                            options={options}
                            value={field?.value || formData?.[field.name]}
                            onChange={field.onChange || onChange}
                            disabled={
                                typeof field.disabled == "boolean" || field.disabled === undefined
                                    ? field.disabled
                                    : field.disabled(options)
                            }
                            required={field.required}
                            autofocus={field.autofocus || autofocus}
                            errors={field.errors || errors?.[field.name]}
                            showClear={isClearable}
                            {...field.props}
                        />
                    ),
                }[field.fieldType]
            }
        </>
    );
};
