import Axios from "axios";

import { IUserTableConfig } from "../interfaces/index";
import { I } from "..";

// saves table config
export const saveTableConfig = async (tableName: string, request: IUserTableConfig) => {
    try {
        await Axios.post("bs/column-config", { tableName, columns: request, forRole: false });
        // const res = await Axios.get(`bs/column-config/${tableName}`);
    } catch {}
};

// gets table config
export const getTableConfig = async (tableName: string): Promise<IUserTableConfig | undefined> => {
    try {
        const config = await Axios.get(`bs/column-config/${tableName}`);
        return config.data.data;
    } catch {}
};

export const compareColumns = async (
    configColumns: I.IGeneratedPrimeTableColumn[],
    defaultColumns: I.IGeneratedPrimeTableColumn[],
    errorCallback: Function | undefined
): Promise<I.IGeneratedPrimeTableColumn[]> => {
    try {
        return await new Promise((resolve, reject) => {
            let cols: I.IGeneratedPrimeTableColumn[] = [];

            configColumns.forEach((savedColumn) => {
                if (!savedColumn) reject(defaultColumns); // returns default columns if saved column is null or undefined

                const col = defaultColumns.find((col) => col.field == savedColumn.field);

                if (!col) reject(defaultColumns); // returns default columns if saved column is not found in default columns
                else cols.push({ ...col, header: col?.header, width: savedColumn.width });
            });

            resolve(cols);
        });
    } catch (e: any) {
        errorCallback && errorCallback(); // saving new default columns in DB, if column in table config is not found in default columns
        return e;
    }
};
