export {};

// STRING
String.prototype.getBetween = function (this: string, start: string, end: string) {
    const fullStr = this;

    const startSliceIndex = fullStr.indexOf(start);
    const endSliceIndex = fullStr.indexOf(end);

    if (startSliceIndex != -1 && endSliceIndex != -1) return fullStr.slice(startSliceIndex + start.length, endSliceIndex);
    else return undefined;
};

String.prototype.getAfter = function (this: string, start: string) {
    const fullStr = this;

    const startSliceIndex = fullStr.indexOf(start);

    if (startSliceIndex != -1) return fullStr.slice(startSliceIndex + start.length);
    else return undefined;
};

String.prototype.getBefore = function (this: string, end: string) {
    const fullStr = this;

    const endSliceIndex = fullStr.indexOf(end);

    if (endSliceIndex != -1) return fullStr.slice(0, endSliceIndex);
    else return undefined;
};

String.prototype.firstLetterToUpperCase = function (this: string) {
    const fullStr = this;

    return fullStr.charAt(0).toUpperCase() + fullStr.slice(1);
};

String.prototype.translate = function (this: string, t: (str: string) => string) {
    const fullStr = this;

    return t(fullStr);
};
