/**
 * External Dependencies
 */
import React, { Component } from "react";
import classnames from "classnames/dedupe";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPageFallback, NetworkErrorPageFallback } from "../error-page-fallback/error-page-fallback";

import { useSelector } from "react-redux";

/**
 * Component
 */
const PageWrap = (props) => {
    const { className, children } = props;

    const { isConnected } = useSelector((state) => ({ isConnected: state.serverConnection.isConnected }));

    return (
        <div className={classnames("rui-page rui-page-react content-wrap route-transition", className)}>
            <ErrorBoundary FallbackComponent={navigator.onLine ? ErrorPageFallback : NetworkErrorPageFallback}>
                {isConnected ? <div>{children}</div> : <NetworkErrorPageFallback />}
            </ErrorBoundary>
        </div>
    );
};

export default PageWrap;
