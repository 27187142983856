/**
 * Internal Dependencies
 */

// Auth
import AuthSignIn from "./AuthSignIn";
import AuthSignUp from "./AuthSignUp";

// Start
import Dashboard from "./Dashboard";

// Apps
import ProjectsPage from "./Projects";
import ProjectDetailsPage from "./ProjectsOld/Details";
// import ExperimentsPage from "./Experiments";
// import ExperimentSectionsPage from "./Experiments/Sections";
import SamplesPage from "./Samples";
import OrdersTilesPage from "./Orders/TileView";
import EquipmentsPage from "./Equipments";
import BSTests from "./BasicSettings/Tests";
import BSUnitOfMeasures from "./BasicSettings/UnitOfMeasures";
import BSEquipmentTypes from "./BasicSettings/EquipmentTypes";
import BSMaintenanceTypes from "./BasicSettings/MaintenanceTypes";
import BSSuppliers from "./BasicSettings/Suppliers";
import BSMaterials from "./BasicSettings/Materials";
import BSMaterialTypes from "./BasicSettings/MaterialTypes";
import BSSpecifications from "./BasicSettings/Specifications";
import BSTestPlans from "./BasicSettings/TestPlans";
import BSSystemEmail from "./BasicSettings/SystemEmail";
import BSLocations from "./BasicSettings/Locations";
import BSUsersManagement from "./BasicSettings/UsersManagement";
import TasksPage from "./Tasks";
import AccessSettings from "./AccessSettings";
import BasicSettings from "./BasicSettings/BSTileView";
import InventoryPage from "./Inventory";
import BSLaboratories from "./BasicSettings/Laboratories";
import BSMethods from "./BasicSettings/Methods";
import BSAudit from "./BasicSettings/Audit";
import BSLookups from "./BasicSettings/Lookups";
import InventorySamplePage from "./InventorySample";
import PriceListPage from "./CRM/PriceList";
import TrainingsPage from "./Trainings";
import PatientPage from "./Patients";
import BSReportTemplate from "./BasicSettings/ReportTemplate";

import CDRTilesPage from "./CDR/TileView";
import CDRDocumentTypes from "./CDR/DocumentTypes";
import CDRDocumentWorkflows from "./CDR/DocumentWorkflows";
import CDRDocumentRepository from "./CDR/DocumentRepository";
import CDRReleasedDocuments from "./CDR/ReleasedDocuments";

import BSPersonel from "./BasicSettings/Personel";

import BSIntegrations from "./BasicSettings/Integrations";
import MyProjects from "./My/Projects";
import MyExperiments from "./My/Experiments";
import MySamples from "./My/Samples";
import MyTasks from "./My/Tasks";
import MyEquipment from "./My/Equipment";
import BSMetadata from "./BasicSettings/Metadata";
import MetadataPreview from "./MetadataPreview";
import CSVTilesPage from "./CSV";
import CSVTemplate from "./CSV/Template";
import CSVImport from "./CSV/Import";
import TransactionSettings from "./TransactionSettings";
import Subcontractors from "./Subcontractors";
import CRMTilesPage from "./CRM/TileView";
import MyTilesPage from "./My/TileView";
import BSResultQualifier from "./BasicSettings/ResultQualifier";

import ManageResultSheetPage from "./Orders/ResultSheet/Manage";
import MyResultSheetPage from "./Orders/ResultSheet/My";
import OrdersRegister from "./Orders/Register";
import DemandsTiles from "./Demands/DemandsTileView";
import Suppliers from "./Demands/Suppliers";
import DemandsList from "./Demands/DemandsList";
import OrdersReleaseResultsPage from "./Orders/ReleaseResults";
import OrdersReleaseSamplesPage from "./Orders/ReleaseSamples";
import OrdersReleaseOrdersPage from "./Orders/ReleaseOrders";
import OrdersEditPage from "./Orders/Edit";
import OrdersPreviewPage from "./Orders/Preview";

import BSCostGroups from "./BasicSettings/CostGroups";
import OrdersSampling from "./Orders/Sampling";
import OrdersResults from "./Orders/Results";
import OrdersCrossResults from "./Orders/CrossResults";
import OrdersReceiveInLab from "./Orders/ReceiveInLab";
import OrdersPrepare from "./Orders/Prepare";
import OrdersReceivePage from "./Orders/Receive";
import OrderTestReportsPage from "./Orders/TestReports";
import DemandOrders from "./Demands/DemandOrders";
import ControlCardsPage from "./ControlCards";
import BSWorkflow from "./BasicSettings/Workflow";
import MethodValidations from "./MethodValidations";
import CustomerSatisfactionSurvey from "./CustomerSatisfactionSurvey";
import AdvancedQueries from "./Reports/AdvancedQueries";
import QueryTemplate from "./Reports/QueryTemplate";
import BSCostLimits from "./BasicSettings/CostLimits";
import IntegratedSystemTiles from "./IntegratedSystem/TileView";
import IntegratedSystems from "./IntegratedSystem/IntegratedSystems";
import IntegratedSystemsInstances from "./IntegratedSystem/IntegratedSystemsInstances";
import OffersAppPage from "./CRM/OffersApp";
import ProfileSettings from "./AccountSettings/Profile";
import SamplingListPage from "./Orders/SamplingList";
import Questionnaires from "./Questionnaires";
import MaterialsTreePage from "./BasicSettings/MaterialsTree";
import ClientsPage from "./CRM/Clients";
import Mapping from "./IntegratedSystem/Mapping";
import AuthorizeResultSheetPage from "./Orders/ResultSheet/Authorize";
import ArchiveResultSheetPage from "./Orders/ResultSheet/Archive";
import ErrorLoggerPage from "./ErrorLogger";
import ReportsTiles from "./Reports";
import SamplesLogisticsPage from "./Orders/SamplesLogistics";
import CalendarPage from "./BasicSettings/Calendar";
import OrdersShipmentOfSamplesPage from "./Orders/ShipmentOfSamples";
import HelpPage from "./Help";
import SampleWorkflowsPage from "./BasicSettings/SampleWorkflow";
import ElnTemplatesPage from "./BasicSettings/ElnTemplates";
import ProcessParametersPage from "./BasicSettings/ProcessParameters";
import PasswordPolicyPage from "./Administration/PasswordPolicy";
import JSONImport from "./CSV/JSON-Import";
import SamplingPlansPage from "./CRM/SamplingPlans";
import StatusesConfigPage from "./Administration/StatusesConfig";
import CodeMaskPage from "./Administration/CodeMask";
import StabilityTemplatesPage from "./BasicSettings/StabilityTemplates";
import BSSamplingSites from "./BasicSettings/SamplingSites";
import BSBulkPackaging from "./BasicSettings/BulkPackaging";
import StabilityTestsOrdersPage from "./StabilityTests/StabilityOrders";
import BSStabilityTestsTilesPage from "./StabilityTests/TileView";
import AwaitingSamplesPage from "./StabilityTests/AwaitingSamples";
import ProductTrendsPage from "./ProductTrends";
import MetaAppPage from "./MetaApp";
import BSMetaAppTypes from "./BasicSettings/MetaAppTypes";
import CompanySettingsPage from "./Administration/CompanySettings";
import FormulationsPage from "./Orders/Formulations";
import BSPictogram from "./BasicSettings/Pictograms";
import PrintersPage from "./Administration/Printers";
import FormsPage from "./BasicSettings/Forms";
import SuppliersEvaluationsPage from "./Demands/SuppliersEvaluations";
import ParsersPage from "./BasicSettings/Parsers";
import ViewsPage from "./Reports/ViewsApp";
import MethodValidationsPage from "./BasicSettings/MethodValidation";
import InvoicesPage from "./CRM/Invoices";
import InvestigationsPage from "./QualityAssurance/Investigations";
import ProductQualityReviewsPage from "./QualityAssurance/ProductQualityReviews";
import QATiles from "./QualityAssurance/TileView";
import ComplaintsPage from "./QualityAssurance/Complaints";
import ReturnsPage from "./QualityAssurance/Returns";
import CorrectiveActionsPage from "./QualityAssurance/CorrectiveActions";
import ReclamationsPage from "./QualityAssurance/Reclamations";
import PreventiveActionsPage from "./QualityAssurance/PreventiveActions";

//?MOBILE

//? Sampling
import SampleSamplingPage from "./Mobile/Orders/Sampling/pages/sampling/sampling-page";
import SamplingMobilePage from "./Mobile/Orders/Sampling";

//? Equipments
import EquipmentsDetailsPage from "./Mobile/Equipments/pages/equipmentsDetails/equipments-details-page";
import EquipmentsMobilePage from "./Mobile/Equipments";

//? Results
import EnterResultsPage from "./Mobile/Orders/Results/pages/enterResults";
import ResultsMobilePage from "./Mobile/Orders/Results";

//? Samples
import SampleResultDetailsPage from "./Mobile/Samples/pages/resultDetails";
import ReleaseSamplesMobilePage from "./Mobile/Orders/ReleaseSamples";
import SampleDetailsPage from "./Mobile/Samples/pages/sampleDetails";
import SampleResultsPage from "./Mobile/Samples/pages/results";
import SamplesMobilePage from "./Mobile/Samples";
import QaTypesPage from "./QualityAssurance/QaTypes";

export default {
    "/sign-in": AuthSignIn,
    "/sign-up": AuthSignUp,

    "/profile": ProfileSettings,

    "/": Dashboard,
    "/metadata-preview": MetadataPreview,

    // Apps
    "/projects": ProjectsPage,
    "/projects/:id": ProjectDetailsPage,

    "/equipments": EquipmentsPage,

    // "/experiments": ExperimentsPage,
    // "/experiments/:id": ExperimentSectionsPage,

    "/samples": SamplesPage,

    "/orders": OrdersTilesPage,
    "/orders/register": OrdersRegister,
    "/orders/sampling": OrdersSampling,
    "/orders/receive-in-lab": OrdersReceiveInLab,
    "/orders/prepare": OrdersPrepare,
    "/orders/receive": OrdersReceivePage,
    "/orders/results": OrdersResults,
    "/orders/cross-results": OrdersCrossResults,
    "/orders/release-results": OrdersReleaseResultsPage,
    "/orders/release-samples": OrdersReleaseSamplesPage,
    "/orders/release-orders": OrdersReleaseOrdersPage,
    "/orders/test-reports": OrderTestReportsPage,
    "/orders/edit": OrdersEditPage,
    "/orders/preview": OrdersPreviewPage,
    "/orders/sampling-list": SamplingListPage,
    "/orders/shipment-of-samples": OrdersShipmentOfSamplesPage,
    "/orders/manage-eln": ManageResultSheetPage,
    "/orders/my-eln": MyResultSheetPage,
    "/orders/authorize-eln": AuthorizeResultSheetPage,
    "/orders/archive-eln": ArchiveResultSheetPage,
    "/orders/samples-logistics": SamplesLogisticsPage,
    // "/orders/archive-eln": AuthorizeResultSheetPage, // temporary - jacku said that - only for 08.12 demo
    "/orders/formulations": FormulationsPage,

    "/stability-tests": BSStabilityTestsTilesPage,
    "/stability-tests/stability-protocol": StabilityTestsOrdersPage,
    "/stability-tests/awaiting-samples": AwaitingSamplesPage,

    "/cdr": CDRTilesPage,
    "/cdr/document-types": CDRDocumentTypes,
    "/cdr/document-workflows": CDRDocumentWorkflows,
    "/cdr/document-repository": CDRDocumentRepository,
    "/cdr/released-documents": CDRReleasedDocuments,

    "/accessSettings": AccessSettings,

    "/bs": BasicSettings,
    "/bs/tests": BSTests,
    "/bs/units": BSUnitOfMeasures,
    "/bs/eqTypes": BSEquipmentTypes,
    "/bs/maintTypes": BSMaintenanceTypes,
    "/bs/suppliers": BSSuppliers,
    "/bs/materials": BSMaterials,
    "/bs/pictogram": BSPictogram,
    "/bs/material-types": BSMaterialTypes,
    "/bs/testPlans": BSTestPlans,
    "/bs/specifications": BSSpecifications,
    "/bs/systemEmail": BSSystemEmail,
    "/bs/locations": BSLocations,
    "/bs/laboratories": BSLaboratories,
    "/bs/methods": BSMethods,
    "/bs/audit": BSAudit,
    "/bs/lookups": BSLookups,
    "/bs/personel": BSPersonel,
    "/bs/users-management": BSUsersManagement,
    "/bs/report_template": BSReportTemplate,
    "/bs/metadata": BSMetadata,
    "/bs/result-qualifier": BSResultQualifier,
    "/bs/workflow": BSWorkflow,
    "/bs/cost-group": BSCostGroups,
    "/bs/cost-limits": BSCostLimits,
    "/bs/calendar": CalendarPage,
    "/bs/sample-workflow": SampleWorkflowsPage,
    "/bs/eln-templates": ElnTemplatesPage,
    "/bs/process-parameters": ProcessParametersPage,
    "/bs/stability-templates": StabilityTemplatesPage,
    "/bs/sampling-sites": BSSamplingSites,
    "/bs/meta-app-types": BSMetaAppTypes,
    "/bs/forms": FormsPage,
    "/bs/parsers": ParsersPage,
    "/bs/method-validations": MethodValidationsPage,
    "/bs/bulk-packaging": BSBulkPackaging,
    "/bs/materials-tree": MaterialsTreePage,

    // Administration
    "/administration/password-policy": PasswordPolicyPage,
    "/administration/statuses-config": StatusesConfigPage,
    "/administration/code-mask": CodeMaskPage,
    "/administration/company-settings": CompanySettingsPage,
    "/administration/printers": PrintersPage,

    "/control-cards": ControlCardsPage,
    "/product-trends": ProductTrendsPage,

    "/tasks": TasksPage,

    "/reports": ReportsTiles,
    "/reports/advanced-queries/:id?": AdvancedQueries,
    // "/reports/query-template": QueryTemplate,
    "/reports/query-template": QueryTemplate,
    "/reports/views": ViewsPage,

    "/inventory": InventoryPage,

    // "/inventory-sample": InventorySamplePage,

    "/crm": CRMTilesPage,
    "/crm/clients": ClientsPage,
    "/crm/price-list": PriceListPage,
    "/crm/offers-contracts": OffersAppPage,
    "/crm/sampling-plans": SamplingPlansPage,
    "/crm/invoices": InvoicesPage,

    "/planned-trainings": TrainingsPage,

    // "/patients": PatientPage,

    "/bs/integrations": BSIntegrations,

    "/my": MyTilesPage,
    "/my/projects": MyProjects,
    // "/my/experiments": MyExperiments,
    // "/my/samples": MySamples,
    // "/my/tasks": MyTasks,
    // "/my/equipment": MyEquipment,

    "/csv": CSVTilesPage,
    "/csv/templates": CSVTemplate,
    "/csv/import": CSVImport,
    "/csv/json-import": JSONImport,

    "/transaction_settings": TransactionSettings,

    "/subcontractors": Subcontractors,
    "/demands": DemandsTiles,
    "/demands/suppliers": Suppliers,
    "/demands/suppliers-evaluations": SuppliersEvaluationsPage,
    "/demands/demands-list": DemandsList,
    "/demands/orders": DemandOrders,
    // "/method-validations": MethodValidations,
    // "/customer-satisfaction-survey": CustomerSatisfactionSurvey,

    "/integrated-system": IntegratedSystemTiles,
    "/integrated-system/types": IntegratedSystems,
    "/integrated-system/instances": IntegratedSystemsInstances,
    "/integrated-system/mapping": Mapping,
    // "/questionnaire": Questionnaires,

    "/qa": QATiles,
    "/qa/investigations": InvestigationsPage,
    "/qa/complaints": ComplaintsPage,
    "/qa/returns": ReturnsPage,
    "/qa/corrective-actions": CorrectiveActionsPage,
    "/qa/preventive-actions": PreventiveActionsPage,
    "/qa/reclamations": ReclamationsPage,
    "/qa/product-quality-reviews": ProductQualityReviewsPage,
    "/qa/types": QaTypesPage,

    "/error-logger": ErrorLoggerPage,
    "/help": HelpPage,

    "/meta-app": MetaAppPage,

    // mobile pages
    //! Orders
    //? Sampling
    "/m/orders/sampling": SamplingMobilePage,
    "/m/orders/sampling/:id": SampleSamplingPage,

    //? Release sample
    "/m/orders/release-samples": ReleaseSamplesMobilePage,

    //! Misc
    //? Equipments
    "/m/equipments": EquipmentsMobilePage,
    "/m/equipments/:id": EquipmentsDetailsPage,

    //? Results
    "/m/orders/results": ResultsMobilePage,
    "/m/orders/results/:id": EnterResultsPage,

    //? Samples
    "/m/samples": SamplesMobilePage,
    "/m/samples/:id": SampleDetailsPage,
    "/m/samples/:id/results": SampleResultsPage,
    "/m/samples/:id/results/:resultId": SampleResultDetailsPage,
};
