import React, { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { sweetConfirm } from "../sweet-alert/sweetConfirm";
import CustomButton from "../custom-button/custom-button";
import addToast from "../../utils/addToast";
import Dropdown from "../bs-dropdown";
import Icon from "../icon";

interface IProps {
    options: ILabelValue<number>[];
    url?: string;
    permCode?: string;
    handleStepSubmit?: Function;
    refreshData?: Function;
    CustomModalTemplate?: any;
    isIntersectingMenu?: boolean;
    cyId?: string;
}

type Step = (ILabelValue<number> & { action?: number }) | undefined;

export const StepDropDown = ({
    options = [],
    url,
    handleStepSubmit,
    refreshData,
    permCode = undefined,
    CustomModalTemplate = undefined,
    isIntersectingMenu,
    cyId,
}: IProps) => {
    const { t } = useTranslation();
    const [selectedStep, setSelectedStep] = useState<Step>(undefined);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleStepChange = async (step: ILabelValue<number>) => {
        if (!CustomModalTemplate) handleDefaultStepSubmit(step);
        else setSelectedStep(step);
    };

    const handleDefaultStepSubmit = async (step = selectedStep, additionalParams = {}, e: SyntheticEvent | undefined = undefined) => {
        e && e.preventDefault();
        setIsBusy(true);

        if (handleStepSubmit) {
            await handleStepSubmit(step);
            setIsBusy(false);
            return;
        }

        try {
            if (!url) throw new Error("No url");
            if (!CustomModalTemplate && !(await sweetConfirm(step?.label, { text: "Do you want to proceed?" }))) return;

            await Axios.patch(url, { workflowStep: step?.value, action: step?.action, ...additionalParams });
            refreshData && refreshData();
            addToast({ title: "Operation was success." });

            CustomModalTemplate && handleCloseModal();
        } catch (err: any) {
            console.error(err);
        } finally {
            setIsBusy(false);
        }
    };

    const handleCloseModal = () => setSelectedStep(undefined);

    if (isIntersectingMenu) {
        return (
            <ul>
                {options.map((item, index) => {
                    return (
                        <>
                            <li key={`${index}-${item.value}`} onClick={() => handleStepChange(item)} className="pointer">
                                <a className="nav-link">
                                    <Icon name="arrow-right" />
                                    <span>{t(item.label)}</span>
                                    <span className="rui-nav-circle"></span>
                                </a>
                            </li>
                            {selectedStep && (
                                <CustomModalTemplate
                                    handleStepSubmit={handleDefaultStepSubmit}
                                    step={selectedStep}
                                    handleCloseModal={handleCloseModal}
                                    isBusy={isBusy}
                                />
                            )}
                        </>
                    );
                })}
            </ul>
        );
    }

    return (
        <>
            {options.length == 1 ? (
                <CustomButton
                    text={options[0].label}
                    permCode={permCode}
                    onClick={() => handleStepChange(options[0])}
                    withoutPerm
                    cyId={cyId}
                />
            ) : (
                <Dropdown showTriangle className="dib  w-100">
                    <Dropdown.Toggle disabled={options.length == 0} tag="button" className="btn btn-outline-gt-success mr-10 mt-10">
                        <span className="text">{t("Change status")}</span>
                        <span className="icon">
                            <Icon name="chevron-down" />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu tag="ul" className="nav">
                        {options.map((item, index) => {
                            return (
                                <li key={`${index}-${item.value}`} onClick={() => handleStepChange(item)} className="pointer">
                                    <a className="nav-link">
                                        <Icon name="arrow-right" />
                                        <span>{t(item.label)}</span>
                                        <span className="rui-nav-circle"></span>
                                    </a>
                                </li>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            )}

            {selectedStep && (
                <CustomModalTemplate
                    handleStepSubmit={handleDefaultStepSubmit}
                    step={selectedStep}
                    handleCloseModal={handleCloseModal}
                    isBusy={isBusy}
                />
            )}
        </>
    );
};
