import { useRef, useCallback, useEffect } from "react";

export const useDebouncedCallback = <A extends any[]>(callback: (...args: A) => void, delay: number) => {
    // track args & timeout handle between calls
    const argsRef = useRef<A>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const cleanup = () => timeout.current && clearTimeout(timeout.current);

    useEffect(() => cleanup, []); // make sure our timeout gets cleared if, our consuming component gets unmounted

    return useCallback(
        (...args: A) => {
            argsRef.current = args; // capture latest args
            cleanup(); // clear debounce timer

            // start waiting again
            timeout.current = setTimeout(() => {
                argsRef.current && callback(...argsRef.current);
            }, delay);
        },
        [callback, delay]
    );
};