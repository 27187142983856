/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
/**
 * Internal Dependencies
 */
import AsyncComponent from '../../../components/async-component';
import PageWrap from '../../../components/page-wrap';
import PageContent from '../../../components/page-content';
import { v4 as uuid } from 'uuid';
import { updateBreadcrumb } from '../../../utils/breadcrumbs';

/**
 * Component
 */
class ProjectDetailsPage extends Component<any,any> {
    
    componentDidMount = updateBreadcrumb
    
    render() {
        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={ () => import( './content' ) } />
                </PageContent>
            </PageWrap>
        );
    }
}

export default ProjectDetailsPage ;
