import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { EquipmentsContextProvider } from "./context/equipments-context";
import { isMobile } from "../../../common-assets/js/rootui-parts/_utility";
import AsyncComponent from "../../components/async-component";
import { updateBreadcrumb } from "../../utils/breadcrumbs";
import PageContent from "../../components/page-content";
import PageWrap from "../../components/page-wrap";

class EquipmentsPage extends Component<any, any> {
    componentDidMount = updateBreadcrumb;

    render() {
        const { history, location } = this.props;

        if (isMobile) history.push(`/m${location.pathname}`);

        return (
            <PageWrap>
                <PageContent>
                    <EquipmentsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </EquipmentsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default withRouter(EquipmentsPage);
