import { IGeneratedPrimeTableColumn, IPrimeParams } from "../interfaces/table-interfaces";

export const getNestedObjValue = (obj, parts) => {
    if (parts.length == 1) return obj[parts[0]];
    if (!obj[parts[0]]) return "";
    return getNestedObjValue(obj[parts[0]], parts.slice(1));
};

export const convertDotsToUnderscores = (str: string): string => {
    return str.replaceAll(".", "__");
};

export const mapToIds = (array: any[], parameterName: string = "id"): number[] => array?.map((item) => item[parameterName]);

export const getFrozenColWidth = (col: IGeneratedPrimeTableColumn): string => {
    const minColWidth = 160;
    const maxColWidth = 300;
    const colWidth = parseInt(col.width);

    if (minColWidth < colWidth && colWidth < maxColWidth) return col.width;
    else if (colWidth < maxColWidth) return `${minColWidth}px`;
    else return `${maxColWidth}px`;
};

export const shouldClearParamsAndRefresh = (hiddenColumns: IGeneratedPrimeTableColumn[], params: IPrimeParams): boolean => {
    const hiddenColumnsFieldNames = hiddenColumns.map((col) => col.field);

    return (
        params.multiSortMeta?.some((sortObj) => hiddenColumnsFieldNames.includes(sortObj.field)) ||
        (!!params.filters && Object.keys(params.filters).some((key) => hiddenColumnsFieldNames.includes(key)))
    );
};

export const getDataSetBreakpoint = (tableHeight: number, recordHeight: number) => parseInt(`${tableHeight / recordHeight}`);
