import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { setTimeout } from "timers";

import { FilteringType, IFilterDateInputProps } from "../interfaces/filter-interfaces";
import { FilterDateTypePopup } from "../components/pop-ups/filter-date-popup";
import { formatDate } from "../../../utils/formatDate";
import { memoFilter } from "./memoFilter";

import "../../custom-modal-elements/custom-input-with-state.styles.scss";

// table header filter component, you send it in the column as filterBody prop
// eg. (filterBody: (inputData: IPrimeFilterData) => <MemoFilterDateRangeInput inputData={inputData} />)
export const MemoFilterDateRangeInput = memoFilter((props: IFilterDateInputProps) => {
    const { filterArrayLength, column } = props.inputData;

    if (filterArrayLength < 2) return <FilterDateRangeInput {...props} />;
    else return <DisabledFilterDateRangeInput field={column.field} />;
});

export const FilterDateRangeInput = ({ inputData, placeholder }: IFilterDateInputProps) => {
    const { t } = useTranslation();
    const calendarRef = useRef<any>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [filterType, setFilterType] = useState<FilteringType>("__range");

    const { column, onCalendarClose, onDateFilterChange, onClearDateFilter, filter, tableRef } = inputData;

    useEffect(() => {
        if (filter?.filteringType && filterType != filter?.filteringType) setFilterType(filter?.filteringType);
    }, [filter?.filteringType]);

    const selectTemplate = (e) => {
        let { options, value } = e;
        options = options.map((o) => ({ ...o, label: t(o.label) }));

        return (
            <Dropdown
                className="prime-calendar-select"
                value={value}
                options={options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
            />
        );
    };

    const getRangeValue = (): Date[] => {
        if (typeof filter?.value == "string" || typeof filter?.value == "number" || !filter?.value) return [];
        const _value = filter?.value;

        if (_value?.endDt != null) return [new Date(_value?.startDt), new Date(_value?.endDt)];
        else return [new Date(_value?.startDt), _value?.endDt];
    };

    const getDataPicker = () => {
        switch (filterType) {
            case "__range":
                return (
                    <Calendar
                        ref={calendarRef}
                        selectionMode="range"
                        id="range"
                        readOnlyInput
                        key={`prime-filter-input-${column.field}`}
                        name={column.field}
                        value={getRangeValue()}
                        placeholder={placeholder ? t(placeholder) : `${t("Filter")} ${column.header}`}
                        onHide={() => onCalendarClose(column.field)}
                        onChange={(e) => {
                            onDateFilterChange({
                                name: e.target.name,
                                value: [{ value: { startDt: formatDate(e.value?.[0]), endDt: formatDate(e.value?.[1]) }, filterType }],
                            });
                            if (e.value?.[1] && calendarRef.current) {
                                setTimeout(() => {
                                    calendarRef.current.hideOverlay();
                                }, 0);
                            }
                        }}
                        yearRange="2020:2032"
                        dateFormat="dd/mm/yy"
                        monthNavigator
                        yearNavigator
                        monthNavigatorTemplate={selectTemplate}
                        yearNavigatorTemplate={selectTemplate}
                    />
                );
            default:
                //clear filterValue
                return (
                    <>
                        <Calendar
                            readOnlyInput
                            key={`prime-filter-input-${column.field}`}
                            name={column.field}
                            value={new Date(typeof filter?.value == "string" ? filter?.value : "")}
                            placeholder={placeholder ? t(placeholder) : `${t("Filter")} ${column.header}`}
                            onHide={() => onCalendarClose(column.field)}
                            onChange={(e) =>
                                onDateFilterChange({ name: e.target.name, value: [{ value: formatDate(e.value), filterType }] })
                            }
                            yearRange="2020:2032"
                            dateFormat="dd/mm/yy"
                            monthNavigator
                            yearNavigator
                            monthNavigatorTemplate={selectTemplate}
                            yearNavigatorTemplate={selectTemplate}
                        />
                    </>
                );
        }
    };

    return (
        <div className="custom-search-table-input" key={`prime-filter-input-${column.field}-container`} ref={inputRef}>
            {getDataPicker()}
            <FilterDateTypePopup
                filterDateType={filterType}
                handleFilterDateTypeChange={setFilterType}
                tableRef={tableRef}
                inputRef={inputRef}
                t={t}
            />
            <span onClick={() => filter && onClearDateFilter(column.field)} className="pi pi-times state-input-clear-button" />
        </div>
    );
};

const DisabledFilterDateRangeInput = ({ field }) => {
    const { t } = useTranslation();

    return (
        <div className="custom-search-table-input" key={`prime-filter-input-${field}-container-disabled`}>
            <Calendar
                key={`prime-filter-input-${field}-disabled`}
                placeholder={t("Advanced filter...")}
                readOnlyInput
                name={field}
                disabled
            />
        </div>
    );
};
