export const translateErrorString = (errorStrings: Array<string> | string, t) => {
    let result = [] as any;

    if (typeof errorStrings == "string") {
        return t(errorStrings);
    }

    const regexList = [
        /^Ensure this field has no more than (\d+) characters.$/,
        /^Ensure that there are no more than (\d+) digits in total.$/,
        /^Ensure that there are no more than (\d+) digits before the decimal point.$/,
        /^Ensure that there are no more than (\d+) decimal places.$/,
        /Range is overlaping with (.*) and (.*)/,
    ];
    const translationList = [
        "Ensure this field has no more than {{count}} characters",
        "Ensure that there are no more than {{count}} digits in total",
        "Ensure that there are no more than {{count}} digits before the decimal point",
        "Ensure that there are no more than {{count}} decimal places",
        "Range is overlaping with {{substring}} and {{substring_2}}",
    ];

    let errorMatch;
    for (let errorString of errorStrings) {
        for (let i = 0; i < regexList.length; i++) {
            errorMatch = errorString.match(regexList[i]);
            if (errorMatch) {
                result.push(
                    t(translationList[i], { count: Number(errorMatch[1]), substring: errorMatch[1], substring_2: errorMatch?.[2] })
                );
                break;
            }
        }

        if (!errorMatch) result.push(t(errorString));
    }
    return result.join(". ");
};
