/**
 * External Dependencies
 */
import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "components/async-component";
import { updateBreadcrumb } from "utils/breadcrumbs";
import PageContent from "components/page-content";
import PageWrap from "components/page-wrap";

/**
 * Component
 */
class EnterResultsPage extends Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={() => import("./enter-result-page")} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default EnterResultsPage;
