

const startYear = 2010;

const getArrayLength = () => {
    return new Date().getFullYear() - startYear + 2;
};

export const yearsOptions: ILabelValue<number>[] = Array.from({ length: getArrayLength() }, (_, index) => ({
    label: `${index + startYear}`,
    value: index + startYear,
}));

export const weeksOptions = Array.from({ length: 52 }, (_, index) => ({ label: `${index + 1}`, value: index + 1 }));
