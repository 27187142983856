/**
 * External Dependencies
 */
import React from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../../components/async-component';
import PageWrap from '../../../components/page-wrap';
import PageContent from '../../../components/page-content';
import { updateBreadcrumb } from "../../../utils/breadcrumbs";

/**
 * Component
 */
class SamplingListPage extends React.Component {
    componentDidMount = updateBreadcrumb

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <AsyncComponent component={ () => import( './content' ) } />
                </PageContent>
            </PageWrap>
        );
    }
}

export default SamplingListPage;
