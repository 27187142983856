import React, { createContext, useContext, useState } from "react";

import { ISamplingSiteTable } from "../interfaces/interfaces";

export const SamplingSitesContext = createContext<any>(null);

export const SamplingSitesContextProvider = ({ children }) => {
    const [selectedSamplingSites, setSelectedSamplingSites] = useState<ISamplingSiteTable[]>([]);

    return (
        <SamplingSitesContext.Provider value={{ selectedSamplingSites, setSelectedSamplingSites }}>
            {children}
        </SamplingSitesContext.Provider>
    );
};

export const useSamplingSites = (): IUseSamplingSites => {
    const { selectedSamplingSites, setSelectedSamplingSites } = useContext(SamplingSitesContext);

    return { selectedSamplingSites, setSelectedSamplingSites };
};

interface IUseSamplingSites {
    selectedSamplingSites: ISamplingSiteTable[];
    setSelectedSamplingSites: (arg: ISamplingSiteTable[]) => any;
}
