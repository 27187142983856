import React, { createContext, useContext, useState } from "react";


import { IEvent, IEventDate } from "../../../../components/calendars/interfaces";

const CalendarContext = createContext<any>(null);

export const CalendarContextProvider = ({ children }) => {
    const [calendarName, setCalendarName] = useState<string>();
    const [calendarEvents, setCalendarEvents] = useState<IEvent[]>([]);
    const [calendarOptions, setCalendarOptions] = useState<ILabelValue<number>[]>([]);
    const [selectedData, setSelectedData] = useState<IEvent>();
    const [viewType, setViewType] = useState<string>("month");

    return (
        <CalendarContext.Provider
            value={{
                calendarName,
                setCalendarName,
                calendarEvents,
                setCalendarEvents,
                calendarOptions,
                setCalendarOptions,
                selectedData,
                setSelectedData,
                viewType,
                setViewType,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};

export const useCalendar = (): IUseCalendar => {
    const {
        calendarName,
        setCalendarName,
        calendarEvents,
        setCalendarEvents,
        calendarOptions,
        setCalendarOptions,
        selectedData,
        setSelectedData,
        viewType,
        setViewType,
    } = useContext(CalendarContext);

    return {
        calendarName,
        setCalendarName,
        calendarEvents,
        setCalendarEvents,
        calendarOptions,
        setCalendarOptions,
        selectedData,
        setSelectedData,
        viewType,
        setViewType,
    };
};

interface IUseCalendar {
    calendarName: string;
    setCalendarName: React.Dispatch<React.SetStateAction<string | undefined>>;
    calendarEvents: IEvent[];
    setCalendarEvents: React.Dispatch<React.SetStateAction<IEvent[]>>;
    calendarOptions: ILabelValue<number>[];
    setCalendarOptions: React.Dispatch<React.SetStateAction<ILabelValue<number>[]>>;
    selectedData: IEvent;
    setSelectedData: React.Dispatch<React.SetStateAction<IEventDate | undefined>>;
    viewType: string;
    setViewType: React.Dispatch<React.SetStateAction<string>>;
}
