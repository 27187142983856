import React from "react";

import ModalAdvancedRemarks from "components/prime-data-table/edit-body-components/advanced-remarks/modal-advanced-remarks";
import { useAdvancedRemarks } from "components/prime-data-table/advancedRemarksContext/context";
import { useMountTransition } from "hooks/useMountTransition";

export const AdvancedRemarksPopup = ({ modelName, onReload }) => {
    const { modalData, modalOpen, setModalOpen } = useAdvancedRemarks();

    const { rowId, addFromLookupProps } = modalData;

    const hasTransitionedIn = useMountTransition(modalOpen);

    return (
        <>
            {(modalOpen || hasTransitionedIn) && (
                <ModalAdvancedRemarks
                    modelName={modelName}
                    handleCloseModal={() => setModalOpen(false)}
                    rowId={rowId}
                    addFromLookupProps={addFromLookupProps}
                    modalOpen={modalOpen}
                    onReload={onReload}
                />
            )}
        </>
    );
};
