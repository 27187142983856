import React, { Component } from "react";

import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageWrap from "../../components/page-wrap";

class HelpPage extends Component {

    render() {
        return (
            <PageWrap>
                <PageContent>
                        <AsyncComponent component={() => import("./content")} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default HelpPage;
