import React from "react";

import { ClientsContextProvider } from "./context/clients-context";
import AsyncComponent from "../../../components/async-component";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import PageContent from "../../../components/page-content";
import PageWrap from "../../../components/page-wrap";

class ClientsPage extends React.Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent>
                    <ClientsContextProvider>
                        <AsyncComponent component={() => import("./content")} />
                    </ClientsContextProvider>
                </PageContent>
            </PageWrap>
        );
    }
}

export default ClientsPage;
