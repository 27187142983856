import Axios from "axios";

import { IBaseRecord, OnEditType } from "../interfaces/table-interfaces";
import { EditInputEvent, HandleCustomResponse, TableSetData } from "../interfaces";
import { unstable_batchedUpdates } from "react-dom";
import addToast from "../../../utils/addToast";

interface OnSubmitEdit<T> {
    rowData: T;
    e: EditInputEvent;
    url: string;
    params: any;
    refresh: boolean;
    hideSuccessToast: boolean;
    handleCustomResponse: HandleCustomResponse | undefined;
    urlInstanceId?: number;
    idSelector: string;
    setData?: TableSetData<T>;
    extraParametersToSet?: { [key: string]: any };
    onEdit?: OnEditType<T>;
    onReload?: (undefined: undefined, refresh: boolean) => void;
    reqParams?: IReqParams;
}

export const onSubmitEdit = async <T extends IBaseRecord>({
    rowData,
    e,
    url,
    params,
    refresh,
    hideSuccessToast,
    urlInstanceId,
    idSelector,
    extraParametersToSet,
    reqParams,
    handleCustomResponse,
    setData,
    onEdit,
    onReload,
}: OnSubmitEdit<T>) => {
    const { name, value } = e.target;
    let editData, editResponse;

    try {
        editResponse = await Axios.patch(`${url}/${urlInstanceId || rowData.id}`, {
            ...params,
            [name]: !!value || value === false || value === 0 ? value : value === "" ? "" : null,
            reqParams,
        });
        editData = editResponse.data.data;

        // !hideSuccessToast && addToast({ title: "Data has been edited." });
    } catch (error: any) {
        const { message } = error?.response;
        const defaultMessage = "Something went wrong while editing data.";
        addToast({ color: "danger", title: message ? message : defaultMessage });

        if (error.response?.status == "403") return error.response.data;
        return error.response?.status;
    }

    if (!refresh) {
        unstable_batchedUpdates(async () => {
            setData &&
                setData((prev) => {
                    const _prev = JSON.parse(JSON.stringify(prev));

                    return {
                        ..._prev,
                        data: _prev.data.map((r) => {
                            if (r[idSelector] == rowData[idSelector]) {
                                if (handleCustomResponse) return handleCustomResponse(editData, r);
                                return { ...editData, ...extraParametersToSet };
                            }
                            return r;
                        }),
                    };
                });
        });
    } else {
        onReload && (await onReload(undefined, true));
    }
    onEdit && (await onEdit({ target: { name, value }, extraParametersToSet }, { ...editData, ...extraParametersToSet }));

    return editResponse;
};

export const onClientSideEdit = async <T extends IBaseRecord>(
    rowData: any,
    e: EditInputEvent,
    idSelector: string,
    setData?: TableSetData<T>,
    onEdit?: OnEditType<T>
) => {
    const { name, value } = e.target;

    if (value == rowData[name] || ((rowData[name] == null || rowData[name] == undefined) && !value)) return undefined;

    unstable_batchedUpdates(() => {
        const newRow = { ...rowData, [name]: value };
        setData &&
            setData((prev) => {
                const _prev = JSON.parse(JSON.stringify(prev));

                return {
                    ..._prev,
                    data: _prev.data.map((r) => (r[idSelector] == rowData[idSelector] ? { ...r, ...newRow } : r)),
                };
            });
        onEdit && onEdit({ target: { name, value } }, newRow);
    });

    return { status: 200 };
};
