import React, { createContext, useContext, useState } from "react";
import { ITableIntegratedSystem, ITableTestMap, ITableLaboratoryMap } from "../interfaces/interface";

export const IntegratedSystemsContext = createContext<any>(null);

export const IntegratedSystemsProvider = ({ children }) => {
    const [selectedIntegratedSystems, setSelectedIntegratedSystems] = useState<ITableIntegratedSystem[]>([]);
    const [selectedTestMaps, setSelectedTestMaps] = useState<ITableTestMap[]>([]);
    const [selectedLaboratoryMaps, setSelectedLaboratoryMaps] = useState<ITableLaboratoryMap[]>([]);

    return (
        <IntegratedSystemsContext.Provider
            value={{
                selectedIntegratedSystems,
                setSelectedIntegratedSystems,
                selectedTestMaps,
                setSelectedTestMaps,
                selectedLaboratoryMaps,
                setSelectedLaboratoryMaps,
            }}
        >
            {children}
        </IntegratedSystemsContext.Provider>
    );
};

export const useIntegratedSystems = (): IUseIntegratedSystems => {
    const {
        selectedIntegratedSystems,
        setSelectedIntegratedSystems,
        selectedTestMaps,
        setSelectedTestMaps,
        selectedLaboratoryMaps,
        setSelectedLaboratoryMaps,
    } = useContext(IntegratedSystemsContext);

    return {
        selectedIntegratedSystems,
        setSelectedIntegratedSystems,
        selectedTestMaps,
        setSelectedTestMaps,
        selectedLaboratoryMaps,
        setSelectedLaboratoryMaps,
    };
};

interface IUseIntegratedSystems {
    selectedIntegratedSystems: ITableIntegratedSystem[];
    setSelectedIntegratedSystems: (arg: ITableIntegratedSystem[]) => void;
    selectedTestMaps: ITableTestMap[];
    setSelectedTestMaps: (arg: ITableTestMap[]) => void;
    selectedLaboratoryMaps: ITableLaboratoryMap[];
    setSelectedLaboratoryMaps: (arg: ITableLaboratoryMap[]) => void;
}
