import React, { createContext, useContext, useState } from "react";

import { PreviewTabValuesType, previewTabs } from "../utils/preview-tabs";
import { ISample } from "../../ReleaseOrders/interfaces/interfaces";
import { orderModes, OrderModesTypes } from "../../utils/modes";
import { IOrder } from "../../interface";

export const OrderPreviewContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.preview;
export const OrderPreviewContextProvider = ({ children }) => {
    const [selectedOrders, setSelectedOrders] = useState<IOrder[]>([]);
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);

    const [refreshOrdersData, setRefreshOrdersData] = useState<Function | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState<PreviewTabValuesType>(previewTabs.samples);

    return (
        <OrderPreviewContext.Provider
            value={{
                selectedOrders,
                setSelectedOrders,
                selectedSamples,
                setSelectedSamples,
                selectedTab,
                setSelectedTab,
                mode,
                refreshOrdersData,
                setRefreshOrdersData,
            }}
        >
            {children}
        </OrderPreviewContext.Provider>
    );
};

export const useOrderPreview = (): IUseOrderPreview => {
    const {
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        selectedTab,
        setSelectedTab,
        mode,
        refreshOrdersData,
        setRefreshOrdersData,
    } = useContext(OrderPreviewContext);

    return {
        selectedOrders,
        setSelectedOrders,
        selectedSamples,
        setSelectedSamples,
        selectedTab,
        setSelectedTab,
        mode,
        refreshOrdersData,
        setRefreshOrdersData,
    };
};

interface IUseOrderPreview {
    selectedOrders: IOrder[];
    setSelectedOrders: (arg: IOrder[]) => void;
    selectedSamples: ISample[];
    setSelectedSamples: (arg: ISample[]) => void;
    selectedTab: PreviewTabValuesType;
    setSelectedTab: (arg: PreviewTabValuesType) => void;
    mode: "preview";
    refreshOrdersData: Function;
    setRefreshOrdersData: Function;
}
