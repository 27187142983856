import Axios from "axios";

export const getSelectChoices = async (lookupId) => {
    if (!lookupId) return [];
    try {
        const response = await Axios.get(`bs/lookups/lookup-fields/${lookupId}`);
        return response.data;
    } catch (err: any) {
        console.error(err);
        return [];
    }
};
