import { SelectButton } from "primereact/selectbutton";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";

import hasPermission from "../../utils/hasPermissions";

import "./style.scss";

interface ICustomModalSelectButton {
    value: string;
    options: ILabelValue[];
    onChange: (e: any) => void;
    permCode?: string;
    className?: string;
}

export const CustomModalSelectButton = ({ options, value, onChange, permCode, className = "" }: ICustomModalSelectButton) => {
    const { t } = useTranslation();
    const _options = useMemo(() => options.map((option) => ({ ...option, label: t(option.label) })), [options]);

    if (permCode && !hasPermission(permCode)) return <></>;
    return <SelectButton className={`${className}`} value={value} options={_options} onChange={onChange} />;
};
