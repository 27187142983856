import React, { useEffect, useState } from "react";

import axios from "axios";
import { useDebounce } from "../../../hooks/useDebounce";
import { ISearchData, ISearchResult } from "../interface";

interface IUseGlobalSearch {
    input: string;
    condition?: boolean;
    triggerValues?: any[];
    debounceDelay?: number;
}

interface IUseGlobalSearchResults {
    results: ISearchData;
    isLoading: boolean;
    slicedResults: ISearchData;
}

export const useGlobalSearch = ({
    triggerValues,
    input,
    condition = true,
    debounceDelay = 700,
}: IUseGlobalSearch): IUseGlobalSearchResults => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [results, setResults] = useState<ISearchResult[]>([]);

    const debounceValue = useDebounce(input, debounceDelay);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const getData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get("transactional-search", {
                    params: {
                        q: input,
                    },
                    cancelToken: source.token,
                });
                setResults(response.data);
            } catch (err: any) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        condition && input && getData();

        if (!input) setResults([]);

        return () => source.cancel();
    }, [...(triggerValues || []), debounceValue]);

    return { slicedResults: mapSearchData(results, 5), isLoading, results: mapSearchData(results) };
};

const mapSearchData = (searchData: ISearchResult[], resultsToDisplay?: number): ISearchData =>
    searchData.reduce((acc, result) => {
        if (!resultsToDisplay || acc?.[result.model]?.length < resultsToDisplay || !acc?.[result.model])
            acc[result.model] = [...(acc[result.model] || []), result];
        return acc;
    }, {}) as ISearchData;
