import React from "react";

import { FONT_AWESOME_ICONS } from "components/gt-icon-picker/utils/font-awesome-icons";
import { GT_ICONS } from "components/gt-icon-picker/utils/gt-icons";
import { EditSelectInput } from "./edit-select-input";
import Icon from "components/icon";

export const EditIconInput = (props) => {
    return (
        <EditSelectInput
            {...props}
            itemTemplate={(option) => <ItemTemplate label={option?.label} value={option?.value} type={option?.type} />}
            valueTemplate={(option) => <ValueTemplate label={option?.label} value={option?.value} type={option?.type} />}
            inputData={{ ...props.inputData, selectChoices: [...FONT_AWESOME_ICONS, ...GT_ICONS] }}
            panelClassName={"gt-cell-icon-picker"}
            editParamsBeforeChange={(_, e) => {
                const { name, value } = e.target;
                const type = (() => {
                    if (name == "icon") {
                        if (FONT_AWESOME_ICONS.find((icon) => icon.value == value)) return "fas";
                        else return "gt";
                    }
                })();

                return { type };
            }}
        />
    );
};

const ItemTemplate = ({ label, value, type }) => {
    return <Icon className="gt-cell-icon-picker-icon" name={type ? [type, value] : value} />;
};

const ValueTemplate = ({ label, value, type }) => {
    return <Icon className="gt-cell-icon-picker-icon" name={type ? [type, value] : value} />;
};
