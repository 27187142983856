import { useTranslation } from "react-i18next";
import React from "react";

import { AddSamplingPlanModal, EditSamplingPlanModal } from "../../../modals/addEditSamplingPlanModal/add-edit-sampling-plan-modal";

import { PrimeDataTable } from "components/prime-data-table/tables/prime-data-table";
import { mapToIds } from "components/prime-data-table/helpers/primeHelpers";
import { StepDropDown } from "../../../../../../../components/step-drop-down/step-drop-down";
import { handleDeleteTableData } from "../../../../../../../utils/handleDeleteTableData";
import CustomButton from "../../../../../../../components/custom-button/custom-button";
import { useGetModelFormData } from "../../../../../../../hooks/useGetModelFormData";
import { useSamplingPlans } from "../../../../context/sampling-plans-context";
import { samplingPlansUrls } from "../../../../utils/sampling-plans-urls";
import { ISamplingPlanTable } from "../../../../interfaces/interfaces";
import { useResolved } from "../../../../../../../hooks/useResolved";
import { useFetchTableData } from "hooks/useFetchTableData";
import { samplingPlansColumns } from "./sampling-plans-columns";
import { yearsOptions } from "../../../../utils/helpers";

interface ISamplingPlansFormData {
    status: ILabelValue[];
    year: ILabelValue<number>[];
}

export const SamplingPlansTable = () => {
    const { t } = useTranslation();
    const { selectedSamplingPlans, setSelectedSamplingPlans } = useSamplingPlans();

    const { data, setData, refreshData, handleReload } = useFetchTableData<ISamplingPlanTable>({
        url: samplingPlansUrls.samplingPlans(),
        additionalParams: "&search_field=isExternal&search=False",
    });

    const { formData } = useGetModelFormData<ISamplingPlansFormData>({
        options: { status: { app: "client", model: "SamplingPlan" } },
        mapFormData: (data) => ({
            ...data,
            status: data.status.map((o) => ({ ...o, label: t(o.label) })),
            year: yearsOptions,
        }),
    });

    const handleAddRefresh = () => {
        setSelectedSamplingPlans([]);
        refreshData();
    };

    const handleDelete = () =>
        handleDeleteTableData(samplingPlansUrls.samplingPlans(), "Sampling plan", mapToIds(selectedSamplingPlans), refreshData);

    return (
        <PrimeDataTable
            tableName="sampling_plans-table"
            dataSet={data?.data}
            meta={data?.meta}
            selectedRecords={selectedSamplingPlans}
            onSelect={setSelectedSamplingPlans}
            onReload={handleReload}
            columns={samplingPlansColumns}
            selectChoices={formData}
        >
            <AddSamplingPlanModal refresh={handleAddRefresh} />
            <EditSamplingPlanModal
                disabled={selectedSamplingPlans.length != 1 || selectedSamplingPlans[0]?.status != "draft"}
                editedRecord={selectedSamplingPlans[0]}
                dataSet={data?.data}
                setDataSet={(newDataSet) => setData({ ...data, data: newDataSet })}
            />
            <CustomButton
                disabled={selectedSamplingPlans.length == 0 || selectedSamplingPlans[0]?.status != "draft"}
                text="Delete"
                icon="trash"
                color="danger"
                onClick={handleDelete}
                permCode="delete.crm::sampling-plans.main.delete"
            />
            <StepDropDown
                permCode="change.crm::sampling-plans.main.change"
                refreshData={refreshData}
                options={selectedSamplingPlans[0]?.workflowStep.nextSteps}
                url={samplingPlansUrls.samplingPlans(selectedSamplingPlans[0]?.id)}
            />
        </PrimeDataTable>
    );
};
