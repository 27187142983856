import React from "react";

import {
    AddExternalSamplingPlanModal,
    EditExternalSamplingPlanModal,
} from "../../../modals/addEditExternalSamplingPlanModal/add-edit-sampling-plan-modal";
import { PrimeDataTable } from "components/prime-data-table/tables/prime-data-table";
import { useSamplingPlans } from "../../../../context/sampling-plans-context";
import { mapToIds } from "components/prime-data-table/helpers/primeHelpers";
import { samplingPlansUrls } from "../../../../utils/sampling-plans-urls";
import { StepDropDown } from "components/step-drop-down/step-drop-down";
import { externalSamplingPlansColumns } from "./sampling-plans-columns";
import { ISamplingPlanTable } from "../../../../interfaces/interfaces";
import { handleDeleteTableData } from "utils/handleDeleteTableData";
import CustomButton from "components/custom-button/custom-button";
import { useGetModelFormData } from "hooks/useGetModelFormData";
import { useFetchTableData } from "hooks/useFetchTableData";
import { yearsOptions } from "../../../../utils/helpers";

interface IExternalSamplingPlansFormData {
    status: ILabelValue[];
    year: ILabelValue<number>[];
}

export const ExternalSamplingPlansTable = () => {
    const { selectedExternalSamplingPlans, setSelectedExternalSamplingPlans } = useSamplingPlans();

    const { data, setData, refreshData, handleReload } = useFetchTableData<ISamplingPlanTable>({
        url: samplingPlansUrls.samplingPlans(),
        additionalParams: "&search_field=isExternal&search=True",
    });

    const { formData } = useGetModelFormData<IExternalSamplingPlansFormData>({
        options: { status: { app: "client", model: "SamplingPlan" } },
        mapFormData: (data) => ({
            ...data,
            year: yearsOptions,
        }),
    });

    const handleAddRefresh = () => {
        setSelectedExternalSamplingPlans([]);
        refreshData();
    };

    const handleDelete = () =>
        handleDeleteTableData(samplingPlansUrls.samplingPlans(), "Sampling plan", mapToIds(selectedExternalSamplingPlans), refreshData);

    return (
        <PrimeDataTable
            tableName="external-sampling-plans-table"
            dataSet={data?.data}
            meta={data?.meta}
            selectedRecords={selectedExternalSamplingPlans}
            onSelect={setSelectedExternalSamplingPlans}
            onReload={handleReload}
            columns={externalSamplingPlansColumns}
            selectChoices={formData}
        >
            <AddExternalSamplingPlanModal refreshData={handleAddRefresh} />
            <EditExternalSamplingPlanModal
                disabled={selectedExternalSamplingPlans.length != 1 || selectedExternalSamplingPlans[0]?.status != "draft"}
                editedRecord={selectedExternalSamplingPlans[0]}
                dataSet={data?.data}
                setDataSet={(newDataSet) => setData({ ...data, data: newDataSet })}
            />
            <CustomButton
                disabled={selectedExternalSamplingPlans.length == 0 || selectedExternalSamplingPlans[0]?.status != "draft"}
                text="Delete"
                icon="trash"
                color="danger"
                onClick={handleDelete}
                permCode="delete.crm::sampling-plans.external-sampling-plans.delete"
            />
            <StepDropDown
                permCode="change.crm::sampling-plans.external-sampling-plans.change"
                refreshData={refreshData}
                options={selectedExternalSamplingPlans[0]?.workflowStep.nextSteps}
                url={samplingPlansUrls.samplingPlans(selectedExternalSamplingPlans[0]?.id)}
            />
        </PrimeDataTable>
    );
};
