import React, { createContext, useContext, useRef, useState } from "react";

import { useGetModelFormData } from "../../../../hooks/useGetModelFormData";
import { OfferAppTabValuesType, offerAppTabs } from "../utils/offer-tabs";
import { IOffer } from "../interfaces/interfaces";
import { useTranslation } from "react-i18next";

export const MainContext = createContext<any>(null);

export const MainProvider = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState<OfferAppTabValuesType>(offerAppTabs.contracts);
    const [selectedClient, setSelectedClient] = useState<number | undefined>(undefined);
    const [selectedMainRecord, setSelectedMainRecord] = useState<IOffer[]>([]); // TODO! dodać workflow do contracts | IContract[]

    const refreshSamplesRef = useRef<{ refreshSamples: () => void }>(null);
    const refreshMainDataRef = useRef<{ refreshMainData: () => void }>(null);

    const { t } = useTranslation();

    const { formData: workflowStatuses } = useGetModelFormData<any>({
        options: { status: { app: "client", model: "Deal" } },
        mapFormData: (data) => data.status.map((o) => ({ ...o, label: t(o.label) })),
    });

    return (
        <MainContext.Provider
            value={{
                selectedTab,
                setSelectedTab,
                selectedClient,
                setSelectedClient,
                selectedMainRecord,
                setSelectedMainRecord,
                refreshMainData: refreshMainDataRef?.current?.refreshMainData,
                refreshSamplesRef,
                refreshMainDataRef,
                refreshSamples: refreshSamplesRef?.current?.refreshSamples,
                editableMainRecord: selectedMainRecord[0]?.workflowStep?.editableData,
                workflowStatuses,
            }}
        >
            {children}
        </MainContext.Provider>
    );
};

export const useMainContext = (): IUseMainContext => {
    const {
        selectedTab,
        setSelectedTab,
        selectedClient,
        setSelectedClient,
        selectedMainRecord,
        setSelectedMainRecord,
        refreshMainData,
        refreshSamples,
        refreshSamplesRef,
        refreshMainDataRef,
        editableMainRecord,
        workflowStatuses,
    } = useContext(MainContext);

    return {
        selectedTab,
        setSelectedTab,
        selectedClient,
        setSelectedClient,
        selectedMainRecord,
        setSelectedMainRecord,
        refreshMainData,
        refreshSamples,
        refreshSamplesRef,
        refreshMainDataRef,
        editableMainRecord,
        workflowStatuses,
    };
};

interface IUseMainContext {
    selectedTab: OfferAppTabValuesType;
    setSelectedTab: (arg: OfferAppTabValuesType) => void;
    selectedClient: number | undefined;
    setSelectedClient: (arg: number) => void;
    selectedMainRecord: IOffer[]; // TODO! dodać workflow do contracts | IContract[]
    setSelectedMainRecord: (arg: IOffer[]) => void;
    refreshSamples: () => void;
    refreshMainData: () => void;
    refreshSamplesRef: React.RefObject<{ refreshSamples: () => void }>;
    refreshMainDataRef: React.RefObject<{ refreshMainData: () => void }>;
    editableMainRecord: boolean;
    workflowStatuses: ILabelValue<number>[];
}
