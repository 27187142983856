import React, { ReactElement, ReactNode } from "react";

import { MultiSelect, MultiSelectAppendToType, MultiSelectChangeParams } from "primereact/multiselect";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import SuperscriptDisplay from "../superscript/superscript-display";
import LabelError from "../label-error/label-error";
import { sortObj } from "utils/sortObj";

import "./styles.scss";
import { RequiredLabel } from "components/custom-modal-elements/required-label";

interface IPrimeMultiSelectProps {
    children?: ReactElement;
    rowClassName?: string;
    className?: string;
    labelClassName?: string;
    value?: string[] | number[];
    name?: string;
    options?: any[] | null;
    optionLabel?: string;
    optionValue?: string;
    label?: string;
    labelSm?: number;
    colSm?: number;
    placeholder?: string;
    maxSelectedLabels?: number;
    filter?: boolean;
    isClearable?: boolean;
    filterBy?: string;
    superscript?: boolean;
    disabled?: boolean;
    appendTo?: MultiSelectAppendToType;
    errors?: string | string[];
    AddModal?: any;
    required?: boolean;
    optionDisabled?: string | ((option: any) => boolean);
    translateOptions?: boolean;
    sortBy?: { key: string; ascending?: boolean }; // sort by key in options, default descending
    onChange?: (e: MultiSelectChangeParams) => void;
    optionTemplate?: (option: ILabelValue) => ReactNode;
}

export const PrimeMultiSelect = (props: IPrimeMultiSelectProps) => {
    const { t } = useTranslation();

    const {
        children,
        rowClassName = "",
        className = "",
        labelClassName = "",
        name = undefined,
        optionLabel = "label",
        optionValue = "value",
        label = "",
        labelSm = 3,
        colSm,
        placeholder = undefined,
        maxSelectedLabels = 3,
        filter = false,
        isClearable = true,
        filterBy = undefined,
        superscript = false,
        disabled = false,
        appendTo = undefined,
        errors = undefined,
        required = false,
        sortBy,
        optionDisabled,
        translateOptions,
        onChange = undefined,
        optionTemplate = undefined,
    } = props;

    let { value = undefined, options } = props;

    if (!options) options = [];

    if (superscript) {
        options = options.map((o, i) => ({
            [optionLabel]: (
                <div>
                    <SuperscriptDisplay key={`opt-${i}`} value={o[optionLabel]} />
                </div>
            ),
            stringValue: o[optionLabel].replace(/[↑↓→←]/gi, ""), // '↑', '↓', '→', '←'
            [optionValue]: o[optionValue],
        }));
    }

    const translateErrorString = (errorStrings: string | string[]) => {
        let result = [] as any;

        if (typeof errorStrings == "string") {
            return t(errorStrings);
        }

        const regexList = [
            /^Ensure this field has no more than (\d+) characters.$/,
            /^Ensure that there are no more than (\d+) digits in total.$/,
            /^Ensure that there are no more than (\d+) digits before the decimal point.$/,
            /^Ensure that there are no more than (\d+) decimal places.$/,
            /Range is overlaping with (.*) and (.*)/,
        ];

        const translationList = [
            "Ensure this field has no more than {{count}} characters",
            "Ensure that there are no more than {{count}} digits in total",
            "Ensure that there are no more than {{count}} digits before the decimal point",
            "Ensure that there are no more than {{count}} decimal places",
            "Range is overlaping with {{substring}} and {{substring_2}}",
        ];

        let errorMatch;

        for (let errorString of errorStrings) {
            for (let i = 0; i < regexList.length; i++) {
                errorMatch = errorString.match(regexList[i]);
                if (errorMatch) {
                    result.push(
                        t(translationList[i], { count: Number(errorMatch[1]), substring: errorMatch[1], substring_2: errorMatch?.[2] })
                    );

                    break;
                }
            }

            if (!errorMatch) result.push(t(errorString));
        }

        return result.join(". ");
    };

    if (translateOptions)
        options = options.map((o) => ({
            ...o,
            label: t(o.label),
        }));

    options = sortBy ? options?.sort((a, b) => sortObj(a, b, sortBy.key, sortBy.ascending)) : options;

    //? There is a bug appearing when opening menu, component focuses the document and wants to bring it to view. That's why it scrolls it to the right.
    //? The bug is also known in Material UI and this workaround comes from it
    const preventScrolling = (e) => {
        window.scrollTo({ top: 0, left: 0 });
    };

    return (
        <FormGroup row className={rowClassName}>
            {label && (
                <Label className={`${labelClassName}`} sm={labelSm}>
                    {t(label)}:{required && <RequiredLabel />}
                </Label>
            )}
            <Col sm={colSm ? colSm : label ? 9 : 12}>
                <MultiSelect
                    className={`prime-multi-select ${className}`}
                    name={name}
                    disabled={disabled}
                    value={value}
                    options={options}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    optionDisabled={optionDisabled}
                    selectedItemTemplate={(item) => {
                        const value = options?.find((option) => option[optionValue] == item)?.[optionLabel];

                        return value ? (
                            <div className="selected-item-template">{value}</div>
                        ) : (
                            <div className="superscript-placeholder">
                                {!!placeholder ? t(placeholder) : !!label ? `${t(label)}...` : `${t("Select")}...`}
                            </div>
                        );
                    }}
                    filterBy={filterBy ? filterBy : superscript ? "stringValue" : optionLabel}
                    itemTemplate={optionTemplate}
                    onChange={onChange}
                    onFocus={preventScrolling}
                    placeholder={!!placeholder ? t(placeholder) : !!label ? `${t(label)}...` : `${t("Select")}...`}
                    maxSelectedLabels={maxSelectedLabels}
                    filter={filter}
                    showClear={isClearable}
                    appendTo={appendTo}
                ></MultiSelect>
                {children}
                {errors && <LabelError id={name} error={translateErrorString(errors)} />}
            </Col>
        </FormGroup>
    );
};
