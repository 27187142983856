import React, { createContext, useContext, useState } from "react";
import { IMaterialTable } from "../../Materials/interfaces/interface";

export const BulkPackagingContext = createContext<any>(null);

export const BulkPackagingContextProvider = ({ children }) => {
    const [selectedBulkPackaging, setSelectedBulkPackaging] = useState<IMaterialTable[]>([]);

    return (
        <BulkPackagingContext.Provider value={{ selectedBulkPackaging, setSelectedBulkPackaging }}>
            {children}
        </BulkPackagingContext.Provider>
    );
};

export const useBulkPackaging = (): IUseBulkPackaging => {
    const { selectedBulkPackaging, setSelectedBulkPackaging } = useContext(BulkPackagingContext);

    return { selectedBulkPackaging, setSelectedBulkPackaging };
};

interface IUseBulkPackaging {
    selectedBulkPackaging: IMaterialTable[];
    setSelectedBulkPackaging: (arg: IMaterialTable[]) => void;
}
