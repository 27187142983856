import React, { RefObject, SyntheticEvent, useState } from "react";

import { createPortal } from "react-dom";
import { Form } from "reactstrap";

import CustomModalRequirement from "components/custom-modal-requirement/custom-modal-requirement";
import { IRequirements } from "pages/interfaces/IRequirements";
import requirementStatus from "utils/requirementStatus";

interface IReqParamsHOC {
    onSubmit: (e: SyntheticEvent, reqParams?: IReqParams) => void;
    children: any;
    permCode?: string;
    className?: string;
    formRef: RefObject<HTMLButtonElement>;
    onClose?: () => void;
}

export const ReqParamsHOC = ({ onSubmit, children, className = "w-100 d-flex flex-row", permCode, formRef, onClose }: IReqParamsHOC) => {
    if (!permCode) return <>{children}</>;
    const [toggleReq, setToggleReq] = useState<boolean>(false);
    const [requirements, setRequirements] = useState({} as IRequirements);

    const handleSubmit = async (e: SyntheticEvent, reqParams: IReqParams = {}) => {
        e.preventDefault();

        if (!permCode) await onSubmit(e, { ...reqParams, permCode });
        else {
            const req: IRequirements = requirementStatus(permCode);
            if (!req.requirePassword && !req.requireComment) {
                await onSubmit(e, { ...reqParams, permCode });
            } else {
                setToggleReq(true);
            }
            setRequirements(req);
        }
    };

    //! KEEP THE FRAGMENT THAT CONTAINS REQUIREMENTS MODAL
    // it prevents the error that form get wrong child type
    return (
        <Form onSubmit={handleSubmit} className={className}>
            <button className="d-none" type="submit" ref={formRef}></button>
            {children}
            <>
                {toggleReq &&
                    permCode &&
                    createPortal(
                        <CustomModalRequirement
                            toggleModal={setToggleReq}
                            isOpen={toggleReq}
                            requirements={requirements}
                            submit={onSubmit}
                            permCode={permCode}
                            onClose={onClose}
                        />,
                        document.body
                    )}
            </>
        </Form>
    );
};
