import React, { useEffect, useRef, useState } from "react";

import { createPortal } from "react-dom";

import CustomModalInput from "../../../custom-modal-elements/custom-modal-input";
import { COLORS_ARRAY } from "../../../../utils/colors-array";
import { IEditColorProps } from "components/prime-data-table/interfaces/edit-interfaces";

import "./edit-color-input.styles.scss";

export const EditColorInput = (props: IEditColorProps) => {
    const {
        inputData: { row, fieldName },
        inputEnabled = true,
        value = undefined,
    } = props;

    if (inputEnabled) return <EnabledInput {...props} />;

    return (
        <div
            className="color-input-placeholder"
            id="input-placeholder"
            key={`prime-input-text-${fieldName}-${row.id}`}
            style={{ backgroundColor: value }}
        ></div>
    );
};

const EnabledInput = (props: IEditColorProps) => {
    const {
        name = undefined,
        shouldDataSetRefresh: refresh = false,
        extraEditParams = {},
        editUrl = undefined,
        clientSideEdit = false,
        editParamsBeforeChange = undefined,
        extraParametersToSet = undefined,
        secondPartEditUrl,
    } = props;

    const { row, fieldName, handleEditSubmit } = props.inputData;

    const [color, setColor] = useState<string>(row[fieldName]);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const inputRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const _name = name || fieldName;

    useEffect(() => {
        const handleClickOutside = (e, menuRef, inputRef) =>
            !menuRef?.current?.contains(e.target) && !inputRef?.current?.contains(e.target) && setIsMenuOpen(false);

        document.addEventListener("click", (e) => handleClickOutside(e, menuRef, inputRef));

        return () => document.removeEventListener("click", (e) => handleClickOutside(e, menuRef, inputRef));
    }, []);

    useEffect(() => {
        setColor(row[fieldName]);
    }, [row[fieldName]]);

    const handlePickColor = async (color) => {
        let params = extraEditParams;

        const _e = { target: { name: _name, value: color } };

        editParamsBeforeChange && (params = editParamsBeforeChange(extraEditParams, _e));

        await handleEditSubmit({
            row: row,
            e: _e,
            secondPartEditUrl,
            extraColumnEditParams: params,
            refresh: refresh,
            editUrl,
            clientSideEdit: clientSideEdit,
            extraParametersToSet: extraParametersToSet,
        });

        setColor(color);
        setIsMenuOpen(false);
    };

    return (
        <div>
            {isMenuOpen &&
                createPortal(
                    <ColorsDropdown inputRef={inputRef} menuRef={menuRef} handlePickColor={handlePickColor} color={color} />,
                    document.body
                )}
            <div id="prime-color-input" key={`prime-color-input-${fieldName}-${row.id}`} ref={inputRef}>
                <span onClick={() => setIsMenuOpen((prev) => !prev)} className="color-input-button" style={{ backgroundColor: color }} />
            </div>
        </div>
    );
};

const ColorsDropdown = ({ inputRef, menuRef, handlePickColor, color }) => {
    const rect = inputRef.current.getBoundingClientRect();
    const [textInputColor, setTextInputColor] = useState<string>(color);

    return (
        <div className="color-input-menu" style={{ top: rect.top + 28, left: rect.left - 5 }} ref={menuRef}>
            <CustomModalInput
                value={textInputColor}
                handleChange={(e) => setTextInputColor(e.target.value)}
                handleKeyDown={(e) => {
                    if (e.key == "Enter") handlePickColor(textInputColor);
                }}
                name="color"
                className="color-text-input"
                noLabel
            />
            {COLORS_ARRAY.map((color, index) => (
                <div
                    key={index}
                    className="context-menu-item"
                    role="button"
                    onClick={() => handlePickColor(color)}
                    style={{ backgroundColor: color }}
                />
            ))}
        </div>
    );
};
