import React, { useEffect, useState } from "react";

import { Modal, ModalBody, Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import _ from "lodash";

import { IAddFromLookup } from "components/prime-data-table/interfaces/edit-interfaces";
import { isRichTextInputEmpty } from "../../../rich-text-input.tsx/rich-text-input";
import CustomModalHeader from "../../../custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../../custom-modal-elements/custom-modal-footer";
import { MemoAdvancedRemarkEditor } from "./components/advenced-remarks-editor";
import { MemoAdvancedRemarkTable } from "./components/advanced-remarks-table";
import { useGetModelFormData } from "../../../../hooks/useGetModelFormData";
import CustomForm from "../../../custom-form/custom-form";
import addToast from "../../../../utils/addToast";
interface IProps {
    handleCloseModal: () => void;
    modelName: string | undefined;
    rowId: any;
    addFromLookupProps?: IAddFromLookup;
    modalOpen: boolean;
    onReload: Function;
}

interface IFormData {
    content: string;
    type: string;
    isVisibleOnReport: boolean;
    isDefault: boolean;
}

interface ITypeOptions {
    type: ILabelValue[];
}

interface ICollapseData {
    isOpen: boolean;
    selectedPosition: string;
}

const ModalAdvancedRemarks = ({ handleCloseModal, modelName, rowId, addFromLookupProps = undefined, modalOpen, onReload }: IProps) => {
    const [formData, setFormData] = useState<IFormData>({ content: "", type: "internal", isVisibleOnReport: false, isDefault: false });
    const [collapseData, setCollapseData] = useState<ICollapseData>({ isOpen: false, selectedPosition: "" });

    const { t } = useTranslation();

    useEffect(() => {
        setFormData({ content: "", type: "internal", isVisibleOnReport: false, isDefault: false });
    }, [collapseData?.isOpen]);

    const { formData: typeOptions } = useGetModelFormData<ITypeOptions>({
        options: { type: { app: "advancedremark", model: "AdvancedRemark" } },
        mapFormData: (data) => ({
            ...data,
            type: data.type.map((o) => ({ ...o, label: t(`advancedRemarks>>${o.label}`) })),
        }),
    });

    const handleSetFormData = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        handleSetFormData(name, value);
    };

    const handleChangeCheckbox = (e) => {
        const { checked, name } = e.target;
        handleSetFormData(name, checked);
    };

    const handleSubmitData = async (e) => {
        e.preventDefault();
        if (!collapseData.isOpen) return handleCloseModal();
        try {
            await axios.post("/advanced-remarks", {
                [_.snakeCase(modelName)]: rowId,
                ...formData,
            });
            setCollapseData({ isOpen: false, selectedPosition: "" });
            onReload(undefined, true);
        } catch (err: any) {
            addToast({ title: "Something went wrong while adding remark", color: "danger" });
        }
    };

    const handleCloseButton = () => {
        if (collapseData.isOpen) {
            setCollapseData({ isOpen: false, selectedPosition: "" });
        } else {
            handleCloseModal();
        }
    };

    return (
        <Modal
            onContextMenu={(e) => e.stopPropagation()}
            isOpen={modalOpen}
            toggle={handleCloseModal}
            fade
            className="wide-modal-container-75 advanced-remark-modal"
            onClick={(e) => e.stopPropagation()}
        >
            <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData} className="h-100 d-flex flex-column">
                <CustomModalHeader closeModal={handleCloseModal} name="Remarks" />
                <ModalBody>
                    <Collapse isOpen={collapseData.isOpen}>
                        <MemoAdvancedRemarkEditor
                            handleChange={handleChange}
                            formData={formData}
                            handleChangeCheckbox={handleChangeCheckbox}
                            addFromLookupProps={addFromLookupProps}
                            collapseData={collapseData}
                            setCollapseData={setCollapseData}
                            handleSetFormData={handleSetFormData}
                            typeOptions={typeOptions}
                        />
                    </Collapse>
                    <Collapse isOpen={!collapseData?.isOpen}>
                        <MemoAdvancedRemarkTable
                            collapseData={collapseData}
                            modelName={modelName}
                            rowId={rowId}
                            typeOptions={typeOptions}
                            setCollapseData={setCollapseData}
                        />
                    </Collapse>
                </ModalBody>
                <CustomModalFooter
                    closeModal={handleCloseButton}
                    cancelName={collapseData.isOpen ? "Cancel" : "Close"}
                    okName={collapseData.isOpen ? "Add" : ""}
                    okIsDisabled={collapseData.isOpen && isRichTextInputEmpty(formData.content)}
                />
            </CustomForm>
        </Modal>
    );
};

export default React.memo(ModalAdvancedRemarks);
