import React from "react";

import { CheckboxInputPlaceholder } from "components/prime-data-table/body-components/checkbox-placeholder";
import SuperscriptDisplay from "components/superscript/superscript-display";
import { Body, EditBody, Filters, I } from "components/prime-data-table";
import { snakeToCamel } from "utils/snakeToCamel";

const isWithdrawn = (inputData: I.IPrimeRowData<I.IAdvancedRemark> | I.IPrimeEditData<I.IAdvancedRemark>) =>
    inputData.row?.isWithdrawn ? "withdrawn" : "";

export const ModalAdvancedRemarksColumns: I.IPrimeTableColumn[] = [
    {
        header: "Visible on report",
        field: "isVisibleOnReport",
        sortable: true,
        className: "checkbox-container",
        width: 50,
        filterBody: Filters.checkbox,
        //! somehow Body.checkbox does not work
        body: (rowData: I.IPrimeRowData<any>) => <CheckboxInputPlaceholder rowData={rowData} />,
    },
    {
        header: "advancedRemarks>>Default",
        field: "isDefault",
        sortable: true,
        className: "checkbox-container",
        filterBody: Filters.checkbox,
        body: (rowData: I.IPrimeRowData<any>) => <CheckboxInputPlaceholder rowData={rowData} />,
    },
    // {
    //     header: "Sorter",
    //     field: "sorter",
    //     sortable: true,
    //     filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} type="number" />,
    //     editBody: (inputData: IPrimeEditData<any>) => <EditTextInput inputData={inputData} type="number" />,
    // },
    {
        header: "Subject",
        field: "subject",
        sortable: true,
        filterBody: Filters.text,
    },
    {
        header: "Code",
        field: "sample.code",
        width: 140,
        body: (inputData: I.IPrimeRowData<I.IAdvancedRemark>) => (
            <span className={isWithdrawn(inputData)}>
                <SuperscriptDisplay
                    value={
                        inputData.row[snakeToCamel(inputData.row.level?.value)]?.code ||
                        inputData.row[snakeToCamel(inputData.row.level?.value)]?.name
                    }
                />
            </span>
        ),
    },
    {
        header: "Type",
        field: "type",
        sortable: true,
        width: 140,
        filterBody: Filters.select,
        editBody: (inputData: I.IPrimeEditData<I.IAdvancedRemark>) =>
            EditBody.select(inputData, {
                nextRecordOnEnter: false,
                inputEnabled: !inputData.row?.isWithdrawn,
                clearable: false,
                CustomPlaceholder: () => (
                    <span className={isWithdrawn(inputData)}>{inputData.translate(`advancedRemarks>>${inputData.row?.type}`)}</span>
                ),
            }),
    },
    {
        header: "Created by",
        field: "createdBy",
        sortable: true,
        width: 140,
        filterBody: Filters.date,
        body: (inputData: I.IPrimeRowData<I.IAdvancedRemark>) => (
            <span className={isWithdrawn(inputData)}>{inputData.row.createdBy?.fullName}</span>
        ),
    },
    {
        header: "Created date",
        field: "createdDt",
        sortable: true,
        width: 140,
        filterBody: Filters.date,
        body: (inputData: I.IPrimeRowData<I.IAdvancedRemark>) => <span className={isWithdrawn(inputData)}>{inputData.row.createdDt}</span>,
    },
    {
        header: "Registration level",
        field: "level.label",
        filterFieldName: "level",
        sortFieldName: "level",
        grouped: true,
        width: 80,
        body: (inputData: I.IPrimeRowData<I.IAdvancedRemark>) => (
            <span className={isWithdrawn(inputData)}>{inputData.translate(inputData.row.level?.label)}</span>
        ),
    },
];
