import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { PrimeSelect } from "../../../prime-select/prime-select";
import { sweetConfirm } from "../../../sweet-alert/sweetConfirm";
import CustomButton from "../../../custom-button/custom-button";
import { deprecated_useGetData } from "../../../../hooks/useGetData";

import "./add-from-lookup.scss";

interface IAddFromLookupProps {
    lookupName: string;
    value?: string;
    permCode: string;
    formDataRefresh?: Function;
    disableButtons?: boolean;
    handleChange?: (e: any) => void;
    editLookupButtons?: boolean;
    selectedPosition: string;
    setCollapseData: (arg: any) => void;
}

export const AddFromLookup = ({
    lookupName,
    value,
    permCode,
    formDataRefresh,
    handleChange,
    editLookupButtons = false,
    selectedPosition,
    setCollapseData,
}: IAddFromLookupProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | undefined>(undefined);

    const { data, refreshData } = deprecated_useGetData<any>({
        url: `bs/lookups/lookup-fields/${lookupName}`,
    });

    const handleAddPosition = async () => {
        try {
            let response = await Axios.post("bs/lookups/lookup-fields", {
                newLookup: { lookupName, value: value, label: value },
            });
            refreshData();
            formDataRefresh && formDataRefresh();
            setCollapseData((prev) => ({ ...prev, selectedPosition: response.data.value }));
        } catch (e: any) {
            let errorMess: string = e.response.data?.nonFieldErrors?.[0];
            errorMess && setError(t(errorMess));
        }
    };

    const handleEditPosition = async () => {
        try {
            const position = data?.data?.find((o) => o.value == selectedPosition);
            const response = await Axios.patch(`bs/lookups/lookup-fields/${position.id}`, {
                value: value,
                label: value,
            });

            refreshData();

            setCollapseData((prev) => ({ ...prev, selectedPosition: response.data.value }));
        } catch {}
    };

    const handleDeletePosition = async () => {
        try {
            if (!(await sweetConfirm("Delete", "Do you want to delete dictionary position?"))) return;

            const positionId = data?.data?.find((o) => o.value == selectedPosition)?.id;
            await Axios.delete("bs/lookups/lookup-fields", { data: { ids: [positionId] } });
            refreshData();
            setCollapseData((prev) => ({ ...prev, selectedPosition: "" }));
        } catch {}
    };

    const handleSelected = (e) => {
        handleChange && handleChange({ target: { value: e.target.value, name: "content" } });
        setCollapseData((prev) => ({ ...prev, selectedPosition: e.target.value }));

        error && setError(undefined);
    };

    const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        handleChange && handleChange({ target: { value: e.target.value, name: "content" } });
        error && setError(undefined);
    };

    return (
        <div className="add-from-lookup--container">
            <div className="select-container">
                <label>{t("Dictionary position")}:</label>
                <PrimeSelect
                    placeholder={t("Select dictionary position...")}
                    filterEnabled
                    showClear
                    className="prime-select"
                    panelClassName="mt-5"
                    emptyMessage="No options"
                    onChange={handleSelected}
                    value={selectedPosition}
                    options={data?.data}
                    disabled={data?.data?.length == 0}
                />
            </div>

            <textarea
                id="prime-input-text"
                value={value}
                name="position"
                onChange={handleTextareaChange}
                rows={4}
                className="modal-textarea"
                placeholder={t("Add remark by choosing dictionary position or type in new one...")}
            />

            {error && <div className="error-message">{t(error)}</div>}

            {editLookupButtons && (
                <div className="button-container">
                    <CustomButton
                        text="Add dictionary position"
                        icon="plus"
                        onClick={handleAddPosition}
                        permCode={permCode}
                        disabled={!value?.trim()}
                    />
                    <CustomButton
                        text="Edit"
                        icon="edit"
                        onClick={handleEditPosition}
                        disabled={!selectedPosition || !value?.trim()}
                        permCode={permCode}
                    />
                    <CustomButton
                        text="Delete"
                        color="danger"
                        icon="trash"
                        onClick={handleDeletePosition}
                        disabled={!selectedPosition}
                        permCode={permCode}
                    />
                </div>
            )}
        </div>
    );
};
