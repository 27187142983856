import { TreeExpandedKeysType, TreeExpandedParams, TreeSelectionModeType } from "primereact/tree";
import { ContextMenu } from "primereact/contextmenu";
import { MenuItem } from "primereact/menuitem";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Axios from "axios";

import { IFilterConfig, ISelectedContextNodeEvent, ISelectedNodes, IMenuItemWithModal, IMenuItem } from "../../interfaces/tree-interfaces";
import { sweetConfirm } from "../../../sweet-alert/sweetConfirm";
import addToast from "../../../../utils/addToast";

import "./styles.scss";

interface IPrimeTreeContextMenu {
    disableAdd: boolean;
    contextMenuRef: any;
    //carrying information
    url: string;
    filterConfig: IFilterConfig;
    selectedNodes?: ISelectedNodes;
    expandedKeys: TreeExpandedKeysType;
    selectedContextNodeKey?: number;
    //handlers
    onSelectedNodes?: (value: ISelectedNodes) => void;
    handleToggleFilter: () => void;
    handleSelectedContextNodeKey: (e?: ISelectedContextNodeEvent) => void;
    handleToggleNodes: (e?: TreeExpandedParams) => void;
    handleExpandSelected: () => void;
    //modals
    refreshData: Function;
    entityName?: string;
    deleteNodeEnabled: boolean;
    contextMenuItem: IMenuItem[];
    contextModalItem?: IMenuItemWithModal[];
    selectionMode: TreeSelectionModeType;
}

interface IOpenModals {
    [key: string]: boolean;
}

export const PrimeTreeContextMenu = (props: IPrimeTreeContextMenu) => {
    const {
        disableAdd,
        contextMenuRef,
        //carrying information
        url,
        filterConfig,
        selectedNodes,
        expandedKeys,
        selectedContextNodeKey,
        //handlers
        handleToggleFilter,
        handleSelectedContextNodeKey,
        onSelectedNodes,
        handleToggleNodes,
        handleExpandSelected,
        //add modal
        refreshData,
        entityName,
        contextMenuItem = [],
        contextModalItem = [],
        selectionMode,
        // disable
        deleteNodeEnabled = true,
    } = props;

    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState<IOpenModals | undefined>(undefined);

    const handleCloseModal = () => {
        setOpenModal(undefined);
        handleSelectedContextNodeKey();
    };

    const mapModalItem = (items: IMenuItemWithModal[] | undefined): MenuItem[] | undefined => {
        if (!items || items.length == 0) return undefined;
        return items.map((item) => {
            return {
                label: t(item.label || ""),
                icon: item.icon ? item.icon : "pi pi-fw pi-circle-off",
                command: () => setOpenModal({ [item.modalKey]: true }),
                items: mapModalItem(item.items),
                disabled: !!item.disabled || selectedContextNodeKey == 0,
            };
        });
    };

    const mapItem = (items: MenuItem[] | undefined): MenuItem[] | undefined =>
        items?.map((item) => {
            return { ...item, label: t(item?.label || ""), items: item.items ? mapItem(item.items) : [] };
        });

    const contextMenu: MenuItem[] = [
        {
            label: t("Show filter"),
            icon: "pi pi-fw pi-filter",
            command: () => handleToggleFilter(),
            disabled: !filterConfig.contextItemEnabled || filterConfig.enabled,
        },
        {
            label: t("Hide filter"),
            icon: "pi pi-fw pi-filter-slash",
            command: () => handleToggleFilter(),
            disabled: !filterConfig.enabled,
        },
        ...contextModalItem.map(
            (item): MenuItem => ({
                label: t(item.label || ""),
                icon: item.icon ? item.icon : "pi pi-fw pi-circle-off",
                command: () => setOpenModal({ [item.modalKey]: true }),
                items: mapModalItem(item.items),
                disabled:
                    !!item.disabled ||
                    (!item.addModal && selectedContextNodeKey == 0) ||
                    (item.addModal && disableAdd && selectedContextNodeKey == 0),
            })
        ),
        {
            label: t(`Delete ${entityName}`),
            icon: "pi pi-fw pi-trash",
            command: () => onDelete(),
            disabled: !entityName || selectedContextNodeKey == 0 || !deleteNodeEnabled,
        },
        {
            label: t("Expand selected"),
            icon: "pi pi-fw pi-chevron-down",
            command: () => handleExpandSelected(),
            disabled: !selectedNodes || Object.keys(selectedNodes).length == 0 || selectionMode != "checkbox",
        },
        {
            label: t("Collapse all"),
            icon: "pi pi-fw pi-chevron-up",
            command: () => handleToggleNodes(),
            disabled: Object.keys(expandedKeys).length < 2,
        },
        {
            label: t("Unselect all"),
            icon: "pi pi-fw pi-times",
            command: () => onSelectedNodes && onSelectedNodes({}),
            disabled: !selectedNodes || !onSelectedNodes || Object.keys(selectedNodes).length == 0 || selectionMode == "single",
        },
        ...contextMenuItem.map((item) => ({
            ...item,
            command: (e) => (item.command ? item.command(e, selectedContextNodeKey, refreshData) : () => {}),
            label: t(item?.label || ""),
            items: mapItem(item.items),
        })),
    ];

    const onDelete = () => handleDeleteNode();

    const handleDeleteNode = async () => {
        if (!selectedContextNodeKey || !(await sweetConfirm("Delete", { text: `Do you want to delete ${entityName}(s)?`, count: 1 })))
            return;

        try {
            await Axios.delete(url, { data: { ids: [selectedContextNodeKey] } });
            refreshData();

            const _selectedNodes = JSON.parse(JSON.stringify(selectedNodes));
            delete _selectedNodes[selectedContextNodeKey];

            onSelectedNodes && onSelectedNodes(_selectedNodes);

            addToast({ title: `${entityName?.[0].toUpperCase() + (entityName as string).substring(1)} has been deleted.` });
        } catch (err: any) {
            if (err?.response?.status == "403") return err.response.data;
            return err?.response?.status;
        }
    };

    const getModalItem = (items: IMenuItemWithModal[] | undefined, openModal: IOpenModals): IMenuItemWithModal | undefined => {
        if (!items || items.length == 0) return undefined;
        let _item: IMenuItemWithModal | undefined = undefined;

        items.forEach((item) => {
            if (!!_item) return _item;
            else if (item.modalKey == Object.keys(openModal)[0]) _item = item;
            else _item = getModalItem(item.items, openModal);
        });

        return _item;
    };

    if (contextMenu.every((item) => item.disabled)) return <></>;

    let modalItem: IMenuItemWithModal | undefined = undefined;
    if (!!openModal) modalItem = getModalItem(contextModalItem, openModal);

    return (
        <>
            <ContextMenu
                baseZIndex={1000000}
                className="prime-tree-context-menu"
                model={contextMenu}
                ref={contextMenuRef}
                onHide={() => !openModal && handleSelectedContextNodeKey()}
            />

            {modalItem &&
                ((modalItem.addModal && selectedContextNodeKey == 0) || selectedContextNodeKey != undefined) &&
                modalItem.modal(refreshData, handleCloseModal, selectedContextNodeKey)}
        </>
    );
};
