import React, { createContext, useContext, useState } from "react";

import { MaterialsTabValuesType, materialsTabs } from "../interfaces/formulas-tabs";
import { IFormulaTable, IMaterialTable } from "../interfaces/interface";

export const MaterialsContext = createContext<any>(null);

export const MaterialsContextProvider = ({ children }) => {
    const [selectedMaterials, setSelectedMaterials] = useState<IMaterialTable[]>([]);
    const [selectedFormulas, setSelectedFormulas] = useState<IFormulaTable[]>([]);
    const [selectedTab, setSelectedTab] = useState<MaterialsTabValuesType>(materialsTabs.recipes);
    const [refreshMaterialTable, setRefreshMaterialTable] = useState<Function | undefined>(undefined);

    return (
        <MaterialsContext.Provider
            value={{
                selectedMaterials,
                setSelectedMaterials,
                selectedFormulas,
                setSelectedFormulas,
                selectedTab,
                setSelectedTab,
                refreshMaterialTable,
                setRefreshMaterialTable,
            }}
        >
            {children}
        </MaterialsContext.Provider>
    );
};

export const useMaterials = (): IUseMaterials => {
    const {
        selectedMaterials,
        setSelectedMaterials,
        selectedFormulas,
        setSelectedFormulas,
        selectedTab,
        setSelectedTab,
        refreshMaterialTable,
        setRefreshMaterialTable,
    } = useContext(MaterialsContext);

    return {
        selectedMaterials,
        setSelectedMaterials,
        selectedFormulas,
        setSelectedFormulas,
        selectedTab,
        setSelectedTab,
        refreshMaterialTable,
        setRefreshMaterialTable,
    };
};

interface IUseMaterials {
    selectedMaterials: IMaterialTable[];
    setSelectedMaterials: (selected: IMaterialTable[]) => void;
    selectedFormulas: IFormulaTable[];
    setSelectedFormulas: (selected: IFormulaTable[]) => void;
    selectedTab: MaterialsTabValuesType;
    setSelectedTab: (selected: MaterialsTabValuesType) => void;
    refreshMaterialTable: Function;
    setRefreshMaterialTable: (arg: Function) => void;
}
