import React, { Fragment, useEffect, useState } from "react";
import { Label, FormGroup, Col } from "reactstrap";
import DatePicker from "../../components/date-time-picker";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../utils/formatDate";

import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-GB";

import "../date-time-picker/style.scss";
import Icon from "../icon";

import Select from "react-select";
import { customStyles } from "../../components/custom-modal-elements/custom-modal-select";
import LabelError from "../label-error/label-error";
import { HideButton } from "./hide-button";
import { useCustomForm } from "../custom-form/context/context";
import { RequiredLabel } from "./required-label";

const languages = {
    pl: pl,
    en: en,
};

const CustomModalDateTimePicker = (props) => {
    const {
        i18n,
        t,
        handleChange,
        forLabel,
        labelText,
        name,
        format = "yyyy-MM-dd HH:mm",
        errors,
        selectedDate,
        showTimeSelect = undefined,
        timeIntervals = undefined,
        withPortal = undefined,
        colSm,
        labelSm,
        disabled = false,
        noLabel = false,
        onBlur = undefined,
        handleKeyDown = undefined,
        minDate = undefined,
        maxDate = undefined,
        autoFocus = false,
        startDate = undefined,
        endDate = undefined,
        selectsRange = false,
        inline = false,
        onCalendarClose = undefined,
        required = false,
    } = props;

    const years = Array.from({ length: 130 }, (_, i) => i + 1900).reverse();
    const yearsOpts = years.map((y) => ({ label: y, value: y }));

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const monthsOpts = months.map((m) => ({ label: t(m), value: m }));

    const { isHideMode } = useCustomForm();

    const [currentDate, setCurrentDate] = useState(new Date());
    const [isHidden, setIsHidden] = useState<boolean>(false);

    useEffect(() => {
        selectedDate && setCurrentDate(new Date(selectedDate));
    }, [selectedDate]);

    const handleChangeMonth = (direction: number) => {
        setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + direction)));
    };

    const handleClose = () => {
        selectedDate && setCurrentDate(new Date(selectedDate));
    };

    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                placeItems: "center",
            }}
        >
            {!prevMonthButtonDisabled && (
                <span
                    className="btn btn-custom-round inline-block mr-20"
                    onClick={() => {
                        decreaseMonth();
                        handleChangeMonth(-1);
                    }}
                >
                    <Icon name="chevron-left" />
                </span>
            )}

            <Select
                className="custom-gt-select w-100px"
                styles={customStyles}
                options={yearsOpts}
                onChange={(e) => changeYear(e.value)}
                placeholder={`${props.t("Year")}...`}
                value={yearsOpts.find((x) => x.value == currentDate.getFullYear())}
            />

            <Select
                className="custom-gt-select w-130px"
                styles={customStyles}
                options={monthsOpts}
                onChange={(e) => changeMonth(months.indexOf(e.value))}
                placeholder={`${props.t("Month")}...`}
                value={monthsOpts.find((x) => x.value == months[currentDate.getMonth()].toString())}
            />

            {!nextMonthButtonDisabled && (
                <span
                    className="btn btn-custom-round inline-block ml-20"
                    onClick={() => {
                        increaseMonth();
                        handleChangeMonth(1);
                    }}
                >
                    <Icon name="chevron-right" />
                </span>
            )}
        </div>
    );

    //const addHoursToDate = (d : Date, h : number) => d.setHours(d.getHours()+h)

    const dateChangeWrapper = (date) => {
        //  CLPBDEV-118
        //  THIS IS CURRENTLY DONE ON BACKEND AS DATABASE SEEMS TO HAVE PROBLEMS WITH TIMEZONES
        //  SEE: SERIALIZERS

        // date = addHoursToDate(date, 2)
        return handleChange(date, name);
    };
    if (isHidden && !isHideMode) return <></>;
    return (
        <FormGroup row>
            {noLabel ? null : (
                <Label for={name} sm={labelSm || 3}>
                    {t(labelText)}:{required && <RequiredLabel />}
                </Label>
            )}
            <Col sm={colSm}>
                {isHideMode && !required && <HideButton isHidden={isHidden} setIsHidden={setIsHidden} />}
                <DatePicker
                    name={name}
                    onChange={dateChangeWrapper}
                    className={`rui-datetimepicker form-control w-100 ${errors ? " is-invalid" : ""}`}
                    id={forLabel}
                    selected={selectedDate ? new Date(selectedDate) : false}
                    dateFormat={format}
                    placeholderText={`${t(labelText)}...`}
                    locale={languages[i18n.language]}
                    autoComplete="off"
                    // showTimeSelect={showTimeSelect}
                    timeIntervals={timeIntervals}
                    withPortal={withPortal}
                    showMonthDropdown
                    showYearDropdown
                    showTimeInput
                    dropdownMode="select"
                    disabled={disabled}
                    onBlur={onBlur}
                    onKeyDown={handleKeyDown}
                    renderCustomHeader={customHeader}
                    onCalendarClose={onCalendarClose ? onCalendarClose : handleClose}
                    timeInputLabel={t("Time") + ":"}
                    minDate={minDate}
                    maxDate={maxDate}
                    autoFocus={autoFocus}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange={selectsRange}
                    inline={inline}
                />
                {errors && <LabelError id={forLabel} error={errors} />}
            </Col>
        </FormGroup>
    );
};

export default withTranslation()(CustomModalDateTimePicker);
