import React, { createContext, useContext, useState } from "react";
import { IDocument, IFileVersion } from "../interfaces/interfaces";

export const DocumentRepositoryContext = createContext<any>(null);

export const DocumentRepositoryProvider = ({ children }) => {
    const [selectedFolderID, setSelectedFolderID] = useState<any>(undefined);
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
    const [selectedFileVersion, setSelectedFileVersion] = useState<IFileVersion[]>([]);
    const [expandedFolders, setExpandedFolders] = useState<{} | undefined>(undefined);
    const [treeRefresh, setTreeRefresh] = useState<boolean>(false);
    const [refreshDocumentsData, setRefreshDocumentsData] = useState<Function | undefined>(undefined);

    return (
        <DocumentRepositoryContext.Provider
            value={{
                selectedFolderID,
                setSelectedFolderID,
                expandedFolders,
                setExpandedFolders,
                treeRefresh,
                setTreeRefresh,
                selectedDocuments,
                setSelectedDocuments,
                selectedFileVersion,
                setSelectedFileVersion,
                refreshDocumentsData,
                setRefreshDocumentsData,
            }}
        >
            {children}
        </DocumentRepositoryContext.Provider>
    );
};

export const useDocumentRepository = (): IUseDocumentRepository => {
    const {
        selectedFolderID,
        setSelectedFolderID,
        expandedFolders,
        setExpandedFolders,
        treeRefresh,
        setTreeRefresh,
        selectedDocuments,
        setSelectedDocuments,
        selectedFileVersion,
        setSelectedFileVersion,
        refreshDocumentsData,
        setRefreshDocumentsData,
    } = useContext(DocumentRepositoryContext);

    return {
        selectedFolderID,
        setSelectedFolderID,
        expandedFolders,
        setExpandedFolders,
        treeRefresh,
        setTreeRefresh,
        selectedDocuments,
        setSelectedDocuments,
        selectedFileVersion,
        setSelectedFileVersion,
        refreshDocumentsData,
        setRefreshDocumentsData,
    };
};

interface IUseDocumentRepository {
    selectedFolderID: number | undefined;
    setSelectedFolderID: (selected: number | undefined) => void;
    expandedFolders: {} | undefined;
    setExpandedFolders: (selected: {}) => void;
    treeRefresh: boolean | undefined;
    setTreeRefresh: (selected: boolean) => void;
    selectedDocuments: IDocument[];
    setSelectedDocuments: (selected: IDocument[]) => void;
    selectedFileVersion: IFileVersion[];
    setSelectedFileVersion: (selected: IFileVersion[]) => void;
    refreshDocumentsData: Function;
    setRefreshDocumentsData: (selected: Function) => void;
}
