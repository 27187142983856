import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import React, { useMemo } from "react";

import { IFilterSelectInputProps } from "../interfaces/filter-interfaces";
import SuperscriptDisplay from "../../superscript/superscript-display";
import { memoFilter } from "./memoFilter";

// table header filter component, you send it in the column as filterBody prop
// eg. (filterBody: (inputData: IPrimeFilterData) => <MemoFilterSelectInput inputData={inputData} />)
export const MemoFilterSelectInput = memoFilter((props: IFilterSelectInputProps) => {
    const { column, filterArrayLength } = props.inputData;

    if (filterArrayLength < 2) return <FilterSelectInput {...props} />;
    else return <DisabledFilterSelectInput field={column.field} />;
});

export const FilterSelectInput = ({
    inputData,
    optionFilter = false,
    placeholder,
    clearable = true,
    superscript = false,
    searchType = "__exact",
}: IFilterSelectInputProps) => {
    const { t } = useTranslation();
    const { column, onFilterChange, filter, filterChoices } = inputData;

    const _filterChoices = superscript
        ? useMemo(() => {
              return filterChoices?.map((o, i) => ({
                  label: (
                      <div>
                          <SuperscriptDisplay key={`opt-${i}`} value={o.label} />
                      </div>
                  ),
                  stringValue: o.label.replace(/[↑↓→←]/gi, ""),
                  value: o.value,
              }));
          }, [filterChoices])
        : filterChoices;

    return (
        <div key={`prime-filter-input-${column.field}-container`} className="filter-select-input-container">
            <Dropdown
                key={`prime-filter-input-${column.field}`}
                className="custom-search-table-input prime-table-filter-input-font"
                name={column.field}
                value={filter?.value}
                options={_filterChoices || []}
                onChange={(e) => onFilterChange({ name: e.target.name, value: e.target.value }, searchType)}
                optionLabel="label"
                optionValue="value"
                placeholder={placeholder ? t(placeholder) : `${t("Filter")} ${t(column.header)}`}
                filter={optionFilter}
                showClear={clearable}
                emptyMessage={t("No records found")}
            />
        </div>
    );
};

interface IDisabledFilterSelectInput {
    field: string;
}

export const DisabledFilterSelectInput = ({ field }: IDisabledFilterSelectInput) => {
    const { t } = useTranslation();

    return (
        <div key={`prime-filter-input-${field}-container-disabled`} className="filter-select-input-container">
            <Dropdown
                key={`prime-filter-input-${field}-disabled`}
                className="custom-search-table-input prime-table-filter-input-font"
                placeholder={t("Advanced filter...")}
                disabled
            />
        </div>
    );
};
