/**
 * Styles
 */
import "./style.scss";

/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames/dedupe";
import Dropzone from "react-dropzone";
import { withTranslation } from "react-i18next";

/**
 * Internal Dependencies
 */
import Icon from "../icon";
import { fileSizeToHumanReadable } from "../../utils";

/**
 * Component
 */
class ComponentDropzone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: this.props.files || [],
        };

        this.onChange = this.handleChange.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.clearFiles != prevProps.clearFiles && this.props.clearFiles == 0) {
            this.setState({ files: [] });
        }
    }

    handleChange(chosenFilesLength) {
        const { onChange } = this.props;

        onChange(this.state.files, chosenFilesLength);
    }

    removeFile(file) {
        const newFiles = [...this.state.files];

        newFiles.splice(newFiles.indexOf(file), 1);

        this.setState(
            {
                files: newFiles,
            },
            () => this.handleChange(newFiles.length)
        );
    }

    render() {
        const { t, settings, emptyInputText } = this.props;

        return (
            <Dropzone
                {...this.props}
                disabled={typeof this.props.canUpload == "boolean" && !this.props.canUpload}
                onDrop={(newFiles) => {
                    const files = this.props.multiple ? [...this.state.files, ...newFiles] : newFiles;
                    const maxFiles = this.props.maxFiles;
                    const filesLength = files.length;

                    this.setState({ files: maxFiles && filesLength > maxFiles ? files.slice(filesLength - maxFiles) : files }, () =>
                        this.handleChange(files.length)
                    );
                }}
            >
                {(data) => {
                    const rootProps = data.getRootProps();
                    const inputProps = data.getInputProps();
                    const additionalProps = this.props.additionalInputProps;

                    return (
                        <div
                            className={classnames(
                                "rui-dropzone",
                                data.isDragActive ? "rui-dropzone-is-active" : "",
                                typeof this.props.canUpload == "boolean" && (this.props.canUpload ? "" : "rui-dropzone-cannot-upload"),
                                { [this.props.className]: !!this.props.className }
                            )}
                            {...rootProps}
                        >
                            <input {...inputProps} {...additionalProps} />
                            {this.state.files && this.state.files.length ? (
                                this.state.files.map((fileData) => {
                                    const fileExt = fileData.name.split(".").pop();

                                    return (
                                        <div key={fileData.name} className="rui-dropzone-preview">
                                            <div className="rui-dropzone-image">
                                                <img
                                                    src={settings.img_file[fileExt] || settings.img_file.empty}
                                                    className="icon-file"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="rui-dropzone-details">
                                                <div className="rui-dropzone-size">
                                                    <span>{fileSizeToHumanReadable(fileData.size)}</span>
                                                </div>
                                                <div className="rui-dropzone-filename">
                                                    <span>{fileData.name}</span>
                                                </div>
                                            </div>
                                            <button
                                                className="rui-dropzone-remove"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.removeFile(fileData);
                                                }}
                                            >
                                                <Icon name="x" />
                                            </button>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="rui-dropzone-message">
                                    <span className="rui-dropzone-icon">
                                        <Icon name="upload-cloud" />
                                    </span>
                                    <span className="rui-dropzone-text">
                                        {emptyInputText ? t(emptyInputText) : t("Drop files here or click to upload")}.
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                }}
            </Dropzone>
        );
    }
}

export default withTranslation()(
    connect(({ settings }) => ({
        settings,
    }))(ComponentDropzone)
);
