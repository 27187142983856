import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { FilterType, IFilterTextInputProps } from "../../interfaces/tree-interfaces";
import { useDebounce } from "../../../../hooks/useDebounce"; 
import { FilterTypePopup } from "./filter-type-popup";

import "../../../custom-modal-elements/custom-input-with-state.styles.scss"

// table header filter component, you send it in the column as filterBody prop
// eg. (filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />)
export const TreeFilterInput = (props: IFilterTextInputProps) => {
    const { filter, onFilterChange } = props;

    return useMemo(() => <FilterTextInput {...props} />, [filter, onFilterChange]);
};

const FilterTextInput = ({ onFilterChange, tableRef, filter, placeholder }: IFilterTextInputProps) => {
    const _value = filter?.value || "";
    const _filterType: FilterType = filter?.filterType || "__icontains";
    const _name = "tree-filter-input";

    const { t } = useTranslation();
    const inputRef = useRef<HTMLDivElement>(null);

    const [value, setValue] = useState<string>(_value);
    const [filterType, setFilterType] = useState<FilterType>(_filterType);
    const debounceValue = useDebounce(value, 500, 0);

    useEffect(() => {
        if (value != _value || (value == "" && _value != "")) {
            onFilterChange({ value: value, name: _name }, filterType);
        }
    }, [debounceValue]);

    useEffect(() => {
        _value != value && setValue(_value);
    }, [_value]);

    useEffect(() => {
        _filterType != filterType && setFilterType(filter?.filterType);
    }, [_filterType]);

    return (
        <div className="prime-tree-filter-input-container" ref={inputRef}>
            <Input
                name={_name}
                className="prime-tree-filter-input"
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                step="any"
            />
            <FilterTypePopup
                t={t}
                type="text"
                tableRef={tableRef}
                inputRef={inputRef}
                filterType={filterType}
                handleFilterTypeChange={(newFilterType) => {
                    setFilterType(newFilterType);
                    if (value.trim() != "") onFilterChange({ value: value, name: _name }, newFilterType);
                }}
            />
            <span onClick={() => setValue("")} className="pi pi-times tree-filter-input-clear-button" />
        </div>
    );
};
