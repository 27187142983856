export type ReleaseOrdersTabKeysType = "samples" | "additionalInformation" | "patient" | "attachmentsForOrder" | "crossResults" | "formulations";
export type ReleaseOrdersTabValuesType = "Samples" | "Additional informations" | "Patient" | "Attachments for order" | "Cross results" | "Formulations";

export type ReleaseOrdersTabs = {
    [key in ReleaseOrdersTabKeysType]: ReleaseOrdersTabValuesType;
};

export const releaseOrdersTabs: ReleaseOrdersTabs = {
    samples: "Samples",
    formulations: "Formulations",
    additionalInformation: "Additional informations",
    patient: "Patient",
    attachmentsForOrder: "Attachments for order",
    crossResults: "Cross results"
}; 