import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Label, Col } from "reactstrap";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

import { translateErrorString } from "utils/translateErrorString";
import { FONT_AWESOME_ICONS } from "./utils/font-awesome-icons";
import LabelError from "components/label-error/label-error";
import { useDebounce } from "hooks/useDebounce";
import { Icon } from "..";

import "./styles.scss";

interface IIconPickerProps {
    value?: string;
    name: string;
    onChange: (arg: { target: { value: string; name?: string } }) => void;
    popupCloseCondition?: (event) => boolean;
    readOnly?: boolean;
    errors?: any;
    forLabel?: string;
    className?: string;
    label?: string;
    labelSm?: number;
    autofocus?: boolean;
    disabled?: boolean;
    colSm?: number;
    placeholder?: string;
}

export const GtIconPicker = (props: IIconPickerProps) => {
    const { t } = useTranslation();
    const inputPopupRef = useRef<HTMLDivElement>(null);

    const {
        value,
        name,
        onChange,
        popupCloseCondition,
        readOnly = true,
        forLabel = "",
        className,
        label,
        labelSm = 3,
        colSm = label ? 9 : 12,
        autofocus,
        disabled,
    } = props;
    let { errors } = props;

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [externalFilterValue, setExternalFilterValue] = useState<string>("");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputPopupRef.current &&
                !inputPopupRef.current.contains(event.target) &&
                (popupCloseCondition ? popupCloseCondition(event) : true)
            ) {
                setIsPopupOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [inputPopupRef]);

    useEffect(() => {
        isPopupOpen && setIsPopupOpen(false);
    }, [value]);

    if (value && errors && errors[0] == "This field is required." && errors.length == 1) {
        errors = undefined;
    }

    const openPopUp = () => setIsPopupOpen(true);

    const handleChange = (icon) => {
        onChange({ target: { value: icon, name: name } });
    };

    const onDebounceFilterChange = (value) => setExternalFilterValue(value);

    return (
        <FormGroup row className={className}>
            {!!label && (
                <Label for={t(forLabel)} sm={labelSm}>
                    {t(label)}:
                </Label>
            )}
            <Col sm={colSm}>
                {value && (
                    <div className="selected-icon-container">
                        <Icon name={["fas", value]} />
                    </div>
                )}
                <InputText
                    disabled={disabled}
                    className={"prime-popup-icon-input"}
                    onClick={() => setIsPopupOpen(true)}
                    value={value || ""}
                    onChange={onChange}
                    readOnly={readOnly}
                    placeholder={`${t(label || "")}...`}
                    autoFocus={autofocus}
                />
                <div className="icon-input-popup-container" id="icon-input-popup-container">
                    <div ref={inputPopupRef} className={`icon-input-popup ${isPopupOpen ? "opened" : ""}`}>
                        {isPopupOpen && (
                            <>
                                <IconFilter t={t} openPopUp={openPopUp} onDebounceFilterChange={onDebounceFilterChange} />
                                <div className="icons-container">
                                    {FONT_AWESOME_ICONS.filter((icon) => icon.label.startsWith(externalFilterValue)).map((icon) => (
                                        <div className="icon-container" onClick={() => handleChange(icon.value)}>
                                            <Icon name={["fas", icon.value]} />
                                            <p>{icon.label}</p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {errors && <LabelError id={forLabel} error={translateErrorString(errors, t)} />}
            </Col>
        </FormGroup>
    );
};

const IconFilter = ({ t, openPopUp, onDebounceFilterChange }) => {
    const [filterValue, setFilterValue] = useState<string>("");

    const debounceFilterValue = useDebounce(filterValue);

    useEffect(() => {
        onDebounceFilterChange(debounceFilterValue);
    }, [debounceFilterValue]);

    return (
        <InputText
            className="prime-popup-icon-input"
            onClick={openPopUp}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={`${t("Filter icons")}...`}
        />
    );
};
