import React, { createContext, useContext, useRef, useState } from "react";

import { orderModes, OrderModesTypes } from "../../utils/modes";
import { ISample } from "../../../Samples/interface";

export const OrderResultsContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.results;
export const OrderResultsContextProvider = ({ children }) => {
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);

    const resultsRef = useRef<{ refresh: () => void } | null>(null);
    return (
        <OrderResultsContext.Provider
            value={{
                selectedSamples,
                setSelectedSamples,
                mode,
                resultsRef,
            }}
        >
            {children}
        </OrderResultsContext.Provider>
    );
};

export const useOrderResults = (): IUseOrderResults => {
    const { selectedSamples, setSelectedSamples, mode, resultsRef } = useContext(OrderResultsContext);

    return { selectedSamples, setSelectedSamples, mode, resultsRef };
};

interface IUseOrderResults {
    selectedSamples: ISample[];
    setSelectedSamples: Function;
    mode: OrderModesTypes;
    resultsRef: React.MutableRefObject<{ refresh: () => void } | null>;
}
