import React, { createContext, useContext, useState } from "react";

import { IMethodTable } from "../interfaces/interfaces";

export const MethodsContext = createContext<any>(null);

export const MethodsContextProvider = ({ children }) => {
    const [selectedMethods, setSelectedMethods] = useState<IMethodTable[]>([]);

    return <MethodsContext.Provider value={{ selectedMethods, setSelectedMethods }}>{children}</MethodsContext.Provider>;
};

export const useMethods = (): IUseMethods => {
    const { selectedMethods, setSelectedMethods } = useContext(MethodsContext);

    return { selectedMethods, setSelectedMethods };
};

interface IUseMethods {
    selectedMethods: IMethodTable[];
    setSelectedMethods: (arg: IMethodTable[]) => void;
}
