import React from "react";

import { IMultiSelectPlaceholder } from "../interfaces/placeholders-interfaces";
import SuperscriptDisplay from "../../superscript/superscript-display";
import { TooltipHOC } from "components/index";

export const MultiItemPlaceholder = ({
    rowData,
    value,
    separator = " • ",
    translate = false,
    superScript = false,
}: IMultiSelectPlaceholder) => {
    if ((!value || (value && value.length == 0)) && rowData.row[rowData.fieldName]?.length == 0) return <></>;

    let _value: string[] | number[] = value || rowData.row[rowData.fieldName];

    if (translate) _value = (_value as string[])?.map((el) => rowData.translate(el));

    const convertedValue: string = _value?.join(separator);

    return (
        <TooltipHOC tooltipContent={convertedValue} className="cell-tooltip">
            {superScript ? <SuperscriptDisplay value={convertedValue} /> : convertedValue}
        </TooltipHOC>
    );
};
