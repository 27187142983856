import React, { useEffect, useState } from "react";

export const useMountTransition = (isMounted, unmountDelay = 200) => {
    const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

    useEffect(() => {
        let timeoutId;

        if (isMounted && !hasTransitionedIn) {
            setTimeout(() => setHasTransitionedIn(true), 0);
        } else if (!isMounted && hasTransitionedIn) {
            timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [unmountDelay, isMounted, hasTransitionedIn]);

    return hasTransitionedIn;
};
