import { useTranslation } from "react-i18next";

import { TextInputType } from "../interfaces/edit-interfaces";
import { FilteringType } from "../interfaces";

type FilterInputType = "date" | TextInputType;
type IFilterLabelValue = ILabelValue<FilteringType>;

const filterOptions: IFilterLabelValue[] = [
    { label: "Exact", value: "__exact" },
    { label: "Includes", value: "__icontains" },
    { label: "Lower then", value: "__lt" },
    { label: "Lower or equal", value: "__lte" },
    { label: "Greater then", value: "__gt" },
    { label: "Greater or equal", value: "__gte" },
];

const textFilterOptions: IFilterLabelValue[] = [
    { label: "Includes", value: "__icontains" },
    { label: "Exact", value: "__exact" },
];

const dateFilterOptions: IFilterLabelValue[] = [
    { label: "Range", value: "__range" },
    { label: "Exact", value: "__icontains" },
    { label: "Includes", value: "__icontains" },
    { label: "Lower then", value: "__lt" },
    { label: "Lower or equal", value: "__lte" },
    { label: "Greater then", value: "__gt" },
    { label: "Greater or equal", value: "__gte" },
];

export const getFilterOptions = (type: FilterInputType): IFilterLabelValue[] => {
    const { t } = useTranslation();
    let _filterOptions: IFilterLabelValue[] = [];

    if (type == "text") _filterOptions = textFilterOptions;
    else if (type == "date") _filterOptions = dateFilterOptions;
    else _filterOptions = filterOptions;

    return _filterOptions.map((o) => ({ ...o, label: t(o.label) }));
};
