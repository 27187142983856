import { ModalFooter } from "reactstrap";
import React from "react";

import CustomButton from "../../components/custom-button/custom-button";
import hasPermission from "../../utils/hasPermissions";

interface IModalFooter {
    closeModal: Function;
    cancelName?: string;
    reqModal?: boolean;
    okName?: string;
    isBusy?: boolean;
    okIsBusy?: boolean;
    cancelIsBusy?: boolean;
    okIsDisabled?: boolean;
    additionalButtons?: any;
    permCodeOk?: any;
    onClick?: Function;
    okBtnCyId?: string;
    className?: string;
}

const CustomModalFooter = (props: IModalFooter) => {
    const {
        closeModal,
        cancelName,
        okName,
        reqModal,
        isBusy,
        okIsBusy,
        cancelIsBusy,
        okIsDisabled,
        additionalButtons,
        permCodeOk = undefined,
        onClick = undefined,
        className,
        okBtnCyId,
    } = props;

    let enableOk = permCodeOk == undefined ? true : hasPermission(permCodeOk);

    const handleClick = (e, reqParams: IReqParams) => {
        onClick && onClick(e, reqParams);
    };

    return (
        <ModalFooter className={className}>
            {additionalButtons}
            {okName && enableOk && (
                <CustomButton
                    permCode={permCodeOk}
                    isBusy={isBusy || okIsBusy || false}
                    type={onClick ? "button" : "submit"}
                    text={okName}
                    disabled={okIsDisabled}
                    onClick={handleClick}
                    withoutPerm={!permCodeOk}
                    reqModal={reqModal}
                    cyId={okBtnCyId}
                />
            )}
            {cancelName && (
                <CustomButton
                    isBusy={isBusy}
                    disabled={isBusy || cancelIsBusy || false}
                    text={cancelName}
                    className="mr-0 cancel-modal-button"
                    color="secondary"
                    onClick={closeModal}
                    withoutPerm
                />
            )}
        </ModalFooter>
    );
};

export default CustomModalFooter;
