export type MaterialsTabKeysType = "recipes" | "qualityControl" | "pictogram" | "metadata";
export type MaterialsTabValuesType = "Recipes" | "Quality Control" | "Pictogram" | "Metadata";

export type MaterialsTabs = {
    [key in MaterialsTabKeysType]: MaterialsTabValuesType;
};

export const materialsTabs: MaterialsTabs = {
    recipes: "Recipes",
    qualityControl: "Quality Control",
    pictogram: "Pictogram",
    metadata: "Metadata",
};
