import React from "react";

import { MemoFilterSelectInput } from "components/prime-data-table/filter-body-components/filter-select-input";
import { MemoFilterTextInput } from "components/prime-data-table/filter-body-components/filter-text-input";
import { EditSelectInput } from "components/prime-data-table/edit-body-components/edit-select-input";
import { ISamplingPointTable } from "../../../../../../BasicSettings/SamplingSites/interfaces/interfaces";
import PrettyCell from "components/prime-data-table/body-components/pretty-cell/pretty-cell";
import {
    IPrimeEditData,
    IPrimeTableColumn,
    IPrimeRowData,
} from "components/prime-data-table/interfaces";
import { IPrimeFilterData } from "components/prime-data-table/interfaces";

export const samplingPointsColumns: IPrimeTableColumn[] = [
    {
        header: "Name",
        field: "name",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "External code",
        field: "externalCode",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "Status",
        field: "status",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterSelectInput inputData={inputData} />,
        body: (rowData: IPrimeRowData<ISamplingPointTable>) => <PrettyCell text={rowData.row.status} />,
    },
    {
        header: "Address",
        field: "address",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "Zip code",
        field: "zipCode",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "City",
        field: "city",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "Country",
        field: "country",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
    },
    {
        header: "Metadata template",
        field: "metadataTemplate.name",
        sortable: true,
        filterBody: (inputData: IPrimeFilterData) => <MemoFilterTextInput inputData={inputData} />,
        editBody: (inputData: IPrimeEditData<ISamplingPointTable>) => (
            <EditSelectInput
                value={inputData.row.metadataTemplate?.id}
                inputData={inputData}
                name="template"
                CustomPlaceholder={() => <>{inputData.row.metadataTemplate?.name}</>}
            />
        ),
    },
];
