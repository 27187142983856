import React from "react";

import loadable from "@loadable/component";
import { Spinner } from "reactstrap";

export default function AsyncComponent(props) {
    const LoadableComponent = loadable(props.component, {
        fallback: <Fallback />,
    });

    return <LoadableComponent />;
}

const Fallback = () => {
    return (
        <Spinner color="secondary" type="grow">
            Loading...
        </Spinner>
    );
};
