import React from "react";
import "./show-similarity-options.scss";
import { createPortal } from "react-dom";
import SuperscriptDisplay from "../superscript/superscript-display";

export const ShowSimilarityOptions = ({ values, inputRef }) => (
    <>
        {!!values?.length &&
            createPortal(
                <div
                    className="similar-wrapper"
                    style={{
                        top: inputRef?.current.getBoundingClientRect().y + inputRef.current.getBoundingClientRect().height + 10 ,
                        left: inputRef?.current.getBoundingClientRect().x,
                        maxWidth: inputRef?.current.getBoundingClientRect().width,
                    }}
                >
                    {values?.map((x, idx) => (
                        <SuperscriptDisplay value={x} key={idx} className="similar-option"/>
                    ))}
                </div>,
                document.body
            )}
    </>
);
