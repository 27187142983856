// sort by key in the object, you can only sort by strings
// example of use options.sort((a, b) => sortObj(a, b, "label"))
export const sortObj = (a, b, key: string, ascending: boolean = false) => {
    a = String(a[key]);
    b = String(b[key]);
    if (ascending) {
        if (a.toUpperCase() < b.toUpperCase()) {
            return -1;
        }
        if (a > b.toUpperCase()) {
            return 1;
        }
        return 0;
    }
    if (a.toUpperCase() > b.toUpperCase()) {
        return -1;
    }
    if (a < b.toUpperCase()) {
        return 1;
    }
    return 0;
};
