import React, { createContext, useContext, useState } from "react";

import { orderModes, OrderModesTypes } from "../../utils/modes";
import { ISample } from "../../../Samples/interface";

export const OrderReleaseResultsContext = createContext<any>(null);

const mode: OrderModesTypes = orderModes.releaseResults;
export const OrderReleaseResultsContextProvider = ({ children }) => {
    const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);

    const [refreshSamplesData, setRefreshSamplesData] = useState<Function | undefined>(undefined);

    return (
        <OrderReleaseResultsContext.Provider
            value={{ selectedSamples, setSelectedSamples, refreshSamplesData, setRefreshSamplesData, mode }}
        >
            {children}
        </OrderReleaseResultsContext.Provider>
    );
};

export const useOrderReleaseResults = (): IUseOrderReleaseResults => {
    const { selectedSamples, setSelectedSamples, refreshSamplesData, setRefreshSamplesData, mode } = useContext(OrderReleaseResultsContext);

    return { selectedSamples, setSelectedSamples, refreshSamplesData, setRefreshSamplesData, mode };
};

interface IUseOrderReleaseResults {
    selectedSamples: ISample[];
    setSelectedSamples: Function;
    refreshSamplesData: Function;
    setRefreshSamplesData: Function;
    mode: OrderModesTypes;
}
