export const metadataUrls = {
    templates: {
        main: (groupId?: number) => (groupId ? `metadata/templates/${groupId}` : "metadata/templates"),
        formData: "metadata/template-form-data",
    },
    version: {
        main: (templateId?: number) => (templateId ? `metadata/versions/${templateId}` : "metadata/versions"),
        formData: (templateId?: number) => (templateId ? `metadata/version-form-data/${templateId}` : "metadata/version-form-data"),
    },

    getIds: (instanceId: number) => `metadata/version-template-ids/${instanceId}`,
};
