export type OrderModesTypes =
    | "register"
    | "sampling"
    | "receive"
    | "prepare"
    | "receive-in-lab"
    | "results"
    | "release-results"
    | "release-samples"
    | "release-orders"
    | "edit"
    | "preview";

type OrderModesKeys =
    | "register"
    | "sampling"
    | "receive"
    | "prepare"
    | "receiveInLab"
    | "results"
    | "releaseResults"
    | "releaseSamples"
    | "releaseOrders"
    | "edit"
    | "preview";

type IOrderModes = {
    [key in OrderModesKeys]: OrderModesTypes;
};

export const orderModes: IOrderModes = {
    register: "register",
    sampling: "sampling",
    receive: "receive",
    prepare: "prepare",
    receiveInLab: "receive-in-lab",
    results: "results",
    releaseResults: "release-results",
    releaseSamples: "release-samples",
    releaseOrders: "release-orders",
    edit: "edit",
    preview: "preview",
};
