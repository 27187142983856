import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, ModalBody } from "reactstrap";

import CustomModalHeader from "../../components/custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../components/custom-modal-elements/custom-modal-footer";
import CustomModalSelect from "../../components/custom-modal-elements/custom-modal-select";
import { IErrors } from "../../pages/interfaces/IErrors";
import addToast from "../../utils/addToast";
import { connect } from "react-redux";
import { updateAuth as actionUpdateAuth } from "../../actions";
import CustomForm from "../custom-form/custom-form";

const CustomNavbarLaboratoryModal = (props) => {
    const [labData, setLabData] = useState([] as any);
    const [errors, setErrors] = useState({} as IErrors);
    const [selectedLab, setSelectedLab] = useState(null as any);

    useEffect(() => {
        if (props.modalOpen) {
            axios.get("sign-in/laboratories").then((response) => {
                setLabData(response.data.laboratories);
                let selected = response.data.laboratories.find((obj) => obj.label == props.labName);
                setSelectedLab(selected);
                setErrors({});
            });
        }
    }, [props.modalOpen]);

    const handleSubmitData = async (e) => {
        e.preventDefault();

        if (selectedLab == undefined) {
            setErrors({ selectedLab: "This field may not be null" });
            return;
        }

        const sendState = { selectedLab: selectedLab.value };

        try {
            await axios.post("change-lab", sendState);
            props.closeModal();
            props.updateAuth({ laboratory: selectedLab.label });
            addToast({
                title: "Laboratory has been changed",
            });
        } catch (err) {
            if (err.response?.status == "400") setErrors(err.response.data);
        }
    };

    const handleChangeLab = (e) => {
        let selected = labData.find((obj) => obj.value == e.target.value);
        setSelectedLab(selected);
    };

    return (
        <>
            <Modal isOpen={props.modalOpen} toggle={props.closeModal} fade>
                <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData}>
                    <CustomModalHeader closeModal={props.closeModal} name="Change laboratory" />
                    <ModalBody>
                        <CustomModalSelect
                            errors={errors.selectedLab}
                            labelName={"Laboratory"}
                            options={labData}
                            handleChange={handleChangeLab}
                            name="selectedLab"
                            value={selectedLab}
                        />
                    </ModalBody>
                    <CustomModalFooter closeModal={props.closeModal} cancelName="Cancel" okName="change" />
                </CustomForm>
            </Modal>
        </>
    );
};

export default connect(({ auth }) => ({ auth }), { updateAuth: actionUpdateAuth })(CustomNavbarLaboratoryModal);
