import { IAdvancedFilterConfig, IPrimeTableColumn } from "../interfaces/table-interfaces";
import { IPrimeFilterData } from "../interfaces/filter-interfaces";
import { convertDotsToUnderscores } from "./primeHelpers";

export type IAdvancedFiltersOptionsType = "text" | "number" | "dropdown" | "date" | "checkbox";
export interface IAdvancedFiltersOptions {
    filterLabel: string;
    filterField: string;
    filterOptionConfig?: {
        appModelPath: string;
        field: string;
    };
    type: IAdvancedFiltersOptionsType;
}

export const generateAdvancedFiltersOptions = (
    advancedFiltersConfig: IAdvancedFilterConfig[],
    t: (arg: string) => string
): IAdvancedFiltersOptions[] => {
    let filterColumns: IAdvancedFiltersOptions[] = [];

    advancedFiltersConfig.forEach((configObj) => {
        const columns = [...configObj.columns].map((col) => ({ ...col, header: t(col.header) })).sort((a, b) => a.header.localeCompare(b.header));

        const prefixFilterLabel = t(configObj.prefix?.filterLabel || "");
        const prefixFilterField = configObj.prefix?.filterField;

        columns.forEach((col) => {
            const filterField = convertDotsToUnderscores(col.filterFieldName ? col.filterFieldName : col.field);

            let baseFilterColumn: IAdvancedFiltersOptions = {
                ...(col.filterOptionConfig && {
                    filterOptionConfig: {
                        appModelPath: `${col.filterOptionConfig.app}.${col.filterOptionConfig.model}`,
                        field: col.filterOptionConfig.field ? col.filterOptionConfig.field : col.field,
                    },
                }),
                type: getFilterOptionType(col),
            } as IAdvancedFiltersOptions;

            if (configObj.prefix) {
                filterColumns.push({
                    ...baseFilterColumn,
                    filterLabel: `[${prefixFilterLabel}]: ${col.header}`,
                    filterField: `${prefixFilterField}.${filterField}`,
                });
            } else {
                filterColumns.push({ ...baseFilterColumn, filterLabel: col.header, filterField: filterField });
            }
        });
    });

    return filterColumns;
};

const getFilterOptionType = (col: IPrimeTableColumn): IAdvancedFiltersOptionsType => {
    if (col?.filterBody) {
        switch (col.filterBody({} as IPrimeFilterData).type.name) {
            case "MemoFilterTextInput":
                return col.filterBody({} as IPrimeFilterData).props.type == "text" ? "text" : "number";
            case "MemoFilterSelectInput":
                return "dropdown";
            case "MemoFilterCheckboxInput":
                return "checkbox";
            case "MemoFilterDateRangeInput":
                return "date";
            default:
                return "text";
        }
    } else return "text";
};
