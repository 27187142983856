import React from "react";

import { handleDeleteTableData } from "utils/handleDeleteTableData";
import { sweetConfirm } from "components/sweet-alert/sweetConfirm";
import { mapToIds } from "components/prime-data-table/helpers";
import { Button } from "..";

interface IDeleteButtonProps {
    url: string;
    instanceName: string;
    disabled: boolean;
    instances: any[];
    permCode: string;
    sweetConfirm?: {
        title: string;
        content: string;
        count?: string | number;
    };
    refreshData: () => void;
    onDelete?: (response) => void;
}

export const DeleteButton = (props: IDeleteButtonProps) => {
    const { url, instanceName, sweetConfirm: sweetConfirmProps, disabled, instances, permCode, refreshData, onDelete } = props;

    const handleDelete = async (e, reqParams) => {
        if (
            sweetConfirmProps &&
            !(await sweetConfirm(sweetConfirmProps.title, { text: sweetConfirmProps.content, count: sweetConfirmProps.count }))
        )
            return;

        await handleDeleteTableData(url, instanceName, mapToIds(instances), refreshData, undefined, reqParams, onDelete);
    };

    return <Button permCode={permCode} text="Delete" color="danger" onClick={handleDelete} disabled={disabled} icon="trash" reqModal />;
};
