import React, { SyntheticEvent, useMemo, useState } from "react";
import { ContextMenu } from "primereact/contextmenu";
import { MenuItem } from "primereact/menuitem";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Axios from "axios";

import { getColumnField, getColumnToGroupBy, getVisibleAndHiddenColumns } from "../../helpers/context-menu-helpers";
import { AddEditAdditionalColumnsModal } from "../modals/add-edit-additional-columns-modal";
import { IMetaAppTypeTable } from "pages/BasicSettings/MetaAppTypes/interfaces/interfaces";
import { generateMenuModel } from "components/prime-data-table/helpers/generateMenuModel";
import { CreateMetaAppRecordModal } from "../modals/create-meta-app-record-modal";
import { metaAppsUrls } from "components/MetaApps/utils/meta-apps-urls";
import { PrimeFreezeColumnModal } from "../modals/column-freeze-modal";
import { PrimeColumnGroupModal } from "../modals/column-group-modal";
import { HideColumnsModal } from "../modals/hide-columns-modal";
import { PrimeTranslateModal } from "../modals/translate-modal";
import { AuditTable } from "../prime-audit-window/audit-table";
import { handleRequest } from "utils/handleRequest";
import { deprecated_useFormData } from "hooks";

import * as CI from "../../interfaces/context-interfaces";
import * as I from "../../interfaces/table-interfaces";

import "./styles.scss";

export const PrimeContextMenu = (props: CI.IPrimeContextMenu) => {
    const {
        // Props needed for context-menu
        contextMenuRef,
        contextMenuItems,
        // Props that provide data
        auditData,
        selectedRecord,
        dataSet,
        multiSort,
        tableConfig,
        meta,
        // Enabled/disabled
        exportCSVEnabled,
        hidingColumnEnabled,
        groupRowsEnabled = false,
        filterEnabled,
        frozenColumnEnabled = false,
        translateDisabled,
        additionalColumnsEnabled,
        // Columns
        defaultColumns,
        visibleColumns,
        // handlers
        onRestoreColumns: handleRestoreColumns,
        onGroupBy: handleGroupBy,
        onHideColumns: handleHideColumns,
        onToggleFilters: handleToggleFilters,
        onFreezeColumn: handleFreezeColumn,
        onContextColumnFieldChange: handleContextColumnFieldChange,
        onAdditionalColumns: handleAdditionalColumns,
    } = props;

    const { t } = useTranslation();

    const { metaAppsModels } = useSelector((state) => ({ metaAppsModels: state.metaAppTypes.map((metaApp) => metaApp.model) }));

    const { formData: metaAppTypes } = deprecated_useFormData<CI.IPrimeContextMenuMetaAppTypes[], IMetaAppTypeTable[]>({
        url: metaAppsUrls.metaAppType(),
        mapFormData: (data) => data.map((metaAppType) => ({ name: metaAppType.name, status: metaAppType.status })),
        shouldFetch: !!(meta?.ModelInfo && metaAppsModels.includes(meta.ModelInfo.appModelLabel)),
        params: { params: { search_field: "model_name__exact", search: meta?.ModelInfo?.appModelLabel } },
        defaultEmptyValue: [],
    });

    const [modalOpen, setModalOpen] = useState<CI.IContextMenuModals | undefined>(undefined);

    // columnField description:
    // - if context menu closed,columnField is set to undefined
    // - if context menu open, columnField hold column field, on which context menu was open (but only if it is found)
    // - if context menu open, but column field is not found columnField is set to null,
    //   when it is set to null we know that some of the context menu functionality should be disabled
    const [columnField, setColumnField] = useState<CI.ColumnFieldType>(undefined);

    const openModal = (modalName: CI.ContextMenuModalsTypes, additionalData: any = undefined) =>
        additionalData ? setModalOpen({ [modalName]: true, additionalData: additionalData }) : setModalOpen({ [modalName]: true });

    const onHideColumn = () => {
        if (typeof columnField != "string") return;

        const selectedVisibleColumns = visibleColumns
            .filter((visibleCol) => visibleCol.field != columnField)
            .map((filteredCol) => filteredCol.field);

        const columns = getVisibleAndHiddenColumns(defaultColumns, visibleColumns, selectedVisibleColumns);

        handleHideColumns(columns.visibleColumns, columns.hiddenColumns);
    };

    const onGroupByColumn = () => {
        if (typeof columnField != "string") return;

        const groupBy = getColumnToGroupBy(
            visibleColumns.filter((visibleCol) => visibleCol.sortable),
            columnField
        );

        groupBy && handleGroupBy && handleGroupBy(groupBy);
    };

    const onFreezeColumn = () => {
        if (typeof columnField != "string") return;
        visibleColumns.some((visibleCol, index) => visibleCol.field == columnField && handleFreezeColumn(visibleCol, index));
    };

    const onDeleteAdditionalColumn = async (columnField) => {
        if (!columnField || !handleAdditionalColumns) return;

        let _extraFields: I.ExtraFields = { ...meta?.ExtraFields };

        delete _extraFields[columnField];
        await handleRequest({
            request: () =>
                Axios.post("upsert-extra-fields-info", {
                    info: _extraFields,
                    modelLabel: meta?.ModelInfo?.appModelLabel,
                }),
            onSuccess: () =>
                handleAdditionalColumns({
                    payload: { extraField: columnField as I.ExtraFieldsKeys },
                    action: I.ADDITIONAL_COLUMNS_EVENT_ACTION.DELETE,
                }),
            sweetConfirmConfig: {
                title: "Delete additional column",
                content: "This operation is not reversible.",
            },
        });
    };

    const closeModal = () => setModalOpen(undefined);

    const menuModel: MenuItem[] = useMemo(
        () =>
            generateMenuModel(
                t,
                {
                    openModal,
                    handleToggleFilters,
                    onHideColumn,
                    onGroupByColumn,
                    onFreezeColumn,
                    handleGroupBy,
                    handleFreezeColumn,
                    onDeleteAdditionalColumn,
                    handleRestoreColumns,
                },
                {
                    auditData,
                    exportCSVEnabled,
                    dataSet,
                    visibleColumns,
                    filterEnabled,
                    tableConfig,
                    hidingColumnEnabled,
                    groupRowsEnabled,
                    frozenColumnEnabled,
                    additionalColumnsEnabled,
                    columnField,
                    multiSort,
                    translateDisabled,
                    meta,
                    contextMenuRef,
                    contextMenuItems,
                    metaAppTypes,
                }
            ),
        [columnField]
    );

    const handleShow = (e: SyntheticEvent) => {
        const _columnField = getColumnField(e.target);

        handleContextColumnFieldChange && handleContextColumnFieldChange(_columnField);
        setColumnField(_columnField);
    };

    const handleClose = () => {
        handleContextColumnFieldChange && handleContextColumnFieldChange(undefined);
        setColumnField(undefined);
    };

    return (
        <>
            <ContextMenu
                baseZIndex={10000}
                className="prime-table-context-menu"
                model={menuModel}
                ref={contextMenuRef}
                onShow={handleShow}
                onHide={handleClose}
            />
            {modalOpen?.hideColumns && (
                <HideColumnsModal
                    defaultColumns={defaultColumns}
                    visibleColumns={visibleColumns}
                    handleHideColumns={handleHideColumns}
                    closeModal={closeModal}
                />
            )}
            {modalOpen?.groupRows && (
                <PrimeColumnGroupModal
                    multiSort={multiSort}
                    visibleColumns={visibleColumns}
                    handleGroupBy={handleGroupBy}
                    closeModal={closeModal}
                />
            )}
            {modalOpen?.auditData && (
                <AuditTable auditData={auditData} instance={selectedRecord} tableColumns={defaultColumns} closeModal={closeModal} />
            )}
            {modalOpen?.freezeColumn && (
                <PrimeFreezeColumnModal
                    frozenColumn={tableConfig.frozenColumn}
                    visibleColumns={visibleColumns}
                    handleFreezeColumn={handleFreezeColumn}
                    closeModal={closeModal}
                />
            )}
            {modalOpen?.translation && (
                <PrimeTranslateModal closeModal={closeModal} selectedRecord={selectedRecord} meta={meta as I.IMeta} />
            )}
            {modalOpen?.createMetaAppRecord && (
                <CreateMetaAppRecordModal
                    closeModal={closeModal}
                    selectedRecordId={selectedRecord?.id}
                    metaAppTypeName={modalOpen.additionalData}
                />
            )}
            {modalOpen?.addAdditionalColumns && handleAdditionalColumns && (
                <AddEditAdditionalColumnsModal
                    mode="add"
                    closeModal={closeModal}
                    handleAdditionalColumns={handleAdditionalColumns}
                    columnField={columnField || ""}
                    meta={meta as I.IMeta}
                />
            )}
            {modalOpen?.editAdditionalColumns && handleAdditionalColumns && (
                <AddEditAdditionalColumnsModal
                    mode="edit"
                    closeModal={closeModal}
                    handleAdditionalColumns={handleAdditionalColumns}
                    columnField={modalOpen?.additionalData}
                    meta={meta as I.IMeta}
                />
            )}
        </>
    );
};
