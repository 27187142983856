import { mapToIds } from "./primeHelpers";

export const handleSelection = (event: any, dataSet: any, selection: any[], onSelect: (arg: any[]) => void, idSelector: string) => {
    const { originalEvent, value } = event;

    if (Array.isArray(value)) {
        //! this resolves bug when table start with autoselected record, then click with shift is treaded
        //! as the first click and shift key selection functionality does not work
        if (originalEvent.shiftKey && selection.length == 1) {
            const firstRecordIndex = dataSet.indexOf(value[0]);
            const lastRecordIndex = dataSet.indexOf(value[value.length - 1]);

            if (firstRecordIndex < lastRecordIndex) {
                const recordsToSave = dataSet.filter((record, index) => {
                    if (index >= firstRecordIndex && index <= lastRecordIndex) return record;
                });
                onSelect(mapToIds(recordsToSave, idSelector));
                return;
            }
        }
        onSelect(mapToIds(value, idSelector));
    } else {
        onSelect(!!value ? mapToIds([value], idSelector) : []);
    }
};
